import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import axios from "axios";
import {baseUrl} from "../../../util/keys";

import {
    URL_CLIENT_COUNTRIES_LIST,
    URL_SURVEY_GET_RECORD_BY_ID,
    URL_SURVEY_NEW_SURVEY_GENERATE_WEB_URL,
    URL_SURVEY_NEW_SURVEY_GET_ALL_RECORDS,
    URL_SURVEY_NEW_SURVEY_GET_QUESTIONS,
    URL_SURVEY_NEW_SURVEY_SAVE_INFO,
    URL_SURVEY_UPDATE_STATUS,
    URL_SURVEY_SAVE_CLOSING_MESSAGE,
    URL_SURVEY_SAVE_OPENING_MESSAGE,
    URL_SURVEY_SAVE_REDEPLOY,
    URL_SURVEY_REDEPLOY_GET_SERIES_QUESTIONS,
    URL_SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS,
    URL_SURVEY_REDEPLOY_GET_QUESTION_SUMMARY
} from "../../../constants/Urls";

import AppUtils from "../../../util/util";

import {
    getSurveyTemplateQuestionsError,
    getSurveyTemplateQuestionsSuccess,
} from "../../../actions/survey/templates/ActionsSurveyTemplates";
import {
    SURVEY_CLIENT_COUNTRIES,
    SURVEY_GET_NEXT_LIST,
    SURVEY_GET_SURVEY_BY_ID,
    SURVEY_INFO_UPDATE_STATUS,
    SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL,
    SURVEY_NEW_SURVEY_GET_ALL_RECORDS,
    SURVEY_NEW_SURVEY_GET_QUESTIONS,
    SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO,
    SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_UPDATE,
    SURVEY_NEW_CLOSING_MESSAGE_SAVE,
    SURVEY_NEW_OPENING_MESSAGE_SAVE,
    SURVEY_REDEPLOY_SURVEY,
    SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS,
    SURVEY_REDEPLOY_GET_SERIES_QUESTIONS,
    SURVEY_REDEPLOY_GET_QUESTION_SUMMARY
} from "../../../constants/actions/survey/survey/actionTypesSurvey";

import {
    generateSurveyWebUrlError,
    generateSurveyWebUrlSuccess,
    getSurveyAllRecordsError,
    getSurveyAllRecordsSuccess,
    getSurveyByIdError,
    getSurveyByIdSuccess,
    getSurveyPaginatedListError,
    getSurveyPaginatedListSuccess,
    saveNewSurveyInfoError,
    saveNewSurveyInfoSuccess,
    updateSurveyInfoStatusError,
    updateSurveyInfoStatusSuccess,
    saveSurveyClosingMessageSuccess,
    saveSurveyOpeningMessageSuccess,
    saveSurveyClosingMessageError,
    saveSurveyOpeningMessageError,
    redeploySurveySuccess,
    redeploySurveyError,
    getSurveyRedeploySeriesQuestionsSuccess,
    getSurveyRedeploySeriesQuestionsError,
    saveSurveyRedeploySeriesQuestionsSuccess,
    saveSurveyRedeploySeriesQuestionsError,
    getSurveyRedeployQuestionSummarySuccess,
    getSurveyRedeployQuestionSummaryError
} from "../../../actions/survey/survey/ActionsSurvey";

import {
    getClientCountriesError,
    getClientCountriesSuccess
} from "../../../actions/survey/util/ActionsSurveyInfoUtils";


/************ methods : http *******************/
/** http : Survey : http save **/
const httpSaveNewSurveyInfo = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_NEW_SURVEY_SAVE_INFO, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey : http update **/
const httpUpdateSurveyInfo = async (data) =>
    await axios.patch(baseUrl + URL_SURVEY_NEW_SURVEY_SAVE_INFO, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey : get all records **/
const httpGetSurveyList = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_NEW_SURVEY_GET_ALL_RECORDS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey : get all questions **/
const httpGetSurveyQuestions = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_NEW_SURVEY_GET_QUESTIONS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : Survey : to generate survey web url **/
const httpGenerateSurveyWebUrl = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_NEW_SURVEY_GENERATE_WEB_URL, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/** http : Survey : update survey status **/
const httpSurveyUpdateStatus = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_UPDATE_STATUS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : client countries list **/
const httpGetClientCountries = async (data) =>
    await axios.post(baseUrl + URL_CLIENT_COUNTRIES_LIST, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : get survey by id **/
const httpGetSurveyById = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_GET_RECORD_BY_ID, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : save survey closing message **/
const httpSaveSurveyClosingMessage = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_SAVE_CLOSING_MESSAGE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : save survey opening message **/
const httpSaveSurveyOpeningMessage = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_SAVE_OPENING_MESSAGE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey : http update **/
const httpSaveSurveyRedeploy = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_SAVE_REDEPLOY, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey redeploy : get series questions **/
const httpGetSurveyRedeploySeriesQuestions = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_REDEPLOY_GET_SERIES_QUESTIONS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey redeploy : save series questions **/
const httpSaveSurveyRedeploySeriesQuestions = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey redeploy : to get line graph details **/
const httpGetRedeployQuestionSummary = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_REDEPLOY_GET_QUESTION_SUMMARY, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });


/************ methods : http response handler *******************/

/** http : New Survey : http response handler **/
function* handleHttpSaveNewSurveyInfo({payload}) {
    try {

        let result = yield call(httpSaveNewSurveyInfo, payload);

        if (result.message) {
            yield put(saveNewSurveyInfoError(result.response));
        } else {
            yield put(saveNewSurveyInfoSuccess(result));
        }
    } catch (error) {
        yield put(saveNewSurveyInfoError(error));
    }
}

/** http : update Survey : http response handler **/
function* handleHttpUpdateSurveyInfo({payload}) {
    try {
        let result = yield call(httpUpdateSurveyInfo, payload);

        if (result.message) {
            yield put(saveNewSurveyInfoError(result.response));
        } else {
            yield put(saveNewSurveyInfoSuccess(result));
        }
    } catch (error) {
        yield put(saveNewSurveyInfoError(error));
    }
}

/** http : Survey : http response handler **/
function* handleHttpGetSurveyList({payload}) {
    try {

        let result = yield call(httpGetSurveyList, payload);

        if (result.message) {
            yield put(getSurveyAllRecordsError(result.response));
        } else {
            yield put(getSurveyAllRecordsSuccess(result));
        }
    } catch (error) {
        yield put(getSurveyAllRecordsError(error));
    }
}

/** http : Survey paginated list: http response handler **/
function* handleHttpGetSurveyPaginatedList({payload}) {
    try {

        let result = yield call(httpGetSurveyList, payload);

        if (result.message) {
            yield put(getSurveyPaginatedListError(result.response));
        } else {
            yield put(getSurveyPaginatedListSuccess(result));
        }
    } catch (error) {
        yield put(getSurveyPaginatedListError(error));
    }
}


/** http : Survey : http response handler **/
function* handleHttpGetSurveyQuestions({payload}) {
    try {

        let result = yield call(httpGetSurveyQuestions, payload);

        if (result.message) {
            yield put(getSurveyTemplateQuestionsError(result.response));
        } else {
            yield put(getSurveyTemplateQuestionsSuccess(result));
        }
    } catch (error) {
        yield put(getSurveyTemplateQuestionsError(error));
    }
}


/** http Survey : to generate survey web url **/
function* handleHttpGenerateSurveyWebUrl(payload) {
    try {

        let result = yield call(httpGenerateSurveyWebUrl, payload.payload);

        if (result.message) {
            yield put(generateSurveyWebUrlError(result.response));
        } else {
            yield put(generateSurveyWebUrlSuccess(result));
        }
    } catch (error) {
        yield put(generateSurveyWebUrlError(error));
    }
}

/** http Survey : to update survey status **/
function* handleHttpUpdateSurveyInfoStatus(payload) {
    try {

        let result = yield call(httpSurveyUpdateStatus, payload.payload);

        if (result.message) {
            yield put(updateSurveyInfoStatusError(result.response));
        } else {
            yield put(updateSurveyInfoStatusSuccess(result));
        }
    } catch (error) {
        yield put(updateSurveyInfoStatusError(error));
    }
}

/** http Survey : to update survey status **/
function* handleHttpGetClientCountries(payload) {
    try {

        let result = yield call(httpGetClientCountries, payload.payload);

        if (result.message) {
            yield put(getClientCountriesError(result.response));
        } else {
            yield put(getClientCountriesSuccess(result));
        }
    } catch (error) {
        yield put(getClientCountriesError(error));
    }
}

/** http Survey : get single record id **/
function* handleHttpGetSurveyById(payload) {
    try {

        let result = yield call(httpGetSurveyById, payload.payload);

        if (result.message) {
            yield put(getSurveyByIdError(result.response));
        } else {
            yield put(getSurveyByIdSuccess(result));
        }
    } catch (error) {
        yield put(getSurveyByIdError(error));
    }
}

/**http survey : save closing message */
function* handleHttpSavesurveyClosingMessage({payload}) {
    try {
        let result = yield call(httpSaveSurveyClosingMessage, payload);

        if (result.message) {
            yield put(saveSurveyClosingMessageError(result.response));
        } else {
            yield put(saveSurveyClosingMessageSuccess(result));
        }
    } catch (error) {
        yield put(saveSurveyClosingMessageError(error));
    }
}

/**http survey : save opening message */
function* handleHttpSavesurveyOpeningMessage({payload}) {
    try {
        let result = yield call(httpSaveSurveyOpeningMessage, payload);

        if (result.message) {
            yield put(saveSurveyOpeningMessageError(result.response));
        } else {
            yield put(saveSurveyOpeningMessageSuccess(result));
        }
    } catch (error) {
        yield put(saveSurveyOpeningMessageError(error));
    }
}

/** http : save redeploy Survey : http response handler **/
function* handleHttpSaveSurveyRedeploy({payload}) {
    try {

        let result = yield call(httpSaveSurveyRedeploy, payload);

        if (result.message) {
            yield put(redeploySurveyError(result.response));
        } else {
            yield put(redeploySurveySuccess(result));
        }
    } catch (error) {
        yield put(redeploySurveyError(error));
    }
}

/** http : Survey redeploy get series questions : http response handler **/
function* handleHttpGetSurveyRedeploySeriesQuestions({payload}) {
    try {

        let result = yield call(httpGetSurveyRedeploySeriesQuestions, payload);

        if (result.message) {
            yield put(getSurveyRedeploySeriesQuestionsError(result.response));
        } else {
            yield put(getSurveyRedeploySeriesQuestionsSuccess(result));
        }
    } catch (error) {
        yield put(getSurveyRedeploySeriesQuestionsError(error));
    }
}

/** http : Survey redeploy save series questions : http response handler **/
function* handleHttpSaveSurveyRedeploySeriesQuestions({payload}) {
    try {

        let result = yield call(httpSaveSurveyRedeploySeriesQuestions, payload);

        if (result.message) {
            yield put(saveSurveyRedeploySeriesQuestionsError(result.response));
        } else {
            yield put(saveSurveyRedeploySeriesQuestionsSuccess(result));
        }
    } catch (error) {
        yield put(saveSurveyRedeploySeriesQuestionsError(error));
    }
}

/** http : Survey redeploy save series questions : http response handler **/
function* handleHttpGetSurveyRedeployQuestionsSummary({payload}) {
    try {

        let result = yield call(httpGetRedeployQuestionSummary, payload);

        if (result.message) {
            yield put(getSurveyRedeployQuestionSummaryError(result.response));
        } else {
            yield put(getSurveyRedeployQuestionSummarySuccess(result));
        }
    } catch (error) {
        yield put(getSurveyRedeployQuestionSummaryError(error));
    }
}

/************ methods : export access *******************/

/** Survey template info : save **/
export function* saveNewSurveyInfo() {
    yield takeEvery(SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO, handleHttpSaveNewSurveyInfo);
}

/** Survey template info : save **/
export function* updateNewSurveyInfo() {
    yield takeEvery(SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_UPDATE, handleHttpUpdateSurveyInfo);
}

/** Survey : get records **/
export function* setSurveyList() {
    yield takeEvery(SURVEY_NEW_SURVEY_GET_ALL_RECORDS, handleHttpGetSurveyList);
}

/** Survey : get paginated records **/
export function* getSurveyPaginatedList() {
    yield takeEvery(SURVEY_GET_NEXT_LIST, handleHttpGetSurveyPaginatedList);
}


/** Survey questions : get records **/
export function* setSurveyQuestions() {
    yield takeEvery(SURVEY_NEW_SURVEY_GET_QUESTIONS, handleHttpGetSurveyQuestions);
}

/** Survey : to generate survey web url **/
export function* generateSurveyWebUrl() {
    yield  takeEvery(SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL, handleHttpGenerateSurveyWebUrl);
}

/** Survey : to update info status **/
export function* updateSurveyInfoStatus() {
    yield  takeEvery(SURVEY_INFO_UPDATE_STATUS, handleHttpUpdateSurveyInfoStatus);
}

/** Survey : client countries **/
export function* getClientCountries() {
    yield  takeEvery(SURVEY_CLIENT_COUNTRIES, handleHttpGetClientCountries);
}

/** Survey : get single record id **/
export function* getSurveyById() {
    yield  takeEvery(SURVEY_GET_SURVEY_BY_ID, handleHttpGetSurveyById);
}

/**Survey : save closing message */
export function* saveSurveyClosingMessage() {
    yield takeEvery(SURVEY_NEW_CLOSING_MESSAGE_SAVE, handleHttpSavesurveyClosingMessage)
}

/**Survey : save opening message */
export function* saveSurveyOpeningMessage() {
    yield takeEvery(SURVEY_NEW_OPENING_MESSAGE_SAVE, handleHttpSavesurveyOpeningMessage)
}

/** Survey Redeploy : save **/
export function* saveRedeployedSurvey() {
    yield takeEvery(SURVEY_REDEPLOY_SURVEY, handleHttpSaveSurveyRedeploy);
}

/** Survey Redeploy : save **/
export function* sagaGetSurveyRedeploySeriesQuestions() {
    yield takeEvery(SURVEY_REDEPLOY_GET_SERIES_QUESTIONS, handleHttpGetSurveyRedeploySeriesQuestions);
}

/** Survey Redeploy : save question series **/
export function* sagaSaveSurveyRedeploySeriesQuestions() {
    yield takeEvery(SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS, handleHttpSaveSurveyRedeploySeriesQuestions);
}

/** Survey Redeploy : to get line graph details **/
export function* sagaGetSurveyRedeployQuestionsSummary() {
    yield takeEvery(SURVEY_REDEPLOY_GET_QUESTION_SUMMARY, handleHttpGetSurveyRedeployQuestionsSummary);
}


export default function* rootSaga() {
    yield all([
        fork(saveNewSurveyInfo),
        fork(updateNewSurveyInfo),
        fork(setSurveyList),
        fork(setSurveyQuestions),
        fork(generateSurveyWebUrl),
        fork(updateSurveyInfoStatus),
        fork(getClientCountries),
        fork(getSurveyPaginatedList),
        fork(getSurveyById),
        fork(saveSurveyClosingMessage),
        fork(saveSurveyOpeningMessage),
        fork(saveRedeployedSurvey),
        fork(sagaGetSurveyRedeploySeriesQuestions),
        fork(sagaSaveSurveyRedeploySeriesQuestions),
        fork(sagaGetSurveyRedeployQuestionsSummary)
    ]);
}