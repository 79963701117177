import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import AppUtils from "../../util/util";
import {updateUserMenuConfiguration} from "../../actions";
import {APP_NAVIGATION_PATHS} from "../../constants/config";


class SidenavContent extends Component {

    constructor(props) {
        super(props);
    }

    onUpdate = (props, prevProps, force = false) => {
        const {selectedClient, auth, user} = props;
        if ( force || (((prevProps && !prevProps.selectedClient.id) || !prevProps) && selectedClient.id)) {
            AppUtils.setModuleConfigData(auth.currentUserRole, user["module_config"], selectedClient);
            window.setTimeout(() => {
                props.updateUserMenuConfiguration(selectedClient);
            },1);

            window.setTimeout(() => {
                this.addMenuClickEvents();
            },10)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onUpdate(this.props, prevProps)
    }

    componentWillReceiveProps(nextProps) {
        const {history} = nextProps;
        const pathname = `${history.location.pathname}`;// get current path
        this.highlightCurrentItem(pathname);
    }

    componentDidMount () {
        this.onUpdate(this.props, null, true)
    }

    highlightCurrentItem = (pathname) => {
        try{
            const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if ( activeNav != null && activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch ( ex ) {
            // console.error("highlightCurrentItem : error : ", ex );
        }
    }

    addMenuClickEvents = () => {
        try{
            const {history} = this.props;
            const that = this;
            const pathname = `${history.location.pathname}`;

            const menuLi = document.getElementsByClassName('menu');
            for (let i = 0; i < menuLi.length; i++) {
                menuLi[i].onclick = function (event) {

                    try{
                        let prevActiveNode = document.querySelector('.wovo_icon_active');

                        if ( prevActiveNode ) {
                            prevActiveNode.className = prevActiveNode.className.replace("_active wovo_icon_active", "");
                        }

                        let activeChild = menuLi[i].querySelector('.wovo_icon');

                        if ( activeChild ){
                            activeChild.className += "_active wovo_icon_active";
                        }
                    } catch ( ex ){
                    }

                    const parentLiEle = that.closest(this, 'li');
                    if (menuLi[i].classList.contains('menu') && parentLiEle !== null) {
                        event.stopPropagation();

                        if (menuLi[i].classList.contains('open')) {
                            menuLi[i].classList.remove('open', 'active');
                        } else {
                            menuLi[i].classList.add('open', 'active');
                        }
                    } else {
                        for (let j = 0; j < menuLi.length; j++) {
                            const parentLi = that.closest(this, 'li');
                            if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                                menuLi[j].classList.remove('open');
                            } else {
                                if (menuLi[j].classList.contains('open')) {
                                    menuLi[j].classList.remove('open');
                                } else {
                                    menuLi[j].classList.add('open');
                                }
                            }
                        }
                    }
                }
            }


            this.highlightCurrentItem(pathname);

            if (pathname.toString().indexOf("dashboard") !== -1 ) {
                this.props.history.push(APP_NAVIGATION_PATHS.DASHBOARD);
            }

        } catch ( ex ) {
            console.error("addMenuClickEvents : error : ", ex );
        }
    }


    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] === 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    renderMenu = () => {

        const {menuConfiguration} = this.props;

        return (
            <ul className="nav-menu">
                {menuConfiguration.map(menu => (
                    (menu.items && menu.items.length > 0) ? (
                        <li key={menu["name"]} className="ui_tooltip menu" id={"menu_item_"+menu["name"]}>
                            <Button className="void">
                                { typeof menu.wovoIcon != "undefined" ? (<span className={menu.wovoIcon} /> ): (<i className={menu.icon}/>)}
                                <span className={ "nav-text " + (menu.dicClass ? menu.dicClass : "")  }>
                                    <IntlMessages id={menu.dic_key}/>
                                </span>
                            </Button>
                            <ul className="sub-menu">
                                {menu.items.map(item => (
                                    <li key={item.dic_key}>
                                        <NavLink to={item.url}>
                                            { typeof item.wovoIcon != "undefined" ? (<span className={item.wovoIcon} /> ): (<i className={item.icon}/>)}
                                            <span className={ "nav-text " + (item.dicClass ? item.dicClass : "")  }>
                                                <IntlMessages id={item.dic_key}/>
                                            </span>
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ) : (
                        <li key={menu["name"]} className="menu no-arrow">
                            <NavLink to={menu.url}>
                                { typeof menu.wovoIcon != "undefined" ? (<span className={menu.wovoIcon} /> ): (<i className={menu.icon}/>)}
                                <span className={ "nav-text " + (menu.dicClass ? menu.dicClass : "")  }>
                                    <IntlMessages id={menu.dic_key}/>
                                </span>
                            </NavLink>
                        </li>
                    )
                ))}
            </ul>
        )
    };

    render() {
        return (
            <CustomScrollbars className=" scrollbar mt-2" >
                <ul className="nav-menu">
                    {this.renderMenu()}
                </ul>
            </CustomScrollbars>
        );
    }
}

const mapStateToProps = ({settings, auth, ReducerClients, ReducerClientModules}) => {
    const {drawerType, locale, navigationStyle, horizontalNavPosition} = settings;
    const {user,menuConfiguration} = auth;
    return {
        drawerType,
        locale,
        navigationStyle,
        horizontalNavPosition,
        user,
        menuConfiguration,
        selectedClient: ReducerClients.selectedClient,
        auth,
        ReducerClientModules
    }
};

//export default withRouter(SidenavContent);
export default withRouter(connect(mapStateToProps, {
    updateUserMenuConfiguration
})(SidenavContent));
