import { takeEvery, call, put, all, fork } from "redux-saga/effects";
import axios from "axios";
import {
     GET_DOWNTIME_DETAILS
} from "../../../constants/actions/adminSettings/maintenanceBanner/actionMaintenanceBanner";
import {
     getDownTimeDetailsError,
     getDownTimeDetailsSuccess
} from '../../../actions/adminSettings/maintenanceBanner/ActionsMaintenanceBanner';
import { baseUrl } from "../../../util/keys";
import { HTTP_STATUS_CODE } from "../../../constants/config";
import {
     URL_GET_DOWNTIME_DETAILS
} from "../../../constants/Urls";

/************ methods : http *******************/

const httpGetDownTimeDetails = async (data) => {
     return await axios.get(baseUrl + URL_GET_DOWNTIME_DETAILS, data)
          .then(response => response)
          .catch(error => error);
};

/************ methods : http response handler *******************/

function* handleHttpGetDownTimeDetails({ payload }) {
     try {
          let response = yield call(httpGetDownTimeDetails, payload);

          if (typeof response.status !== 'undefined' &&
               (response.status === HTTP_STATUS_CODE['SUCCESS'] || response.status === HTTP_STATUS_CODE['OK'])) {
               yield put(getDownTimeDetailsSuccess(response));
          } else {
               yield put(getDownTimeDetailsError(response));
          }
     } catch (error) {
          yield put(getDownTimeDetailsError(error));
     }
}

/************ methods : Listeners *******************/

export function* getDownTimeDetails() {
     yield takeEvery(GET_DOWNTIME_DETAILS, handleHttpGetDownTimeDetails)
}

export default function* rootSaga() {
     yield all([
          fork(getDownTimeDetails)
     ])
}