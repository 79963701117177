import React from "react";
import {HTTP_REQUEST_STATUS} from "../../../constants/constants";
import IntlMessages from "../../../util/IntlMessages";
import AppUtils from "../../../util/util"

import {
    SURVEY_GET_QUICK_INSIGHTS,
    SURVEY_GET_QUICK_INSIGHTS_ERROR,
    SURVEY_GET_QUICK_INSIGHTS_IN_PROGRESS,
    SURVEY_GET_QUICK_INSIGHTS_NONE,
    SURVEY_GET_QUICK_INSIGHTS_SUCCESS,

    SURVEY_GET_QUESTION_SUMMARY,
    SURVEY_GET_QUESTION_SUMMARY_ERROR,
    SURVEY_GET_QUESTION_SUMMARY_IN_PROGRESS,
    SURVEY_GET_QUESTION_SUMMARY_NONE,
    SURVEY_GET_QUESTION_SUMMARY_SUCCESS,
    SURVEY_GET_QUESTION_SUMMARY_GRAPH_TYPE,

    SURVEY_RESPONSES_DOWNLOAD,
    SURVEY_RESPONSES_DOWNLOAD_ERROR,
    SURVEY_RESPONSES_DOWNLOAD_SUCCESS,
    SURVEY_RESPONSES_DOWNLOAD_IN_PROGRESS,
    SURVEY_RESPONSES_DOWNLOAD_NONE,
    SURVEY_REDEPLOY_GET_QUESTION_SUMMARY,
    SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_SUCCESS,
    SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_ERROR,
    SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_NONE,

    SURVEY_QUICK_INSIGHTS_QUESTION_ORDER_FILTERS_CHANGE,
    SURVEY_QUICK_INSIGHTS_RESPONDER_ACCESS_FILTERS_CHANGE,
    SURVEY_QUICK_INSIGHTS_INVITED_MEDIA_FILTERS_CHANGE,
    SURVEY_QUICK_INSIGHTS_FILTERS_RESET,
    SURVEY_QUICK_INSIGHTS_UPDATE_SELECTED_COUNTRIES,
    SURVEY_QUICK_INSIGHTS_COMPANY_STATUS_CHANGE,
    SURVEY_QUICK_INSIGHTS_JOB_ROLE_CHANGE
} from "../../../constants/actions/survey/survey/actionTypesSurvey";

import {
    SURVEY_QUESTION_DROPDOWN_TYPES,
    SURVEY_QUESTION_TYPES_VALUES,
    SURVEY_QUICK_INSIGHTS_FILTERS,
    SURVEY_QUESTION_SUMMARY_DISPLAY_TYPE,
    SURVEY_USER_ACTIONS,
    SURVEY_REPORTS_RAW_DATA,
    SURVEY_QUESTION_SUMMARY_TEXT_RESPONSE,
    COMPANY_STATUS_VALUE_MAP, WC_JOB_ROLES
} from "../../../constants/config";
import UIUtils from "../../../util/ui_utils";

let quickInsightJobRoleFilters = [
    {
        key: SURVEY_QUICK_INSIGHTS_FILTERS.JOB_ROLE,
        type: "multiselect_react_chips_dropdown",
        localisation: "DIC_SURVEY_LIST_BRAND_JOB_ROLE",
        style: {}
    }
];

let quickInsightFilters = [
    {
        key: SURVEY_QUICK_INSIGHTS_FILTERS.COMPANY_STATUS,
        type: "outline_dropdown",
        localisation: "DIC_KEY_CONNECT_REPORTS_COMPANY_STATUS",
        style: {}
    },
    {
        key: SURVEY_QUICK_INSIGHTS_FILTERS.CLIENTS,
        type: "outline_dropdown",
        localisation: "DIC_SURVEY_LIST_FILTER_CLIENTS",
        style: {}
    },
    {
        key: SURVEY_QUICK_INSIGHTS_FILTERS.COUNTRY,
        type: "multiselect_react_chips_dropdown",
        localisation: "DIC_KEY_MULTISELECT_DROPDOWN_NAME_LABEL",
        style: {}
    }
];

let filterValuesJobRole = [
    {
        id: WC_JOB_ROLES.WORKER,
        key: WC_JOB_ROLES.WORKER,
        localisation: "DIC_SURVEY_REPORT_FILTER_JOB_ROLE_WORKER"
    },
    {
        id: WC_JOB_ROLES.SUPERVISOR,
        key: WC_JOB_ROLES.SUPERVISOR,
        localisation: "DIC_SURVEY_REPORT_FILTER_JOB_ROLE_SUPERVISOR"
    },
    {
        id: WC_JOB_ROLES.MANAGER,
        key: WC_JOB_ROLES.MANAGER,
        localisation: "DIC_SURVEY_REPORT_FILTER_JOB_ROLE_MANAGER"
    },
    {
        id: WC_JOB_ROLES.HEAD,
        key: WC_JOB_ROLES.HEAD,
        localisation: "DIC_SURVEY_REPORT_FILTER_JOB_ROLE_HEAD"
    },
    {
        id: WC_JOB_ROLES.DEFAULT,
        key: WC_JOB_ROLES.DEFAULT,
        localisation: "DIC_SURVEY_REPORT_FILTER_JOB_ROLE_UNDISCLOSED"
    }
];

let genderDropDown = [
    {
        value: -1,
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTERS_GENDER_SELECT_ALL"/>
    },
    {
        value: "male",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_GENDER_MALE"/>
    },
    {
        value: "female",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_GENDER_FEMALE"/>
    },
    {
        value: "others",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_GENDER_OTHERS"/>
    },
    {
        value: "undisclosed",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_GENDER_UNDISCLOSED"/>
    }
];

let ageGroupDropDown = [
    {
        value: -1,
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTERS_AGE_GROUPS_SELECT_ALL"/>
    },
    {
        value: "0_18",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_AGE_GROUP_LESS_THAN_18"/>
    },
    {
        value: "18_30",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_AGE_GROUP_18_TO_30"/>
    },
    {
        value: "31_40",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_AGE_GROUP_31_TO_40"/>
    },
    {
        value: "41_49",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_AGE_GROUP_41_TO_49"/>
    },
    {
        value: "51_100",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_AGE_GROUP_GREATER_THAN_50"/>
    },
    {
        value: "undisclosed",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_AGE_GROUP_UNDISCLOSED"/>
    }
];

let tenureDropDown = [
    {
        value: -1,
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTERS_TENURE_SELECT_ALL"/>
    },
    {
        value: [{type: "<", value: 1}],
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_TENURE_LESS_THAN_1_YEAR"/>
    },
    {
        value: [{type: ">=", value: 1}, {type: "<=", value: 3}],
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_TENURE_1_to_3"/>
    },
    {
        value: [{type: ">", value: "3"}],
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_TENURE_LESS_THAN_3_YEARS"/>
    },
    {
        value: "undisclosed",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_TENURE_UNDISCLOSED"/>
    }
];

let customFieldDropDown = [
    {
        value: -1,
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTERS_CUSTOM_FIELD_SELECT_ALL"/>
    }

];

let quickInsightViewDropdownFilters = [
    {
        id : "QUICK_INSIGHT_VIEW_CHART",
        label: "DIC_SURVEY_TEMPLATE_REPORTS_QUICK_INSIGHTS",
        value: SURVEY_USER_ACTIONS.NAVIGATE_TO_QUICK_INSIGHTS,
        icon: "chartIcon",
        activeIcon: "chartIconBlue"
    },
    {
        id : "QUICK_INSIGHT_VIEW_TABLE",
        label: "DIC_SURVEY_TEMPLATE_REPORTS_SURVEY_BREAKDOWN_TABLE",
        value: SURVEY_USER_ACTIONS.NAVIGATE_TO_SUMMARY_TABLE,
        icon: "tableIcon",
        activeIcon: "tableIconBlue",
    },
];

let quickInsightDownloadDropdownFilters = [
    {
        id : "QUICK_INSIGHT_DOWNLOAD_RAW_DATA",
        label: "DIC_SURVEY_QUESTIONNAIRE_QUICK_INSIGHT_DOWNLOAD_EXCEL",
        value: SURVEY_REPORTS_RAW_DATA.DOWNLOAD_RAW_DATA,
        icon: "excelIcon"
    }
];

let quickInsightChartDownloadDropdownFilters = [
    {
        id : "QUICK_INSIGHT_DOWNLOAD_PDF",
        label: "DIC_SURVEY_QUESTIONNAIRE_QUICK_INSIGHT_DOWNLOAD_PDF",
        value: SURVEY_QUESTION_SUMMARY_TEXT_RESPONSE.DOWNLOAD_REPORT,
        icon: "iconPdf"
    }
];

let quickInsightTableDownloadDropdownFilters = [
    {
        id : "QUICK_INSIGHT_DOWNLOAD_EXCEL",
        label: "DIC_REPORTS_CONNECT_SUMMARY_TABLE_XLS_DOWNLOAD",
        value: SURVEY_QUESTION_SUMMARY_TEXT_RESPONSE.DOWNLOAD_REPORT,
        icon: "excelIcon"
    }
];

let surveySettingFilters = [
    {
        key: SURVEY_QUICK_INSIGHTS_FILTERS.QUESTION_ORDER,
        type: "outline_dropdown",
        localisation: "DIC_SURVEY_REPORT_FILTER_QUESTION_ORDER_LABEL",
        style: {}
    },
    {
        key: SURVEY_QUICK_INSIGHTS_FILTERS.RESPONDER_ACCESS,
        type: "outline_dropdown",
        localisation: "DIC_SURVEY_REPORT_FILTER_RESPONDER_ACCESS_LABEL",
        style: {}
    },
    {
        key: SURVEY_QUICK_INSIGHTS_FILTERS.INVITED_MEDIA,
        type: "outline_dropdown",
        localisation: "DIC_SURVEY_REPORT_FILTER_INVITED_MEDIA_LABEL",
        style: {}
    }
];

let filterValuesQuestionOrder = [
    {
        key: "overall",
        value: 'all',
        label: "DIC_SURVEY_REPORT_FILTER_QUESTION_ORDER_ALL"
    },
    {
        key: "randomize_order",
        value: 'random',
        label: "DIC_SURVEY_REPORT_FILTER_QUESTION_ORDER_RANDOMIZED"
    },
    {
        key: "standard_order",
        value: 'standard',
        label: "DIC_SURVEY_REPORT_FILTER_QUESTION_ORDER_STANDARD"
    }
];

let filterValuesResponderAccess = [
    {
        key: "overall",
        value: 'all',
        label: "DIC_SURVEY_REPORT_FILTER_RESPONDER_ACCESS_ALL"
    },
    {
        key: "unique_response",
        value: 'single',
        label: "DIC_SURVEY_REPORT_FILTER_RESPONDER_ACCESS_UNIQUE"
    },
    {
        key: "shared_response",
        value: 'multiple',
        label: "DIC_SURVEY_REPORT_FILTER_RESPONDER_ACCESS_SHARED"
    }
];

let filterValuesInvitedMedia = [
    {
        key: "All",
        value: 'all',
        label: "DIC_SURVEY_REPORT_FILTER_INVITED_MEDIA_ALL"
    },
    {
        key: "QR",
        value: 'qrcode',
        label: "DIC_SURVEY_REPORT_FILTER_INVITED_MEDIA_QR"
    },
    {
        key: "URL",
        value: 'url',
        label: "DIC_SURVEY_REPORT_FILTER_INVITED_MEDIA_URL"
    }
];

let filterValueCompanyStatus = [
    {
        key: "overall",
        value: COMPANY_STATUS_VALUE_MAP.ALL,
        label: "DIC_REPORTS_CONNECT_ALL"
    },
    {
        key: "active_response",
        value: COMPANY_STATUS_VALUE_MAP.ACTIVE,
        label: "DIC_REPORTS_CONNECT_COMPANY_STATUS_ACTIVE"
    },
    {
        key: "inactive_response",
        value: COMPANY_STATUS_VALUE_MAP.INACTIVE,
        label: "DIC_REPORTS_CONNECT_COMPANY_STATUS_INACTIVE"
    }
]

let allJobRoleValues = [...filterValuesJobRole];

const initialData = {
    quickInsightViewDropdownFilters: quickInsightViewDropdownFilters,
    quickInsightDownloadDropdownFilters: quickInsightDownloadDropdownFilters,
    quickInsightChartDownloadDropdownFilters: quickInsightChartDownloadDropdownFilters,
    quickInsightTableDownloadDropdownFilters: quickInsightTableDownloadDropdownFilters,
    loader: false,
    alertMessage: '',
    isSuccessMessage: false,
    isErrorMessage: false,
    SurveyQuickInsightsFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    quickInsightsData: [],
    SurveyQuestionSummaryFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    questionSummaryData: [],
    questions: [],
    quickInsightFilters: quickInsightFilters,
    quickInsightJobRoleFilters: quickInsightJobRoleFilters,
    genderDropDown: genderDropDown,
    ageGroupDropDown: ageGroupDropDown,
    tenureDropDown: tenureDropDown,
    customFieldDropDown: customFieldDropDown,
    graphData: [],
    templateDisplayData: null,
    supportedLanguages: [],
    surveyResponseData: [],
    surveyResponseDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyResponseDownloadLink: "",
    overallRatingScore: 0,
    clientsFilterDropdown: [],
    clientsCountryFilterDropdownValues: [],
    categorySummaryData: [],
    surveyLineGraphData : {},
    redeployedSurveyQuestionSummaryStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveySettingFilters: surveySettingFilters,
    filterValuesQuestionOrder: filterValuesQuestionOrder,
    filterValuesResponderAccess: filterValuesResponderAccess,
    filterValuesInvitedMedia: filterValuesInvitedMedia,
    selectedQuestionOrder: filterValuesQuestionOrder[0]['value'],
    selectedResponderAccess: filterValuesResponderAccess[0]['value'],
    selectedInvitedMedia: filterValuesInvitedMedia[0]['value'],
    selectedCountries: [],
    clientFilterLookup: {},
    filterValueCompanyStatus: filterValueCompanyStatus,
    selectedCompanyStatus: filterValueCompanyStatus[0]["value"],
    filterValuesJobRole: filterValuesJobRole,
    selectedJobRoles: allJobRoleValues,
    allJobRoleValues: allJobRoleValues
};

const ReducerSurveyQuickInsights = (state = initialData, action) => {

    switch (action.type) {

        case SURVEY_REDEPLOY_GET_QUESTION_SUMMARY:{
            return {
                ...state,
                redeployedSurveyQuestionSummaryStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                surveyLineGraphData: {}
            }
        }

        case SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_SUCCESS:{

            let parsedData = parseSurveyLineGraphData(action.payload.data.data);

            return {
                ...state,
                redeployedSurveyQuestionSummaryStatus: HTTP_REQUEST_STATUS.SUCCESS,
                surveyLineGraphData: parsedData
            }
        }

        case SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_ERROR:{
            return {
                ...state,
                redeployedSurveyQuestionSummaryStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }

        case SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_NONE:{
            return {
                ...state,
                redeployedSurveyQuestionSummaryStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case SURVEY_GET_QUICK_INSIGHTS_IN_PROGRESS:
            return {
                ...state,
                SurveyQuickInsightsFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                loader: true
            };

        case SURVEY_GET_QUICK_INSIGHTS_ERROR:
            return {
                ...state,
                alertMessage: action.payload,
                isErrorMessage: true
            };

        case SURVEY_GET_QUICK_INSIGHTS_NONE:
            return {
                ...state,
                SurveyQuickInsightsFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                quickInsightsData: []
            };

        case SURVEY_GET_QUICK_INSIGHTS:
            return {
                ...state,
                SurveyQuickInsightsFetchStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload,
                loader: false,
            };

        case SURVEY_GET_QUICK_INSIGHTS_SUCCESS:
            let templateDisplayData = action.payload;
            templateDisplayData = updateSurveyQuickInsightDetails(templateDisplayData);

            let quickInsightsData = action.payload;
            if ( typeof quickInsightsData != "undefined" && typeof quickInsightsData["no_of_responses"] != "undefined" ) {
                quickInsightsData["response_count"] = AppUtils.numberWithCommas(quickInsightsData["no_of_responses"]);
            }
            return {
                ...state,
                SurveyQuickInsightsFetchStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false,
                quickInsightsData: quickInsightsData,
                templateDisplayData: templateDisplayData
            };

        case SURVEY_GET_QUESTION_SUMMARY_IN_PROGRESS:
            return {
                ...state,
                SurveyQuestionSummaryFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                loader: true
            };

        case SURVEY_GET_QUESTION_SUMMARY_ERROR:
            return {
                ...state,
                alertMessage: action.payload,
                isErrorMessage: true
            };

        case SURVEY_GET_QUESTION_SUMMARY_NONE:
            return {
                ...state,
                SurveyQuestionSummaryFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                questionSummaryData: []
            };

        case SURVEY_GET_QUESTION_SUMMARY:
            return {
                ...state,
                alertMessage: action.payload.data,
                loader: false,
            };

        case SURVEY_GET_QUESTION_SUMMARY_GRAPH_TYPE:
            return {
                ...state,
                currentGraph: action.payload
            };

        case SURVEY_GET_QUESTION_SUMMARY_SUCCESS:
            let selectedCountries = state.selectedCountries;
            let parsedQuestionData = parseQuestionSummaryData(action.payload);
        
            if (typeof parsedQuestionData != "undefined") {
                parsedQuestionData = AppUtils.getSortedQuestions(parsedQuestionData);
            }

            let overall_rating_score = 0;
            let clientDropdown = [];
            let supportedLanguages = [];
            let clientFilterLookup = {};
            let clientsCountryFilterDropdownValues = [];

            if (typeof action.payload != "undefined" && action.payload.survey) {
                overall_rating_score = action.payload.survey.overall_rating_score;
                clientDropdown = generateClientsFilterDropdown(action.payload.survey.shared_clients);
                let tempData = generateClientsCountryDropdownData(action.payload.survey.shared_clients);

                clientsCountryFilterDropdownValues = tempData;

                if( selectedCountries.length === 0 ){
                    selectedCountries = clientsCountryFilterDropdownValues;
                }

                supportedLanguages = AppUtils.getSupportedLanguagesForQuickInsights(action.payload.survey);
                clientFilterLookup = AppUtils.generateClientsLookup(action.payload.survey.shared_clients);
            }

            return {
                ...state,
                SurveyQuestionSummaryFetchStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload,
                loader: false,
                questionSummaryData: action.payload,
                questions: parsedQuestionData,
                overallRatingScore: overall_rating_score,
                clientsFilterDropdown: clientDropdown,
                clientsCountryFilterDropdownValues: clientsCountryFilterDropdownValues,
                categorySummaryData: action.payload,
                supportedLanguages: supportedLanguages,
                clientFilterLookup: clientFilterLookup,
                selectedCountries: selectedCountries
            };

        case SURVEY_RESPONSES_DOWNLOAD_ERROR:
            return {
                ...state,
                surveyResponseDownloadStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: AppUtils.getErrorStringFromResponse(action.payload),
                isErrorMessage: true
            };

        case SURVEY_RESPONSES_DOWNLOAD_SUCCESS:
            let surveyResponseDownloadLink = action.payload;

            if (action.payload && typeof action.payload != "undefined") {
                surveyResponseDownloadLink = AppUtils.getAppBaseUrl() + action.payload.link;
            }

            return {
                ...state,
                surveyResponseDownloadStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false,
                alertMessage: action.payload,
                surveyResponseData: action.payload,
                surveyResponseDownloadLink: surveyResponseDownloadLink

            };

        case SURVEY_RESPONSES_DOWNLOAD_IN_PROGRESS:
            return {
                ...state,
                surveyResponseDownloadStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                surveyResponseDownloadLink: ""

            };

        case SURVEY_RESPONSES_DOWNLOAD_NONE:
            return {
                ...state,
                surveyResponseDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                surveyResponseDownloadLink: ""

            };

        case SURVEY_QUICK_INSIGHTS_QUESTION_ORDER_FILTERS_CHANGE: {
            return {
                ...state,
                selectedQuestionOrder: action.payload
            }
        }

        case SURVEY_QUICK_INSIGHTS_UPDATE_SELECTED_COUNTRIES:{

            return {
                ...state,
                selectedCountries: action.payload
            }
        }

        case SURVEY_QUICK_INSIGHTS_RESPONDER_ACCESS_FILTERS_CHANGE: {
            return {
                ...state,
                selectedResponderAccess: action.payload
            }
        }

        case SURVEY_QUICK_INSIGHTS_INVITED_MEDIA_FILTERS_CHANGE: {
            return {
                ...state,
                selectedInvitedMedia: action.payload
            }
        }

        case SURVEY_QUICK_INSIGHTS_JOB_ROLE_CHANGE:{
            return {
                ...state,
                selectedJobRoles: action.payload
            }
        }

        case SURVEY_QUICK_INSIGHTS_COMPANY_STATUS_CHANGE:{
            return {
                ...state,
                selectedCompanyStatus: action.payload
            }
        }

        case SURVEY_QUICK_INSIGHTS_FILTERS_RESET: {
            return {
                ...state,
                selectedQuestionOrder: filterValuesQuestionOrder[0]['value'],
                selectedResponderAccess: filterValuesResponderAccess[0]['value'],
                selectedCompanyStatus: filterValueCompanyStatus[0]["value"],
                selectedInvitedMedia: filterValuesInvitedMedia[0]['value']
            }
        }

        default:
            return state;
    }
}


const updateSurveyQuickInsightDetails = function (survey) {
    try {

        let questionResponse = survey.lang_and_response.map((lang) => lang.response);
        const add = questionResponse => questionResponse.reduce((a, b) => a + b, 0);
        let responseCount = add(questionResponse);
        responseCount = AppUtils.numberWithCommas(responseCount);

        survey.infoMenu = [
            {
                id: 1,
                title: <IntlMessages id="DIC_SURVEY_INSTANT_REPORTING_INSIGHTS_SURVEY_START_DATE"/>,
                icon: "calendar",
                userList: "",
                desc: [survey.start_date]
            },
            {
                id: 2,
                title: <IntlMessages id="DIC_SURVEY_INSTANT_REPORTING_INSIGHTS_SURVEY_END_DATE"/>,
                icon: "calendar",
                userList: [],
                desc: [survey.end_date]
            },
            {
                id: 3,
                title: <IntlMessages id="DIC_SURVEY_INSTANT_REPORTING_INSIGHTS_SURVEY_NO_OF_QUESTIONS"/>,
                icon: "view-list",
                userList: "",
                desc: [survey.no_of_questions]
            },
            {
                id: 4,
                title: <IntlMessages id="DIC_SURVEY_INSTANT_REPORTING_INSIGHTS_SURVEY_NO_OF_RESPONSES"/>,
                icon: "view-list",
                userList: "",
                desc: [responseCount]
            }
        ]
    } catch (e) {
    }
    return survey;

};


function parseQuestionSummaryData(data) {
    let result = [];
    if (data && data.survey && data.survey.questions) {
        try {
            
            let questions = data.survey.questions;
            
            for (let i = 0; i < questions.length; i++) {
                if (
                    (questions[i].question_type != SURVEY_QUESTION_TYPES_VALUES.LINEAR_RATING_SCALE) &&
                    (questions[i].question_type != SURVEY_QUESTION_TYPES_VALUES.TEXT_LONG) &&
                    (questions[i].question_type != SURVEY_QUESTION_TYPES_VALUES.TEXT_SHORT) &&
                    (questions[i].question_type != SURVEY_QUESTION_TYPES_VALUES.EMAIL)) {
                    questions[i]['question_display_type'] = SURVEY_QUESTION_SUMMARY_DISPLAY_TYPE.GRAPH_BASED;
                } else {
                    questions[i]['question_display_type'] = SURVEY_QUESTION_SUMMARY_DISPLAY_TYPE.TEXT_BASED;
                }

                let questionTranslations = [];

                for (let j = 0; j < questions[i].translations.length; j++) {
                    let surveyQuestionSupportedLanguages = questions[i] && questions[i].translations[j] && questions[i].translations[j].language;

                    if (surveyQuestionSupportedLanguages && surveyQuestionSupportedLanguages.language_code && questions[i].translations[j]) {
                        questionTranslations[surveyQuestionSupportedLanguages.language_code] = questions[i].translations[j].translation;
                    }
                }
                
                if ( questions[i]["is_emoji_enabled"] ) {
                    let questionOptions = questions[i]["question_options"];
                    
                    for(let j = 0 ; j < questionOptions.length; j++ ){
                        if(questionOptions[j]["icon_class"] !== "" ) {
                            questionOptions[j]["iconSvg"] = UIUtils.getSurveyGrayScaleEmojiSvgContent(questionOptions[j]["icon_class"]);
                        }
                    }
                }
                
                questions[i]['question_title_translations'] = questionTranslations;

            }
            result = questions;
        } catch (ex) {
            console.error("parseQuestionSummaryData : error : ", ex);
        }

        return result;
    }

}

function generateClientsFilterDropdown(data) {
    let result = [];

    try {

        result.push({
            key: -1,
            value: -1,
            label: "DIC_CLIENTS_DROPDOWN_ALL",
            client_id: -1
        });

        for (let i = 0; i < data.length; i++) {
            result.push({
                key: data[i]['id'],
                value: data[i]['client_key'] ? data[i]['client_key'] : data[i]['id'],
                label: data[i]['name'],
                status: data[i]['status'],
                client_id: data[i]['client_id']
            })
        }
    } catch (ex) {
        console.error(ex);
    }
    return result;
}

function generateClientsCountryDropdownData(data) {
    let result = [];
    try {

        let tempResult = {};
        
        for (let i = 0; i < data.length; i++) {
            let tempData = {}
            if (typeof tempResult[data[i]['country']] == "undefined") {
                tempResult[data[i]['country']] = {
                    name: data[i]['country']
                };
                tempData = {
                    id: data[i]['country_mapping']['short_code'],
                    key: data[i]['country_mapping']['short_code'],
                    name : data[i]['country']
                }
                result.push(tempData)
            }
        }
    } catch (ex) {
        console.error("generateClientsCountryFilterDropdown : error : ", ex);
    }
    return result;
}

function parseSurveyLineGraphData(responseData){
    let result = {};

    try{

        result = responseData["questions"];

        let surveysData = responseData["survey_details"];

        for ( let questionId in result ){

            let responses = [];
            let questionResponses = result[questionId]["question_responses"];

            for ( let surveyId in questionResponses ) {

                questionResponses[surveyId]["avg_score"] = AppUtils.getAverageScoreLabel(questionResponses[surveyId]["avg_score"]);

                if ( typeof surveysData[surveyId] != "undefined" ) {
                    questionResponses[surveyId]["name"] = surveysData[surveyId]["name"];
                    questionResponses[surveyId]["id"] = surveysData[surveyId]["id"];
                    questionResponses[surveyId]["translations"] = surveysData[surveyId]["translations"];
                    questionResponses[surveyId]["sequence_id"] = surveysData[surveyId]["sequence_id"];
                    questionResponses[surveyId]["start_date"] = AppUtils.getDateLabelFromDateDDMMYYYY(surveysData[surveyId]["start_date"], "-");
                    questionResponses[surveyId]["end_date"] = AppUtils.getDateLabelFromDateDDMMYYYY(surveysData[surveyId]["end_date"], "-");
                }

                if ( questionResponses[surveyId]["avg_score"] !== "NA" ) {
                    responses.push(questionResponses[surveyId]);
                }
            }


            responses.sort(function(a,b){return a["sequence_id"] - b["sequence_id"]});
            result[questionId]["responses"] = responses;

        }

    } catch ( ex ) {
        console.error("parseSurveyLineGraphData : error : ", ex );
    }

    return result;
}


export default ReducerSurveyQuickInsights;
