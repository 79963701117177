import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {baseUrl} from "../../../util/keys";
import {store} from '../../../MainApp';
import AppUtils from "../../../util/util";

import {
    SURVEY_GET_QUICK_INSIGHTS,
    SURVEY_GET_QUESTION_SUMMARY,
    SURVEY_RESPONSES_DOWNLOAD,
} from "../../../constants/actions/survey/survey/actionTypesSurvey";

import {
    getSurveyQuickInsightsError,
    getSurveyQuickInsightsSuccess,
    getSurveyQuestionSummaryError,
    getSurveyQuestionSummarySuccess,
    surveyDownloadResponsesSuccess,
    surveyDownloadResponsesError
} from "../../../actions/survey/quickInsights/ActionsSurveyQuickInsights";


import {
    URL_SURVEY_GET_QUICK_INSIGHT_DATA,
    URL_SURVEY_GET_QUESTION_SUMMARY_DATA,
    URL_SURVEY_SURVEY_RESPONSES_DOWNLOAD

} from "../../../constants/Urls";

/************ methods : http *******************/


/** http : Survey Quick Insights : get insights data**/
const httpGetSurveyQuickInsights = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_GET_QUICK_INSIGHT_DATA, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
}

/** http : Survey Question Summary : get question summary data**/
const httpGetSurveyQuestionSummary = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_GET_QUESTION_SUMMARY_DATA, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
}

/** http : Survey Question Summary : To download the text responses**/

const httpSurveyResponsesDownload = async (data) => {
    return await axios.post(baseUrl + URL_SURVEY_SURVEY_RESPONSES_DOWNLOAD, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser.data)
        .catch(error => {
            return error;
        });
}

/************ methods : http response handler *******************/

/** http : Survey Quick Insights List : http response handler **/
function* handleHttpGetSurveyQuickInsightsListResponse({payload}) {
    try {
        let dataValue = yield call(httpGetSurveyQuickInsights, payload);
        if (dataValue.message) {
            yield put(getSurveyQuickInsightsSuccess(dataValue.data));
        } else {
            yield put(getSurveyQuickInsightsError(dataValue.message));
        }
    } catch (error) {
        yield put(getSurveyQuickInsightsError(error));
    }
}

/** http : Survey Question Summary List : http response handler **/
function* handleHttpGetSurveyQuestionSummaryListResponse({payload}) {
    try {
        let dataValue = yield call(httpGetSurveyQuestionSummary, payload);
        if (dataValue.message) {
            yield put(getSurveyQuestionSummarySuccess(dataValue.data));
        } else {
            yield put(getSurveyQuestionSummaryError(dataValue.message));
        }
    } catch (error) {
        yield put(getSurveyQuestionSummaryError(error));
    }
}

/** http : Survey Text response Download: http response handler **/

function* handleHttpSurveyResponsesDownload({payload}) {
    try {
        let dataValue = yield call(httpSurveyResponsesDownload, payload);

        console.log("dataValue : ", dataValue)

        if ( AppUtils.isResponseSuccess(dataValue)){
            console.log("success")
            yield put(surveyDownloadResponsesSuccess(dataValue));
        } else {
            console.log("error")
            yield put(surveyDownloadResponsesError(dataValue.response));
        }
    } catch (error) {
        yield put(surveyDownloadResponsesError(error));
    }
}


/************ methods : export access *******************/

/** Survey quick insights : get all records **/
export function* getSurveyQuickInsightsList() {
    yield takeEvery(SURVEY_GET_QUICK_INSIGHTS, handleHttpGetSurveyQuickInsightsListResponse);
}

/** Survey question summary : get all records **/
export function* getSurveyQuestionSummaryList() {
    yield takeEvery(SURVEY_GET_QUESTION_SUMMARY, handleHttpGetSurveyQuestionSummaryListResponse);
}

/** Survey text responses download **/

export function* surveyDownloadResponses() {
    yield takeEvery(SURVEY_RESPONSES_DOWNLOAD, handleHttpSurveyResponsesDownload);
}

export default function* rootSaga() {
    yield all([
        fork(getSurveyQuickInsightsList),
        fork(getSurveyQuestionSummaryList),
        fork(surveyDownloadResponses)
    ]);
}
