import React from 'react'

import {
    SURVEY_QUESTION_TYPES_VALUES,
    OPTION_TYPES,
    SURVEY_RESPONSE_STRINGS,
    DEFAULT_LANGUAGE_CODE,
    SURVEY_RESPONSE_ITERATIONS_COUNT,
    SURVEY_QUESTION_EMOJI_CLASS_URL_CONFIG
} from '../../../constants/config'
import {
    SURVEY_RESPONSE_GET_AUTHENTICATE_MSG_RESET,
    SURVEY_RESPONSE_GET_AUTHENTICATE_SET_ERROR,
    SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER_ERROR,
    SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER_SUCCESS,
    SURVEY_RESPONSE_SAVE_RESPONSE_ERROR,
    SURVEY_RESPONSE_SAVE_RESPONSE_SUCCESS,
    SURVEY_RESPONSE_SET_EMPLOYEE_ID,
    SURVEY_RESPONSE_UPDATE_LANGUAGE,
    SURVEY_RESPONSE_CURRENT_LANGUAGE_FETCH_STATUS,
    SURVEY_RESPONSE_CHANGE_SURVEY_STATUS,
    SURVEY_RESPONSE_APPLY_ALERT_MESSAGE_TRANSLATIONS,
    SURVEY_RESPONSE_SET_CURRENT_CLIENT,
    SURVEY_RESPONSE_SAVE_RESPONSE,
    SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND,
    SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_ERROR,
    SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_SUCCESS,
    SURVEY_RESPONSE_SET_IDENTIFIER
} from "../../../constants/actions/survey/responses/actionTypesSurveyResponses";
import AppUtils from "../../../util/util";
import StorageHandler from "../../../util/storageHandler";
import IntlMessages from 'util/IntlMessages';
import {HTTP_REQUEST_STATUS} from "../../../constants/constants";

function surveyJSON(data) {

    let questionList = [];

    if (typeof data != "undefined" && typeof data.questions != "undefined" && data.questions.length > 0) {

        if (typeof data["is_randomize"] != "undefined" && data["is_randomize"]) {
            questionList = AppUtils.getRandomizedQuestions(data.questions);
        }
        else {
            questionList = data.questions.sort(AppUtils.sortJSONArray("sequence_id"));
        }
    }

    return {
        title: surveyTitleObject(data.translations),
        logo: require('../../../assets/images/wovologo.jpg'),
        logoWidth: 50,
        logoHeight: 50,
        showProgressBar: 'bottom',
        //questions: questionsObject(questionList),
        pages: pageObject(questionList)
    }
}

//this function can be used when single question is displayed in a single page
function pageObject(questionList) {
    const pageObjectsList = []
    questionList.map(question => {
        const questionArrayObject = []
        const questionObject = {}
        questionArrayObject.push({
            type: question.q_type.question_type == SURVEY_QUESTION_TYPES_VALUES.EMAIL ? "text" : OPTION_TYPES[question.q_type.question_type],
            choices: choicesObject(question.question_options),
            isRequired: question.is_mandatory,
            name: question.id,
            title: titleObject(question.translations),
            inputType: OPTION_TYPES[question.q_type.question_type],
            minRateDescription: SURVEY_RESPONSE_STRINGS.MIN_DESCRIPTION,
            maxRateDescription: SURVEY_RESPONSE_STRINGS.MAX_DESCRIPTION,
            rateMin: 0,
            rateMax: 10,
            question_type : question.q_type.question_type
        });

        questionObject['questions'] = questionArrayObject;
        pageObjectsList.push(questionObject);
    });
    
    return pageObjectsList;
}

function surveyTitleObject(surveyTranslations) {
    let surveyTitle = {};
    surveyTranslations.map(translation => {
        surveyTitle[translation.language.language_code] = translation.translation
    });
    return surveyTitle;

}

function questionsObject(questions) {
    const questionsObject = [];
    questions.map(question =>
        questionsObject.push({
            type: question.q_type.question_type === SURVEY_QUESTION_TYPES_VALUES.EMAIL ? "text" : OPTION_TYPES[question.q_type.question_type],
            choices: choicesObject(question.question_options),
            isRequired: true,
            name: question.id,
            title: titleObject(question.translations),
            inputType: OPTION_TYPES[question.q_type.question_type],
            minRateDescription: SURVEY_RESPONSE_STRINGS.MIN_DESCRIPTION,
            maxRateDescription: SURVEY_RESPONSE_STRINGS.MAX_DESCRIPTION,
            rateMin: 0,
            rateMax: 10
        }));
    return questionsObject;
}

function titleObject(translations) {
    const questionTitle = {};
    translations.map(translation => {
        questionTitle[translation.language.language_code] = translation.translation
    });

    return questionTitle;
}

function choicesObject(questionOptions) {
    const questionChoices = [];
    
    try {
        for (let i = 0; i < questionOptions.length; i++) {
            
            let textValue = optionsTextObject(questionOptions[i].option_translations);
            
            if (questionOptions[i]['icon_class'] != "") {
                textValue = '<img src="' + SURVEY_QUESTION_EMOJI_CLASS_URL_CONFIG[questionOptions[i]['icon_class']] + '" alt="' + textValue + '" width="32" height="32" />';
            }
            
            questionChoices.push({
                value: questionOptions[i].id,
                text: textValue
            });
        }
        
    } catch (ex) {
        console.error("choicesObject : error : ", ex);
    }
    
    return questionChoices;
}

function optionsTextObject(optionTranslations) {
    const choiceText = {};
    optionTranslations.map(optionTranslation => {
        choiceText[optionTranslation.language.language_code] = optionTranslation.option_translation
    });
    return choiceText;
}

function getSupportingLanguages(data) {


    try {

        if ( data === null ) {
            return []
        }

        if (data.questions.length === 0) {
            return [];
        }

        let missedLanguages = [];

        if ( typeof data['missed_languages'] != "undefined" ) {
            missedLanguages = data['missed_languages'];
        }

        if ( typeof data['opening_message'] !== "undefined" && data['opening_message'] !== null && typeof data['opening_message']['missed_languages'] != "undefined" ) {
            let missedValues = data['opening_message']['missed_languages'];
            for (let i = 0 ; i < missedValues.length ; i++ ) {
                if ( !missedLanguages.includes(missedValues[i]) ) {
                    missedLanguages.push(missedValues[i]);
                }
            }
        }

        if ( typeof data['closing_message'] != "undefined" && data['closing_message'] != null && typeof data['closing_message']['missed_languages'] != "undefined" ) {
            let missedValues = data['closing_message']['missed_languages'];
            for (let i = 0 ; i < missedValues.length ; i++ ) {
                if ( !missedLanguages.includes(missedValues[i]) ) {
                    missedLanguages.push(missedValues[i]);
                }
            }
        }


        let validLanguages = [];

        for ( let i = 0 ; i < data['supported_languages'].length; i++ ) {
            if ( !missedLanguages.includes(data['supported_languages'][i]['language_code'])){
                validLanguages.push(data['supported_languages'][i]['language_code']);
            }
        }

        return data.supported_languages.filter(l => validLanguages.includes(l.language_code));

    } catch ( ex ) {
        console.error("getSupportingLanguages : ")
    }

    return [];
}

const translationStrings ={
    pagePrevText: "DIC_SURVEY_RESPONSE_PREVIOUS_BUTTON_TEXT",
    pageNextText: "DIC_SURVEY_RESPONSE_NEXT_BUTTON_TEXT",
    completeText: "DIC_SURVEY_RESPONSE_COMPLETE_BUTTON_TEXT",
    completingSurvey: "DIC_SURVEY_RESPONSE_SURVEY_COMPLETE_TEXT",
    requiredError: "DIC_SURVEY_RESPONSE_VALIDATION_TEXT",
    progressText: "DIC_SURVEY_RESPONSE_PAGE_NUMBER_TEXT"
};

const alertMessagesTranslations = {
    "DIC_SURVEY_RESPONSE_START_SURVEY_BUTTON": <IntlMessages id="DIC_SURVEY_RESPONSE_START_SURVEY_BUTTON"/>,
    "DIC_SURVEY_RESPONSE_ERROR_ALREADY_SUBMITTED": <IntlMessages id="DIC_SURVEY_RESPONSE_ERROR_ALREADY_SUBMITTED"/>
};

const initialData = {
    surveys: {},
    supportingLanguages: [],
    surveyId: "",
    clientId: -1,
    isErrorMessage: false,
    isSuccessMessage: false,
    alertMessage: '',
    fetchStatus: true,
    currentLanguage: '',
    isSurveyCompleted: false,
    currentEmployeeId : null,
    translationStrings: translationStrings,
    isCurrentLanguageFetched: false,
    openMessage:{},
    closeMessage:{},
    startSurvey: true,
    isOneResponsePerDevice: true,
    isTranslationApplied: false,
    alertMessagesTranslations: alertMessagesTranslations,
    currentResponses : {},
    currentResponseKey : "",
    responseSaveStatus : HTTP_REQUEST_STATUS.NOT_STARTED,
    attemptCount : 0,
    isRetryButtonVisible : false,
    isAutoRetryEnabled : false,
    isResponseSubmitted : false,
    responseBackgroundSaveStatus : HTTP_REQUEST_STATUS.NOT_STARTED,
    prevSavedResponses : {},
    requestId : "",
    identifier: null
};

const ReducerSurveyResponses = (state = initialData, action) => {

    switch (action.type) {

        case SURVEY_RESPONSE_SET_EMPLOYEE_ID:{
            return {
                ...state,
                currentEmployeeId : action.payload
            }
        }

        case SURVEY_RESPONSE_SET_IDENTIFIER: {
            return {
                ...state,
                identifier: action.payload
            }
        }

        case SURVEY_RESPONSE_GET_AUTHENTICATE_SET_ERROR: {
            return {
                ...state,
                fetchStatus: false,
                isErrorMessage: true,
                alertMessage: action.payload,
                isTranslationApplied: true
            };
        }

        /**survey response error reset **/
        case SURVEY_RESPONSE_GET_AUTHENTICATE_MSG_RESET: {
            return {
                ...state,
                isSuccessMessage: false,
                isErrorMessage: false,
                alertMessage: ""
            };
        }

        /**survey response fetch error handing **/
        case SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER_ERROR: {

            return {
                ...state,
                isErrorMessage: true,
                alertMessage: action.payload.error,
                fetchStatus: false,
                isTranslationApplied: true
            };
        }

        case SURVEY_RESPONSE_UPDATE_LANGUAGE: {

            let closeMessage = state.closeMessage;
            const selectedLanguage = action.payload;

            if (typeof closeMessage !== 'undefined' &&
                typeof closeMessage['translations'] !== 'undefined' &&
                !(selectedLanguage in closeMessage['translations'])) {
                closeMessage["translations"][selectedLanguage] = "DIC_SURVEY_RESPONSE_SURVEY_COMPLITION_INFO";
            }

            return {
                ...state,
                currentLanguage: action.payload,
                closeMessage: closeMessage
            }
        }

        case SURVEY_RESPONSE_SET_CURRENT_CLIENT:{

            return {
                ...state,
                clientId : action.payload
            }
        }

        /**authenticate survey user success **/
        case SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER_SUCCESS: {

            let isSurveyCompleted = action.payload['is_completed'];
            let requestId = typeof action.payload['request_id'] != "undefined" ? action.payload['request_id'] : "";

            let survey = action.payload.survey;
            let user = action.payload.user;

            let currentLanguage = DEFAULT_LANGUAGE_CODE;

            try {
                if (typeof survey.supported_languages != "undefined" && survey.supported_languages.length > 0) {

                    currentLanguage = getCurrentLanguage(survey.supported_languages, user);

                }
            } catch (e) {
                console.log("error : ", e)
            }

            if (isSurveyCompleted) {
                return {
                    ...state,
                    isSurveyCompleted : isSurveyCompleted,
                    requestId: "",
                    fetchStatus: false,
                    isErrorMessage: false,
                    alertMessage: "DIC_SURVEY_RESPONSE_ERROR_ALREADY_SUBMITTED",
                    currentLanguage: currentLanguage,
                    supportingLanguages: getSupportingLanguages(survey),
                    isCurrentLanguageFetched: true
                }
            } else {
                survey.questions = AppUtils.getSortedQuestions(survey.questions);
                
                // if ( typeof survey["is_randomize"] != "undefined" && survey["is_randomize"]) {
                //     survey.questions = AppUtils.getRandomizedQuestions(survey.questions);
                // }
                let openMessage = {
                    "translations" : {},
                    "url" : ""
                };

                let startSurvey = true;
                if(survey['opening_message'] != null){
                    const translations = survey['opening_message'].translation;
                    translations.map(transaltion =>{
                        openMessage["translations"][transaltion.language.language_code] = transaltion['translation'].replace('$$',' ');
                    })
                    startSurvey = false;
                    if ( typeof survey['opening_message'] != "undefined" && typeof survey['opening_message']["url"] != "undefined" ) {
                        openMessage["url"] = survey['opening_message']["url"];
                    }
                }

                let closeMessage = {
                    "translations" : {},
                    "url" : ""
                };

                if(survey['closing_message'] != null){
                    const translations = survey['closing_message'].translation;
                    translations.map(transaltion =>{
                        closeMessage["translations"][transaltion.language.language_code] = transaltion['translation'].replace('$$',' ');
                    })

                    if ( typeof survey['closing_message'] != "undefined" && typeof survey['closing_message']["url"] != "undefined" ) {
                        closeMessage["url"] = survey['closing_message']["url"];
                    }
                } else {
                    closeMessage["translations"][currentLanguage] = "DIC_SURVEY_RESPONSE_SURVEY_COMPLITION_INFO";
                }

                let surveyId = survey.id;
                let clientId = user && user.client_id.length > 0 ? user.client_id[0] : state.clientId;

                /**if employee id is null then check for identifier */
                let userIdentifier = null;
                if(state.currentEmployeeId !== null) {
                    userIdentifier = state.currentEmployeeId;
                }
                else if(state.identifier !== null) {
                    userIdentifier = state.identifier;
                }

                let prevSavedResponses = StorageHandler.getSurveyPartialResponse(surveyId, clientId, userIdentifier);

                return {
                    ...state,
                    surveys: surveyJSON(survey),
                    supportingLanguages: getSupportingLanguages(survey),
                    surveyId: survey.id,
                    clientId: clientId,
                    isErrorMessage: false,
                    isSuccessMessage: true,
                    fetchStatus: false,
                    currentLanguage: currentLanguage,
                    isCurrentLanguageFetched: true,
                    survey: survey,
                    openMessage: openMessage,
                    closeMessage: closeMessage,
                    startSurvey: startSurvey,
                    isOneResponsePerDevice: survey['one_response_per_device'],
                    prevSavedResponses: prevSavedResponses,
                    requestId: requestId
                };
            }
        }

        case SURVEY_RESPONSE_SAVE_RESPONSE: {
            let responses = action.payload;
            StorageHandler.saveSurveyResponse(responses);

            /**if employee id(user id) is null then check for identifier */
            let userIdentifier = null;
            if(state.currentEmployeeId !== null) {
                userIdentifier = state.currentEmployeeId;
            }
            else if(state.identifier !== null) {
                userIdentifier = state.identifier;
            }

            StorageHandler.deleteSurveyPartialResponse(state.surveyId, state.clientId, userIdentifier);

            return {
                ...state,
                currentResponses: responses,
                currentResponseKey: responses['key'],
                responseSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                fetchStatus: true
            }
        }


        /** survey response save : error **/
        case SURVEY_RESPONSE_SAVE_RESPONSE_ERROR: {

            let attemptCount = state.attemptCount + 1;
            let isRetryButtonVisible = state.isRetryButtonVisible;
            let isAutoRetryEnabled = state.isAutoRetryEnabled;

            if (attemptCount <= SURVEY_RESPONSE_ITERATIONS_COUNT) {
                isAutoRetryEnabled = true;
                isRetryButtonVisible = false;
            } else {
                isRetryButtonVisible = true;
                isAutoRetryEnabled = false;
            }

            return {
                ...state,
                alertMessage: typeof action.payload.message != "undefined" ? action.payload.message : "",
                isErrorMessage: true,
                isSuccessMessage: false,
                responseSaveStatus: HTTP_REQUEST_STATUS.FAIL,
                attemptCount: attemptCount,
                fetchStatus: false,
                isRetryButtonVisible: isRetryButtonVisible,
                isAutoRetryEnabled: isAutoRetryEnabled,
                isResponseSubmitted: false
            };
        }

        /** survey response save : success **/
        case SURVEY_RESPONSE_SAVE_RESPONSE_SUCCESS: {

            if ( typeof action.payload.key != "undefined" ) {
                StorageHandler.removeSurveyResponse(action.payload.key);
            }

            let storageKey = state.surveyId;

            if ( state.clientId !== -1 ) {
                storageKey += "_" + state.clientId;
            }

            /**if employee id(user id) is null then check for identifier */
            let userIdentifier = null;
            if(state.currentEmployeeId !== null) {
                userIdentifier = state.currentEmployeeId;
            }
            else if(state.identifier !== null) {
                userIdentifier = state.identifier;
            }
            
            StorageHandler.markSurveyAsComplete( storageKey, userIdentifier );

            return {
                ...state,
                alertMessage: action.payload.message,
                isErrorMessage: false,
                isSuccessMessage: true,
                responseSaveStatus : HTTP_REQUEST_STATUS.SUCCESS,
                fetchStatus: false,
                isRetryButtonVisible: false,
                isAutoRetryEnabled: false,
                attemptCount: 0,
                isResponseSubmitted : true
            }
        }

        case SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND: {

            return {
                ...state,
                responseBackgroundSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }


        /** survey response save : error **/
        case SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_ERROR: {

            return {
                ...state,
                responseBackgroundSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };
        }

        /** survey response save : success **/
        case SURVEY_RESPONSE_SAVE_RESPONSE_BACKGROUND_SUCCESS: {

            if ( typeof action.payload.key != "undefined" ) {
                StorageHandler.removeSurveyResponse(action.payload.key);
            }

            return {
                ...state,
                responseBackgroundSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case SURVEY_RESPONSE_CURRENT_LANGUAGE_FETCH_STATUS: {
            return{
                ...state,
                isCurrentLanguageFetched: false
            }
        }

        case SURVEY_RESPONSE_CHANGE_SURVEY_STATUS:{
            return{
                ...state,
                startSurvey: true
            }
        }

        case SURVEY_RESPONSE_APPLY_ALERT_MESSAGE_TRANSLATIONS:{
            return {
                ...state,
                alertMessagesTranslations: Object.keys(action.payload).length > 0?action.payload:alertMessagesTranslations,
                isTranslationApplied: true
            };
        }

        default:
            return state
    }
};

function getCurrentLanguage(supportedLanguages, user) {
    try {
        const languageCodes = supportedLanguages.map(lang => lang.language_code);

        let userLang = navigator.language || navigator.userLanguage;

        if (typeof user !== 'undefined' && typeof user['user_language_code'] !== 'undefined' && typeof languageCodes !== 'undefined') {
            if (languageCodes.includes(user['user_language_code'])) {
                return user['user_language_code'];
            } else {
                return languageCodes[0];
            }
        }
        else {
            if (userLang && languageCodes && languageCodes.includes(userLang)) {
                return userLang;
            }
            else if (userLang && languageCodes && userLang.indexOf("-") !== -1 && languageCodes.includes(userLang.split("-")[0])) {
                return userLang.split("-")[0];
            }
            else if (userLang && languageCodes && userLang.indexOf("-") === -1) {
                for (let i = 0; i < languageCodes.length; i++) {
                    if (languageCodes[i].indexOf("-") !== -1 && languageCodes[i].split("-")[0] === userLang)
                        return languageCodes[i];
                }
            }
        }
    }
    catch (ex) {
        console.log("Getting current language : ERROR : " + ex);
    }

    return supportedLanguages[0]['language_code'];
}

export default ReducerSurveyResponses;
