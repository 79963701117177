const STORAGE_KEY_CLIENTS_STORAGE = "CLIENTS_STORAGE";
const STORAGE_KEY_CURRENT_USER = "CURRENT_USER";
const STORAGE_KEY_CURRENT_USER_STATE = "CURRENT_USER_STATE";
const STORAGE_KEY_CURRENT_USER_NAME = "CURRENT_USER_NAME";
const STORAGE_KEY_SURVEY_SUBMIT_STATUS = "SURVEY_SUBMIT_STATUS";
const STORAGE_KEY_SURVEY_RESPONSES = "STORAGE_KEY_SURVEY_RESPONSES";
const STORAGE_KEY_SURVEY_PARTIAL_RESPONSES = "STORAGE_KEY_SURVEY_PARTIAL_RESPONSES";

const StorageHandler = {
    keys: {
        "PREFERRED_CLIENT": "PREFERRED_CLIENT"

    },
    /** to mark passed survey as submitted responses **/
    markSurveyAsComplete: function (surveyId, employeeId) {
        try {

            let prevState = window.localStorage[STORAGE_KEY_SURVEY_SUBMIT_STATUS];

            if (typeof prevState != "undefined" && prevState !== "undefined" && prevState !== "") {
                prevState = JSON.parse(prevState);
            } else {
                prevState = {};
            }

            if (typeof prevState[surveyId] == "undefined") {
                prevState[surveyId] = [];
            }

            prevState[surveyId].push(employeeId);

            window.localStorage[STORAGE_KEY_SURVEY_SUBMIT_STATUS] = JSON.stringify(prevState);

        } catch (ex) {
            console.error("StorageHandler : markSurveyAsComplete : ERROR : ", ex);
        }
    },

    /** to check whether shared survey responses submitted or not  **/
    isSurveyCompleted: function (surveyId, employeeId) {

        let result = false;

        try {

            let prevState = window.localStorage[STORAGE_KEY_SURVEY_SUBMIT_STATUS];
            let userSubmitArray = [];

            try {
                if (typeof prevState != "undefined" && prevState !== "undefined" && prevState !== "") {
                    prevState = JSON.parse(prevState);
                    userSubmitArray = prevState[surveyId];
                }
            } catch (ex) {
            }

            if (typeof userSubmitArray == "undefined") {
                userSubmitArray = []
            }

            for (let i = 0; i < userSubmitArray.length; i++) {
                if (userSubmitArray[i] == employeeId) {
                    result = true;
                    break;
                }
            }
        } catch (ex) {
            console.error("StorageHandler : isSurveyCompleted : ERROR : ", ex);
        }

        return result;

    },

    /** to check whether shared survey responses submitted or not  **/
    isSurveyCompletedForTheDevice: function (surveyId) {

        let result = false;

        try {

            let prevState = window.localStorage[STORAGE_KEY_SURVEY_SUBMIT_STATUS];
            let userSubmitArray = [];

            try {
                if (typeof prevState != "undefined" && prevState !== "undefined" && prevState !== "") {
                    prevState = JSON.parse(prevState);
                    userSubmitArray = prevState[surveyId];
                }
            } catch (ex) {
            }

            if (typeof userSubmitArray != "undefined" && userSubmitArray.length > 0) {
                result = true;
            }


        } catch (ex) {
            console.error("StorageHandler : isSurveyCompletedForTheDevice : ERROR : ", ex);
        }

        return result;

    },

    /** to store the client details based on passed user **/
    saveCurrentClient: function (client) {
        try {


            let clientsStorage = window.localStorage[STORAGE_KEY_CLIENTS_STORAGE];

            if (typeof clientsStorage == "undefined") {
                clientsStorage = {};
            } else {
                clientsStorage = JSON.parse(clientsStorage);
            }

            let userName = this.getCurrentUserName();
            clientsStorage[userName] = JSON.stringify(client);
            window.localStorage[STORAGE_KEY_CLIENTS_STORAGE] = JSON.stringify(clientsStorage);

        } catch (ex) {
            console.error("StorageHandler : saveCurrentClient : ERROR : ", ex);
        }
    },

    /** to retrieve the stored client details based on passed user **/
    getCurrentClient: function () {
        let result = {};
        try {

            let userName = this.getCurrentUserName();

            if (typeof userName != "undefined") {
                let clientsStorage = window.localStorage[STORAGE_KEY_CLIENTS_STORAGE];

                if (typeof clientsStorage != "undefined") {
                    clientsStorage = JSON.parse(clientsStorage);
                    result = typeof clientsStorage[userName] != "undefined" ? JSON.parse(clientsStorage[userName]) : {};
                }
            }

        } catch (ex) {
            console.error("StorageHandler : saveCurrentClient : ERROR : ", ex);
        }

        return result;
    },

    /** to get the current user from storage **/
    getCurrentUser: function () {
        let result = {};

        try {
            let user = window.localStorage[STORAGE_KEY_CURRENT_USER];

            if (typeof user != "undefined") {
                result = JSON.parse(user);
                if (typeof result['user'] != "undefined") {

                }
            }
        } catch (ex) {
            console.error("StorageHandler : saveCurrentUser : ERROR : ", ex);
        }

        return result;
    },

    /** to get the current user from storage **/
    saveCurrentUserName: function (userName) {
        try {
            window.localStorage[STORAGE_KEY_CURRENT_USER_NAME] = userName;
        } catch (ex) {
            console.error("StorageHandler : saveCurrentUserName : ERROR : ", ex);
        }
    },

    /** to get the current user from storage **/
    getCurrentUserName: function () {
        let result = "";
        try {
            let tempUserName = window.localStorage[STORAGE_KEY_CURRENT_USER_NAME];
            if (typeof tempUserName != "undefined") {
                result = tempUserName;
            }
        } catch (ex) {
            console.error("StorageHandler : getCurrentUserName : ERROR : ", ex);
        }
        return result;
    },

    saveUserState: function (userName, keys) {
        try {
            window.localStorage[STORAGE_KEY_CURRENT_USER_STATE] = JSON.stringify(keys);
        } catch (ex) {
            console.error("StorageHandler : saveUserKeys : ERROR : ", ex);
        }
    },

    /** to retrieve the stored keys details based on user **/
    getUserState: function () {
        let result = {};
        try {

            let currentUser = window.localStorage[STORAGE_KEY_CURRENT_USER_STATE];

            if (typeof currentUser != "undefined") {
                result = JSON.parse(currentUser);
            }
        } catch (ex) {
            console.error("StorageHandler : getUserKeys : ERROR : ", ex);
        }

        return result;
    },

    setCookie: (cookie_name, cookie_value, expires_in = 90) => {
        try {
            const d = new Date();
            d.setTime(d.getTime() + (expires_in * 24 * 60 * 60 * 1000));
            let expires = "expires="+d.toUTCString();
            document.cookie = cookie_name + "=" + cookie_value + ";" + expires + ";path=/";
        } catch (e) {
            console.log("document cookie storage not compatible")
        }
    },

    eraseCookie: (name) => {
        document.cookie = name + '=;Max-Age=-99;expires=Thu, 01-Jan-70 00:00:01 GMT;path=/'
    },

    getCookie: (cookie_name) => {
        let name = cookie_name + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    },

    saveItem: function (key, value) {
        try {
            window.localStorage[key] = value;
        } catch (ex) {
            console.error("StorageHandler : saveItem : ERROR : ", ex);
        }
    },

    getItem: function (key) {
        let result = "";
        try {
            let temp = window.localStorage[key];

            if (typeof temp != "undefined") {
                result = temp;
            }

        } catch (ex) {
            console.error("StorageHandler : getItem : ERROR : ", ex);
        }

        return result;
    },

    saveSurveyResponse: function (payload) {
        try {
            let key = payload['key'];
            let currentData = this.getItem(STORAGE_KEY_SURVEY_RESPONSES);

            if (currentData === "") {
                currentData = {};
            }

            currentData[key] = payload;

            this.saveItem(STORAGE_KEY_SURVEY_RESPONSES, JSON.stringify(currentData));

        } catch (ex) {
            console.error("saveSurveyResponse : error : ", ex);
        }
    },

    removeSurveyResponse: function (key) {
        try {
            let currentData = this.getItem(STORAGE_KEY_SURVEY_RESPONSES);

            if (currentData !== "") {
                currentData = {};
            }

            if (typeof currentData[key] != "undefined") {
                delete currentData[key];
            }

            this.saveItem(STORAGE_KEY_SURVEY_RESPONSES, currentData);

        } catch (ex) {
            console.error("removeSurveyResponse : error : ", ex);
        }
    },

    getSurveyResponses: function () {
        try {

            let content = this.getItem(STORAGE_KEY_SURVEY_RESPONSES);

            if (typeof content == "undefined" || content === "") {
                content = {};
            } else {
                content = JSON.parse(content);
            }

            return content;

        } catch (ex) {
            console.error("getSurveyResponses : error : ", ex);
        }

        return {};
    },

    saveSurveyPartialResponse: function (responses) {
        try {



            let content = this.getItem(STORAGE_KEY_SURVEY_PARTIAL_RESPONSES);

            if ( typeof content != "undefined" && content != "" ) {
                content = JSON.parse(content);
            } else {
                content = {};
            }

            let surveyId = responses['surveyId'] + "";
            let clientId = responses['clientId'] + "";
            let userId = responses['userId'] + "";
            let data = responses['data'];

            if (typeof content[surveyId] == "undefined") {
                content[surveyId] = {};
            }

            if (typeof content[surveyId][clientId] == "undefined") {
                content[surveyId][clientId] = {};
            }

            if (typeof content[surveyId][clientId][userId] == "undefined") {
                content[surveyId][clientId][userId] = {};
            }

            content[surveyId][clientId][userId] = data;
            
            this.saveItem(STORAGE_KEY_SURVEY_PARTIAL_RESPONSES, JSON.stringify(content));

        } catch (ex) {
            console.error("saveSurveyPartialResponse : error : ", ex);
        }
    },

    getSurveyPartialResponse: function (surveyId, clientId, userId) {
        try {

            console.log("getSurveyPartialResponse : ", surveyId, clientId, userId)


            let content = this.getItem(STORAGE_KEY_SURVEY_PARTIAL_RESPONSES);

            if ( typeof content != "undefined" && content != "" ) {
                content = JSON.parse(content);
            } else {
                content = {};
            }

            if ( typeof content[surveyId] != "undefined" && typeof content[surveyId][clientId] != "undefined" && typeof content[surveyId][clientId][userId] != "undefined") {
                return content[surveyId][clientId][userId];
            }

        } catch (ex) {
            console.error("getSurveyPartialResponse : error : ", ex);
        }

        return {};
    },

    deleteSurveyPartialResponse: function (surveyId, clientId, userId) {
        try {

            let content = this.getItem(STORAGE_KEY_SURVEY_PARTIAL_RESPONSES);

            if ( typeof content != "undefined" && content != "" ) {
                content = JSON.parse(content);
            } else {
                content = {};
            }

            if ( typeof content[surveyId] != "undefined" && typeof content[surveyId][clientId] != "undefined" && typeof content[surveyId][clientId][userId] != "undefined") {
                delete content[surveyId][clientId][userId];
            }

            this.saveItem(STORAGE_KEY_SURVEY_PARTIAL_RESPONSES, JSON.stringify(content));

        } catch (ex) {
            console.error("deleteSurveyPartialResponse : error : ", ex);
        }
    },




};

export default StorageHandler;
