import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import { FormControl } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import { PRIMARY_COLOR_BLUE } from 'constants/customUIConfig';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
	maxingSelectHeight: {
		'& .MuiSelect-selectMenu': {
			height: '1.1876em',
		},
	},
}));

function MultiSelectChipDropdown({ params }) {
	const {
		items: origianlItems,
		localisation,
		key,
		inputLabelStyle,
		changeCallback,
		selectedItems,
		id,
		withInput,
	} = params;
	/**State variable to store the selected and deselected values locally */
	const [currentItems, setCurrentItems] = useState(origianlItems);
	const [items, setItems] = useState(origianlItems);
	/**State variable to validate when deletion is done*/
	const [isItemDeleted, setIsItemDeleted] = useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [searchTerm, setSearchTerm] = useState(null);

	const [selectWidth, setSelectWidth] = useState(0);
	/**This is to handle unfocus on single deselection and window resize**/
	const refFilterWidth = useRef(0);
	/**This is to handle unfocus on single deselection**/
	const refLabelFocus = useRef(null);
	const classes = useStyles();
	/**Max width of the chip to calculate number of chips based on resolution */
	const chipMaxWidth = 110;

	/**External label configuration if any given */
	let labelStyle = {};
	if (typeof inputLabelStyle !== 'undefined') {
		for (let key in inputLabelStyle) {
			labelStyle[key] = inputLabelStyle[key];
		}
	}

	useEffect(() => {
		setItems(origianlItems);
	}, [origianlItems]);

	useEffect(() => {
		if (searchTerm === '' || searchTerm === null || typeof searchTerm === 'undefined') {
			setItems(origianlItems);
		} else {
			const searchedItems = origianlItems.filter((item) => {
				if (item.name) {
					return item.name.toLowerCase().includes(searchTerm.toLowerCase());
				} else if (item.label) {
					return item.label.toLowerCase().includes(searchTerm.toLowerCase());
				}
			});
			// const currentSelectedItems = currentItems.map((item) => item.id);
			// const newCurrentItems = searchedItems.filter((item) =>
			// 	currentSelectedItems.includes(item.id)
			// );
			// setCurrentItems(newCurrentItems);
			setItems([...searchedItems]);

			// handleChange()
		}
	}, [searchTerm]);

	/*********************************************State && Side Effects******************************************************** */

	/**to reload component based on total list of items passed to it*/
	useEffect(() => {
		if (selectedItems) {
			const selectedItemIds = selectedItems.map((item) => item.id);
			const newSelectedItems = origianlItems.filter((item) =>
				selectedItemIds.includes(item.id)
			);
			setCurrentItems(newSelectedItems);
		}
	}, [selectedItems]);

	/**to reset accomodate chips based on with of select element*/
	useEffect(() => {
		function handleResize() {
			setWindowWidth(window.innerWidth);
		}
		window.addEventListener('resize', handleResize);
		return (_) => window.removeEventListener('resize', handleResize);
	}, [selectWidth]);

	/**to reload component with new set of values on reload*/
	useEffect(() => {
		if (isItemDeleted) {
			changeCallback(key, currentItems);
			setIsItemDeleted(false);
		}
		refFilterWidth.current.classList.remove('Mui-focused');
		refLabelFocus.current.classList.remove('Mui-focused');
	}, [isItemDeleted]);

	/**to reload component based on window resize*/
	useLayoutEffect(() => {
		setSelectWidth(refFilterWidth.current.clientWidth);
	}, [windowWidth]);

	/**To adjust the number of elements to be displayed on selection*/
	const numberOfElementsToBeDisplayed =
		Math.floor(selectWidth / chipMaxWidth) * -1;

	/****************************************************Event Handler Functions functions*********************************************** */

	let handleSelectAll = () => {
		try {
			setCurrentItems(items);
			refFilterWidth.current.classList.add('Mui-focused');
		} catch (err) {
			console.error('handleSelectAll : error : ', err);
		}
	};

	let handleDeselectAll = () => {
		try {
			setCurrentItems([]);
		} catch (err) {
			console.error('handleDeselectAll : error : ', err);
		}
	};

	let handleDelete = (id) => {
		try {
			setCurrentItems(currentItems.filter((x) => x.id !== id));
			setIsItemDeleted(true);
		} catch (err) {
			console.error('handleDelete : error : ', err);
		}
	};

	let handleMenuClose = () => {
		try {
			changeCallback(key, currentItems);
		} catch (err) {
			console.error('handleMenuClose : error : ', err);
		}
	};

	/****************************************************Event Handlers******************************************************/

	/* Triggers handlechange only when the checkbox element is interacted with*/
	let handleChange = (event) => {
		try {
			if (
				event &&
				event.currentTarget.nodeName &&
				event.currentTarget.nodeName === 'LI'
			) {
				setCurrentItems(event.target.value);
			}
		} catch (err) {
			console.error('handleChange : error', err);
		}
	};

	/****************************************************Renders********************************************************* */

	/* This renders select and deselect label depending on number of options selected */
	let renderSelection = () => {
		let currentItemsLength = 0;
		let totalItemsLength = 0;

		if (typeof currentItems !== 'undefined') {
			currentItemsLength = currentItems.length;
		}
		if (typeof items !== 'undefined') {
			totalItemsLength = items.length;
		}

		return currentItemsLength === totalItemsLength ? (
			<div
				onClick={() => {
					handleDeselectAll();
				}}
				className="menu_item_selectAll_deselectAll_label"
			>
				<IntlMessages id="DIC_KEY_MULTISELECT_DROPDOWN_CHIP_DE_SELECT_ALL_LABEL" />
			</div>
		) : (
			<div
				onClick={() => {
					handleSelectAll();
				}}
				className="menu_item_selectAll_deselectAll_label"
			>
				<IntlMessages id="DIC_KEY_MULTISELECT_DROPDOWN_CHIP_SELECT_ALL_LABEL" />
			</div>
		);
	};

	/* This function renders the chip inside box component */
	let renderChip = (value) => {
		let chipName = value.localisation ? (
			<IntlMessages id={value.localisation} />
		) : value.name ? (
			value.name
		) : (
			''
		);
		return (
			<Chip
				size="medium"
				className="chip_style"
				key={value.id}
				label={chipName}
				color={PRIMARY_COLOR_BLUE}
				onMouseDown={(event) => {
					event.stopPropagation();
				}}
				onDelete={() => {
					handleDelete(value.id);
				}}
				deleteIcon={<Close className="chip_delete_icon" />}
			/>
		);
	};

	/* This function renders the box inside the select component */
	let renderBox = (selected) => {
		return (
			<Box className="chip_box">
				<div className="chip_dots">
					{Math.abs(numberOfElementsToBeDisplayed) < selected.length
						? '...'
						: ''}
				</div>
				{selected.length < 2
					? selected.map((value) => {
							return renderChip(value);
					  })
					: selected.slice(numberOfElementsToBeDisplayed).map((value) => {
							return renderChip(value);
					  })}
				<div className="total_chip_count">
					{Math.abs(numberOfElementsToBeDisplayed) < selected.length &&
						`(${selected.length})`}
				</div>
			</Box>
		);
	};

	/* This function is used to show if all or none of the elements is selected instead of chips */
	let renderText = (value) => {
		if (value.length === (origianlItems && origianlItems.length)) {
			return (
				<span className="single_select_label">
					<IntlMessages id="DIC_KEY_MULTISELECT_DROPDOWN_CHIP_ALL_LABEL" />
				</span>
			);
		} else {
			return (
				<span className="single_select_label">
					<IntlMessages id="DIC_KEY_MULTISELECT_DROPDOWN_CHIP_SELECT_NONE" />
				</span>
			);
		}
	};

	const renderSearchInput = () => {
		return (
			<MenuItem
				className="menu_item"
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
				}}
			>
				<TextField
					value={searchTerm}
					onChange={(e) => {
						setSearchTerm(e.currentTarget.value);
						e.stopPropagation();
						e.preventDefault();
					}}
					fullWidth
					labelWidth={0}
					size="small"
					variant="outlined"
					onKeyDown={(e)=>{
						e.stopPropagation();
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			</MenuItem>
		);
	};

	return (
		<FormControl className="form_select" size="medium">
			<InputLabel
				className="select_input_label"
				shrink
				htmlFor="multiple-checkbox-label"
				style={labelStyle}
				ref={refLabelFocus}
			>
				{localisation}
			</InputLabel>
			<Select
				className={classes.maxingSelectHeight}
				id={id}
				ref={refFilterWidth}
				variant="outlined"
				labelId="multiple-checkbox-label"
				multiple
				displayEmpty
				value={currentItems}
				autoWidth={false}
				onChange={(event) => {
					event.stopPropagation()
					event.preventDefault()
					handleChange(event)
				}}
				onClose={(e) => {
					handleMenuClose();
				}}
				onKeyDown={(e)=>{
					e.stopPropagation();
				}}
				input={
					<OutlinedInput
						notched
						id="multiple-checkbox-label"
						label={localisation}
					/>
				}
				MenuProps={{
					style: {
						width: selectWidth,
					},
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right',
					},
					onKeyDown: (e) => {
						e.stopPropagation();
					},
					variant: 'menu',
					getContentAnchorEl: null,
					transformOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				}}
				/* This render value is what provides the list of items back to the component for implementation */
				renderValue={(selected) => {
					let lengthOfSelectedItems = origianlItems && origianlItems.length;
					if (
						selected.length === 1 &&
						typeof lengthOfSelectedItems !== undefined &&
						selected.length < lengthOfSelectedItems
					) {
						return renderBox(selected);
					} else if (
						selected.length > 1 &&
						typeof lengthOfSelectedItems !== undefined &&
						selected.length < lengthOfSelectedItems
					) {
						return renderBox(selected);
					} else {
						return renderText(selected);
					}
				}}
			>
				{/* This part renders the list items as no data available in case the passed list is empty */}
				{origianlItems && selectedItems ? (
					<>
						{withInput && renderSearchInput()}
						{renderSelection()}
					</>
				) : (
					<IntlMessages id="DIC_KEY_MULTISELECT_DROPDOWN_NO_DATA_TO_DISPLAY" />
				)}
				{items &&
					items.map((data, index) => {
						return (
							items &&
							selectedItems && (
								<MenuItem
									className="menu_item"
									key={`${data.id}-${index}`}
									value={data}
								>
									<CustomCheckbox
										className="list_item_checkbox"
										checked={
											typeof (
												currentItems &&
												currentItems.find((x) => x.id === data.id)
											) !== 'undefined'
										}
									/>
									<ListItemText
										style={{
											whiteSpace: 'initial',
										}}
										className="list_item_label"
										primary={
											data.localisation ? (
												<IntlMessages id={data.localisation} />
											) : data.name ? (
												data.name
											) : (
												''
											)
										}
									/>
								</MenuItem>
							)
						);
					})}
			</Select>
		</FormControl>
	);
}

MultiSelectChipDropdown.propTypes = {
	items: PropTypes.array,
	localisation: PropTypes.string,
	key: PropTypes.string,
	inputLabelStyle: PropTypes.any,
	changeCallback: PropTypes.func,
	selectedItems: PropTypes.array,
};

const checkBoxStyles = (theme) => ({
	root: {
		'&$checked': {
			color: PRIMARY_COLOR_BLUE,
		},
		tickSize: {
			transform: 'scale(1.5)',
		},
	},
	checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

export default MultiSelectChipDropdown;
