import React from 'react';
import {HTTP_REQUEST_STATUS} from "../../../constants/constants";
import { SORTING_DIRECTION_VALUES } from "../../../constants/config";
import {
    SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_IN_PROGRESS,
    SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_NONE,
    SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_ERROR,
    SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_SUCCESS,    
    SURVEY_TEMPLATE_SET_CURRENT_REPORT_TYPE,
    SURVEY_TEMPLATE_SET_CURRENT_GROUP_BY_TYPE,
    SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_IN_PROGRESS,
    SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_SUCCESS,
    SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_ERROR,
    SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_NONE,
    SURVEY_TEMPLATE_SUMMARY_SET_TABLE_RELOAD
} from "../../../constants/actions/survey/survey/actioTypesSurveyTemplate";
import {
    SURVEY_GET_SUMMARY_TABLE_DATA_ERROR,
    SURVEY_GET_SUMMARY_TABLE_DATA_IN_PROGRESS,
    SURVEY_GET_SUMMARY_TABLE_DATA_NONE,
    SURVEY_GET_SUMMARY_TABLE_DATA_SUCCESS,
    SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_ERROR,
    SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_IN_PROGRESS,
    SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_NONE,
    SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_SUCCESS,
    SURVEY_SET_CURRENT_REPORT_TYPE,
    SURVEY_SET_CURRENT_GROUP_BY_TYPE,
    SURVEY_SUMMARY_SET_TABLE_RELOAD,
    SET_SUMMARY_TABLE_LAYOUT_TYPE,
    SURVEY_SUMMARY_TABLE_SORTING,
    SURVEY_SUMMARY_TABLE_SORTING_NONE
} from "../../../constants/actions/survey/survey/actionTypesSurvey";
import {
    SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS,
    SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS,
    SURVEY_SUMMARY_TABLE_REPORT_TYPE_FILTER_VALUE_CONSTANTS,
    SUMMARY_TABLE_LAYOUT_TYPES
} from "../../../constants/config";
import IntlMessages from "../../../util/IntlMessages";
import {
    TABLE_HEADER_SURVEY_AVERAGE_BACKGROUND_COLOR,
    TABLE_HEADER_RESPONSE_COUNT_BACKGROUND_COLOR,
    TABLE_RESPONSE_COUNT_COLOR,
    TABLE_CELL_BACKGROUB_COLOR
} from "../../../constants/customUIConfig";
import {baseServerUrl} from "../../../util/keys";
import { FlashOnRounded } from '@material-ui/icons';

let groupByOptions = [
    {
        key: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_OVERALL,
        value: SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_OVERALL,
        headerValue: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_OVERALL,
        label: "DIC_SURVEY_REPORTS_SUMMARY_TABLE_COLUMN_TITLE_OVERALL"
    },
    {
        key: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_GENDER,
        value: SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_GENDER,
        headerValue: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_GENDER,
        label: "DIC_SURVEY_REPORTS_SUMMARY_TABLE_COLUMN_TITLE_GENDER"
    },
    {
        key: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_AGE_GROUP,
        value: SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_AGE_GROUP,
        headerValue: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_AGE_GROUP,
        label: "DIC_SURVEY_REPORTS_SUMMARY_TABLE_COLUMN_TITLE_AGE_GROUP"
    },
    {
        key: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_TENURE_GROUP,
        value: SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_TENURE_GROUP,
        headerValue: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_TENURE_GROUP,
        label: "DIC_SURVEY_REPORTS_SUMMARY_TABLE_COLUMN_TITLE_TENURE_GROUP"
    },
    {
        key: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_JOB_ROLE,
        value: SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_JOB_ROLE,
        headerValue: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_JOB_ROLE,
        label: "DIC_SURVEY_REPORTS_SUMMARY_TABLE_COLUMN_TITLE_JOB_ROLE"
    }
    
];

let additionalGroupByOptions = [
    {
        key: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_COMPANIES,
        value: SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_COMPANIES,
        headerValue: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_COMPANIES,
        label: "DIC_SURVEY_REPORTS_SUMMARY_TABLE_COLUMN_TITLE_COMPANIES"
    },
    {
        key: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_COUNTRIES,
        value: SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_COUNTRIES,
        headerValue: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_COUNTRIES,
        label: "DIC_SURVEY_REPORTS_SUMMARY_TABLE_COLUMN_TITLE_COUNTRIES"
    }
];

let clientDeptGroupByOptions = [
    {
        key: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_DEPARTMENTS,
        value: SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_DEPARTMENTS,
        headerValue: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_DEPARTMENTS,
        label: "DIC_SURVEY_REPORTS_SUMMARY_TABLE_COLUMN_TITLE_DEPARTMENTS"
    }
];

let overallGroupByOptions = [
    {
        key: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_OVERALL,
        value: SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_OVERALL,
        headerValue: SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS.FILTER_VALUE_OVERALL,
        label: "DIC_SURVEY_REPORTS_SUMMARY_TABLE_COLUMN_TITLE_OVERALL_COMPANIES"
    }
];

let viewDataByOptions = [
    {
        value: SURVEY_SUMMARY_TABLE_REPORT_TYPE_FILTER_VALUE_CONSTANTS.BY_QUESTION,
        headerLabel: <IntlMessages id="DIC_SURVEY_REPORTS_SUMMARY_TABLE_COLUMN_TITLE_QUESTIONS"/>,
        label: "DIC_SURVEY_TEMPLATE_REPORTS_SUMMARY_TABLE_REPORT_TYPE_TITLE_BY_QUESTION"
    },
    {
        value: SURVEY_SUMMARY_TABLE_REPORT_TYPE_FILTER_VALUE_CONSTANTS.BY_CATEGORY,
        headerLabel: <IntlMessages id="DIC_SURVEY_REPORTS_SUMMARY_TABLE_COLUMN_TITLE_CATEGORIES"/>,
        label: "DIC_SURVEY_TEMPLATE_REPORTS_SUMMARY_TABLE_REPORT_TYPE_TITLE_BY_CATEGORY"
    }
];

const initialData = {
    loader: false,
    alertMessage: '',
    isSuccessMessage: false,
    isErrorMessage: false,
    groupByOptions: groupByOptions,
    additionalGroupByOptions: additionalGroupByOptions,
    clientDeptGroupByOptions: clientDeptGroupByOptions,
    overallGroupByOptions: overallGroupByOptions,
    viewDataByOptions: viewDataByOptions,
    summaryTableDataFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    summaryTableData: [],
    summaryTableHeaderData: [],
    summaryTableReportingType : "",
    summaryTableGroupByType : "",
    summaryTableDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    summaryTableDownloadUrl : "",
    summaryTableDownloadError : "",
    isReloadSummaryTable : false,
    layoutType: '',
    columnSortingOptions: [],
    rowSortingOptions: [],
    sortOptions: {},
    columnSorted: false,
    rowSorted: false,
    isSortingProcessed: false,
    defaultSummaryTableData: [],
    defaultSummaryTableHeaderData: []
};

const ReducerSummaryTable = (state = initialData, action) =>{
    switch (action.type) {

        case SURVEY_SUMMARY_SET_TABLE_RELOAD:
        case SURVEY_TEMPLATE_SUMMARY_SET_TABLE_RELOAD:{
            return {
                ...state,
                isReloadSummaryTable : action.payload
            };
        }

        case SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_IN_PROGRESS :
        case SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_IN_PROGRESS : {
            return {
                ...state,
                summaryTableDownloadStatus : HTTP_REQUEST_STATUS.IN_PROGRESS,
                summaryTableDownloadUrl : "",
                summaryTableDownloadError : ""
            };
        }

        case SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_SUCCESS :
        case SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_SUCCESS : {

            let url = baseServerUrl + action.payload.data;

            return {
                ...state,
                summaryTableDownloadUrl : url,
                summaryTableDownloadStatus : HTTP_REQUEST_STATUS.SUCCESS,
                summaryTableDownloadError : ""
            };
        }

        case SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_ERROR :
        case SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_ERROR : {            
            return {
                ...state,
                summaryTableDownloadUrl : "",
                summaryTableDownloadStatus : HTTP_REQUEST_STATUS.FAIL,
                summaryTableDownloadError : action.payload
            };
        }

        case SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_NONE :
        case SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA_NONE : {
            return {
                ...state,
                summaryTableDownloadUrl : "",
                summaryTableDownloadStatus : HTTP_REQUEST_STATUS.NOT_STARTED
            };
        }

        case SURVEY_SET_CURRENT_REPORT_TYPE:
        case SURVEY_TEMPLATE_SET_CURRENT_REPORT_TYPE:
            return {
                ...state,
                summaryTableReportingType : action.payload
            };
        
        case SURVEY_SET_CURRENT_GROUP_BY_TYPE:    
        case SURVEY_TEMPLATE_SET_CURRENT_GROUP_BY_TYPE:
            return {
                ...state,
                summaryTableGroupByType : action.payload
            };      

        case SURVEY_GET_SUMMARY_TABLE_DATA_IN_PROGRESS:
        case SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_IN_PROGRESS:
            return {
                ...state,
                summaryTableDataFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                loader: true
            };

        case SURVEY_GET_SUMMARY_TABLE_DATA_NONE:
        case SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_NONE:
            return {
                ...state,
                summaryTableDataFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                isSuccessMessage: false,
            };

        case SURVEY_GET_SUMMARY_TABLE_DATA_ERROR:
        case SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_ERROR:
            return {
                ...state,
                alertMessage: action.payload,
                isErrorMessage: true,
                isSuccessMessage: false,
                summaryTableDataFetchStatus: HTTP_REQUEST_STATUS.FAIL
            };
        
        case SURVEY_GET_SUMMARY_TABLE_DATA_SUCCESS:    
        case SURVEY_TEMPLATE_GET_SUMMARY_TABLE_DATA_SUCCESS: {
            
            let summaryTableHeaderData = [];
            let summaryTableData = [];
            let columnSortingOptions = [];
            let rowSortingOptions = [];

            let averageTitle = <IntlMessages id="DIC_SURVEY_SUMMARY_TABLE_COLUMN_DEFAULT_TITLE_AVERAGE" />;
            if(state.layoutType === SUMMARY_TABLE_LAYOUT_TYPES.SURVEY){
                averageTitle = <IntlMessages id="DIC_SURVEY_SUMMARY_TABLE_COLUMN_TITLE_AVERAGE" />;
            }else if(state.layoutType === SUMMARY_TABLE_LAYOUT_TYPES.QUESTIONNAIRE){
                averageTitle = <IntlMessages id="DIC_SURVEY_TEMPLATE_SUMMARY_TABLE_COLUMN_TITLE_AVERAGE" />;
            }        

            if (action.payload) {

                let data = action.payload.data;

                if ( data['summary_table'].length > 0 ) {
                    if (typeof action.payload.report_type !== 'undefined' && action.payload.report_type === SURVEY_SUMMARY_TABLE_REPORT_TYPE_FILTER_VALUE_CONSTANTS.BY_QUESTION) {
                        summaryTableHeaderData = getQuestionHeaders(action.payload.data);
                        summaryTableData = parseSurveySummaryDataForQuestionType(action.payload.data, averageTitle);
                        rowSortingOptions = getQuestionTypeRowSortingOptions(summaryTableData);
                    } else {
                        summaryTableHeaderData = getCategoryHeaders(action.payload.data);
                        summaryTableData = parseSurveySummaryDataForCategories(action.payload.data, averageTitle);
                        rowSortingOptions = getCategoryTypeRowSortingOptions(summaryTableData);
                    }
                    columnSortingOptions = getColumnSortingOptions(summaryTableData, summaryTableHeaderData, data['group_by']);
                }
            }

            return {
                ...state,
                summaryTableDataFetchStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false,
                isSuccessMessage: true,
                isErrorMessage: false,
                summaryTableData: summaryTableData,
                summaryTableHeaderData: summaryTableHeaderData,
                columnSortingOptions: columnSortingOptions,
                rowSortingOptions: rowSortingOptions,
                defaultSummaryTableData: summaryTableData,
                defaultSummaryTableHeaderData: summaryTableHeaderData,
                columnSorted: false,
                rowSorted: false
            };
        }
        
        case SET_SUMMARY_TABLE_LAYOUT_TYPE:
            return{
                ...state,
                layoutType: action.payload
            };
            
        case SURVEY_SUMMARY_TABLE_SORTING: {

            let tableData = [];
            let tableHeaderData = [];

            let defaultSummaryTable = state.defaultSummaryTableData;
            let defaultSummaryTableHeader = state.defaultSummaryTableHeaderData;

            let columnSorted = false;
            let rowSorted = false;
            let isSortingProcessed = false;

            if (typeof action.payload !== 'undefined' && typeof action.payload['reportType'] !== 'undefined') {
                if (action.payload['reportType'] === SURVEY_SUMMARY_TABLE_REPORT_TYPE_FILTER_VALUE_CONSTANTS.BY_QUESTION) {
                    if (action.payload['columnSortDirection'] !== '' && action.payload['columnSortOption'] !== '') {
                        tableData = getQuestionDataSortedByColumn(defaultSummaryTable, action.payload);
                        columnSorted = true;
                    }
                    else {
                        tableData = defaultSummaryTable;
                    }

                    if (action.payload['rowSortDirection'] !== '' && action.payload['rowSortOption'] !== '' && defaultSummaryTableHeader.length > 2) {
                        tableHeaderData = getQuestionDataSortedByRow(defaultSummaryTable, action.payload, defaultSummaryTableHeader);
                        rowSorted = true;
                    }
                    else {
                        tableHeaderData = defaultSummaryTableHeader;
                    }
                    isSortingProcessed = true;
                } 
                else {
                    if (action.payload['columnSortDirection'] !== '' && action.payload['columnSortOption'] !== '') {
                        tableData = getCategoryDataSortedByColumn(defaultSummaryTable, action.payload);
                        columnSorted = true;
                    }
                    else {
                        tableData = defaultSummaryTable;
                    }

                    if (action.payload['rowSortDirection'] !== '' && action.payload['rowSortOption'] !== '' && defaultSummaryTableHeader.length > 2) {
                        tableHeaderData = getQuestionDataSortedByRow(defaultSummaryTable, action.payload, defaultSummaryTableHeader);
                        rowSorted = true;
                    }
                    else {
                        tableHeaderData = defaultSummaryTableHeader;
                    }
                    isSortingProcessed = true;
                }
            }

            /**If there is issue in sorted data, fall back on default state data*/
            if(tableData.length !== defaultSummaryTable.length) {
                tableData = defaultSummaryTable;
                columnSorted = false;
            }
            if(tableHeaderData.length !== defaultSummaryTableHeader.length) {
                tableHeaderData = defaultSummaryTableHeader;
                rowSorted = false;
            }

            return {
                ...state,
                summaryTableData: tableData,
                summaryTableHeaderData: tableHeaderData,
                sortOptions: action.payload,
                columnSorted: columnSorted,
                rowSorted: rowSorted,
                isSortingProcessed: isSortingProcessed
            }
        }

        case SURVEY_SUMMARY_TABLE_SORTING_NONE : {
            return {
                ...state,
                isSortingProcessed: false
            }
        }

        default:
            return state;
    }

};

/*** TO get the column data for the headers of summary table questions*/
function parseSurveySummaryDataForQuestionType(data, averageTitle) {

    let resultMap = {};

    if (data && data['summary_table'] && data['summary_table'].length > 0) {

        let summaryTableData = data['summary_table'];

        try {

            let questionTranslations = data['question_translations'];
            let headerResponseCount = {};
            let firstLanguageCode = "";

            if (data['response_count'] && Object.keys(data['response_count']).length > 0) {
                headerResponseCount = getQuestionResponseCount(data['response_count']);
            }

            for (let i = 0; i < summaryTableData.length; i++) {


                let filtersData = summaryTableData[i].filters;
                let filterResult = {};

                for (let j = 0; j < filtersData.length; j++) {
                    if (typeof filtersData[j]['key'] != "undefined" && typeof filtersData[j]['avg_weightage'] != "undefined") {
                        filterResult[filtersData[j]['key']] = filtersData[j]['avg_weightage'];
                    }
                }

                for (let languageCode in questionTranslations) {

                    if (typeof resultMap[languageCode] == "undefined") {
                        resultMap[languageCode] = [];
                        //resultMap[languageCode].push(headerResponseCount);

                        if (firstLanguageCode == "") {
                            firstLanguageCode = languageCode;
                        }

                    }

                    let tempObj = {};
                    tempObj['id'] = summaryTableData[i]['id'];
                    tempObj['title'] = questionTranslations[languageCode][summaryTableData[i]['id']];
                    tempObj['type'] = 'question';
                    tempObj['applySorting'] = true;
                    tempObj['backgroundColor'] = TABLE_CELL_BACKGROUB_COLOR;

                    tempObj = {...tempObj, ...JSON.parse(JSON.stringify(filterResult))};

                    resultMap[languageCode].push(tempObj);
                }
            }

            let questions = resultMap[firstLanguageCode].filter(item => item.type === 'question');

            if (questions && questions.length > 0) {
                let avg = getQuestionAverage(questions, summaryTableData, averageTitle);

                try{
                    let overallRatingScore = data['overall_rating_score'];
                    for ( let item in overallRatingScore ) {
                        if ( typeof avg[item] != "undefined" ) {
                            avg[item] = overallRatingScore[item];
                        }
                    }
                } catch ( ex ) {
                    console.error("parseSurveySummaryDataForQuestionType : error : ", ex );
                }

                for (let languageCode in resultMap) {
                    //resultMap[languageCode].splice(1, 0, avg);
                    resultMap[languageCode].push(avg);
                    resultMap[languageCode].push(headerResponseCount);
                }
            }

            if('filter_dic_keys' in data && Object.keys(data['filter_dic_keys']).length > 0) { 

                const filterTranslations = data['filter_dic_keys'];

                if('group_by' in data && data['group_by'] !== 'overall') {
                    filterTranslations['overall'] = "DIC_SURVEY_SUMMARY_TABLE_HEADER_LABEL_ALL";
                }
                resultMap['filterTranslations'] = filterTranslations;
            }

        } catch (ex) {
            console.error('parseSurveySummaryDataForQuestionType : error : ', ex);
        }
    }

    return resultMap;
}

/*** TO get the column data for the headers of summary table for categories */
function parseSurveySummaryDataForCategories(data, averageTitle) {

    let resultMap = {};

    try {

        let responseCount = {};
        let responseAvg = {};

        if (data && data['summary_table'] && data['summary_table'].length > 0) {


            if (data["filters"]) {
                responseCount = getCategoryResponseCount(data["filters"]);
                responseAvg = getCategoryAverage(data.filters, averageTitle);
            }

            let summaryTableData = data['summary_table'];
            let categoryTranslations = data['category_translations'];
            let questionTranslations = data['question_translations'];

            for (let languageCode in questionTranslations) {
                resultMap[languageCode] = [];
                //resultMap[languageCode].push(responseCount);
                //resultMap[languageCode].push(responseAvg);
            }

            summaryTableData.sort((a, b) => (a.title > b.title) ? 1 : -1);
            for (let i = 0; i < summaryTableData.length; i++) {

                let categoryAvgScores = {};

                let categoryFilters = summaryTableData[i].filters[summaryTableData[i].title];

                if (categoryFilters) {

                    for (let key in categoryFilters) {
                        categoryAvgScores[key] = categoryFilters[key]['avg_weight'];
                    }
                }

                for (let languageCode in resultMap) {

                    let tempObj = {};
                    tempObj['title'] = summaryTableData[i].title;
                    tempObj['bold'] = true;
                    tempObj['type'] = 'category';

                    if (summaryTableData[i] && summaryTableData[i]['id'] && categoryTranslations[languageCode][summaryTableData[i]['id']]) {
                        tempObj['title'] = categoryTranslations[languageCode][summaryTableData[i]['id']];
                        tempObj['id'] = summaryTableData[i]['id'];
                    }

                    tempObj['questionArray'] = [];
                    tempObj['applySorting'] = true;
                    tempObj['backgroundColor'] = TABLE_CELL_BACKGROUB_COLOR;

                    resultMap[languageCode].push({ ...tempObj, ...categoryAvgScores });
                }

                if (summaryTableData[i] && summaryTableData[i]['questions']) { 
                    let questions = summaryTableData[i]['questions'];

                    for (let j = 0; j < questions.length; j++) {

                        let questionAvgs = {};

                        if (questions[j] && questions[j]['filters']) {

                            let questionFilters = questions[j]['filters'];

                            for (let key in questionFilters) {

                                if (questionFilters[key] && typeof questionFilters[key]['avg_weight'] != "undefined") {
                                    questionAvgs[key] = questionFilters[key]['avg_weight'];
                                }
                            }
                        }

                        if (questions[j]) {

                            for (let languageCode in resultMap) {

                                let tempObj = {};
                                tempObj['type'] = 'question';
                                tempObj['applyMargin'] = true;
                                tempObj['applySorting'] = true;

                                if (questions[j]['title']) {
                                    tempObj['title'] = questions[j]['title'];
                                }

                                if (questions[j]['id'] && questionTranslations[languageCode] && questionTranslations[languageCode][questions[j]['id']]) {
                                    tempObj['title'] = questionTranslations[languageCode][questions[j]['id']];
                                }

                                /**Pushing questions to the respective category */
                                let category = resultMap[languageCode].find(item => item.id === summaryTableData[i]['id']);
                                if(typeof category !== 'undefined') {
                                    category['questionArray'].push({ ...tempObj, ...questionAvgs });
                                }

                                tempObj['backgroundColor'] = TABLE_CELL_BACKGROUB_COLOR;

                                resultMap[languageCode].push({ ...tempObj, ...questionAvgs });
                            }
                        }
                    }
                }

            }

            for (let languageCode in questionTranslations) {                                           
                resultMap[languageCode].push(responseAvg);
                resultMap[languageCode].push(responseCount);
            } 
        }

        if('filter_dic_keys' in data && Object.keys(data['filter_dic_keys']).length > 0) {

            const filterTranslations = data['filter_dic_keys'];

            if('group_by' in data && data['group_by'] !== 'overall') {
                filterTranslations['overall'] = "DIC_SURVEY_SUMMARY_TABLE_HEADER_LABEL_ALL";
            }
            resultMap['filterTranslations'] = filterTranslations;
        }


    } catch (ex) {
        console.error('parseSurveySummaryDataForCategories : error : ', ex);
    }

    return resultMap;
}

/*** TO calculate average for questions */
function getQuestionAverage(data, summaryTableData, averageTitle) {
    
    const result = {};
    try {
        let keys = [];
        keys = getKeys(summaryTableData);

        if (data && keys.length > 0) {

            keys.map(key => { 
                let temp = 0;
                let questionLength = 0;

                for (let j = 0; j <  data.length; j++) {
                    if (key in data[j]){
                        if(typeof data[j][key] == 'number'){
                            temp += data[j][key];
                            questionLength++;
                        }
                    }
                }
                if(questionLength > 0)
                    result[key] = parseFloat((temp / questionLength).toFixed(1));
            })
        }

        result['title'] = averageTitle;
        result['type'] = 'average';
        result['backgroundColor'] = TABLE_HEADER_SURVEY_AVERAGE_BACKGROUND_COLOR;
        result['bold'] = true;
        result['id'] = 'question_average_score';

    } catch (ex) {
        console.error("getQuestionAverage : error : ", ex);
    }

    return result;
}

/*** TO calculate average for categories */
function getCategoryAverage(data, averageTitle) {
    let result = {};

    try {

        if (data) {
            result['title'] = averageTitle;

            Object.entries(data).forEach(([key, value]) => {
                result[value.title] = value.avg_weight;
            });

            result['type'] = 'average';
            result['backgroundColor'] = TABLE_HEADER_SURVEY_AVERAGE_BACKGROUND_COLOR;
            result['bold'] = true;
            result['id'] = 'category_average_score';
        }

    } catch (ex) {
        console.error("getCategoryAverage : ", ex);
    }

    return result;
}

/*** TO get the response count for Questions */
function getQuestionResponseCount(data) {

    let result = data;

    try {
        if (data) {
            result['title'] = <IntlMessages id="DIC_SURVEY_REPORTS_SUMMARY_TABLE_COLUMN_TITLE_RESPONSE_COUNT"/>;
            result['backgroundColor'] = TABLE_HEADER_RESPONSE_COUNT_BACKGROUND_COLOR;
            result['color'] = TABLE_RESPONSE_COUNT_COLOR;
            result['type'] = 'respCount';
            result['bold'] = true;
        }
    } catch (ex) {
        console.error("getQuestionResponseCount : error : ", ex);
    }

    return result;
}

/****To get the response count for Categories ****/
function getCategoryResponseCount(data) {

    let responseObject = {};

    try {

        if (data) {

            for (let key in data) {

                if (data[key] && data[key]['response_count']) {
                    responseObject[key] = data[key]['response_count'];
                }
            }

            responseObject['title'] = <IntlMessages id="DIC_SURVEY_REPORTS_SUMMARY_TABLE_COLUMN_TITLE_RESPONSE_COUNT"/>;
            responseObject['backgroundColor'] = TABLE_HEADER_RESPONSE_COUNT_BACKGROUND_COLOR;
            responseObject['color'] = TABLE_RESPONSE_COUNT_COLOR;
            responseObject['type'] = 'respCount';
            responseObject['bold'] = true;
        }

    } catch (ex) {
        console.error("getCategoryResponseCount : error : ", ex);
    }

    return responseObject;
}

/****To get the Header keys for Questions ****/
function getQuestionHeaders(data) {
    
    let headers = [];

    try {
        if (data && data['summary_table'] && data['summary_table'].length > 0) {
            headers = getKeys(data['summary_table']);
        }
    } catch (ex) {
        console.error('getQuestionHeaders : Error :' + ex);
    }
    return headers;
}

/****To get the Header keys for Categories ****/
function getCategoryHeaders(data) {

    let result = [];

    try {

        if (data && data.filters) {
            Object.entries(data.filters).forEach(
                ([key, value]) => {
                    result.push(value.title);
                })
        }

    } catch (ex) {
        console.error('getCategoryHeaders : Error :' + ex);
    }

    return result;
}

function getKeys(summaryTableData){
    let keys = [];
    try {
        if (summaryTableData && summaryTableData.length > 0) {

            let filters = [];

            for(let i = 0; i < summaryTableData.length; i++){
                if('filters' in summaryTableData[i]){
                    filters.push(...summaryTableData[i]['filters']);
                }
            }
    
            if(filters && filters.length > 0){
                for(let j = 0; j < filters.length; j++){
                    if(!keys.includes(filters[j]['key'])){
                        keys.push(filters[j]['key']);
                    }
                }
            }
        }
    } catch (ex) {
        console.error('get Keys : Error :' + ex);
    }
    return keys;
}

/**To create dropdown options for column sorting */
function getColumnSortingOptions(tableData, tableHeaderData, groupBy) {

    let options = [];

    try {

        let filterTranslations = {};

        if (typeof tableData !== 'undefined' && 'filterTranslations' in tableData) {
            filterTranslations = tableData['filterTranslations'];
        }

        if (typeof tableHeaderData !== 'undefined' && tableHeaderData.length > 0) {

            let defaultOption = {
                label: 'DIC_SURVEY_SUMMARY_TABLE_SORTING_OPTIONS_SELECT_COLUMN',
                key: '',
                value: ''
            };
            options.push(defaultOption);

            for (let i = 0; i < tableHeaderData.length; i++) {

                let option = {};

                if (tableHeaderData[i] === SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_UNDISCLOSED) {
                    option['label'] = 'DIC_SURVEY_SUMMARY_TABLE_SORTING_OPTIONS_UNDISCLOSED';
                }
                else if (tableHeaderData[i] === 'overall') {

                    option['label'] = getColumnSortingOptionOverallLabel(groupBy);

                    if (option['label'] === '' && typeof filterTranslations[tableHeaderData[i]] !== 'undefined') {
                        option['label'] = filterTranslations[tableHeaderData[i]];
                    }
                }
                else if (typeof filterTranslations[tableHeaderData[i]] !== 'undefined') {
                    option['label'] = filterTranslations[tableHeaderData[i]];
                }
                else {
                    option['label'] = tableHeaderData[i];
                }
                option['key'] = tableHeaderData[i];
                option['value'] = tableHeaderData[i];

                options.push(option);
            }
        }
    } catch (err) {
        console.error('getColumnSortingOptions : Error :' + err);
    }
    return options;
}

/**To get column sorting option over all label based on group by */
function getColumnSortingOptionOverallLabel(groupBy) {

    let label = '';

    try {
        if (typeof groupBy !== 'undefined') {
            switch (groupBy) {
                case SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_OVERALL:
                    label = 'DIC_SURVEY_REPORTS_SUMMARY_TABLE_COLUMN_TITLE_OVERALL';
                    break;

                case SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_GENDER:
                    label = 'DIC_SURVEY_SUMMARY_TABLE_SORTING_OPTIONS_ALL_GENDER';
                    break;

                case SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_AGE_GROUP:
                    label = 'DIC_SURVEY_SUMMARY_TABLE_SORTING_OPTIONS_ALL_AGE_GROUP';
                    break;

                case SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_TENURE_GROUP:
                    label = 'DIC_SURVEY_SUMMARY_TABLE_SORTING_OPTIONS_ALL_TENURE_GROUP';
                    break;

                case SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_JOB_ROLE:
                    label = 'DIC_SURVEY_SUMMARY_TABLE_SORTING_OPTIONS_ALL_JOB_ROLE';
                    break;

                case SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_COMPANIES:
                    label = 'DIC_SURVEY_SUMMARY_TABLE_SORTING_OPTIONS_ALL_COMPANIES';
                    break;

                case SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS.FILTER_VALUE_COUNTRIES:
                    label = 'DIC_SURVEY_SUMMARY_TABLE_SORTING_OPTIONS_ALL_COUNTRIES';
                    break;

                default:
                    break;
            }
        }
    } catch (err) {
        console.error('getColumnSortingOptionOverallLabel : Error :' + err);
    }
    return label;
}

/**To create dropdown options for row sorting for question type */
function getQuestionTypeRowSortingOptions(tableData) {

    let optionsList = {};

    try {
        if (typeof tableData !== 'undefined') {

            for (let item in tableData) {

                if(tableData[item] !== 'undefined' && tableData[item].length > 0) {

                    let questions = tableData[item];

                    let options = [];

                    let defaultOption = {
                        label: 'DIC_SURVEY_SUMMARY_TABLE_SORTING_OPTIONS_SELECT_ROW',
                        key: '',
                        value: ''
                    };
                    options.push(defaultOption);

                    for (let i = 0; i < questions.length; i++) {
                        if(questions[i]['type'] && questions[i]['type'] === 'question') {

                            let option = {};
                            option['label'] = questions[i]['title'];
                            option['key'] = questions[i]['id'];
                            option['value'] = questions[i]['id'];

                            options.push(option);
                        }                        
                    }

                    /**Adding question average to dropdown  */
                    let surveyAverage = {
                        label: 'DIC_SURVEY_SUMMARY_TABLE_COLUMN_DEFAULT_TITLE_AVERAGE',
                        key: 'average',
                        value: 'question_average_score'
                    };
                    options.push(surveyAverage);

                    optionsList[item] = options;  
                }           
            }
        }
    } catch (err) {
        console.error('getQuestionTypeRowSortingOptions : Error :' + err);
    }
    return optionsList;
}

/**To create dropdown options for row sorting for category type */
function getCategoryTypeRowSortingOptions(summaryData) {

    let sortingOptions = [];

    try {
        if(typeof summaryData !== 'undefined') {
            for(let item in summaryData) {

                if(item === 'filterTranslations') {
                    sortingOptions[item] = summaryData[item];
                    continue;
                }

                let options = [];

                let defaultOption = {
                    label: 'DIC_SURVEY_SUMMARY_TABLE_SORTING_OPTIONS_SELECT_ROW',
                    key: '',
                    value: ''
                };
                options.push(defaultOption);

                for (let i = 0; i < summaryData[item].length; i++) {

                    let rowData = summaryData[item][i];

                    if(rowData['type'] &&  rowData['type'] === 'category') {

                        let tempObj = {};

                        tempObj['label'] = rowData['title'];
                        tempObj['value'] = rowData['id'];
                        tempObj['key'] = rowData['title'];

                        options.push(tempObj);   
                    }               
                }

                /**Adding category average to dropdown  */
                let surveyAverage = {
                    label: 'DIC_SURVEY_SUMMARY_TABLE_COLUMN_DEFAULT_TITLE_AVERAGE',
                    key: 'average',
                    value: 'category_average_score'
                };
                options.push(surveyAverage);

                sortingOptions[item] = options;
            }
        }        
    } catch (err) {
        console.error('getCategoryTypeRowSortingOptions : Error :' + err);
    }
    return sortingOptions;
}

/**To get the sorted data of question when column sorting applied */
function getQuestionDataSortedByColumn(summaryData, sortingParams) {

    let result = {};

    try {
        if (typeof summaryData !== 'undefined') {

            for (let item in summaryData) {

                if (item === 'filterTranslations') {
                    result[item] = summaryData[item];
                    continue;
                }

                result[item] = [];

                let data = summaryData[item];

                let sortableData = [];
                let nonSortableData = [];

                /**Separate sortable and non sortable data */
                if (typeof data !== 'undefined') {
                    for (let i = 0; i < data.length; i++) {
                        if (typeof data[i]['applySorting'] !== 'undefined' && data[i]['applySorting']) {
                            sortableData.push(data[i]);
                        } else {
                            nonSortableData.push(data[i]);
                        }
                    }
                }

                /**sort based on the options selected */
                if (sortingParams['columnSortDirection'] === SORTING_DIRECTION_VALUES['COLUMN_ASCENDING']) {
                    sortableData.sort((a, b) => a[sortingParams['columnSortOption']] - b[sortingParams['columnSortOption']]);
                }
                else {
                    sortableData.sort((a, b) => b[sortingParams['columnSortOption']] - a[sortingParams['columnSortOption']]);
                }

                /**pushing sorted data and non sorted in order */
                result[item] = [...sortableData,...nonSortableData];
            }
        }
    } catch (err) {
        console.error('getQuestionDataSortedByColumn : error : ', err);
    }

    return result;
}

/**To get the sorted data of question type when row sorting applied */
function getQuestionDataSortedByRow(tableData, sortingParams, tableHeaderData) {

    let sortedData = [];
    let requiredRow = {};
    let headerData = [];

    try {
        /**Get the required row data */
        if (typeof tableData !== 'undefined') {
            for (let item in tableData) {
                if (item !== 'filterTranslations' && typeof tableData[item] !== 'undefined') {

                    let itemValues = tableData[item];

                    if (typeof itemValues !== 'undefined' && itemValues.length > 0) {

                        for (let i = 0; i < itemValues.length; i++) {
                            if (itemValues[i]['id'] === sortingParams['rowSortOption']) {
                                requiredRow = itemValues[i];
                                break;
                            }
                        }
                    }
                    break;
                }
            }
        }

        /**Get the required fields in the row data */
        if (typeof tableHeaderData !== 'undefined' && tableHeaderData.length > 0) {
            for (let i = 0; i < tableHeaderData.length; i++) {
                if (tableHeaderData[i] in requiredRow && tableHeaderData[i] !== 'overall') {
                    sortedData.push([tableHeaderData[i], requiredRow[tableHeaderData[i]]]);                    
                }
            }
        }

        /**sort based on the options selected */
        if (sortingParams['rowSortDirection'] === SORTING_DIRECTION_VALUES['ROW_ASCENDING']) {
            sortedData.sort((a, b) => a[1] - b[1]);
        }
        else {
            sortedData.sort((a, b) => b[1] - a[1]);
        }

        /**create table header data with sorted data */
        headerData.push("overall");
        for (let i = 0; i < sortedData.length; i++) {
            headerData.push(sortedData[i][0]);            
        }
    } catch (err) {
        console.error('getQuestionDataSortedByRow : error : ', err);
    }

    return headerData;
}

/**To get category sorted data when column sorting applied */
function getCategoryDataSortedByColumn(tableData, sortingParams) {

    let result = {};

    try {
        if (typeof tableData !== 'undefined') {

            for (let item in tableData) {

                if (item === 'filterTranslations') {
                    result[item] = tableData[item];
                    continue;
                }

                let data = tableData[item]; 

                let categoryData = [];
                let nonSortableData = [];
                
                result[item] = [];

                if (typeof data !== 'undefined') {
                    for (let i = 0; i < data.length; i++) {
                        if (typeof data[i]['type'] !== 'undefined' && data[i]['type'] === 'category') {
                            categoryData.push(data[i]);
                        }
                    }
                }

                /**Separate non-sortable data */
                if (typeof data !== 'undefined') {
                    for (let i = 0; i < data.length; i++) {
                        if (typeof data[i]['applySorting'] === 'undefined') {                            
                            nonSortableData.push(data[i]);
                        }
                    }
                }

                /**sort */
                if (sortingParams['columnSortDirection'] === SORTING_DIRECTION_VALUES['COLUMN_ASCENDING']) {
                    categoryData.sort((a, b) => a[sortingParams['columnSortOption']] - b[sortingParams['columnSortOption']]);
                }
                else {
                    categoryData.sort((a, b) => b[sortingParams['columnSortOption']] - a[sortingParams['columnSortOption']]);
                }

                /**pu all the item together */
                for (let i = 0; i < categoryData.length; i++) {
                    if(typeof categoryData[i] !== 'undefined') {
                        result[item].push(categoryData[i]);
                    }
                    if(typeof categoryData[i]['questionArray'] !== 'undefined') {
                        result[item].push(...categoryData[i]['questionArray']);
                    }
                }                

                /**Push non sortable data at end */
                result[item].push(...nonSortableData);
            }
        }
    } catch (err) {
        console.error('getCategoryDataSortedByColumn : error : ', err);
    }
    return result;
}

export default ReducerSummaryTable;
