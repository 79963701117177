import IntlMessages from "../util/IntlMessages";
import React from "react";

export const DEFAULT_DATE_FORMAT = "DD-MM-YYYY";
export const DEFAULT_DATE_DELIMITER = "/";
export const SIZE_KB = 1024;
export const CLIENT_LOGO_MAX_SIZE = 400 * SIZE_KB;
export const PARTICIPANTS_CUSTOM_FIELD_MAX_COUNT = 3;
export const CLIENTS_TABLE_TEXT_LENGTH_ELLIPSIS = 20;
export const DEFAULT_LANGUAGE_CODE = "en-US";
export const DEFAULT_LANGUAGE_LABEL = "English";
export const ERROR_KEY = "error";
export const UUID_REFRESH_TIMER = 5000;
export const SURVEY_RESPONSE_ITERATION_DELAY = 30000;
export const SURVEY_RESPONSE_ITERATIONS_COUNT = 2;
export const APP_FONT_FILES_PATH = "/vendors/wovo/fonts/";

export const MOODLE_DEFAULT_LANGUAGES = ["en", "en_us"];

export const CONNECT_DEFAULT_TIME_RANGE_DIFF = 12;

export const SURVEY_WEB_URL_PATH = "/survey_response/?id=";

export const WOVO_INTERACT_COOKIE_KEY = 'wovo_interact_cookie';

export const WOVO_PRIVACY_POLICY_URL = 'https://www.laborsolutions.tech/dashboard-privacy-policy';

export const WOVO_DEFAULT_SURVEY_END_TIME = '23:59'

export const UUID_STATUS = {
    "SUCCESS": "SUCCESS",
    "IN_PROGRESS": "IN_PROGRESS",
    "NOT_FOUND": "NOT_FOUND"
};


export const SURVEY_QUESTION_TYPES = {
    CHECK_BOX: "CHECK_BOX",
    RADIO_BUTTON: 'RADIO_BUTTON',
    RATING_SCALE: 'RATING_SCALE',
    LINEAR_RATING_SCALE: 'LINEAR_RATING_SCALE',
    TEXT_LONG: 'TEXT_LONG',
    TEXT_SHORT: 'TEXT_SHORT',
    EMAIL: 'EMAIL',
    MULTIPLE_CHOICE_GRID: 'MULTIPLE_CHOICE_GRID',
    CHECKBOX_GRID: 'CHECKBOX_GRID',
    DATE: 'DATE',
    TIME: 'TIME'
};

export const SURVEY_QUESTION_BANK_TYPES = {
    CHECK_BOX: "checkBoxes",
    RADIO_BUTTON: 'radioButtons',
    RATING_SCALE: 'ratingValue'
};

export let SURVEY_CREATION_TYPES = {
    "SCRATCH": "SCRATCH",
    "TEMPLATE": "TEMPLATE",
    "SURVEY": "SURVEY"
};

export const HTTP_UUID_STATUS = {
    'IN_PROGRESS': 'IN_PROGRESS',
    'SUCCESS': 'SUCCESS',
    'NOT_FOUND': 'NOT_FOUND',
    'PARTIAL': 'PARTIAL'
}

export const HTTP_STATUS_CODE = {
    'SUCCESS': 200,
    'FAIL': 500,
    'OK': 201
}

export let APP_NAVIGATION_PATHS = {
    LOGIN: "/signin",
    DASHBOARD: "/app/dashboard/",
    NEW_SURVEY: "/app/survey/newsurvey/",
    SURVEY_VIEW: "/app/survey/info/",
    TEMPLATE_VIEW: "/app/questionnaire/info",
    TEMPLATE_LIST: "/app/survey/templates/",
    NEW_TEMPLATE: '/app/survey/newtemplates/',
    USER_SETTINGS: '/app/user/settings/',
    QUICK_INSIGHTS: '/app/survey/surveyResults/',
    SUMMARY_TABLE: '/app/survey/breakdownTable/',
    TEMPLATE_QUICK_INSIGHTS: '/app/questionnaire/report/',
    QUESTIONNAIRE_SUMMARY_TABLE: '/app/questionnaire/breakdownTable/',
    ADMIN_CLIENT_MODULE_LIST: '/app/admin/client_modules',
    ADMIN_CLIENT_MODULE_NEW_EDIT: '/app/admin/new_edit_client_modules',
    QUESTION_BANK: '/app/survey/questionBank/',
    NEW_QUESTION_BANK: '/app/survey/question_bank/new',
    SURVEY_HOME: '/app/survey/home/',
    WC_CONTACT_VIEW: "/app/worker_contacts/contact/",
    WC_CONTACT_LIST: "/app/worker_contacts/list/",
    WC_IMPORT_HOME: "/app/worker_contacts/import/",
    WC_IMPORT_RESET_REGISTRATION: "/app/worker_contacts/reset_registration/",
    WC_CONTACT_GROUPS: "/app/worker_contacts/groups/",
    CLIENTS_HOME: "/app/clients/home/",
    CLIENTS_CLIENT_VIEW: "/app/client/view/",
    CONTACT_SETTINGS: "/app/contact_settings/home/",
    PAY_SLIP_VIEW:"/app/payslip/view/",
    PAY_SLIP_LIST:"/app/payslip/list/"
};

export const NAVIGATION_STEPS = {
    "SETTINGS": "SETTINGS",
    "QUESTION_BUILDER": "QUESTION_BUILDER",
    "INVITE": "INVITE",
    "REPORTS": "REPORTS"
};

export const SURVEY_CLONE_TYPES = {
    "SURVEY_FROM_TEMPLATE": 2,
    "SURVEY_FROM_SURVEY": 3,
    "TEMPLATE_FROM_TEMPLATE": 4,
};


export const SURVEY_QUESTION_LAYOUT_TYPES = {
    TEMPLATE: 1,
    SURVEY: 2,
};

export const SURVEY_QUESTION_TYPES_VALUES = {
    CHECK_BOX: 1,
    RADIO_BUTTON: 2,
    RATING_SCALE: 3,
    LINEAR_RATING_SCALE: 4,
    TEXT_LONG: 5,
    TEXT_SHORT: 6,
    EMAIL: 7,
    MULTIPLE_CHOICE_GRID: 8,
    CHECKBOX_GRID: 9,
    DATE: 10,
    TIME: 11
};

export const SURVEY_STEPPER_STEPS = {
    IMPORT: "IMPORT",
    INFO: "INFO",
    QUESTIONS: "QUESTIONS",
    FINAL: "FINAL",
    NONE: "NONE"
};

export const SURVEY_SUMMARY_TYPE = {
    TEMPLATE: "TEMPLATE",
    SURVEY: "SURVEY"
};

export const SURVEY_RESPONSE_TYPE = {
    SINGLE: 0,
    MULTIPLE: 1
};

export const SURVEY_RESPONSE_SOURCE = {
    QR_CODE: 0,
    URL: 1,
    APP: 2
};

export const SURVEY_VIEW_INFO_TYPE = {
    "QUESTIONS_LAYOUT": "QUESTIONS_LAYOUT",
    "EDIT_LAYOUT": "EDIT_LAYOUT",
    "SUMMARY_LAYOUT": "SUMMARY_LAYOUT"
};


export const SURVEY_QUESTION_DROPDOWN_TYPES = [
    {
        "value": SURVEY_QUESTION_TYPES.CHECK_BOX,
        "label": <IntlMessages id="DIC_SURVEY_QUESTION_TYPE_CHECK_BOX"/>,
        "typeValue": SURVEY_QUESTION_TYPES_VALUES.CHECK_BOX,
    },
    {
        "value": SURVEY_QUESTION_TYPES.RADIO_BUTTON,
        "label": <IntlMessages id="DIC_SURVEY_QUESTION_TYPE_RADIO_BUTTONS"/>,
        "typeValue": SURVEY_QUESTION_TYPES_VALUES.RADIO_BUTTON
    },
    {
        "value": SURVEY_QUESTION_TYPES.RATING_SCALE,
        "label": <IntlMessages id="DIC_SURVEY_QUESTION_TYPE_RATING_SCALE"/>,
        "typeValue": SURVEY_QUESTION_TYPES_VALUES.RATING_SCALE
    },
    {
        "value": SURVEY_QUESTION_TYPES.LINEAR_RATING_SCALE,
        "label": <IntlMessages id="DIC_SURVEY_QUESTION_TYPE_LINEAR_RATING_SCALE"/>,
        "typeValue": SURVEY_QUESTION_TYPES_VALUES.LINEAR_RATING_SCALE
    },
    {
        "value": SURVEY_QUESTION_TYPES.TEXT_LONG,
        "label": <IntlMessages id="DIC_SURVEY_QUESTION_TYPE_TEXT_LONG"/>,
        "typeValue": SURVEY_QUESTION_TYPES_VALUES.TEXT_LONG
    },
    {
        "value": SURVEY_QUESTION_TYPES.TEXT_SHORT,
        "label": <IntlMessages id="DIC_SURVEY_QUESTION_TYPE_TEXT_SHORT"/>,
        "typeValue": SURVEY_QUESTION_TYPES_VALUES.TEXT_SHORT
    },
    {
        "value": SURVEY_QUESTION_TYPES.EMAIL,
        "label": <IntlMessages id="DIC_SURVEY_QUESTION_TYPE_EMAIL"/>,
        "typeValue": SURVEY_QUESTION_TYPES_VALUES.EMAIL
    },
    {
        "value": SURVEY_QUESTION_TYPES.MULTIPLE_CHOICE_GRID,
        "label": <IntlMessages id="DIC_SURVEY_QUESTION_TYPE_MULTIPLE_CHOICE_GRID"/>,
        "typeValue": SURVEY_QUESTION_TYPES_VALUES.MULTIPLE_CHOICE_GRID
    },
    {
        "value": SURVEY_QUESTION_TYPES.CHECKBOX_GRID,
        "label": <IntlMessages id="DIC_SURVEY_QUESTION_TYPE_CHECKBOX_GRID"/>,
        "typeValue": SURVEY_QUESTION_TYPES_VALUES.CHECKBOX_GRID
    },
    {
        "value": SURVEY_QUESTION_TYPES.DATE,
        "label": <IntlMessages id="DIC_SURVEY_QUESTION_TYPE_DATE"/>,
        "typeValue": SURVEY_QUESTION_TYPES_VALUES.DATE
    },
    {
        "value": SURVEY_QUESTION_TYPES.TIME,
        "label": <IntlMessages id="DIC_SURVEY_QUESTION_TYPE_TIME"/>,
        "typeValue": SURVEY_QUESTION_TYPES_VALUES.TIME
    }
];

export const SURVEY_STATUSES_VALUE = {
    ALL: -1,
    DRAFT: 0,
    ACTIVE: 1,
    INACTIVE: 2,
    SCHEDULED: 3,
    DELETED: 4,
    COMPLETED: 6
};

export const SURVEY_STATUS_SENDING_VALUE = {
    scheduled: 0,
    sending: 1,
    sent: 2,
    cancelled: 3,
    partial: 4,
    failed: 5
};

export const SURVEY_STATUS_SENDING_TEXT = {
    0: "scheduled",
    1: "sending",
    2: "sent",
    3: "cancelled",
    4: "partial",
    5: "failed"
};

export const SURVEY_INFO_CARD_BUTTON_ACTIONS = {
    "REDEPLOY": "REDEPLOY",
    "CREATE_SURVEY": "CREATE_SURVEY",
    "LANGUAGE_DROP_DOWN_OPEN": "LANGUAGE_DROP_DOWN_OPEN",
    "LANGUAGE_DROP_DOWN_CLOSE": "LANGUAGE_DROP_DOWN_CLOSE",
    "LANGUAGE_DROP_DOWN_ITEM_CLICK": "LANGUAGE_DROP_DOWN_ITEM_CLICK",
    "STATUS_LABEL_OPEN": "STATUS_LABEL_OPEN",
    "STATUS_LABEL_CLOSE": "STATUS_LABEL_CLOSE",
    "STATUS_LABEL_ITEM_CLICK": "STATUS_LABEL_ITEM_CLICK",
    "REDEPLOY_LABEL_OPEN": "REDEPLOY_LABEL_OPEN",
    "REDEPLOY_LABEL_CLOSE": "REDEPLOY_LABEL_CLOSE",
    "REDEPLOY_LABEL_ITEM_CLICK": "REDEPLOY_LABEL_ITEM_CLICK",
    "DOWNLOAD_QR_CODE": "DOWNLOAD_QR_CODE",
    "COPY_URL": "COPY_URL",
    "SURVEY_QUESTIONNAIRE_DELETE": "SURVEY_QUESTIONNAIRE_DELETE",
    "SURVEY_QUESTIONNAIRE_DELETE_OK": "SURVEY_QUESTIONNAIRE_DELETE_OK",
    "SURVEY_QUESTIONNAIRE_DELETE_CANCEL": "SURVEY_QUESTIONNAIRE_DELETE_CANCEL",
    "SURVEY_QUESTIONNAIRE_ACTIVATE": "SURVEY_QUESTIONNAIRE_ACTIVATE",
    "SURVEY_QUESTIONNAIRE_ACTIVATE_OK": "SURVEY_QUESTIONNAIRE_ACTIVATE_OK",
    "SURVEY_QUESTIONNAIRE_ACTIVATE_CANCEL": "SURVEY_QUESTIONNAIRE_ACTIVATE_CANCEL",
    "SURVEY_QUESTIONNAIRE_CLOSE": "SURVEY_QUESTIONNAIRE_CLOSE",
    "SURVEY_QUESTIONNAIRE_CLOSE_OK": "SURVEY_QUESTIONNAIRE_CLOSE_OK",
    "SURVEY_QUESTIONNAIRE_CLOSE_CANCEL": "SURVEY_QUESTIONNAIRE_CLOSE_CANCEL",
    "CREATE_SURVEY_FROM_QUESTIONNAIRE": "CREATE_SURVEY_FROM_QUESTIONNAIRE",
    "CREATE_SURVEY_FROM_QUESTIONNAIRE_OK": "CREATE_SURVEY_FROM_QUESTIONNAIRE_OK",
    "CREATE_SURVEY_FROM_QUESTIONNAIRE_CANCEL": "CREATE_SURVEY_FROM_QUESTIONNAIRE_CANCEL",
    "SURVEY_SETTINGS_POPUP_CANCEL": "SURVEY_SETTINGS_POPUP_CANCEL",
    "SURVEY_SETTINGS_POPUP_DOWNLOAD_QR_CODE": "SURVEY_SETTINGS_POPUP_DOWNLOAD_QR_CODE",
    "SURVEY_SETTINGS_POPUP_COPY_URL": "SURVEY_SETTINGS_POPUP_COPY_URL"
};


export const SURVEY_STATUSES = [
    {
        "value": SURVEY_STATUSES_VALUE.ALL,
        "label": <IntlMessages id="DIC_SURVEY_STATUS_ALL"/>
    },
    {
        "value": SURVEY_STATUSES_VALUE.DRAFT,
        "label": <IntlMessages id="DIC_SURVEY_STATUS_DRAFTS"/>
    },
    {
        "value": SURVEY_STATUSES_VALUE.ACTIVE,
        "label": <IntlMessages id="DIC_SURVEY_STATUS_ACTIVE"/>
    },
    {
        "value": SURVEY_STATUSES_VALUE.COMPLETED,
        "label": <IntlMessages id="DIC_SURVEY_STATUS_COMPLETED"/>
    },

];

export const OPTION_TYPES = {
    1: "checkbox",
    2: "radiogroup",
    3: "radiogroup",
    4: "rating",
    5: "comment",
    6: "text",
    7: "email"
};

export const SURVEY_RESPONSE_STRINGS = {
    MIN_DESCRIPTION: "Not Satisfied",
    MAX_DESCRIPTION: "Completely satisfied"
};


export const SURVEY_QUESTION_SUMMARY_DISPLAY_TYPE = {
    TEXT_BASED: "TEXT_BASED",
    GRAPH_BASED: "GRAPH_BASED"
};

export const SURVEY_QUESTION_SUMMARY_GRAPH_TYPES = {
    "HORIZONTAL_BAR_CHART": "HORIZONTAL_BAR_CHART",
    "PIE_CHART": "PIE_CHART",
    "BAR_CHART": "BAR_CHART",
    "DEFAULT_CHART": "HORIZONTAL_BAR_CHART",
    "LINE_CHART": "LINE_CHART"
};

export const SURVEY_QUESTION_SUMMARY_GRAPH_CONDITIONAL_DISPLAY = {
    "HORIZONTAL_BAR_CHART": true,
    "PIE_CHART": true,
    "BAR_CHART": false,
    "LINE_CHART": true
};

export const SURVEY_QUICK_INSIGHTS_FILTERS = {
    GENDER: "GENDER",
    AGE_GROUP: "AGE_GROUP",
    TENURE: "TENURE",
    CUSTOM_FIELD: "CUSTOM_FIELD",
    SUPPORTED_LANGUAGE: "SUPPORTED_LANGUAGE",
    CLIENTS: "CLIENTS",
    COUNTRY: "COUNTRY",
    SURVEY: "SURVEY",
    QUESTION_STATE: "QUESTION_STATE",
    QUESTION_ORDER: "QUESTION_ORDER",
    RESPONDER_ACCESS: "RESPONDER_ACCESS",
    COMPANY_STATUS:"COMPANY_STATUS",
    JOB_ROLE: "JOB_ROLE",
    INVITED_MEDIA: "INVITED_MEDIA"
};

export const SURVEY_INVITE_FILTERS = {
    STATUS_OPEN : "STATUS_OPEN",
    STATUS_DRAFT: "STATUS_DRAFT",
    DATE_PICKER: "DATE_PICKER",
    MESSAGES: "MESSAGES",
    DATE_RANGE_PICKER: "DATE_RANGE_PICKER",
    CHANNEL_APP: "CHANNEL_APP",
    CHANNEL_SMS: "CHANNEL_SMS"
}

export const SURVEY_INVITE_ACTIONS = {
    COPY_SINGLE_OPTION_URL: "COPY_SINGLE_OPTION_URL",
    COPY_MULTIPLE_OPTION_URL: "COPY_MULTIPLE_OPTION_URL",
    SHOW_SINGLE_OPTION_QR: "SHOW_SINGLE_OPTION_QR",
    DOWNLOAD_SINGLE_OPTION_QR: "DOWNLOAD_SINGLE_OPTION_QR",
    DOWNLOAD_MULTIPLE_OPTION_QR: "DOWNLOAD_MULTIPLE_OPTION_QR",
    SHOW_MULTIPLE_OPTIONS_QR: "SHOW_MULTIPLE_OPTIONS_QR",
    SHOW_RESPONSE_DIVISION: "SHOW_RESPONSE_DIVISION",
    SHOW_INVITED_CONTACT: "SHOW_INVITED_CONTACT",
    CHECK_OUTREACH: "CHECK_OUTREACH",
    SHARE_INVITES: "SHARE_INVITES",
    TARGET_AUDIENCE: "TARGET_AUDIENCE",
    TOTAL_INVITED: "TOTAL_INVITED",
    CANCEL_INVITED: "CANCEL_INVITED",
    CANCEL_INVITED_INNER: "CANCEL_INVITED_INNER",
    DELIVERY_REPORT: "DELIVERY_REPORT",
    SEND_REMINDER: "SEND_REMINDER",
    SEND_REMINDER_INNER: "SEND_REMINDER_INNER",
    ASSIGNED_COMPANY_POPUP: "ASSIGNED_COMPANY_POPUP",
    CLOSE_SURVEY: "CLOSE_SURVEY",
    OPEN_SURVEY: "OPEN_SURVEY",
    SHOW_INVITE_DETAIL: "SHOW_INVITE_DETAIL",
    CLOSE_CLOSE_BUTTON_POPUP: "CLOSE_CLOSE_BUTTON_POPUP"
}

export const SURVEY_SUMMARY_TABLE_FILTERS = {
    LANGUAGE: "LANGUAGE",
    START_DATE: "START_DATE",
    END_DATE: "END_DATE"
};

export const SURVEY_TEMPLATE_SUMMARY_TABLE_FILTERS = {
    LANGUAGE: "LANGUAGE",
    START_DATE: "START_DATE",
    END_DATE: "END_DATE",
    REPORT_TYPE_FILTER: 'REPORT_TYPE_FILTER',
    GROUP_TYPE_FILTER: 'GROUP_TYPE_FILTER',
};

export const SURVEY_SUMMARY_TABLE_FILTER_VALUE_CONSTANTS = {
    "FILTER_VALUE_OVERALL": "overall",
    "FILTER_VALUE_GENDER": "gender",
    "FILTER_VALUE_AGE_GROUP": "age_group",
    "FILTER_VALUE_TENURE_GROUP": "tenure_group",
    "FILTER_VALUE_JOB_ROLE": "job_role",
    "FILTER_VALUE_SMART_PHONE": "smart_phone",
    "FILTER_VALUE_DEPARTMENTS": "department",
    "FILTER_VALUE_COMPANIES": "company",
    "FILTER_VALUE_COUNTRIES": "country",
    "FILTER_VALUE_UNDISCLOSED": "undisclosed"
};

/*** Constant values for the filters ***/
export const SURVEY_SUMMARY_TABLE_HEADER_FILTER_VALUE_CONSTANTS = {
    "FILTER_VALUE_OVERALL": "Overall",
    "FILTER_VALUE_GENDER": "Gender",
    "FILTER_VALUE_AGE_GROUP": "Age Group",
    "FILTER_VALUE_TENURE_GROUP": "Tenure Group",
    "FILTER_VALUE_JOB_ROLE": "Job Role",
    "FILTER_VALUE_SMART_PHONE": "Smart Phone",
    "FILTER_VALUE_DEPARTMENTS": "Departments",
    "FILTER_VALUE_COMPANIES": "Companies",
    "FILTER_VALUE_COUNTRIES": "Countries"
};

/*** Constant values for the filters key val matching ***/
export const SURVEY_SUMMARY_TABLE_KEY_VALUE_MATCHING_CONSTANTS = {
    "overall": "Overall",
    "gender": "Gender",
    "age_group": "Age Group",
    "tenure_group": "Tenure Group",
    "smart_phone": "Smart Phone",
    "department": "Departments",
    "company": "Companies",
    "country": "Countries"
};

export const SURVEY_SUMMARY_TABLE_REPORT_TYPE_FILTER_VALUE_CONSTANTS = {
    "BY_QUESTION": "question",
    "BY_CATEGORY": "category"
};

export const SURVEY_QUESTION_SUMMARY_TEXT_RESPONSE = {
    DOWNLOAD: "DOWNLOAD",
    DOWNLOAD_REPORT: "DOWNLOAD_REPORT"
};

export const SURVEY_REPORTS_RAW_DATA = {
    DOWNLOAD_RAW_DATA: "DOWNLOAD_RAW_DATA"
};

export const GENDER_TYPES = {
    ALL: -1,
    MALE: 1,
    FEMALE: 2,
    UNDISCLOSED: 3,
    OTHER: 4
};

export const AGE_GROUP_TYPES = {
    "ALL": -1,
    "0_13": 1,
    "14_18": 2,
    "19_35": 3,
    "36_50": 4,
    "51_100": 5
};

export const TENURE_GROUP_TYPES = {
    "ALL": -1,
    "0_1": 1,
    "1_3": 2,
    "3_MORE": 3
};

export const SURVEY_USER_ACTIONS = {
    "ADD_QUESTION": "ADD_QUESTION",
    "COPY_TEXT": "COPY_TEXT",
    "DOWNLOAD_QR": "DOWNLOAD_QR",
    "NAVIGATE_TO_REPORTS": "NAVIGATE_TO_REPORTS",
    "NAVIGATE_TO_SUMMARY_TABLE": "NAVIGATE_TO_SUMMARY_TABLE",
    "ADD_OPENING_MESSAGE": "ADD_OPENING_MESSAGE",
    "MESSAGE_CANCEL": "MESSAGE_CANCEL",
    "NAVIGATE_TO_QUICK_INSIGHTS": "NAVIGATE_TO_QUICK_INSIGHTS",
    "MANAGE_TRANSLATIONS": "MANAGE_TRANSLATIONS",
    "DOWNLOAD_TRANSLATIONS": "DOWNLOAD_TRANSLATIONS",
    "UPLOAD_TRANSLATIONS": "UPLOAD_TRANSLATIONS",
    "BROWSE_TRANSLATIONS": "BROWSE_TRANSLATIONS",
    "SURVEY_SETTINGS_POPUP_CANCEL": "SURVEY_SETTINGS_POPUP_CANCEL",
    "SURVEY_SETTINGS_DOWNLOAD_QR_CODE": "SURVEY_SETTINGS_DOWNLOAD_QR_CODE",
    "SURVEY_SETTINGS_COPY_URL": "SURVEY_SETTINGS_COPY_URL",
    "SURVEY_ASSIGNED_COMPANIES_POPUP_CANCEL": "SURVEY_ASSIGNED_COMPANIES_POPUP_CANCEL",
    "SURVEY_ASSIGNED_COMPANIES_DOWNLOAD_QR_CODE": "SURVEY_ASSIGNED_COMPANIES_DOWNLOAD_QR_CODE",
    "SURVEY_ASSIGNED_COMPANIES_COPY_URL": "SURVEY_ASSIGNED_COMPANIES_COPY_URL",
};

/*export const SURVEY_RATING_SCALE_CONFIG = {
    "MAX_OPTIONS_SIZE": 7,
    "WEIGHTAGES": {
        1: [100],
        2: [100, 0],
        3: [100, 50, 0],
        4: [100, 66.67, 33.33, 0],
        5: [100, 75, 50, 25, 0],
        6: [100, 80, 60, 40, 20, 0],
        7: [100, 83.33, 66.67, 50, 33.33, 16.67, 0]
    }
}*/

export const SURVEY_QUESTION_CONSTANTS = {
    "RATING_MAX_LABEL": "RATING_MAX_LABEL",
    "RATING_MIN_LABEL": "RATING_MIN_LABEL",
    "TITLE": "TITLE",
    "QUESTION_TYPE": "QUESTION_TYPE",
    "RADIO_BUTTON": "RADIO_BUTTON",
    "CHECK_BOX": "CHECK_BOX",
    "RATING_SCALE_LABEL": "RATING_SCALE_LABEL",
    "RATING_SCALE_WEIGHT": "RATING_SCALE_WEIGHT",
    "LINEAR_SCALE_MIN": "LINEAR_SCALE_MIN",
    "LINEAR_SCALE_MAX": "LINEAR_SCALE_MAX",
    "IS_MANDATORY": "IS_MANDATORY",
    "IS_CNA": "IS_CNA",
    "IS_LOCKED": "IS_LOCKED"
};

export const SURVEY_QUESTION_EMOJI_TYPES = {
    "VERY_POSITIVE": "VERY_POSITIVE",
    "POSITIVE": "POSITIVE",
    "NEUTRAL": "NEUTRAL",
    "NEGATIVE": "NEGATIVE",
    "VERY_NEGATIVE": "VERY_NEGATIVE",
    "SKIP": "SKIP"
};

export const SURVEY_QUESTION_EMOJI_COUNT_CONFIG = {
    1: [SURVEY_QUESTION_EMOJI_TYPES["POSITIVE"]],
    2: [SURVEY_QUESTION_EMOJI_TYPES["POSITIVE"], SURVEY_QUESTION_EMOJI_TYPES["NEGATIVE"]],
    3: [SURVEY_QUESTION_EMOJI_TYPES["POSITIVE"], SURVEY_QUESTION_EMOJI_TYPES["NEUTRAL"], SURVEY_QUESTION_EMOJI_TYPES["NEGATIVE"]],
    4: [SURVEY_QUESTION_EMOJI_TYPES["VERY_POSITIVE"], SURVEY_QUESTION_EMOJI_TYPES["POSITIVE"], SURVEY_QUESTION_EMOJI_TYPES["NEGATIVE"], SURVEY_QUESTION_EMOJI_TYPES["VERY_NEGATIVE"]],
    5: [SURVEY_QUESTION_EMOJI_TYPES["VERY_POSITIVE"], SURVEY_QUESTION_EMOJI_TYPES["POSITIVE"], SURVEY_QUESTION_EMOJI_TYPES["NEUTRAL"], SURVEY_QUESTION_EMOJI_TYPES["NEGATIVE"], SURVEY_QUESTION_EMOJI_TYPES["VERY_NEGATIVE"]]
};

export const SURVEY_QUESTION_EMOJI_CLASS_URL_CONFIG = {
    "VERY_POSITIVE": "/vendors/wovo/survey_emojis/very_positive.png",
    "POSITIVE": "/vendors/wovo/survey_emojis/positive.png",
    "NEUTRAL": "/vendors/wovo/survey_emojis/neutral.png",
    "NEGATIVE": "/vendors/wovo/survey_emojis/negative.png",
    "VERY_NEGATIVE": "/vendors/wovo/survey_emojis/very_negative.png",
    "SKIP": "/vendors/wovo/survey_emojis/skip.png"
};

export const SURVEY_QUESTION_EMOJI_CLASS_CONFIG = {
    "VERY_POSITIVE": "surveyQuestionEmoji survey_question_emoji_very_positive",
    "POSITIVE": "surveyQuestionEmoji survey_question_emoji_positive",
    "NEUTRAL": "surveyQuestionEmoji survey_question_emoji_neutral",
    "NEGATIVE": "surveyQuestionEmoji survey_question_emoji_negative",
    "VERY_NEGATIVE": "surveyQuestionEmoji survey_question_emoji_very_negative",
    "SKIP": "surveyQuestionEmoji survey_question_emoji_skip"
};

export const SURVEY_QUESTION_DISPLAY_EMOJI_CLASS_CONFIG = {
    "VERY_POSITIVE": "surveyDisplayQuestionEmoji survey_question_emoji_very_positive",
    "POSITIVE": "surveyDisplayQuestionEmoji survey_question_emoji_positive",
    "NEUTRAL": "surveyDisplayQuestionEmoji survey_question_emoji_neutral",
    "NEGATIVE": "surveyDisplayQuestionEmoji survey_question_emoji_negative",
    "VERY_NEGATIVE": "surveyDisplayQuestionEmoji survey_question_emoji_very_negative",
    "SKIP": "surveyDisplayQuestionEmoji survey_question_emoji_skip"
};


export const CONNECT_REPORTS_DOWNLOAD = {
    DOWNLOAD: 0,
    DOWNLOAD_PDF: 1,
    DOWNLOAD_SUMMARY_TABLE_DATA: 2,
    DOWNLOAD_RAW_DATA: 3
};

export const CONNECT_REPORTS_DATA = {
    IN_COMING: 0,
    OUT_GOING: 1,
    SUMMARY_TABLE: 2
};

export const COMPANY_TYPE = [
    {
        "label": "WOVO",
        "value": false
    },
    {
        "label": "NON-WOVO",
        "value": true
    }
]

export const RATING_SCORE_RANGES = {
    "ZERO_TO_SEVENTY_FIVE": <IntlMessages id="DIC_SURVEY_RATING_SCORE_RANGES_LESS_THAN_SEVENTY_FIVE"/>,
    "SEVENTY_FIVE_TO_EIGHTY_FIVE": <IntlMessages id="DIC_SURVEY_RATING_SCORE_RANGES_SEVENTY_FIVE_TO_EIGHTY_FIVE"/>,
    "EIGHTY_FIVE_AND_MORE": <IntlMessages id="DIC_SURVEY_RATING_SCORE_RANGES_EIGHTY_FIVE_AND_MORE"/>
}

export const SURVEY_CLONE_SOURCE_TYPES = {
    NONE: 1,
    TEMPLATE: 2,
    SURVEY: 3
};

export const SURVEY_RATING_SCALE_INITIAL_WEIGHTAGE = 100;

export const SURVEY_GRAPH_COLORS = ["#00A181", "#2581FF", "#D8005E", "#0DC3D1", "#066168", "#E1AC84", "#6A3777", "#05C6BC", "#2F95C7", "#CC4C5A", "#088A86", "#0C2243", "#BD68A0"];

export const SURVEY_GRAPH_COLOR_CNA = "#969696";

export const SURVEY_QUESTION_LAYOUT_DISPLAY_TYPE = {
    "QUESTION": "question",
    "CATEGORY": "category"
}

export const SURVEY_TEMPLATE_REPORT_TYPE_DISPLAY = {
    "QUICK_INSIGHTS": "QUICK_INSIGHTS",
    "SUMMARY_TABLE": "SUMMARY_TABLE"
}

export const SURVEY_REPORT_TYPE_DISPLAY = {
    "QUICK_INSIGHTS": "QUICK_INSIGHTS",
    "SUMMARY_TABLE": "SUMMARY_TABLE"
}

export const SURVEY_INFO_CARD_ITEM_VALUES = {
    SURVEY_PERIOD: 1,
    NUMBER_OF_QUESTIONS: 2,
    LANGUAGES: 3,
    RESPONSES_PER_DEVICE: 4,
    NUMBER_OF_CATEGORIES: 5,
    NUMBER_OF_COMPANIES: 6,
    NUMBER_OF_SURVEYS: 7
};

export const SURVEY_MESSAGE_LAYOUT_TYPES = {
    ADD_MESSAGE: 1,
    CLOSE_MESSAGE: 2,
};

export const SUMMARY_TABLE_LAYOUT_TYPES = {
    "SURVEY": "SURVEY",
    "QUESTIONNAIRE": "QUESTIONNAIRE"
};

export const DATE_RANGE_REPORT_TYPES = {
    "CONNECT": "CONNECT",
    "ELEARNING": "ELEARNING",
    "EXECUTIVE": "EXECUTIVE",
    "COMPANY_POST_RAW_DATA": "COMPANY_POST_RAW_DATA"
};

export const MANULA_URL = {
    "BRAND_USER": "https://www.manula.com/manuals/labor-solutions/worker-engagement-dashboard-brand-dashboard/1/en/topic/your-worker-engagement-dashboard-brand-guide",
    "COMPANY_USER": "https://www.manula.com/manuals/labor-solutions/worker-engagement-dashboard-company-dashboard/1/en/topic/your-worker-engagement-dashboard-user-guide"
};

export const JIRA_WIDGET_SCRIPT_SRC = {
    "DATA_KEY": "f3ef489e-2126-4c45-989d-e995e34c4b56",
    "DATA_BASE_URL": "https://jsd-widget.atlassian.com",
    "SOURCE": "https://jsd-widget.atlassian.com/assets/embed.js"
}

export const REPORTS_GROUP_BY_FILTERS = {
    "FILTER_VALUE_INDIVIDUAL": "INDIVIDUAL",
    "FILTER_VALUE_OVERALL": "OVERALL",
    "FILTER_VALUE_GENDER": "GENDER",
    "FILTER_VALUE_AGE_GROUP": "AGE_GROUP",
    "FILTER_VALUE_TENURE_GROUP": "TENURE_GROUP",
    "FILTER_VALUE_SMART_PHONE": "SMART_PHONE",
    "FILTER_VALUE_JOB_ROLE": "JOB_ROLE",
    "FILTER_VALUE_COUNTRY": "COUNTRY",
    "FILTER_VALUE_COMPANY": "COMPANY",
    "FILTER_VALUE_DEPARTMENT": "DEPARTMENT"
};

export const CONFIG_E_LEARNING_REPORTS = {
    "REPORT_PERCENTAGE_COLORS": {
        "<10": "#0A929D",
        "10_50": "#0B7D87",
        ">50": "#075A61",
    },
    "TABLE_HEADER_COLORS": {
        "ROW_1": {
            "BACKGROUND": "#2196F3",
            "COLOR": "#FFFFFF"
        },
        "ROW_2": {
            "BACKGROUND": "#4EB0FE",
            "COLOR": "#FFFFFF"
        },
        "ROW_3": {
            "BACKGROUND": "#C8C6C6",
            "COLOR": "#4F4F4F"
        }
    },
    "NA_COLOR": "#4F4F4F",
    "STICKER_CONF": {
        "BRONZE": 5,
        "SILVER": 25,
        "GOLD": 50
    }
};


export const CONFIG_TABLE = {
    ROW_HEIGHT: 50,
    HEADER_HEIGHT: 60
};

export const POST_TYPE = {
    "ALL": -1,
    "NEWS_LETTER": 1,
    "BROADCAST": 2,
    "SURVEY": 3,
};

export const COMPANY_POST_REPORTS_DOWNLOAD = {
    DOWNLOAD_RAW_DATA: 1
};

export const E_LEARNING_REPORTS_DOWNLOAD = {
    DOWNLOAD_RAW_DATA: 1
}

export const QUICK_INSIGHT_DROP_DOWN_VALUES = {
    CHART: "CHART",
    TABLE: "TABLE",
    DOWNLOAD_RAW_DATA: "DOWNLOAD_RAW_DATA",
    DOWNLOAD_PDF: "DOWNLOAD_PDF"
};

export const QUICK_INSIGHT_SCORE_RANGES = {
    HIGH: 92.9,
    LOW: 78.6
};

export const TABLE_SORT_CONFIG = {
    "asc": "asc",
    "desc": "desc"
};

export const POPUP_ACTION_BUTTONS = {
    "OK": "OK",
    "CANCEL": "CANCEL",
    "LEFT_BUTTON": "LEFT_BUTTON"
};

export const SURVEY_QUESTIONNAIRE_SNACKBAR_TIME = 5000;

export const WC_IMPORT_STATUS = {
    "NOT_PROCESSED": 1,
    "PROCESSED": 2,
    "INVALID_TEMPLATE": 3,
    "ERROR_IN_PROCESS": 4,
    "MAX_RECORDS": 5,
    "PARTIALLY_PROCESSED": 6,
    "IN_PROCESSING": 7
    
};

export const WC_IMPORT_STATUS_STRING_KEYS = {
    1: "DIC_KEY_WC_IMPORT_TBL_STATUS_LABEL_NOT_PROCESSED",
    2: "DIC_KEY_WC_IMPORT_TBL_STATUS_LABEL_PROCESSED",
    3: "DIC_KEY_WC_IMPORT_TBL_STATUS_LABEL_INVALID_TEMPLATE",
    4: "DIC_KEY_WC_IMPORT_TBL_STATUS_LABEL_ERROR_IN_PROCESS",
    5: "DIC_KEY_WC_IMPORT_TBL_STATUS_LABEL_MAX_RECORDS",
    6: "DIC_KEY_WC_IMPORT_TBL_STATUS_LABEL_PARTIALLY_PROCESSED",
    7: "DIC_KEY_WC_IMPORT_TBL_STATUS_LABEL_IN_PROCESSING"
};

export const PAGINATION_DATA_STATUS = {
    "NO_DATA": "NO_DATA",
    "IN_PROGRESS": "IN_PROGRESS",
    "COMPLETED": "COMPLETED"
};

export const WC_IMPORT_STATUS_POPUP_STRING_KEYS = {
    1: "DIC_KEY_WC_IMPORT_STATUS_POPUP_TITLE_NOT_PROCESSED",
    2: "DIC_KEY_WC_IMPORT_STATUS_POPUP_TITLE_SUCCESS",
    3: "DIC_KEY_WC_IMPORT_STATUS_POPUP_TITLE_INVALID_FILE",
    4: "DIC_KEY_WC_IMPORT_STATUS_POPUP_TITLE_FAILED",
    5: "DIC_KEY_WC_IMPORT_STATUS_POPUP_TITLE_MAX_ERROR",
    6: "DIC_KEY_WC_IMPORT_STATUS_POPUP_TITLE_PARTIAL",
    7: "DIC_KEY_WC_IMPORT_STATUS_POPUP_TITLE_PROCESSING"
};


export const WC_CONTACT_SMART_PHONE_TYPES = {
    UNKNOWN: 0,
    YES: 1,
    NO: 2
};

export const WC_CONTACT_GENDER_TYPES = {
    MALE: 1,
    FEMALE: 2,
    OTHERS: 3,
    UNDISCLOSED: 4
};

export const SURVEY_INVITE_GENDER_TYPES = {
    MALE: 0,
    FEMALE: 1,
    UNDISCLOSED: 2,
    OTHERS: 3
}

export const WC_CONTACT_AGE_GROUP_CONFIG = {
    "-1_18": {
        "key": "-1_18",
        "translationKey": "DIC_KEY_WC_LIST_FILTER_AGE_RANGE_LESS_THAN_18"
    },
    "18_30": {
        "key": "18_30",
        "translationKey": "DIC_KEY_WC_LIST_FILTER_AGE_RANGE_18_30"
    },
    "30_40": {
        "key": "30_40",
        "translationKey": "DIC_KEY_WC_LIST_FILTER_AGE_RANGE_30_40"
    },
    "40_50": {
        "key": "40_50",
        "translationKey": "DIC_KEY_WC_LIST_FILTER_AGE_RANGE_40_50"
    },
    "50_-1": {
        "key": "50_-1",
        "translationKey": "DIC_KEY_WC_LIST_FILTER_AGE_RANGE_GREATER_THAN_50"
    },
    "UNDISCLOSED": {
        "key": "-1",
        "translationKey": "DIC_KEY_WC_LIST_FILTER_AGE_RANGE_UNDISCLOSED"
    }
};

export const WC_CONTACT_TENURE_GROUP_CONFIG = {
    "-1_1": {
        "key": "-1_1",
        "translationKey": "DIC_KEY_WC_LIST_FILTER_TENURE_RANGE_LESS_THAN_1"
    },
    "1_3": {
        "key": "1_30",
        "translationKey": "DIC_KEY_WC_LIST_FILTER_TENURE_RANGE_1_3"
    },
    "3_-1": {
        "key": "3_-1",
        "translationKey": "DIC_KEY_WC_LIST_FILTER_TENURE_RANGE_GREATER_THAN_3"
    },
    "UNDISCLOSED": {
        "key": "-1",
        "translationKey": "DIC_KEY_WC_LIST_FILTER_TENURE_RANGE_UNDISCLOSED"
    }
};

export const CLIENT_CUSTOM_FIELD_TYPES = {
    "DROP_DOWN": "CustomFieldDropDownList"
};

export const WORKER_CONTACTS_CONFIG = {
    "LIST_CONTACT_GROUP_COLUMN_LENGTH": 15
};

export const SURVEY_SETTINGS_POPUP_RESPONSE_TYPE = {
    SINGLE_RESPONSE: 0,
    MULTI_RESPONSE: 1
};

export const SURVEY_RESPONSE_TYPE_MAPPING = {
    SINGLE: 'single',
    MULTIPLE: 'multiple'
};

export const SURVEY_RESPONSE_SOURCE_MAPPING = {
    QR_CODE: 'qr_code',
    URL: 'url',
    APP: 'app'
};

export const SURVEY_SETTINGS_RAMDOMIZE_FILTER_VALUES = {
    'all': -1,
    'random': 1,
    'standard': 0
};

export const  SURVEY_SETTINGS_INVITED_MEDIA_FILTER_VALUES = {
    'all': -1,
    'qrcode': 0,
    'url': 1
}

export const SURVEY_SETTINGS_RESPONSE_TYPE_FILTER_VALUES = {
    'all': -1,
    'single': 0,
    'multiple': 1
};

export const SORTING_TYPE = {
    "ROW": "SORT_ROW",
    "COLUMN": "SORT_COLUMN"
};

export const SORTING_DIRECTION_VALUES = {
    COLUMN_ASCENDING : 'col_asc',
    COLUMN_DESCENDING:'col_dsc',
    ROW_ASCENDING : 'row_asc',
    ROW_DESCENDING:'row_dsc'
};

export const WC_JOB_ROLES = {
    DEFAULT: 0,
    WORKER: 1,
    SUPERVISOR: 2,
    MANAGER: 3,
    HEAD: 4
};

export const WC_JOB_ROLES_KEYS = {
    DEFAULT: 'DEFAULT',
    WORKER: 'WORKER',
    SUPERVISOR: 'SUPERVISOR',
    MANAGER: 'MANAGER',
    HEAD: 'HEAD'
};

const CUSTOM_FIELD_TYPES = {
    "GENERAL": "GENERAL",
    "PHONE": "PHONE",
    "DATE": "DATE",
    "EMAIL": "EMAIL",
    "SINGLE_SELECTION": "SINGLE_SELECTION",
    "MULTIPLE_SELECTION": "MULTIPLE_SELECTION"
};

const CUSTOM_FIELD_TYPE_VALUE = {
    "GENERAL": 1,
    "PHONE": 2,
    "DATE": 3,
    "EMAIL": 4,
    "SINGLE_SELECTION": 5,
    "MULTIPLE_SELECTION": 6
};

export const CONTACT_SETTINGS_CONFIG = {
    "CUSTOM_FIELD_TYPES": CUSTOM_FIELD_TYPES,
    "CUSTOM_FIELD_TYPE_VALUE": CUSTOM_FIELD_TYPE_VALUE,
    "CUSTOM_FIELD_WOVO_CONFIG": {
        "CustomFieldCheckBox": CUSTOM_FIELD_TYPE_VALUE.MULTIPLE_SELECTION,
        "CustomFieldDropDownList": CUSTOM_FIELD_TYPE_VALUE.MULTIPLE_SELECTION,
        "CustomFieldRadioButton": CUSTOM_FIELD_TYPE_VALUE.SINGLE_SELECTION,
        "CustomFieldTextBox": CUSTOM_FIELD_TYPE_VALUE.GENERAL,
    },
    "CUSTOM_FIELD_TYPES_CONFIG": [
        {
            "value": CUSTOM_FIELD_TYPE_VALUE.GENERAL,
            "typeValue": CUSTOM_FIELD_TYPE_VALUE.GENERAL,
            "headerValue": CUSTOM_FIELD_TYPE_VALUE.GENERAL,
            "label": "DIC_KEY_CONTACT_SETTINGS_CUSTOM_FIELD_TYPE_GENERAL"
        },
        {
            "value": CUSTOM_FIELD_TYPE_VALUE.PHONE,
            "typeValue": CUSTOM_FIELD_TYPE_VALUE.PHONE,
            "headerValue": CUSTOM_FIELD_TYPE_VALUE.PHONE,
            "label": "DIC_KEY_CONTACT_SETTINGS_CUSTOM_FIELD_TYPE_PHONE"
        },
        {
            "value": CUSTOM_FIELD_TYPE_VALUE.DATE,
            "typeValue": CUSTOM_FIELD_TYPE_VALUE.DATE,
            "headerValue": CUSTOM_FIELD_TYPE_VALUE.DATE,
            "label": "DIC_KEY_CONTACT_SETTINGS_CUSTOM_FIELD_TYPE_DATE"
        },
        {
            "value": CUSTOM_FIELD_TYPE_VALUE.EMAIL,
            "typeValue": CUSTOM_FIELD_TYPE_VALUE.EMAIL,
            "headerValue": CUSTOM_FIELD_TYPE_VALUE.EMAIL,
            "label": "DIC_KEY_CONTACT_SETTINGS_CUSTOM_FIELD_TYPE_EMAIL"
        },
        {
            "value": CUSTOM_FIELD_TYPE_VALUE.SINGLE_SELECTION,
            "typeValue": CUSTOM_FIELD_TYPE_VALUE.SINGLE_SELECTION,
            "headerValue": CUSTOM_FIELD_TYPE_VALUE.SINGLE_SELECTION,
            "label": "DIC_KEY_CONTACT_SETTINGS_CUSTOM_FIELD_TYPE_SINGLE_SELECTION"
        },
        {
            "value": CUSTOM_FIELD_TYPE_VALUE.MULTIPLE_SELECTION,
            "typeValue": CUSTOM_FIELD_TYPE_VALUE.MULTIPLE_SELECTION,
            "headerValue": CUSTOM_FIELD_TYPE_VALUE.MULTIPLE_SELECTION,
            "label": "DIC_KEY_CONTACT_SETTINGS_CUSTOM_FIELD_TYPE_MULTIPLE_SELECTION"
        }
    ]
}


export const REPORT_FILTER_TYPE = {
    COUNTRY_FILTER: 'filter_child_country',
    COMPANY_STATUS_FILTER : 'filter_client_status'
};

export const COMPANY_STATUS_VALUE_MAP = {
    "ALL": '-1',
    "ACTIVE": '1',
    "INACTIVE": '0'
};

export const PAYSLIP_LIST_ACTION_TYPES = {
    "ADD_NEW" : "ADD_NEW"
}

export const PAYSLIP_STATUS = {
    "ALL": "ALL",
    "DRAFT": "DRAFT",
    "SCHEDULED": "SCHEDULED",
    "SENDING": "SENDING",
    "COMPLETED": "COMPLETED",
    "FAILED": "FAILED",
    "PARTIAL": "PARTIAL"
};

export const PAYSLIP_PREVIEW_ACTIONS = {
    "SEND_PAYSLIP": "SEND_PAYSLIP",
    "RESEND_PAYSLIP": "RESEND_PAYSLIP",
    "DELETE_PAYSLIP": "DELETE_PAYSLIP",
    "CANCEL_DELIVERY": "CANCEL_DELIVERY",
    "DOWNLOAD_REPORT": "DOWNLOAD_REPORT",
    "CLOSE_PLAYSLIP_PREVIEW": "CLOSE_PLAYSLIP_PREVIEW",
    "DOWNLOAD_PAYSLIP_EXCEL_UPLOADED": "DOWNLOAD_PAYSLIP_EXCEL_UPLOADED"
};

export const PAYSLIP_COMMUNICATION_CHANNELS = {
    "All": 1,
    "App": 2,
    "SMS": 3,
    "NONE":0
};

export const SURVEY_INVITE_COMMUNICATION_CHANNELS = {
    "All": 1,
    "App": 2,
    "SMS": 3,
    "NONE":0
}

export const PAYSLIP_STATUS_MAPPING = {
    ALL: -1,
    VERIFY:'',
    DRAFT: 1,
    SCHEDULED: 2,
    SENDING: 3,
    COMPLETED: 4,
    PARTIAL: 5,
    FAILED: 6,
    CANCELLED: 7,
    LOWCREDIT: 8,
    DELETED: 0
};

export const PAYSLIP_DETAILS_DIALOG_LOCATION = {
    'PAYSLIP_LIST': 'PAYSLIP_LIST',
    'PAYSLIP_VIEW': 'PAYSLIP_VIEW'
};

export const PAYSLIP_DATA_SOURCE = {
    DOT_NET: 1,
    PYTHON: 0
};

export const APPLICATION_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const APPLICATION_DATE_FORMAT = 'DD/MM/YYYY';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';

export const RESET_REGISTRATION_STATUS_MAPPING = {
    ALL: -1,
    UPLOADING: 1,
    PROCESSING: 2,
    COMPLETED: 3,
    PARTIAL: 4,
    FAILED: 5
}

export const RESET_REGISTRATION_STATUS_TO_LABEL_MAPPING = {
    1: 'DIC_KEY_WC_RESET_REGISTRATION_SEARCH_FILTER_OPTION_UPLOADING',
    2: 'DIC_KEY_WC_RESET_REGISTRATION_SEARCH_FILTER_OPTION_PROCESSIONG',
    3: 'DIC_KEY_WC_RESET_REGISTRATION_SEARCH_FILTER_OPTION_COMPLETED',
    4: 'DIC_KEY_WC_RESET_REGISTRATION_SEARCH_FILTER_OPTION_PARTIAL',
    5: 'DIC_KEY_WC_RESET_REGISTRATION_SEARCH_FILTER_OPTION_FAILED'
}