import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import ReducerClients from './clients/ReducerClients'
import e_learning_reports from './ELearningReports'

import SurveyTemplates from './survey/templates/reducerSurveyTemplate';
import SurveyCategories from './survey/category/SurveyCategories'
import SurveyQuestionTypes from "./survey/QuestionTypes/SurveyQuestionTypes";
import ReducerSurveyQuestionBank from "./survey/QuestionBank/reducerSurveyQuestionBank";
import SurveyQuestions from "./survey/questions/reducerSurveyQuestions";
import ReducerSurveyResponses from "./survey/responses/ReducerSurveyResponses";
import ReducerSurveyReportsRawdata from "./reports/survey/rawdata/reducerSurveyReportsRawdata";
import ReducerSurveyReportingCategory from "./survey/ReportingCategory/reducerSurveyReportingCategory";

import Translations from "./Translations";
import ReducerAccountManagers from './adminSettings/accountManagers/reducerAccountManagers';
import ReducerWoVoSurvey from "./survey/survey/reducerSurvey";
import clientPIC from "./adminSettings/ClientPIC/ClientPIC";
import ReducerWoVoSurveyUtils from "./survey/utils/reducerSurveyUtils"
import IndustryTypes from "./adminSettings/Industry/IndustryType";

import ReducerWoVoClients from "./clients/ReducerWovoClients";

import ReducerSurveyQuickInsights from './survey/QuickInsights/reducerSurveyQuickInsights';
import ReducerSurveyCloneHandler from "./survey/clone/reducerSurveyCloneHandler";
import ReducerUserSettings from "./user/settings/reducerUserSettings";
import ReducerTranslationCategories from "./adminSettings/Translations/Category/reducerTranslationCategories";
import ReducerTranslationSubmodules from "./adminSettings/Translations/Submodule/reducerTranslationSubmodules";
import ReducerTranslationModules from "./adminSettings/Translations/Module/reducerTranslationModules";
import ReducerTranslationStrings from "./adminSettings/Translations/strings/reducerTranslationStrings";
import ConnectReducers from './connect/connectReducers';
import ReducerConnectReportsRawdata from "./reports/connect/rawdata/reducerConnectReportsRawdata";
import ReducerSurveyTemplateQuickInsights from './survey/QuickInsights/reducerSurveyTemplateQuickInsights';
import ReducerSurveyTemplateReportsRawdata from './reports/template/rawdata/reducerSurveyTemplateReportsRawdata';
import ReducerConnectSummaryTableDownload from './reports/connect/summaryTable/reducerConnectSummaryTableDownload';
import ReducerReportFilters from "./reports/filters/reducerReportFilters";
import ReducerSurveySummaryTable from './survey/SummaryTable/reducerSummaryTable';
import ReducerAdminControls from "./adminControls/reducerAdminControls";
import ReducerELearningReports from "./reports/elearning/reducerElearningReports";
import ReducerCompanyPostReports from './reports/companyPost/reducerCompanyPostRawData';
import ReducerClientModules from './clientModules/reducerClientModules';
import ReducerTranslationFonts from "./adminSettings/Translations/fonts/reducerTranslationFonts";
import ReducerWorkerContacts from "./wc/reducerWorkerContacts";
import ReducerContactSettings from "./contactSettings/reducerContactSettings";
import ReducerCountries from './countries/reducerCountries';
import ReducerPayslip from './payslip/reducerPayslip';
import ReducerSurveyViewHistory from "./survey/survey/reducerSurveyViewHistory";
import ResetRegistration from './wc/resetRegistration/reducerResetRegistration';
import ReducerSurveyInvitePage from './survey/survey/reducerSurveyInvitePage';
import ReducerMaintenanceBanner from './adminSettings/maintenanceBanner/reducerMaintenanceBanner';

export default (history) => combineReducers({
    router: connectRouter(history),
    ReducerContactSettings: ReducerContactSettings,
    ReducerWoVoClients: ReducerWoVoClients,
    ReducerWorkerContacts: ReducerWorkerContacts,
    ReducerReportFilters: ReducerReportFilters,
    ReducerUserSettings: ReducerUserSettings,
    settings: Settings,
    auth: Auth,
    ReducerClients: ReducerClients,
    e_learning_reports: e_learning_reports,
    Translations: Translations,
    ReducerTranslationModules: ReducerTranslationModules,
    ReducerTranslationCategories: ReducerTranslationCategories,
    ReducerTranslationSubmodules: ReducerTranslationSubmodules,
    ReducerSurveyViewHistory: ReducerSurveyViewHistory,
    ReducerTranslationStrings: ReducerTranslationStrings,
    clientPIC: clientPIC,
    IndustryTypes: IndustryTypes,
    AccountManagers: ReducerAccountManagers,
    SurveyCategories: SurveyCategories,
    SurveyQuestionTypes: SurveyQuestionTypes,
    ReducerSurveyQuestionBank: ReducerSurveyQuestionBank,
    ReducerSurveyQuickInsights: ReducerSurveyQuickInsights,
    ReducerSurveyResponses: ReducerSurveyResponses,
    ReducerSurveyCloneHandler: ReducerSurveyCloneHandler,
    SurveyQuestions: SurveyQuestions,
    SurveyTemplates: SurveyTemplates,
    ReducerWoVoSurvey: ReducerWoVoSurvey,
    ReducerWoVoSurveyUtils: ReducerWoVoSurveyUtils,
    ReducerSurveyReportsRawdata: ReducerSurveyReportsRawdata,
    ConnectReducers: ConnectReducers,
    ReducerConnectReportsRawdata: ReducerConnectReportsRawdata,
    ReducerSurveyReportingCategory: ReducerSurveyReportingCategory,
    ReducerSurveyTemplateQuickInsights: ReducerSurveyTemplateQuickInsights,
    ReducerSurveyTemplateReportsRawdata: ReducerSurveyTemplateReportsRawdata,
    ReducerConnectSummaryTableDownload: ReducerConnectSummaryTableDownload,
    ReducerSurveySummaryTable: ReducerSurveySummaryTable,
    ReducerAdminControls: ReducerAdminControls,
    ReducerELearningReports: ReducerELearningReports,
    ReducerCompanyPostReports: ReducerCompanyPostReports,
    ReducerClientModules : ReducerClientModules,
    ReducerTranslationFonts: ReducerTranslationFonts,
    ReducerCountries: ReducerCountries,
    ReducerPayslip: ReducerPayslip,
    ResetRegistration: ResetRegistration,
    ReducerSurveyInvitePage: ReducerSurveyInvitePage,
    ReducerMaintenanceBanner: ReducerMaintenanceBanner
});
