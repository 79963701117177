import {BUTTON_CLASS_COLOR_NEW, BUTTON_COLOR_BLUE, BUTTON_COLOR_YELLOW} from "../constants/customUIConfig";

const commonStyles = ({
    containerRight: {
        "width": '100%',
        "display": "flex",
        "padding": "8px",
        "alignItems": "center",
        "justifyContent": "flex-end",
        "marginTop": "0rem"
    },
    containerRightNonPadding: {
        "width": '100%',
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "flex-end",
        "marginTop": "0rem"
    },
    rightNonPadding: {
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "flex-end",
        "marginTop": "0rem"
    },
    commonNewButton: {
        backgroundColor: BUTTON_COLOR_BLUE
    },
    commonCancelButton: {
        backgroundColor: BUTTON_COLOR_YELLOW
    },
    popupContainer: {
        minWidth: '500px',
    },
    surveyTemplateSourceSelectionPopup: {
        minWidth: '400px',
    },
    popupCheckboxBottom: {
        position: 'relative',
        paddingBottom: "25%",
    },
    
    dropdown:{
        "marginTop": "-5.5%"
    },

    survey: {
        questions : {
            title : {
                fontSize : '1.2rem',
                fontWeight : '400'
            },
            optionLabel : {
                fontSize : '1rem',
                fontWeight : '400'
            },
            overlay : {
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: '20px',
                justifyContent: "flex-end",
                zIndex: 2
            }
        }
    }

});

export default commonStyles;