import {
    AUTH_UPDATE_MENU_CONFIGURATION,
    CLIENT_GET_ALL_CLIENTS_SUCCESS,
    DISPLAY_ERROR_NOTIFICATION,
    HIDE_ERROR_NOTIFICATION,
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE, SIGNIN_USER,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS,
    SAVE_SEARCH_PARAMS,
    CLEAR_SEARCH_PARAMS,
    UPDATE_LOCAL_TIMEZONE_SELECTION
} from "constants/ActionTypes";
import {baseServerUrl} from "../util/keys";
import {SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER_SUCCESS} from "../constants/actions/survey/responses/actionTypesSurveyResponses";
import {WOVO_USER_ROLES} from "../constants/userRoles";
import AppUtils from "../util/util";
import StorageHandler from "../util/storageHandler";
import {SET_USER} from "../constants/ActionTypes";
import moment from "moment-timezone";


const MENU_ITEMS = {
    "VIEW_DASHBOARD": {
        name: 'dashboard',
        url: '/app/dashboard',
        wovoIcon: 'wovo_icon wovo_icon_dashboard',
        dicClass: 'wovo_span_label',
        dic_key: 'DIC_SIDE_BAR_DASHBOARD'
    },
    
    "MENU_CONNECT": {
        name: 'connect',
        url: '',
        wovoIcon: 'wovo_icon wovo_icon_connect',
        dicClass: 'wovo_span_label',
        dic_key: 'DIC_SIDE_BAR_CONNECT'
    },
    "MENU_CONNECT_REPORT": {
        name: 'connect_report',
        url: '/app/connect/reports/',
        dic_key: 'DIC_SIDE_BAR_CONNECT_REPORTS'
    },
    "MENU_PAY_SLIP": {
        name : 'pay_slip',
        url : '/app/payslip/list/',
        dic_key: 'DIC_SIDE_BAR_PAY_SLIP',
        tooltipItems : {
            name:'new_pay_slip',
            url: '/app/payslip/view/',
            dic_key: 'DIC_SIDE_BAR_NEW_PAY_SLIP'
        }
    },

    "MENU_SURVEY": {
        name: 'survey',
        url: '',
        wovoIcon: 'wovo_icon wovo_icon_survey',
        dicClass: 'wovo_span_label',
        dic_key: 'DIC_SIDE_BAR_SURVEY',
        is_admin: true
    },
    "MENU_SURVEY_HOME": {
        name: 'survey_home',
        url: '/app/survey/home/',
        dic_key: 'DIC_SIDE_BAR_SURVEY_HOME',
        is_admin: false
    },
    "MENU_SURVEY_QUESTIONNAIRE": {
        name: 'survey_templates',
        url: '/app/survey/templates/',
        dic_key: 'DIC_SIDE_BAR_SURVEY_TEMPLATES',
        is_admin: true
    },
    "MENU_SURVEY_QUESTION_TYPES": {
        name: 'survey_question_types',
        url: '/app/survey/questiontypes/',
        dic_key: 'DIC_SIDE_BAR_SURVEY_QUESTION_TYPES',
        is_admin: true
    },
    "MENU_SURVEY_QUESTION_BANK": {
        name: 'survey_question_bank',
        url: '/app/survey/questionbank/',
        dic_key: 'DIC_SIDE_BAR_SURVEY_QUESTION_BANK',
        is_admin: true
    },
    "MENU_SURVEY_CATEGORIES": {
        name: 'survey_reporting_categories',
        url: '/app/survey/reporting/categories/',
        dic_key: 'DIC_SIDE_BAR_SURVEY_REPORTING_CATEGORIES',
        is_admin: true
    },
    
    "MENU_E_LEARNING": {
        name: 'e_learning',
        url: '',
        wovoIcon: 'wovo_icon wovo_icon_elearning',
        dicClass: 'wovo_span_label',
        dic_key: 'DIC_SIDE_BAR_E_LEARNING'
    },
    "MENU_E_LEARNING_REPORTS": {
        name: 'e_learning_report',
        url: '/app/elearning/reports/',
        dic_key: 'DIC_SIDE_BAR_E_LEARNING_REPORTS'
    },
    
    "MENU_COMPANY_POST": {
        name: 'company_post',
        url: '',
        wovoIcon: 'wovo_icon wovo_icon_post_raw_data',
        dicClass: 'wovo_span_label',
        dic_key: 'DIC_SIDE_BAR_COMPANY_POST'
    },
    "MENU_COMPANY_POST_RAW_DATA_REPORT": {
        name: 'company_post_raw_data_report',
        url: '/app/companypost/reports/',
        dic_key: 'DIC_SIDE_BAR_COMPANY_POST_RAW_DATA'
    },
    
    "MY_WOVO": {
        name: 'my_wovo',
        url: '',
        wovoIcon: 'wovo_icon wovo_icon_participants',
        dicClass: 'wovo_span_label',
        dic_key: 'DIC_SIDE_BAR_MY_WOVO',
        is_admin: true
    },
    "MY_WOVO_CLIENTS": {
        name: 'clients',
        url: '/app/company/index/',
        dic_key: 'DIC_SIDE_BAR_WOVO_CLIENTS',
        is_admin: false,
        tooltipItems: {
            name: 'new_clients',
            url: '/app/client/view/',
            dic_key: 'DIC_SIDE_BAR_WOVO_CLIENTS_NEW_COMPANY',
            is_admin: false,
        }
    },
    "MY_WOVO_WC": {
        name: 'worker_contacts',
        url: '/app/worker_contacts/list/',
        dic_key: 'DIC_SIDE_BAR_WORKER_CONTACTS',
        is_admin: false
    },
    
    "WOVO_ADMIN": {
        name: 'admin',
        url: '',
        wovoIcon: 'wovo_icon wovo_icon_settings',
        dicClass: 'wovo_span_label',
        dic_key: 'DIC_SIDE_BAR_SETTINGS',
        is_admin: true
    },
    "WOVO_ADMIN_TRANSLATIONS": {
        name: 'translations',
        url: '/app/admin/settings/localisation/',
        dic_key: 'DIC_SIDE_BAR_SETTINGS_TRANSLATIONS',
        is_admin: true
    },
    "WOVO_ADMIN_CLIENT_MODULES": {
        name: 'clientModuleConfig',
        url: '/app/admin/settings/clientModuleConfig/',
        dic_key: 'DIC_KEY_ADMIN_CLIENT_MODULES',
        is_admin: true
    },
    "WOVO_ADMIN_INDUSTRY_TYPES": {
        name: 'industryTypes',
        url: '/app/admin/settings/industryTypes/',
        dic_key: 'DIC_SIDE_BAR_SETTINGS_INDUSTRY_TYPES',
        is_admin: true
    },
    "WOVO_ADMIN_ACCOUNT_MANAGERS": {
        name: 'managers',
        url: '/app/accountManagers/index/',
        dic_key: 'DIC_SIDE_BAR_SETTINGS_ACCOUNT_MANAGERS',
        is_admin: true
    },
    
    "WOVO_ADMIN_ACTIVATE_SURVEY": {
        name: 'activate_survey',
        url: '/app/admin/activate_survey/',
        dic_key: 'DIC_SIDE_BAR_ADMIN_ACTIVATE_SURVEY'
    },
    "WOVO_ADMIN_SURVEY_DUPLICATE_RESPONSES": {
        name: 'survey_duplicate_responses',
        url: '/app/admin/survey_duplicate_responses/',
        dic_key: 'DIC_SIDE_BAR_ADMIN_SURVEY_DUPLICATE_RESPONSES'
    },
    "WOVO_ADMIN_SURVEY_ALL_RAW_DATA_RESPONSES": {
        name: 'survey_all_raw_data_responses',
        url: '/app/admin/survey_all_raw_data_report/',
        dic_key: 'DIC_SIDE_BAR_ADMIN_SURVEY_RAW_DATA_REPORT'
    },
    "WOVO_ADMIN_SURVEY_RESPONSES_UPDATE": {
        name: 'survey_responses_update',
        url: '/app/admin/survey_responses_update/',
        dic_key: 'DIC_SIDE_BAR_ADMIN_SURVEY_RESPONSES_UPDATE'
    },
    "WOVO_ADMIN_ADMIN_CLIENT_MODULES": {
        name: 'admin_client_modules',
        url: '/app/admin/client_modules/',
        dic_key: 'DIC_SIDE_BAR_ADMIN_CLIENT_MODULES'
    },
    "WOVO_ADMIN_ADMIN_INCREASE_SURVEY_RESPONSES": {
        name: 'admin_increase_survey_responses',
        url: '/app/admin/increase_survey_responses/',
        dic_key: 'DIC_SIDE_BAR_ADMIN_SURVEY_INCREASE_RESPONSES'
    },
    "WOVO_ADMIN_RESET_SURVEY_CACHED_DATA": {
        name: 'admin_reset_survey_cached_data',
        url: '/app/admin/reset_survey_cached_data/',
        dic_key: 'DIC_SIDE_BAR_ADMIN_SURVEY_RESET_CACHED_DATA'
    },
    "WOVO_ADMIN_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID": {
        name: 'admin_save_survey_response_by_request_id',
        url: '/app/admin/save_survey_response_by_request_id/',
        dic_key: 'DIC_SIDE_BAR_ADMIN_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID'
    },
    "WOVO_ADMIN_WORKER_CONTACTS": {
        name: 'admin_worker_contacts',
        url: '/app/admin/worker_contacts/',
        dic_key: 'DIC_SIDE_BAR_ADMIN_WORKER_CONTACTS'
    },
    "SYNC_CLIENTS": {
        name: 'clients',
        url: '/app/wovoimport/clients/',
        dic_key: 'DIC_SIDE_BAR_SYNC_CLIENTS_SYNC'
    },
    
    "TOOLTIP_MENU_CLIENTS": {
        name: 'new_clients',
        url: '/app/client/view/',
        dic_key: 'DIC_SIDE_BAR_WOVO_CLIENTS_NEW_COMPANY',
        is_admin: false,
    }
};

const MENU_CONFIG = {
    "ADMIN": {
        "VIEW_DASHBOARD": {},
        "MENU_CONNECT": {
            "MENU_CONNECT_REPORT": [],
            "MENU_PAY_SLIP": [],
        },
        "MENU_SURVEY": {
            "MENU_SURVEY_HOME": [],
            "MENU_SURVEY_QUESTIONNAIRE": [],
            "MENU_SURVEY_QUESTION_TYPES": [],
            "MENU_SURVEY_QUESTION_BANK": [],
            "MENU_SURVEY_CATEGORIES": []
        },
        "MENU_E_LEARNING": {
            "MENU_E_LEARNING_REPORTS": []
        },
        "MENU_COMPANY_POST": {
            "MENU_COMPANY_POST_RAW_DATA_REPORT": []
        },
        "MY_WOVO": {
            "MY_WOVO_CLIENTS": ["TOOLTIP_MENU_CLIENTS"],
            "MY_WOVO_WC": []
        },
        "WOVO_ADMIN": {
            "WOVO_ADMIN_TRANSLATIONS": [],
            "WOVO_ADMIN_CLIENT_MODULES": [],
            "SYNC_CLIENTS": []
        }
    },
    "DM": {
        "VIEW_DASHBOARD": {},
        "MENU_CONNECT": {
            "MENU_CONNECT_REPORT": [],
            "MENU_PAY_SLIP": [],
        },
        "MENU_SURVEY": {
            "MENU_SURVEY_HOME": [],
            "MENU_SURVEY_QUESTIONNAIRE": [],
            "MENU_SURVEY_CATEGORIES": []
        },
        "MENU_E_LEARNING": {
            "MENU_E_LEARNING_REPORTS": []
        },
        "MENU_COMPANY_POST": {
            "MENU_COMPANY_POST_RAW_DATA_REPORT": []
        },
        "MY_WOVO": {
            /*"MY_WOVO_CLIENTS": [],*/
            "MY_WOVO_WC": []
        }
    },
    "DA": {
        "VIEW_DASHBOARD": {},
        "MENU_CONNECT": {
            "MENU_CONNECT_REPORT": [],
            "MENU_PAY_SLIP": [],
        },
        "MENU_SURVEY": {
            "MENU_SURVEY_HOME": [],
            "MENU_SURVEY_QUESTIONNAIRE": [],
            "MENU_SURVEY_CATEGORIES": []
        },
        "MENU_E_LEARNING": {
            "MENU_E_LEARNING_REPORTS": []
        },
        "MENU_COMPANY_POST": {
            "MENU_COMPANY_POST_RAW_DATA_REPORT": []
        },
        "MY_WOVO": {
            /*"MY_WOVO_CLIENTS": [],*/
            "MY_WOVO_WC": []
        }
    },
    "BDM": {
        "VIEW_DASHBOARD": {},
        "MENU_CONNECT": {
            "MENU_CONNECT_REPORT": []
        },
        "MENU_SURVEY": {
            "MENU_SURVEY_HOME": [],
            "MENU_SURVEY_QUESTIONNAIRE": [],
            "MENU_SURVEY_CATEGORIES": []
        },
        "MENU_E_LEARNING": {
            "MENU_E_LEARNING_REPORTS": []
        },
        "MENU_COMPANY_POST": {
            "MENU_COMPANY_POST_RAW_DATA_REPORT": []
        },
        "MY_WOVO": {
            /*"MY_WOVO_CLIENTS": [],*/
            "MY_WOVO_WC": []
        }
    },
    "SUPER_ADMIN": {
        "VIEW_DASHBOARD": {},
        "MENU_CONNECT": {
            "MENU_CONNECT_REPORT": [],
            "MENU_PAY_SLIP": [],
        },
        "MENU_SURVEY": {
            "MENU_SURVEY_HOME": [],
            "MENU_SURVEY_QUESTIONNAIRE": [],
            "MENU_SURVEY_QUESTION_TYPES": [],
            "MENU_SURVEY_QUESTION_BANK": [],
            "MENU_SURVEY_CATEGORIES": []
        },
        "MENU_E_LEARNING": {
            "MENU_E_LEARNING_REPORTS": []
        },
        "MENU_COMPANY_POST": {
            "MENU_COMPANY_POST_RAW_DATA_REPORT": []
        },
        "MY_WOVO": {
            "MY_WOVO_CLIENTS": ["TOOLTIP_MENU_CLIENTS"],
            "MY_WOVO_WC": []
        },
        "WOVO_ADMIN": {
            "WOVO_ADMIN_TRANSLATIONS": [],
            "WOVO_ADMIN_CLIENT_MODULES": [],
            "WOVO_ADMIN_ACTIVATE_SURVEY": [],
            "WOVO_ADMIN_SURVEY_DUPLICATE_RESPONSES": [],
            "WOVO_ADMIN_SURVEY_ALL_RAW_DATA_RESPONSES": [],
            "WOVO_ADMIN_SURVEY_RESPONSES_UPDATE": [],
            "WOVO_ADMIN_ADMIN_INCREASE_SURVEY_RESPONSES": [],
            "WOVO_ADMIN_RESET_SURVEY_CACHED_DATA": [],
            "WOVO_ADMIN_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID": [],
            "WOVO_ADMIN_WORKER_CONTACTS": [],
            "SYNC_CLIENTS": []
            
        }
    }
};

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    authUser: localStorage.getItem('user_id'),
    user: {},
    bearerHeader: '',
    menuConfiguration: [],
    selectedLanguage: 'en-US',
    selectedLanguageId: 1,
    isProduction: baseServerUrl.indexOf('wovonew.') !== -1 && baseServerUrl.indexOf('localhost') === -1,
    isDebug: baseServerUrl.indexOf('127.0.0.1') !== -1 || baseServerUrl.indexOf('localhost') !== -1,
    currentUserRole: "",
    isUserHasTemplateCreateAccess: false,
    avatarName: "",
    accessToken: "",
    refreshToken: "",
    commonError: "",
    isSuperAdmin: false,
    searchParam: '',
    localSelectedTimeZone: null
};


export default (state = INIT_STATE, action) => {
    
    switch (action.type) {
        
        case HIDE_ERROR_NOTIFICATION: {
            
            return {
                ...state,
                commonError: ""
            }
        }
        
        case DISPLAY_ERROR_NOTIFICATION: {
            return {
                ...state,
                commonError: action.payload
            }
        }
        
        case SIGNUP_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        
        case SURVEY_RESPONSE_GET_AUTHENTICATE_SURVEY_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                user: action.payload.user,
                authUser: action.payload,
                bearerHeader: {Authorization: `Bearer ${action.payload.access}`},
                accessToken: `Bearer ${action.payload.access}`
            }
        }
        
        case CLIENT_GET_ALL_CLIENTS_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        
        case SIGNIN_USER : {
            return {
                ...state,
                loader: true
            }
        }
        
        case AUTH_UPDATE_MENU_CONFIGURATION: {
            
            let menuConfiguration = prepareUserMenu(state.user, state.isSuperAdmin);

            return {
                ...state,
                menuConfiguration: menuConfiguration
            }
        }

        case UPDATE_LOCAL_TIMEZONE_SELECTION: {
            return {
                ...state,
                localSelectedTimeZone: action.payload
            }
        }
        
        case SIGNIN_USER_SUCCESS: {
            let user = action.payload.user;
            let avatarName = "";
            let isSuperAdmin = false;
            
            if (typeof user['first_name'] != "undefined" && user['first_name'] != null) {
                avatarName = user['first_name'].substring(0, 1).toUpperCase();
            }
            
            if (typeof user['last_name'] != "undefined" && user['last_name'] != null) {
                avatarName += user['last_name'].substring(0, 1).toUpperCase();
            }
            
            if (typeof user['is_super_admin'] != "undefined" && user['is_super_admin'] != null) {
                isSuperAdmin = user['is_super_admin'];
            }
            
            /**Set time zone to empty in case time zone value from db is invalid */
            if (typeof user['timezone'] === "undefined" ||
                user['timezone'] === null ||
                user['timezone'] === '' ||
                (!moment.tz.zone(user['timezone']))) {
                user['timezone'] = '';
            }
            
            let currentUserRole = action.payload.user["role"];
            let isUserHasTemplateCreateAccess = false;
            if (currentUserRole === WOVO_USER_ROLES.SUPER_ADMIN ||
                currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER ||
                currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER_PHASE_1 ||
                currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER_UNDER_ARMOUR ||
                currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_TCP) {
                isUserHasTemplateCreateAccess = true;
            }
            
            let accessToken
            if (action.payload.access) {
                accessToken = `Bearer ${action.payload.access}`
                const useCookieToValidate = process.env.REACT_APP_USE_COOKIES_TO_AUTHENTICATE === "true"
                if (!useCookieToValidate) {
                    StorageHandler.setCookie("access_token", action.payload.access);
                }
            }
            
            let userState = {
                user: user,
                avatarName: avatarName,
                authUser: action.payload,
                currentUserRole: currentUserRole,
                bearerHeader: {Authorization: accessToken},
                refreshKey: action.payload.refresh,
                accessToken: accessToken
            };
            
            if (typeof user['preferred_client'] != "undefined") {
                StorageHandler.saveItem(StorageHandler.keys.PREFERRED_CLIENT, user['preferred_client']);
            }
            
            StorageHandler.saveCurrentUserName(user['user_name']);
            StorageHandler.saveUserState(user['user_name'], userState);

            return {
                ...state,
                ...userState,
                isUserHasTemplateCreateAccess: isUserHasTemplateCreateAccess,
                isSuperAdmin: isSuperAdmin
            }
        }
        
        case SET_USER: {
            let userState = action.payload;
            
            return {
                ...state,
                menuConfiguration: userState.menuConfiguration,
                user: userState.user,
                avatarName: userState.avatarName,
                authUser: userState.authUser,
                currentUserRole: userState.currentUserRole,
                bearerHeader: userState.bearerHeader,
                refreshKey: userState.refreshKey,
                isUserHasTemplateCreateAccess: userState.isUserHasTemplateCreateAccess,
                initURL: '/',
            }
        }
        
        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            
            AppUtils.reloadPage();
            
            return {
                ...state
            }
        }
        
        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }
        
        case ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        
        /*** save search param in case of redirection */
        case SAVE_SEARCH_PARAMS: {
            return {
                ...state,
                searchParam: action.payload
            }
        }

        /*** clear search param */
        case CLEAR_SEARCH_PARAMS: {
            return {
                ...state,
                searchParam: ''
            }
        }

        default:
            return state;
    }
}

function prepareUserMenu(user, isSuperAdmin) {
    let result = [];
    try {
        let menuRole = "DM";
        
        switch (user["role"]) {
            
            case WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER_UNDER_ARMOUR:
            case WOVO_USER_ROLES.BRAND_DASHBOARD_TCP:
            case WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER_PHASE_1:
            case WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER: {
                menuRole = "BDM";
                break;
            }
            
            case WOVO_USER_ROLES.SUPER_ADMIN: {
                menuRole = "ADMIN";
                break;
            }

            case WOVO_USER_ROLES.DASHBOARD_AGENT: {
                menuRole = "DA";
                break;
            }
            
            default: {
                break;
            }
        }
        
        if (isSuperAdmin) {
            menuRole = "SUPER_ADMIN";
        }
        
        let currentConfig = MENU_CONFIG[menuRole];
        let rootElement;
        let childElements;
        let childItems;

        for (let rootItem in currentConfig) {
            childItems = currentConfig[rootItem];
            
            childElements = [];
            
            let moduleKey = AppUtils.getModuleKeyBasedOnKey(rootItem);
            let isValid = true;
            if (moduleKey !== "") {
                isValid = AppUtils.isModuleHasAccess(moduleKey, user['module_config']);
            }
            
            if (!isValid) {
                continue;
            }

            for (let childItem in childItems) {

                let moduleKey = AppUtils.getModuleKeyBasedOnKey(childItem);
                let isValid = true;
                if (moduleKey !== "") {
                    isValid = AppUtils.isModuleHasAccess(moduleKey, user['module_config']);
                }

                if (!isValid) {
                    continue;
                }

                let currentItem = JSON.parse(JSON.stringify(MENU_ITEMS[childItem]));
                let overflowItems = childItems[childItem];
                
                for (let i = 0; i < overflowItems.length; i++) {
                    currentItem[overflowItems[i]] = JSON.parse(JSON.stringify(overflowItems[i]));
                }
                
                childElements.push(currentItem);
            }
            
            rootElement = JSON.parse(JSON.stringify(MENU_ITEMS[rootItem]));
            
            if (childElements.length > 0) {
                rootElement['items'] = childElements
            }
            
            result.push(rootElement);
        }
        
    } catch (ex) {
        console.error(ex)
    }
    
    return result;
}

