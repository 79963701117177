import React from 'react';
import IntlMessages from "../../util/IntlMessages";
import Popover from "@material-ui/core/Popover";
import {PopoverBody} from "reactstrap";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";

class MultiSelectDropDown extends React.Component {

    constructor() {
        super();

        this.state = {
            popOverOpen: false,
            popOverTarget: null,
            currentItems: [],
            previousItems: [],
            selectAll: true
        }
    }

    handleCheckBoxClick = (item) => {
        try {
            const {currentItems} = this.state;
            let selectAll = true;

            for (let i = 0; i < currentItems.length; i++) {
                if (item.id === currentItems[i].id) {
                    currentItems[i].selected = !currentItems[i].selected;
                    break;
                }
            }

            for (let i = 0; i < currentItems.length; i++) {
                if ( !currentItems[i].selected ) {
                    selectAll = false;
                    break;
                }
            }

            this.setState({
                currentItems: currentItems,
                selectAll: selectAll
            });

        } catch (ex) {
            console.error("handleCheckBoxClick : error : ", ex);
        }
    }

    getIdsFromList = (data) => {
        let result = [];
        try {
            if (typeof data != "undefined") {

                for (let i = 0; i < data.length; i++) {
                    if (data[i].selected) {
                        result.push(parseInt(data[i].id + ""));
                    }
                }
            }
        } catch (ex) {
            console.error("getSelectedLessonData : error : ", ex);
        }

        return result;
    }

    hideMenu = () => {
        try {
            const {currentItems, previousItems} = this.state;
            const {data} = this.props;

            let currentIds = this.getIdsFromList(currentItems);
            let previousIds = this.getIdsFromList(previousItems);

            let isSameIds = (currentIds.length === previousIds.length) && currentIds.every(function (element, index) {
                return element === previousIds[index];
            });

            if (!isSameIds && data.changeCallback) {
                data.changeCallback(data.key, currentItems);
            }

            this.setState({
                popOverOpen: false,
                popOverTarget: null,
                currentItems: [],
                previousItems: [],
                selectAll : true
            });

        } catch (ex) {
            console.error("toggleMenu : error : ", ex);
        }
    }

    onOptionMenuSelect = (event) => {
        try {
            const {data} = this.props;
            if (typeof data.items != "undefined" && data.items.length > 0) {

                let currentItems = data.items.filter(x => x);
                let previousItems = JSON.parse(JSON.stringify(currentItems));

                let selectAll = true;

                for (let i = 0; i < currentItems.length; i++) {
                    if ( !currentItems[i].selected ) {
                        selectAll = false;
                        break;
                    }
                }

                this.setState({
                    popOverOpen: true,
                    popOverTarget: event.currentTarget,
                    currentItems: currentItems,
                    previousItems: previousItems,
                    selectAll: selectAll
                });
            }
        } catch (ex) {
            console.error("onOptionMenuSelect : error : ", ex);
        }
    };

    toggleSelectAll = () => {
        try {
            const {currentItems, selectAll} = this.state;

            for(let i = 0 ; i < currentItems.length; i++ ) {
                currentItems[i].selected = !selectAll;
            }

            this.setState({
                currentItems: currentItems,
                selectAll: !selectAll
            });

        } catch (ex) {
            console.log("toggleSelectAll : error : ", ex );
        }
    }

    renderSelectAllLabel = () => {
        const {selectAll} = this.state;
        let labelValue = selectAll ? "DIC_MULTI_SELECT_DROPDOWN_DESELECT_ALL" : "DIC_MULTI_SELECT_DROPDOWN_SELECT_ALL";
        
        return(
            <label className="MultiSelectDropDownSelectAll" onClick={()=>this.toggleSelectAll()} ><IntlMessages id={labelValue} /></label>
        )
    }


    renderPopOver = () => {
        const {popOverOpen, popOverTarget} = this.state;

        return (
            popOverOpen &&
            <Popover
                anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                onClose={this.hideMenu}
                anchorEl={popOverTarget}
                open={popOverOpen}
            >
                <PopoverBody>
                    {this.renderSelectAllLabel()}
                    {this.renderItems()}
                </PopoverBody>
            </Popover>
        )
    }

    renderItems = () => {

        const {data} = this.props;

        return data.items.map((item, index) => {
            return (
                <ListItem className={"hand MultiSelectDropDownItem"} onClick={() => this.handleCheckBoxClick(item)}>
                    <Checkbox
                        color={"primary"}
                        className={"MultiSelectDropDownContentCheckBox"}
                        checked={item.selected}
                    />
                    {item.label}
                </ListItem>
            )
        });
    };

    renderStatusLabel = () => {

        const {data} = this.props;
        const {items, value, selectedLabel} = data;

        let titleId = selectedLabel ? selectedLabel : "DIC_MULTI_SELECT_DROPDOWN_ITEMS";
        let title = <>{"0 "} <IntlMessages id={titleId}/>{" "} <IntlMessages id={"DIC_MULTI_SELECT_DROPDOWN_SELECTED"}/> </>;

        if (items) {
            if (items.length === value.length) {
                title = <IntlMessages id={"DIC_MULTI_SELECT_DROPDOWN_ALL"}/>;
            } else {
                title = <>{value.length + " "} <IntlMessages id={titleId}/>{" "} <IntlMessages id={"DIC_MULTI_SELECT_DROPDOWN_SELECTED"}/> </>
            }
        }

        return (
            <div className="MultiSelectDropDownContent">
                {title}
            </div>
        )
    }

    render() {

        const {data} = this.props;
        const {popOverOpen} = this.state;

        return (
            <div className={"MultiSelectDropDown"}>

                <div className={"MultiSelectDropDownChild" + (popOverOpen ? " MultiSelectDropDownChildActive" : "")} id={data.key + "_dropdown_menu"} onClick={this.onOptionMenuSelect}>
                    {this.renderStatusLabel()}
                </div>

                {this.renderPopOver()}

                {typeof data['label'] != "undefined" && data['label'] !== "" ?
                    <span className={"MultiSelectDropDownHeader" + (popOverOpen ? " MultiSelectDropDownHeaderActive" : "")}>
                        {data['label']}
                    </span> : null
                }
            </div>
        )
    }
}


export default MultiSelectDropDown;
