import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from "axios";
import {baseUrl} from "../../../util/keys";
import {
    SURVEY_QUESTION_BANK_GET_ALL_RECORDS,
    SURVEY_QUESTION_BANK_NEW_EDIT_SAVE,
    SURVEY_QUESTION_BANK_UPDATE_STATUS,
    SURVEY_QUESTION_BANK_UPLOAD
} from "../../../constants/ActionTypes";

import {
    getSurveyQuestionBankSuccess,
    getSurveyQuestionBankActiveSuccess,
    saveSurveyQuestionBankError,
    saveSurveyQuestionBankSuccess,
    surveyQuestionBankUpdateStatusSuccess,
    uploadQuestionBankFileSaveSuccess,
    uploadQuestionBankFileSaveError
} from "../../../actions";

import {
    URL_SURVEY_QUESTION_BANK_LIST,
    URL_SURVEY_QUESTION_BANK_SAVE,
    URL_SURVEY_QUESTION_BANK_UPDATE,
    URL_SURVEY_QUESTION_BANK_UPDATE_STATUS,
    URL_SURVEY_QUESTION_BANK_UPLOAD
} from "../../../constants/Urls";
import AppUtils from "../../../util/util";


/************ methods : http *******************/
/** http : Survey Question bank : http save **/
const httpSaveSurveyQuestionBank = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_QUESTION_BANK_SAVE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Question bank : http update **/
const httpUpdateQuestionBank = async (data) =>
    await axios.patch(baseUrl + URL_SURVEY_QUESTION_BANK_UPDATE, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Question bank : all records **/
const httpGetSurveyQuestionBankList = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_QUESTION_BANK_LIST,data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Survey Question bank : http update status **/
const httpSurveyQuestionBankUpdateStatus = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_QUESTION_BANK_UPDATE_STATUS, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });

/** http : Question Bank file upload : http save **/
const httpUploadQestionBankFile = async (data) =>
    await axios.post(baseUrl + URL_SURVEY_QUESTION_BANK_UPLOAD, data, AppUtils.getHttpHeaders())
        .then(authUser => authUser)
        .catch(error => {
            return error
        });
/************ methods : http response handler *******************/

/** http : Survey Question banks : http response handler **/
function* handleHttpSaveSurveyQuestionBank({payload}) {
    try {
        let result = yield call( payload.id && payload.id !== "" && payload.id !== -1  ? httpUpdateQuestionBank : httpSaveSurveyQuestionBank,payload);

        if (result.message) {
            yield put(saveSurveyQuestionBankError(result.response));
        } else {
            yield put(saveSurveyQuestionBankSuccess(result));
        }
    } catch (error) {
        yield put(saveSurveyQuestionBankError(error));
    }
}

/** http : Survey Question banks list : http response handler **/
function* handleHttpGetSurveyQuestionBankListResponse({payload}) {
    try {
        let result = yield call(httpGetSurveyQuestionBankList,payload);
        
        if (result.message) {
            yield put(saveSurveyQuestionBankError(result.response));
        } else {
            if (payload) {
                yield put(getSurveyQuestionBankActiveSuccess(result));
            } else {
                yield put(getSurveyQuestionBankSuccess(result));
            }
        }
    } catch (error) {
        yield put(saveSurveyQuestionBankError(error));
    }
}

/** http : Question bank file upload : http response handler **/
function* handleHttpUploadQuestionBankFile({payload}) {
    try {

        let result = yield call(httpUploadQestionBankFile,payload);

        if (result.message) {
            yield put(uploadQuestionBankFileSaveError(result.response));
        } else {
            yield put(uploadQuestionBankFileSaveSuccess(result));
        }
    } catch (error) {
        yield put(uploadQuestionBankFileSaveError(error));
    }
}

/** http : Survey Question bank status update : http response handler **/
function* handleHttpUpdateSurveyQuestionBankStatusResponse({payload}) {
    try {
        let result = yield call(httpSurveyQuestionBankUpdateStatus, payload);

        if (result.message) {
            yield put(saveSurveyQuestionBankError(result.response));
        } else {
            yield put(surveyQuestionBankUpdateStatusSuccess(result));
        }
    } catch (error) {
        yield put(saveSurveyQuestionBankError(error));
    }
}


/************ methods : export access *******************/

/** Survey question bank : save **/
export function* saveSurveyQuestionBank() {
    yield takeEvery(SURVEY_QUESTION_BANK_NEW_EDIT_SAVE, handleHttpSaveSurveyQuestionBank);
}

/** Survey question banks : get all records **/
export function* getSurveyQuestionBankList() {
    yield takeEvery(SURVEY_QUESTION_BANK_GET_ALL_RECORDS, handleHttpGetSurveyQuestionBankListResponse);
}

/** Survey question bank : update status **/
export function* updateSurveyQuestionBankStatus() {
    yield takeEvery(SURVEY_QUESTION_BANK_UPDATE_STATUS, handleHttpUpdateSurveyQuestionBankStatusResponse);
}

/** To upload qestion bank file to server **/
export function* uploadQuestionBankFile() {
    yield takeEvery(SURVEY_QUESTION_BANK_UPLOAD, handleHttpUploadQuestionBankFile);
}

export default function* rootSaga() {
    yield all([
        fork(saveSurveyQuestionBank),
        fork(getSurveyQuestionBankList),
        fork(updateSurveyQuestionBankStatus),
        fork(uploadQuestionBankFile)
    ]);
}


