import {
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA,
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_ERROR,
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_NONE,
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_SUCCESS,
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA,
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_ERROR,
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_NONE,
    E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_SUCCESS,
    E_LEARNING_REPORTS_RESET_FILTERS,
    E_LEARNING_REPORTS_SET_FILTER_GROUP_BY,
    E_LEARNING_REPORTS_SET_FILTER_METRIC,
    E_LEARNING_REPORTS_SET_FILTER_LESSON,
    E_LEARNING_REPORTS_SET_FILTER_VIEW,
    E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS,
    E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR,
    E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE,
    E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS,
    E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR,
    E_LEARNING_REPORTS_SUMMARY_TABLE_SET_LESSON_FILTER,
    E_LEARNING_REPORTS_SUMMARY_TABLE_RESET_LESSON_FILTER

} from "../../../constants/actions/reports/elearning/actionsELearningReports";
import {
    E_LEARNING_REPORTS_DOWNLOAD,
    CONFIG_E_LEARNING_REPORTS,
    CONNECT_REPORTS_DOWNLOAD,
    REPORTS_GROUP_BY_FILTERS,
    UUID_STATUS, HTTP_UUID_STATUS,
    MOODLE_DEFAULT_LANGUAGES,
    DEFAULT_LANGUAGE_CODE
} from "../../../constants/config";
import {HTTP_REQUEST_STATUS} from "../../../constants/constants";
import IntlMessages from "../../../util/IntlMessages";
import React from "react";
import AppUtils from "../../../util/util";
import {AUTH_UPDATE_MENU_CONFIGURATION} from "../../../constants/ActionTypes";
import {REPORT_FILTERS_UPDATE_REFRESH_COUNTER} from "../../../constants/actions/reports/actionsReportFilters";

let groupByFilters = {
    "FILTER_VALUE_OVERALL": "overall",
    "FILTER_VALUE_GENDER": "gender",
    "FILTER_VALUE_AGE_GROUP": "age_group",
    "FILTER_VALUE_TENURE_GROUP": "tenure_group",
    "FILTER_VALUE_SMART_PHONE": "smart_phone",
    "FILTER_VALUE_COUNTRY": "country",
    "FILTER_VALUE_COMPANY": "company"
};

let commonFilters = [
    {
        key: 'filter_view',
        type: "outline_dropdown",
        localisation: "",
        helperTextId: "DIC_REPORTS_E_LEARNING_SUMMARY_TABLE_FILTER_HELPER_TEXT_VIEW",
        style: {},
        disabled: true
    },
    {
        key: 'filter_group_by',
        type: "outline_dropdown",
        localisation: "DIC_REPORTS_E_LEARNING_SUMMARY_TABLE_FILTER_TITLE_GROUP_BY",
        helperTextId: "DIC_REPORTS_E_LEARNING_SUMMARY_TABLE_FILTER_HELPER_TEXT_GROUP_BY",
        style: {}
    },
    {
        key: 'filter_metric',
        type: "outline_dropdown",
        localisation: "DIC_REPORTS_E_LEARNING_SUMMARY_TABLE_FILTER_TITLE_METRIC",
        helperTextId: "DIC_REPORTS_E_LEARNING_SUMMARY_TABLE_FILTER_HELPER_TEXT_METRIC",
        style: {}
    },
    {
        key: 'filter_lesson',
        type: "multiselect_react_dropdown",
        localisation: "DIC_REPORTS_FILTERS_E_LEARNING_LESSONS",
        helperTextId: "",
        style: {}
    }
];

let filterValuesView = [
    {
        key: "lesson",
        value: 'lesson',
        headerValue: 'lesson',
        label: "DIC_REPORTS_E_LEARNING_SUMMARY_TABLE_FILTER_VIEW_LESSON"
    }
];

let filterValuesGroupByCompany = [
    {
        key: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_OVERALL,
        value: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_OVERALL,
        headerValue: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_OVERALL,
        label: "DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_OVERALL"
    },
    {
        key: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_GENDER,
        value: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_GENDER,
        headerValue: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_GENDER,
        label: "DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_GENDER"
    },
    {
        key: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_AGE_GROUP,
        value: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_AGE_GROUP,
        headerValue: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_AGE_GROUP,
        label: "DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_AGE_GROUP"
    },
    {
        key: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_TENURE_GROUP,
        value: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_TENURE_GROUP,
        headerValue: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_TENURE_GROUP,
        label: "DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_TENURE_GROUP"
    },
    {
        key: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_SMART_PHONE,
        value: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_SMART_PHONE,
        headerValue: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_SMART_PHONE,
        label: "DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_SMART_PHONE"
    },
    {
        key: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_JOB_ROLE,
        value: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_JOB_ROLE,
        headerValue: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_JOB_ROLE,
        label: "DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_JOB_ROLE"
    }
];

let filterValuesGroupByCompanyOnly = [
    {
        key: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_DEPARTMENT,
        value: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_DEPARTMENT,
        headerValue: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_DEPARTMENT,
        label: "DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_DEPARTMENT"
    }
];

let filterValuesGroupByBrand = [
    {
        key: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_COUNTRY,
        value: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_COUNTRY,
        headerValue: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_COUNTRY,
        label: "DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_COUNTRY"
    },
    {
        key: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_COMPANY,
        value: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_COMPANY,
        headerValue: REPORTS_GROUP_BY_FILTERS.FILTER_VALUE_COMPANY,
        label: "DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_COMPANY"
    }
];

let eLearningDownloadFilters = [
    {
        id : "E_LEARNING_REPORTS_RAW_DATA",
        label: "DIC_ELEARNING_REPORTS_XLS_DOWNLOAD",
        value: E_LEARNING_REPORTS_DOWNLOAD.DOWNLOAD_RAW_DATA,
        icon: "chartIcon",
        isConfig : true
    },
];

let filterValuesMetric = [
    {
        key: "passed",
        value: 'passed',
        headerValue: 'passed',
        label: "DIC_REPORTS_E_LEARNING_SUMMARY_TABLE_FILTER_METRIC_PASSED"
    },
    {
        key: "enrolled",
        value: 'enrolled',
        headerValue: 'enrolled',
        label: "DIC_REPORTS_E_LEARNING_SUMMARY_TABLE_FILTER_METRIC_ENROLLED"
    },
    {
        key: "avg_score",
        value: 'avg_score',
        headerValue: 'avg_score',
        label: "DIC_REPORTS_E_LEARNING_SUMMARY_TABLE_FILTER_METRIC_AVG_SCORE"
    },
    {
        key: "completed",
        value: 'completed',
        headerValue: 'completed',
        label: "DIC_REPORTS_E_LEARNING_SUMMARY_TABLE_FILTER_METRIC_COMPLETED"
    }
];

const filterValuesMetricInfos = {
    "passed": "DIC_REPORTS_E_LEARNING_SUMMARY_TABLE_FILTER_METRIC_PASSED_INFO",
    "enrolled": "DIC_REPORTS_E_LEARNING_SUMMARY_TABLE_FILTER_METRIC_ENROLLED_INFO",
    "avg_score": "DIC_REPORTS_E_LEARNING_SUMMARY_TABLE_FILTER_METRIC_AVG_SCORE_INFO",
    "completed": "DIC_REPORTS_E_LEARNING_SUMMARY_TABLE_FILTER_METRIC_COMPLETED_INFO"
};

const filterDropMenuItems = [
    {
        label: "DIC_REPORTS_CONNECT_SUMMARY_TABLE_XLS_DOWNLOAD",
        value: CONNECT_REPORTS_DOWNLOAD.DOWNLOAD_SUMMARY_TABLE_DATA,
        icon: "tableIcon"
    }
];


const initialData = {
    summaryTableFilters: commonFilters,
    filterValuesView: filterValuesView,
    filterValuesGroupByCompany: [...filterValuesGroupByCompany, ...filterValuesGroupByCompanyOnly],
    filterValuesGroupByBrand: [...filterValuesGroupByCompany, ...filterValuesGroupByBrand],
    filterValuesMetric: filterValuesMetric,
    selectedView: filterValuesView[0]['value'],
    selectedGroupBy: filterValuesGroupByCompany[0]['value'],
    selectedMetric: filterValuesMetric[0]['value'],
    selectedLessons: -1,
    metricInfoLabel: filterValuesMetricInfos[filterValuesMetric[0]['key']],
    filterDropMenuItems: filterDropMenuItems,
    summaryTableFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    summaryTableUUIDFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    summaryTableUUID: "",
    summaryTableUUIDCounter: 0,
    summaryTableData: [],
    headerData: [],
    loader: false,
    eLearningDownloadFilters: [],
    alertMessage: '',
    eLearningRawDataResponseUUID: "",
    eLearningRawDataResponseUUIDCounter: 0,
    eLearningRawDataDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    eLearningRawDataDownloadUUIDStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    eLearningRawDataDownloadUUID: "",
    eLearningRawDataDownloadUUIDCounter: 0,
    summaryTableLessonFilters : {}
}

const ReducerELearningReports = (state = initialData, action) => {
    
    switch (action.type) {

        case REPORT_FILTERS_UPDATE_REFRESH_COUNTER:
        case E_LEARNING_REPORTS_SUMMARY_TABLE_RESET_LESSON_FILTER:{

            return {
                ...state,
                summaryTableLessonFilters: {}
            }
        }


        case E_LEARNING_REPORTS_SUMMARY_TABLE_SET_LESSON_FILTER:{
            let summaryTableLessonFilters = state.summaryTableLessonFilters;

            let tempItems = action.payload;

            for( let languageCode in summaryTableLessonFilters ){
                let lessons = summaryTableLessonFilters[languageCode];

                for ( let i = 0 ; i < lessons.length; i++ ){
                    for ( let j = 0 ; j < tempItems.length; j++ ){
                        if ( tempItems[j].id === lessons[i].id ){
                            lessons[i].selected = tempItems[j].selected;
                            break;
                        }
                    }
                }
            }

            return {
                ...state,
                summaryTableLessonFilters: summaryTableLessonFilters
            }
        }

        case AUTH_UPDATE_MENU_CONFIGURATION:{
            return {
                ...state,
                eLearningDownloadFilters : updateELearningReportsDownloadFilters(eLearningDownloadFilters)
            }
        }

        /** to set state, on api call for raw data UUID success**/
        case E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS:
        case E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS: {

            let eLearningRawDataDownloadUUID = state.eLearningRawDataDownloadUUID;
            let eLearningRawDataDownloadUUIDCounter = state.eLearningRawDataDownloadUUIDCounter;
            let loader = state.loader;
            let eLearningRawDataDownloadUUIDStatus = state.eLearningRawDataDownloadUUIDStatus;

            if (action.payload && action.payload.message) {

                let message = action.payload.message;

                switch (message['status']) {
                    case UUID_STATUS.SUCCESS: {
                        
                        let eLearningRawDataResponseDownloadLink = AppUtils.redirectDownloadProdToReports(AppUtils.getAppBaseUrl() + action.payload.message.data.data);
                        AppUtils.downloadFile(eLearningRawDataResponseDownloadLink);
                        eLearningRawDataDownloadUUID = "";
                        loader = false;
                        eLearningRawDataDownloadUUIDStatus = HTTP_REQUEST_STATUS.NOT_STARTED;
                        break;
                    }
                    case UUID_STATUS.IN_PROGRESS: {
                        eLearningRawDataDownloadUUID = action.payload.message.uuid;
                        eLearningRawDataDownloadUUIDCounter += 1;
                        loader = true;
                        break;
                    }
                    case UUID_STATUS.NOT_FOUND: {
                        eLearningRawDataDownloadUUID = "";
                        eLearningRawDataDownloadUUIDStatus = HTTP_REQUEST_STATUS.NOT_STARTED;
                        loader = false;
                        break;
                    }
                }

            }

            return {
                ...state,
                eLearningRawDataDownloadUUIDStatus: eLearningRawDataDownloadUUIDStatus,
                eLearningRawDataDownloadUUID: eLearningRawDataDownloadUUID,
                eLearningRawDataDownloadUUIDCounter: eLearningRawDataDownloadUUIDCounter,
                loader: loader
            };
        }

        /** to set state, raw data UUID none**/
        case E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE: {
            return {
                ...state,
                eLearningRawDataDownloadUUIDStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                eLearningRawDataDownloadUUID: "",
                eLearningRawDataDownloadUUIDCounter: 0
            };
        }

        /** to set state, on api call for raw data UUID failure**/
        case E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR:
        case E_LEARNING_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR: {
            return {
                ...state,
                eLearningRawDataDownloadUUIDStatus: HTTP_REQUEST_STATUS.FAIL,
                eLearningRawDataDownloadUUID: "",
                eLearningRawDataDownloadUUIDCounter: 0,
                alertMessage: action.payload
            };
        }

        case E_LEARNING_REPORTS_SET_FILTER_VIEW: {
            return {
                ...state,
                selectedView: action.payload
            }
        }

        case E_LEARNING_REPORTS_SET_FILTER_GROUP_BY: {
            return {
                ...state,
                selectedGroupBy: action.payload
            }
        }

        case E_LEARNING_REPORTS_SET_FILTER_METRIC: {
            return {
                ...state,
                selectedMetric: action.payload,
                metricInfoLabel: filterValuesMetricInfos[action.payload]
            }
        }

        case E_LEARNING_REPORTS_SET_FILTER_LESSON: {
            return {
                ...state,
                selectedLessons: action.payload,
            }
        }

        case E_LEARNING_REPORTS_RESET_FILTERS: {
            return {
                ...state,
                selectedView: filterValuesView[0]['value'],
                selectedGroupBy: filterValuesGroupByCompany[0]['value'],
                selectedMetric: filterValuesMetric[0]['value'],
                metricInfoLabel: filterValuesMetricInfos[filterValuesMetric[0]['key']],
                selectedLessons: -1
            }
        }

        case E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA: {

            return {
                ...state,
                summaryTableFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                summaryTableUUID: "",
                summaryTableUUIDCounter: 0,
                summaryTableData: [],
                headerData: [],
                loader: true
            }
        }

        case E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_SUCCESS: {

            let summaryTableUUID = "";
            let summaryTableData = [];
            let headerData = [];
            let loader = state.loader;
            let summaryTableLessonFilters = state.summaryTableLessonFilters;

            let summaryTableUUIDCounter = state.summaryTableUUIDCounter;
            let summaryTableFetchStatus = state.summaryTableFetchStatus;

            if (action.payload && action.payload.data) {
                let data = action.payload.data;

                if (data["status"] === UUID_STATUS["SUCCESS"]) {
                    summaryTableUUID = "";

                    summaryTableData = parseELearningSummaryTableData(data.data["summary_table"], state.selectedMetric, false);
                    summaryTableLessonFilters = updateSelectedLessons(summaryTableData['lessonData'], summaryTableLessonFilters) ;

                    loader = false;
                    summaryTableUUIDCounter = 0;
                    summaryTableFetchStatus = HTTP_REQUEST_STATUS.NOT_STARTED;

                } else if (data["status"] === UUID_STATUS["IN_PROGRESS"]) {
                    summaryTableUUID = data.uuid;
                    summaryTableUUIDCounter = 1;

                } else if (data["status"] === UUID_STATUS["NOT_FOUND"]) {
                    summaryTableUUID = "";
                    summaryTableUUIDCounter = "";
                    summaryTableFetchStatus = HTTP_REQUEST_STATUS.NOT_STARTED;
                }
            }

            return {
                ...state,
                summaryTableFetchStatus: summaryTableFetchStatus,
                summaryTableUUID: summaryTableUUID,
                summaryTableData: summaryTableData,
                headerData: headerData,
                summaryTableUUIDCounter: summaryTableUUIDCounter,
                loader: loader,
                summaryTableLessonFilters: summaryTableLessonFilters
            }
        }

        case E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_ERROR: {

            return {
                ...state,
                summaryTableFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                summaryTableUUID: "",
                summaryTableData: [],
                headerData: [],
                summaryTableUUIDCounter: 0,
                loader: false
            }
        }

        case E_LEARNING_REPORTS_GET_SUMMARY_TABLE_DATA_NONE: {

            return {
                ...state,
                summaryTableFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                summaryTableUUID: "",
                summaryTableData: [],
                headerData: [],
                summaryTableUUIDCounter: 0,
                loader: false
            }
        }

        case E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA: {

            return {
                ...state,
                summaryTableUUIDFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_SUCCESS: {


            let summaryTableUUID = state.summaryTableUUID;
            let summaryTableData = [];
            let headerData = [];
            let summaryTableUUIDCounter = state.summaryTableUUIDCounter;
            let loader = state.loader;
            let summaryTableFetchStatus = state.summaryTableFetchStatus;
            let summaryTableLessonFilters = state.summaryTableLessonFilters;

            if (action.payload && action.payload.data) {
                let data = action.payload.data;

                if (data["status"] === UUID_STATUS["SUCCESS"]) {

                    summaryTableData = parseELearningSummaryTableData(data.data["summary_table"], state.selectedMetric, false);
                    summaryTableLessonFilters = updateSelectedLessons(summaryTableData['lessonData'], summaryTableLessonFilters) ;

                    summaryTableUUID = "";
                    summaryTableUUIDCounter = 0;
                    loader = false;
                    summaryTableFetchStatus = HTTP_REQUEST_STATUS.NOT_STARTED;

                } else if (data["status"] === HTTP_UUID_STATUS["PARTIAL"]) {
                    summaryTableUUIDCounter += 1;

                    summaryTableData = parseELearningSummaryTableData(data.data["summary_table"], state.selectedMetric, true);
                    summaryTableLessonFilters = updateSelectedLessons(summaryTableData['lessonData'], summaryTableLessonFilters) ;


                } else if (data["status"] === HTTP_UUID_STATUS["IN_PROGRESS"]) {
                    summaryTableUUIDCounter += 1;

                } else if (data["status"] === HTTP_UUID_STATUS["NOT_FOUND"]) {
                    summaryTableUUID = "";
                    summaryTableUUIDCounter = "";
                    summaryTableFetchStatus = HTTP_REQUEST_STATUS.NOT_STARTED;
                }
            }

            return {
                ...state,
                summaryTableUUIDFetchStatus: HTTP_REQUEST_STATUS.SUCCESS,
                summaryTableUUID: summaryTableUUID,
                summaryTableUUIDCounter: summaryTableUUIDCounter,
                summaryTableData: summaryTableData,
                headerData: headerData,
                loader: loader,
                summaryTableFetchStatus: summaryTableFetchStatus,
                summaryTableLessonFilters: summaryTableLessonFilters
            }
        }

        case E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_ERROR: {

            return {
                ...state,
                summaryTableUUIDFetchStatus: HTTP_REQUEST_STATUS.FAIL,
                summaryTableUUID: "",
                summaryTableUUIDCounter: 0,
                loader: false,
                summaryTableFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case E_LEARNING_REPORTS_GET_SUMMARY_TABLE_UUID_DATA_NONE: {

            return {
                ...state,
                summaryTableUUIDFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                summaryTableUUID: "",
                summaryTableUUIDCounter: 0,
                loader: false,
                summaryTableFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }


        default:
            return {
                ...state
            }
    }
}

function updateSelectedLessons(lessonFilters, summaryTableLessonFilters) {
    let result = lessonFilters;
    try {

        if (typeof summaryTableLessonFilters["default"] === "undefined") {

            for (let languageKey in lessonFilters) {
                let currentItems = lessonFilters[languageKey];

                for (let i = 0; i < currentItems.length; i++) {
                    currentItems[i]["selected"] = true;
                }
            }

        } else {
            result = summaryTableLessonFilters;
        }

    } catch (ex) {
        console.error("updateSelectedLessons : error : ", ex);
    }

    return result;
}

function parseAllLessonTitles(data){

    let lessonData = {};

    try{
        let lessons = [];
        let scormData = [];

        for (let key in data) {
            lessons = data[key]["all_lessons"];
            scormData = data[key]["all_scorm"];
            break;
        }

        let lessonTitles = parseLessonTitles(lessons);
        let languageResult = lessonTitles['languages'];
        let lessonResult = lessonTitles['lessons'];

        for (let i = 0 ; i < languageResult.length; i++ ) {

            let languageCode = languageResult[i];

            for (let lessonId in lessonResult) {

                let currentLesson = lessonResult[lessonId];
                let lessonTitle = typeof currentLesson['titles'][languageCode] != "undefined" ? currentLesson['titles'][languageCode] : currentLesson['titles']['default'];

                if (typeof lessonData[languageCode] == "undefined") {
                    lessonData[languageCode] = [];
                }

                lessonData[languageCode].push({
                    id: currentLesson["id"],
                    label: lessonTitle,
                    selected: false,
                    isScorm : false
                });
            }

        }


        let scormTitles = parseLessonTitles(scormData);
        let scormResult = scormTitles['lessons'];

        for (let i = 0 ; i < languageResult.length; i++ ) {

            let languageCode = languageResult[i];

            for (let scormId in scormResult) {

                let currentLesson = scormResult[scormId];
                let lessonTitle = typeof currentLesson['titles'][languageCode] != "undefined" ? currentLesson['titles'][languageCode] : currentLesson['titles']['default'];

                if (typeof lessonData[languageCode] == "undefined") {
                    lessonData[languageCode] = [];
                }

                lessonData[languageCode].push({
                    id: currentLesson["id"],
                    label: lessonTitle,
                    selected: false,
                    isScorm : true
                });
            }
        }

    }catch ( ex ) {
        console.error("parseAllLessonTitles : error : ", ex );
    }

    return lessonData;

}

function parseELearningSummaryTableData(data, selectedMetric, isPartial) {

    let result = {};

    try {

        let headerData = [];
        let contentValue = {};
        let contentPercentage = {};
        let participants = {};
        let avgScores = {};
        let avgScoresPercentage = {};
        let lessons = null;
        let colorCode = "";
        let isStickersEnabled = selectedMetric === "passed";
        let percentageLabel = selectedMetric !== "avg_score" ? " %" : "";
        let workerContactsLabel = "";
        let avgScoreLabel = "";
        let lessonData = parseAllLessonTitles(data);

        for (let key in data) {
            headerData.push(key);

            if (workerContactsLabel === "" && typeof data[key]["participants_label"] != "undefined") {
                workerContactsLabel = data[key]["participants_label"];
            }

            if (avgScoreLabel === "" && typeof data[key]["avg_label"] != "undefined") {
                avgScoreLabel = data[key]["avg_label"];
            }

            if (typeof data[key]["participants"] != "undefined") {
                participants[key] = <span className="eLearningValues" style={{
                    color: CONFIG_E_LEARNING_REPORTS["TABLE_HEADER_COLORS"]["ROW_2"]["COLOR"]
                }}>{ AppUtils.numberWithCommas(data[key]["participants"])}</span>;
            }

            if (typeof data[key]["avg"] != "undefined") {
                if (data[key]["avg"].toString().indexOf("DIC_") != -1) {
                    avgScores[key] = <span className="eLearningValues" style={{
                        color: CONFIG_E_LEARNING_REPORTS["TABLE_HEADER_COLORS"]["ROW_3"]["COLOR"]
                    }}><IntlMessages id={data[key]["avg"]}/>  </span>;

                } else {
                    avgScores[key] = <span className="eLearningValues" style={{
                        color: CONFIG_E_LEARNING_REPORTS["TABLE_HEADER_COLORS"]["ROW_3"]["COLOR"]
                    }}>{ data[key]["avg"] != "DIC_NA" ? AppUtils.numberWithCommas(data[key]["avg"]) : data[key]["avg"] }</span>;
                }
            }

            if (typeof data[key]["avg_percentage"] != "undefined") {

                if (data[key]["avg_percentage"].toString().indexOf("DIC_") != -1) {
                    avgScoresPercentage[key] = <span className="eLearningValues" style={{
                        color: CONFIG_E_LEARNING_REPORTS["TABLE_HEADER_COLORS"]["ROW_3"]["COLOR"]
                    }}><IntlMessages id={data[key]["avg_percentage"]}/></span>;

                } else {
                    
                    if (selectedMetric === "avg_score" && key === "DIC_REPORTS_FILTER_OVERALL") {
                        data[key]["avg"] = data[key]["avg_percentage"];
                    }

                    let sticker = "";
                    let avgPercentage = data[key]["avg_percentage"];

                    if (isStickersEnabled) {
                        if (avgPercentage >= CONFIG_E_LEARNING_REPORTS["STICKER_CONF"]["BRONZE"] && avgPercentage < CONFIG_E_LEARNING_REPORTS["STICKER_CONF"]["SILVER"]) {
                            sticker = AppUtils.getBronzeSticker();
                        } else if (avgPercentage >= CONFIG_E_LEARNING_REPORTS["STICKER_CONF"]["SILVER"] && avgPercentage < CONFIG_E_LEARNING_REPORTS["STICKER_CONF"]["GOLD"]) {
                            sticker = AppUtils.getSilverSticker();
                        } else if (avgPercentage >= CONFIG_E_LEARNING_REPORTS["STICKER_CONF"]["GOLD"]) {
                            sticker = AppUtils.getGoldSticker();
                        }
                    }

                    if (sticker !== "") {
                        avgPercentage = <span> {avgPercentage + percentageLabel}{sticker} </span>
                    } else {
                        avgPercentage += percentageLabel;
                    }


                    avgScoresPercentage[key] = <span className="eLearningValues" style={{
                        color: CONFIG_E_LEARNING_REPORTS["TABLE_HEADER_COLORS"]["ROW_3"]["COLOR"]
                    }}>{avgPercentage}</span>;
                }
            }

            if (lessons === null) {
                lessons = data[key]["data"];
            }

        }

        if (workerContactsLabel === "") {
            workerContactsLabel = "DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_HEADER_WORKER_CONTACTS";
        }

        participants["title"] = <span className="eLearningValues" style={{
            color: CONFIG_E_LEARNING_REPORTS["TABLE_HEADER_COLORS"]["ROW_2"]["COLOR"]
        }}><IntlMessages id={workerContactsLabel}/></span>;

        participants['backgroundColor'] = CONFIG_E_LEARNING_REPORTS["TABLE_HEADER_COLORS"]["ROW_2"]["BACKGROUND"];

        avgScores["title"] = <span className="eLearningValues" style={{
            color: CONFIG_E_LEARNING_REPORTS["TABLE_HEADER_COLORS"]["ROW_3"]["COLOR"]
        }}><IntlMessages id={avgScoreLabel}/></span>;

        avgScoresPercentage["title"] = <span className="eLearningValues" style={{
            color: CONFIG_E_LEARNING_REPORTS["TABLE_HEADER_COLORS"]["ROW_3"]["COLOR"]
        }}><IntlMessages id={avgScoreLabel}/></span>;

        avgScores['backgroundColor'] = CONFIG_E_LEARNING_REPORTS["TABLE_HEADER_COLORS"]["ROW_3"]["BACKGROUND"];
        avgScoresPercentage['backgroundColor'] = CONFIG_E_LEARNING_REPORTS["TABLE_HEADER_COLORS"]["ROW_3"]["BACKGROUND"];

        let currentLesson;

        let lessonTitles = parseLessonTitles(lessons);
        let languageResult = lessonTitles['languages'];
        let lessonResult = lessonTitles['lessons'];

        for (let i = 0 ; i < languageResult.length; i++ ){

            let languageCode = languageResult[i];

            contentValue[languageCode] = [];
            contentValue[languageCode].push(participants);
            contentValue[languageCode].push(avgScores);

            contentPercentage[languageCode] = [];
            contentPercentage[languageCode].push(participants);
            contentPercentage[languageCode].push(avgScoresPercentage);


            for (let lessonId in lessonResult) {

                currentLesson = lessonResult[lessonId];
                let lessonTitle = typeof currentLesson['titles'][languageCode] != "undefined" ?  currentLesson['titles'][languageCode] : currentLesson['titles']['default'];

                let tempData = {
                    id: lessonId,
                    title: lessonTitle,
                    lessonId : currentLesson["id"]
                };

                let tempDataPercentage = {
                    id: lessonId,
                    title: lessonTitle,
                    lessonId : currentLesson["id"]
                };

                if ( typeof lessonData[languageCode] != "undefined" ) {

                    for( let j = 0 ; j < lessonData[languageCode].length; j++ ){

                        if ( lessonData[languageCode][j]["id"] == currentLesson["id"] ) {
                            lessonData[languageCode][j]["selected"] = true;
                            break;
                        }
                    }
                }

                for (let key in data) {

                    if ( typeof data[key]["data"] != "undefined" && typeof data[key]["data"][lessonId] != "undefined") {

                        let value = data[key]["data"][lessonId]["value"];
                        let percentage = data[key]["data"][lessonId]["percentage"];
                        colorCode = "";

                        if (value.toString().indexOf("DIC_") !== -1) {
                            if ( isPartial ) {
                                value = <span className="eLearningValues" style={{color: CONFIG_E_LEARNING_REPORTS["NA_COLOR"]}}>{""}</span>;
                            } else {
                                value = <span className="eLearningValues" style={{color: CONFIG_E_LEARNING_REPORTS["NA_COLOR"]}}><IntlMessages id="DIC_NA"/></span>;
                            }

                        } else {

                            colorCode = CONFIG_E_LEARNING_REPORTS["REPORT_PERCENTAGE_COLORS"]["<10"]

                            if (value >= 10 && value < 50) {
                                colorCode = CONFIG_E_LEARNING_REPORTS["REPORT_PERCENTAGE_COLORS"]["10_50"];
                            } else if (value >= 50) {
                                colorCode = CONFIG_E_LEARNING_REPORTS["REPORT_PERCENTAGE_COLORS"][">50"];
                            }
                            value = AppUtils.numberWithCommas(value);
                        }

                        tempData[key] = <span className="eLearningValues" style={{color: colorCode}}> {value} </span>;

                        if (percentage.toString().indexOf("DIC_") !== -1) {
                            if ( isPartial ) {
                                value = <span className="eLearningValues" style={{color: CONFIG_E_LEARNING_REPORTS["NA_COLOR"]}}>{""}</span>;
                            } else {
                                value = <span className="eLearningValues" style={{color: CONFIG_E_LEARNING_REPORTS["NA_COLOR"]}}><IntlMessages id="DIC_NA"/></span>;
                            }
                        } else {

                            colorCode = CONFIG_E_LEARNING_REPORTS["REPORT_PERCENTAGE_COLORS"]["<10"]

                            if (percentage >= 10 && percentage < 50) {
                                colorCode = CONFIG_E_LEARNING_REPORTS["REPORT_PERCENTAGE_COLORS"]["10_50"];
                            } else if (percentage >= 50) {
                                colorCode = CONFIG_E_LEARNING_REPORTS["REPORT_PERCENTAGE_COLORS"][">50"];
                            }

                            let sticker = "";

                            if (isStickersEnabled) {
                                if (percentage >= CONFIG_E_LEARNING_REPORTS["STICKER_CONF"]["BRONZE"] && percentage < CONFIG_E_LEARNING_REPORTS["STICKER_CONF"]["SILVER"]) {
                                    sticker = AppUtils.getBronzeSticker();
                                } else if (percentage >= CONFIG_E_LEARNING_REPORTS["STICKER_CONF"]["SILVER"] && percentage < CONFIG_E_LEARNING_REPORTS["STICKER_CONF"]["GOLD"]) {
                                    sticker = AppUtils.getSilverSticker();
                                } else if (percentage >= CONFIG_E_LEARNING_REPORTS["STICKER_CONF"]["GOLD"]) {
                                    sticker = AppUtils.getGoldSticker();
                                }
                            }

                            if (sticker !== "") {
                                percentage = <span> {percentage + percentageLabel}{sticker} </span>
                            } else {
                                percentage += percentageLabel;
                            }

                        }
                        
                        let percentageValue = percentage;
    
                        if (percentage.toString().indexOf("DIC_") !== -1) {
                            if ( isPartial ) {
                                percentageValue = <span className="eLearningValues" style={{color: CONFIG_E_LEARNING_REPORTS["NA_COLOR"]}}>{""}</span>;
                            } else {
                                percentageValue = <span className="eLearningValues" style={{color: CONFIG_E_LEARNING_REPORTS["NA_COLOR"]}}><IntlMessages id="DIC_NA"/></span>;
                            }
                        }
                        
                        tempDataPercentage[key] = <span className="eLearningValues" style={{color: colorCode}}> {percentageValue} </span>;
                    }
                }

                contentValue[languageCode].push(tempData);
                contentPercentage[languageCode].push(tempDataPercentage);
            }

        }


        result['headerData'] = headerData;
        result['dataValue'] = contentValue;
        result['dataPercentage'] = contentPercentage;
        result['lessonData'] = lessonData;
    } catch (ex) {
        console.error("parseELearningSummaryTableData : error : ", ex);
    }

    return result;
}

function parseLessonTitle(title) {
    let result = {
        'languages': [],
        'titles': {}
    };

    try {

        let titleArray = title.split('{mlang}');
        let isDefaultLanguage = false;

        for (let i = 0; i < titleArray.length; i++) {
            let tempArray = titleArray[i].split('}');
            
            

            if (tempArray.length > 1) {
                let currentLanguage = tempArray[0].replace('{mlang', '').replace(/\s/g, '');
                
                if ( !isDefaultLanguage && MOODLE_DEFAULT_LANGUAGES.includes(currentLanguage)){
                    currentLanguage = DEFAULT_LANGUAGE_CODE;
                    isDefaultLanguage = true;
                }
                
                result['languages'].push(currentLanguage);
                result['titles'][currentLanguage] = tempArray[1];
            }
        }

        if (result['languages'].length > 0) {
            let defaultLanguage = result['languages'][0];
            result['titles']['default'] = result['titles'][defaultLanguage];
        }

    } catch (ex) {
        console.error("parseLessonTitle : error : ", ex);
    }

    return result
}

function parseLessonTitles(lessons) {
    let result = {};

    try {

        let totalLanguages = [];

        for (let i = 0; i < lessons.length; i++) {

            let currentTitle = lessons[i]['name'];
            lessons[i]['languages'] = [];
            lessons[i]['titles'] = {};

            if (currentTitle.indexOf('{mlang') !== -1) {
                let lessonResult = parseLessonTitle(currentTitle);
                totalLanguages = totalLanguages.concat(lessonResult['languages'])
                lessons[i]['languages'] = lessonResult['languages'];
                lessons[i]['titles'] = lessonResult['titles'];
            } else {
                lessons[i]['titles']['default'] = currentTitle;
            }
        }

        totalLanguages.push("default");
        result['languages'] = [...new Set(totalLanguages)];
        result['lessons'] = lessons;
        
    } catch (ex) {
        console.error("parseLessonTitles : error : ", ex);
    }

    return result;
}

function updateELearningReportsDownloadFilters(data) {
    let result = [];
    try {
        let isValid;
        let moduleKey;

        for( let i = 0; i < data.length; i++ ) {

            isValid = true;

            if ( data[i]["isConfig"] ) {
                moduleKey = AppUtils.getModuleKeyBasedOnKey(data[i]["id"]);

                if ( moduleKey !== ""){
                    isValid = AppUtils.isModuleHasAccess(moduleKey);
                }
            }

            if ( isValid ) {
                result.push(data[i]);
            }
        }

    } catch (ex) {
        console.error("updateELearningReportsDownloadFilters : error : ", ex);
    }
    return result;
}

export default ReducerELearningReports;

