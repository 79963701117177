import React from "react";
import {HTTP_REQUEST_STATUS} from "../../../constants/constants";
import IntlMessages from "../../../util/IntlMessages";
import AppUtils from "../../../util/util"

import {
    SURVEY_TEMPLATE_GET_QUICK_INSIGHTS,
    SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_ERROR,
    SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_IN_PROGRESS,
    SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_NONE,
    SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_SUCCESS,

    SURVEY_TEMPLATE_GET_QUESTION_SUMMARY,
    SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_ERROR,
    SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_IN_PROGRESS,
    SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_NONE,
    SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_SUCCESS,
    SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_GRAPH_TYPE,

    SURVEY_TEMPLATE_RESPONSES_DOWNLOAD,
    SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_ERROR,
    SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_SUCCESS,
    SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_IN_PROGRESS,
    SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_NONE,
    SURVEY_TEMPLATE_UPDATE_SURVEY_DROPDOWN,
    SURVEY_TEMPLATE_UPDATE_CLIENT_DROPDOWN,

    SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_QUESTION_ORDER_FILTERS_CHANGE,
    SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_RESPONDER_ACCESS_FILTERS_CHANGE,
    SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_COMPANY_STATUS_CHANGE,
    SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_FILTERS_RESET,
    SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_SELECTED_COUNTRIES,
    SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_INVITED_MEDIA_CHANGE
} from "../../../constants/actions/survey/survey/actioTypesSurveyTemplate";

import {
    SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY,
    SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_SUCCESS,
    SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_ERROR,
    SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_NONE
} from "../../../constants/ActionTypes";

import {
    SURVEY_QUESTION_DROPDOWN_TYPES,
    SURVEY_QUESTION_TYPES_VALUES,
    SURVEY_QUICK_INSIGHTS_FILTERS,
    SURVEY_QUESTION_SUMMARY_DISPLAY_TYPE,
    COMPANY_STATUS_VALUE_MAP,
    SURVEY_TEMPLATE_QUICK_INSIGHTS_INVITED_MEDIA_VALUES
} from "../../../constants/config";
import StateManager from "react-select";
import UIUtils from "../../../util/ui_utils";

let quickInsightFilters = [
    /*{
        key: SURVEY_QUICK_INSIGHTS_FILTERS.GENDER,
        type: "dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_QUICK_INSIGHTS_FILTER_GENDER"
    },
    {
        key: SURVEY_QUICK_INSIGHTS_FILTERS.AGE_GROUP,
        type: "dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_QUICK_INSIGHTS_FILTER_AGE_GROUPS"
    },
    {
        key: SURVEY_QUICK_INSIGHTS_FILTERS.TENURE,
        type: "dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_QUICK_INSIGHTS_FILTER_TENURE"
    },
    {
        key: SURVEY_QUICK_INSIGHTS_FILTERS.CUSTOM_FIELD,
        type: "dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_QUICK_INSIGHTS_FILTER_CUSTOM_FIELD"
    },*/
    {
        key: SURVEY_QUICK_INSIGHTS_FILTERS.QUESTION_ORDER,
        type: "outline_dropdown",
        localisation: "DIC_SURVEY_REPORT_FILTER_QUESTION_ORDER_LABEL",
        style: {zIndex: 0}
    },
    {
        key: SURVEY_QUICK_INSIGHTS_FILTERS.RESPONDER_ACCESS,
        type: "outline_dropdown",
        localisation: "DIC_SURVEY_REPORT_FILTER_RESPONDER_ACCESS_LABEL",
        style: {zIndex: 0}
    },
    {
        key: SURVEY_QUICK_INSIGHTS_FILTERS.QUESTION_STATE,
        type: "outline_dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_LIST_FILTER_QUESTION_STATE",
        style: {zIndex: 0}
    },
    {
        key: SURVEY_QUICK_INSIGHTS_FILTERS.SURVEY,
        type: "multiselect_react_chips_dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_LIST_FILTER_TITLE",
        style: {zIndex: 0}
    },
    {   key: SURVEY_QUICK_INSIGHTS_FILTERS.INVITED_MEDIA,
        type: "outline_dropdown",
        localisation: "DIC_SURVEY_TEMPLATE_QUICK_INSIGHTS_INVITED_MEDIA_LABEL",
        style: {zIndex: 0}
    }
];

let surveySettingFilters = [
    {
        key: SURVEY_QUICK_INSIGHTS_FILTERS.CLIENTS,
        type: "multiselect_react_chips_dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_LIST_FILTER_CLIENTS",
        style: {zIndex: 0}
    },
    {   key: SURVEY_QUICK_INSIGHTS_FILTERS.COMPANY_STATUS,
        type: "outline_dropdown",
        localisation: "DIC_KEY_CONNECT_REPORTS_COMPANY_STATUS",
        style: {zIndex: 0}
    },
    {   key: SURVEY_QUICK_INSIGHTS_FILTERS.COUNTRY,
        type: "multiselect_react_chips_dropdown",
        localisation: "DIC_KEY_MULTISELECT_DROPDOWN_NAME_LABEL",
        style: {zIndex: 0}
    },

];

let genderDropDown = [
    {
        value: -1,
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTERS_GENDER_SELECT_ALL"/>
    },
    {
        value: "male",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_GENDER_MALE"/>
    },
    {
        value: "female",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_GENDER_FEMALE"/>
    },
    {
        value: "others",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_GENDER_OTHERS"/>
    },
    {
        value: "undisclosed",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_GENDER_UNDISCLOSED"/>
    }
];

let ageGroupDropDown = [
    {
        value: -1,
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTERS_AGE_GROUPS_SELECT_ALL"/>
    },
    {
        value: "0_18",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_AGE_GROUP_LESS_THAN_18"/>
    },
    {
        value: "18_30",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_AGE_GROUP_18_TO_30"/>
    },
    {
        value: "31_40",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_AGE_GROUP_31_TO_40"/>
    },
    {
        value: "41_49",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_AGE_GROUP_41_TO_49"/>
    },
    {
        value: "51_100",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_AGE_GROUP_GREATER_THAN_50"/>
    },
    {
        value: "undisclosed",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_AGE_GROUP_UNDISCLOSED"/>
    }
];

let tenureDropDown = [
    {
        value: -1,
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTERS_TENURE_SELECT_ALL"/>
    },
    {
        value: [{type: "<", value: 1}],
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_TENURE_LESS_THAN_1_YEAR"/>
    },
    {
        value: [{type: ">=", value: 1}, {type: "<=", value: 3}],
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_TENURE_1_to_3"/>
    },
    {
        value: [{type: ">", value: "3"}],
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_TENURE_LESS_THAN_3_YEARS"/>
    },
    {
        value: "undisclosed",
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTER_TENURE_UNDISCLOSED"/>
    }
];

let customFieldDropDown = [
    {
        value: -1,
        label: <IntlMessages id="DIC_SURVEY_QUICK_INSIGHTS_FILTERS_CUSTOM_FIELD_SELECT_ALL"/>
    }

];

let questionStateDropDown = [
    {
        key: "-1",
        value: -1,
        label: "DIC_SURVEY_QUICK_INSIGHTS_FILTERS_QUESTION_STATE_SELECT_ALL"
    },
    {
        key: "true",
        value: true,
        label: "DIC_SURVEY_QUICK_INSIGHTS_FILTERS_QUESTION_STATE_LOCKED"
    }

];

let filterValuesQuestionOrder = [
    {
        key: "overall",
        value: 'all',
        label: "DIC_SURVEY_REPORT_FILTER_QUESTION_ORDER_ALL"
    },
    {
        key: "randomize_order",
        value: 'random',
        label: "DIC_SURVEY_REPORT_FILTER_QUESTION_ORDER_RANDOMIZED"
    },
    {
        key: "standard_order",
        value: 'standard',
        label: "DIC_SURVEY_REPORT_FILTER_QUESTION_ORDER_STANDARD"
    }
];

let filterValuesResponderAccess = [
    {
        key: "overall",
        value: 'all',
        label: "DIC_SURVEY_REPORT_FILTER_RESPONDER_ACCESS_ALL"
    },
    {
        key: "unique_response",
        value: 'single',
        label: "DIC_SURVEY_REPORT_FILTER_RESPONDER_ACCESS_UNIQUE"
    },
    {
        key: "shared_response",
        value: 'multiple',
        label: "DIC_SURVEY_REPORT_FILTER_RESPONDER_ACCESS_SHARED"
    }
];

let filterValuesInvitedMedia = [
    {
        key: "All",
        value: 'all',
        label: "DIC_SURVEY_TEMPLATE_QUICK_INSIGHTS_INVITED_MEDIA_ALL"
    },
    {
        key: "QR",
        value: 'qrcode',
        label: "DIC_SURVEY_TEMPLATE_QUICK_INSIGHTS_INVITED_MEDIA_QRLABEL"
    },
    {
        key: "URL",
        value: 'url',
        label: "DIC_SURVEY_TEMPLATE_QUICK_INSIGHTS_INVITED_MEDIA_URL"
    }
];

let companyStatusFilterValue = [
    {
        key: "overall",
        value: COMPANY_STATUS_VALUE_MAP.ALL,
        label: "DIC_REPORTS_CONNECT_ALL"
    },
    {
        key: "active_response",
        value: COMPANY_STATUS_VALUE_MAP.ACTIVE,
        label: "DIC_REPORTS_CONNECT_COMPANY_STATUS_ACTIVE"
    },
    {
        key: "inactive_response",
        value: COMPANY_STATUS_VALUE_MAP.INACTIVE,
        label: "DIC_REPORTS_CONNECT_COMPANY_STATUS_INACTIVE"
    }
];


const initialData = {
    loader: false,
    alertMessage: '',
    isSuccessMessage: false,
    isErrorMessage: false,
    SurveyTemplateQuickInsightsFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    quickInsightsData: [],
    SurveyTemplateQuestionSummaryFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    questionSummaryData: [],
    questions: [],
    quickInsightFilters: quickInsightFilters,
    genderDropDown: genderDropDown,
    ageGroupDropDown: ageGroupDropDown,
    tenureDropDown: tenureDropDown,
    customFieldDropDown: customFieldDropDown,
    questionStateDropDown: questionStateDropDown,
    templateDisplayData: null,
    supportedLanguages: [],
    surveyResponseData: [],
    surveyResponseDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyResponseDownloadLink: "",
    overallRatingScore: 0.,
    defaultSurveyReportDropdown: [],
    templateReportClients: [],
    templateReportSurveys: [],
    clientsCountrySurveyTemplateFilterDropdownValues: [],
    defaultClientReportDropdown: [],
    refreshCounter: 0,
    filterValuesQuestionOrder: filterValuesQuestionOrder,
    filterValuesResponderAccess: filterValuesResponderAccess,
    filterValuesInvitedMedia: filterValuesInvitedMedia,
    selectedQuestionOrder: filterValuesQuestionOrder[0]['value'],
    selectedResponderAccess: filterValuesResponderAccess[0]['value'],
    selectedInvitedMedia: filterValuesInvitedMedia[0]['value'],
    clientFilterLookup: {},
    surveySettingFilters: surveySettingFilters,
    companyStatusFilterValue: companyStatusFilterValue,
    selectedCompanyStatus: companyStatusFilterValue[0]["value"],
    selectedCountries:[]
};

const ReducerSurveyTemplateQuickInsights = (state = initialData, action) => {

    switch (action.type) {

        case SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY:{
            return {
                ...state,
                redeployedSurveyQuestionSummaryStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                surveyLineGraphData: {}
            }
        }

        case SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_SUCCESS:{

            let parsedData = parseSurveyTemplateLineGraphData(action.payload.data.data);

            return {
                ...state,
                redeployedSurveyQuestionSummaryStatus: HTTP_REQUEST_STATUS.SUCCESS,
                surveyLineGraphData: parsedData
            }
        }

        case SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_ERROR:{
            return {
                ...state,
                redeployedSurveyQuestionSummaryStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }

        case SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_NONE:{
            return {
                ...state,
                redeployedSurveyQuestionSummaryStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_IN_PROGRESS:
            return {
                ...state,
                SurveyTemplateQuickInsightsFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                loader: true
            };

        case SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_ERROR:
            return {
                ...state,
                alertMessage: action.payload,
                isErrorMessage: true
            };

        case SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_NONE:
            return {
                ...state,
                SurveyTemplateQuickInsightsFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                quickInsightsData: []
            };

        case SURVEY_TEMPLATE_GET_QUICK_INSIGHTS:
            return {
                ...state,
                SurveyTemplateQuickInsightsFetchStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload,
                loader: false,
            };

        case SURVEY_TEMPLATE_GET_QUICK_INSIGHTS_SUCCESS:
            let templateDisplayData = updateSurveyTemplateQuickInsightDetails(action.payload);
            let templateClients = [];
            let surveyReportFilter = [];

            if (action.payload && action.payload.clients) {
                templateClients = AppUtils.generateClientsFilterDropdown(action.payload.clients);
            }

            if (action.payload && action.payload.survey_data) {
                surveyReportFilter = AppUtils.generateSurveyFilterDropdown(action.payload.survey_data);
            }

            let quickInsightsData = action.payload;
            if ( typeof quickInsightsData != "undefined" && typeof quickInsightsData["no_of_responses"] != "undefined" ) {
                quickInsightsData["response_count"] = AppUtils.numberWithCommas(quickInsightsData["no_of_responses"]);
            }

            const clients = state.templateReportClients.length ? state.templateReportClients : templateClients
            const surveys = state.templateReportSurveys.length ? state.templateReportSurveys : surveyReportFilter

            return {
                ...state,
                SurveyTemplateQuickInsightsFetchStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload,
                loader: false,
                quickInsightsData: quickInsightsData,
                templateDisplayData: templateDisplayData,
                templateReportClients: clients,
                templateReportSurveys: surveys,
                defaultSurveyReportDropdown: surveyReportFilter,
                defaultClientReportDropdown: templateClients,
                refreshCounter: state.refreshCounter + 1
            };

        case SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_IN_PROGRESS:
            return {
                ...state,
                SurveyTemplateQuestionSummaryFetchStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                loader: true
            };

        case SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_ERROR:
            return {
                ...state,
                alertMessage: action.payload,
                isErrorMessage: true
            };

        case SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_NONE:
            return {
                ...state,
                SurveyTemplateQuestionSummaryFetchStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                questionSummaryData: []
            };

        case SURVEY_TEMPLATE_GET_QUESTION_SUMMARY:
            return {
                ...state,
                alertMessage: action.payload.data,
                loader: false,
            };

        case SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_GRAPH_TYPE:
            return {
                ...state,
                currentGraph: action.payload
            };

        case SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_SUCCESS:
            let selectedCountries = state.selectedCountries;
            let parsedQuestionData = parseQuestionSummaryData(action.payload);

            if (typeof parsedQuestionData != "undefined") {
                parsedQuestionData = AppUtils.getSortedQuestions(parsedQuestionData);
            }

            let overall_rating_score = 0;
            let supportedLanguages = [];
            let clientFilterLookup = {};
            let clientsCountrySurveyTemplateFilterDropdownValues = [];

            if (typeof action.payload != "undefined" && action.payload.survey) {
                overall_rating_score = action.payload.survey.overall_rating_score;
                supportedLanguages = AppUtils.getSupportedLanguagesForQuickInsights(action.payload.survey);
                clientFilterLookup = AppUtils.generateClientsLookup(action.payload.survey.shared_clients);

                let tempData = generateClientsCountryDropdownDataSurveyTemplateQuickInsights(action.payload.survey.shared_clients);

                clientsCountrySurveyTemplateFilterDropdownValues = tempData;
                if( selectedCountries.length === 0 ){
                    selectedCountries = clientsCountrySurveyTemplateFilterDropdownValues;
                }

            }
            return {
                ...state,
                SurveyTemplateQuestionSummaryFetchStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false,
                questionSummaryData: action.payload,
                questions: parsedQuestionData,
                overallRatingScore: overall_rating_score,
                supportedLanguages: supportedLanguages,
                clientFilterLookup: clientFilterLookup,
                clientsCountrySurveyTemplateFilterDropdownValues: clientsCountrySurveyTemplateFilterDropdownValues,
                selectedCountries: selectedCountries
            };

        case SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_ERROR:
            return {
                ...state,
                surveyResponseDownloadStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: AppUtils.getErrorStringFromResponse(action.payload),
                isErrorMessage: true
            };

        case SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_SUCCESS:
            let surveyResponseDownloadLink = action.payload;

            if (action.payload && typeof action.payload != "undefined") {
                surveyResponseDownloadLink = AppUtils.getAppBaseUrl() + action.payload.link;
            }

            return {
                ...state,
                surveyResponseDownloadStatus: HTTP_REQUEST_STATUS.SUCCESS,
                loader: false,
                alertMessage: action.payload,
                surveyResponseData: action.payload,
                surveyResponseDownloadLink: surveyResponseDownloadLink

            };

        case SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_IN_PROGRESS:
            return {
                ...state,
                surveyResponseDownloadStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                surveyResponseDownloadLink: ""

            };

        case SURVEY_TEMPLATE_RESPONSES_DOWNLOAD_NONE:
            return {
                ...state,
                surveyResponseDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                surveyResponseDownloadLink: ""

            };

        case SURVEY_TEMPLATE_UPDATE_SURVEY_DROPDOWN:
            return {
                ...state,
                templateReportSurveys: getSurveysBasedonClients(action.payload, state.defaultSurveyReportDropdown)
            };

        case SURVEY_TEMPLATE_UPDATE_CLIENT_DROPDOWN:
            return {
                ...state,
                templateReportClients: getClientsBasedonSurveys(action.payload, state.defaultClientReportDropdown)
            };

        case SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_QUESTION_ORDER_FILTERS_CHANGE: {
            return {
                ...state,
                selectedQuestionOrder: action.payload
            }
        }

        case SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_RESPONDER_ACCESS_FILTERS_CHANGE: {
            return {
                ...state,
                selectedResponderAccess: action.payload
            }
        }

        case SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_INVITED_MEDIA_CHANGE: {
            return {
                ...state,
                selectedInvitedMedia: action.payload
            }
        }

        case SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_COMPANY_STATUS_CHANGE: {
            return {
                ...state,
                selectedCompanyStatus: action.payload
            }
        }

        case SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_FILTERS_RESET: {
            return {
                ...state,
                selectedQuestionOrder: filterValuesQuestionOrder[0]['value'],
                selectedResponderAccess: filterValuesResponderAccess[0]['value'],
                selectedCompanyStatus: companyStatusFilterValue[0]['value'],
                selectedInvitedMedia: filterValuesInvitedMedia[0]['value']
            }
        }

        case SURVEY_QUESTIONNAIRE_QUICK_INSIGHTS_SELECTED_COUNTRIES:{
            return {
                ...state,
                selectedCountries: action.payload
            }
        }

        default:
            return state;
    }
}

/**
 To update questionnaire quick insights info menu
 **/
const updateSurveyTemplateQuickInsightDetails = function (questionnaire) {
    try {

        let infoMenu = [];

        // questionnaire period
        if (typeof questionnaire['period'] != "undefined") {
            infoMenu.push(
                {
                    id: 1,
                    title: <IntlMessages id="DIC_SURVEY_INSTANT_REPORTING_INSIGHTS_SURVEY_PERIOD"/>,
                    icon: "calendar",
                    userList: "",
                    desc: [questionnaire['period']]
                }
            );
        }

        // no of questions
        if (typeof questionnaire['no_of_questions'] != "undefined") {
            infoMenu.push(
                {
                    id: 2,
                    title: <IntlMessages id="DIC_SURVEY_INSTANT_REPORTING_INSIGHTS_SURVEY_NO_OF_QUESTIONS"/>,
                    icon: "view-list",
                    userList: "",
                    desc: [questionnaire['no_of_questions']]
                }
            );
        }

        // no of categories
        if (typeof questionnaire['categories'] != "undefined") {
            infoMenu.push(
                {
                    id: 3,
                    title: <IntlMessages id="DIC_SURVEY_INSTANT_REPORTING_INSIGHTS_SURVEY_NO_OF_CATEGORIES"/>,
                    icon: "view-agenda",
                    userList: "",
                    desc: [questionnaire['categories']]
                }
            );
        }

        // no of languages
        if (typeof questionnaire['languages_count'] != "undefined") {
            infoMenu.push(
                {
                    id: 4,
                    title: <IntlMessages id="DIC_SURVEY_INSTANT_REPORTING_INSIGHTS_SURVEY_LANGUAGES"/>,
                    icon: "globe-alt",
                    userList: "",
                    desc: [questionnaire['languages_count']]
                }
            );
        }

        // allow response per device
        // if (typeof questionnaire['allow_response_per_device'] != "undefined") {
        //     infoMenu.push(
        //         {
        //             id: 5,
        //             title: <IntlMessages id="DIC_SURVEY_NEW_SURVEY_NO_OF_RESPONSES_PER_DEVICE"/>,
        //             icon: "smartphone-android",
        //             userList: "",
        //             desc: [questionnaire['allow_response_per_device'] ? <IntlMessages id="DIC_SURVEY_NEW_SURVEY_LABEL_ONE_RESPONSE"/> : <IntlMessages id="DIC_SURVEY_NEW_SURVEY_LABEL_MULTIPLE_RESPONSES"/>]
        //         }
        //     );
        // }

        // no of clients
        if (typeof questionnaire['clients_count'] != "undefined") {
            infoMenu.push(
                {
                    id: 6,
                    title: <IntlMessages id="DIC_SURVEY_INSTANT_REPORTING_INSIGHTS_SURVEY_NO_OF_COMPANIES"/>,
                    icon: "accounts-list",
                    userList: "",
                    desc: [questionnaire['clients_count']]
                }
            );
        }

        // no of responses
        if (typeof questionnaire['response_count'] != "undefined") {
            infoMenu.push(
                {
                    id: 7,
                    title: <IntlMessages id="DIC_SURVEY_INSTANT_REPORTING_INSIGHTS_SURVEY_NO_OF_RESPONSES"/>,
                    icon: "check-square",
                    userList: "",
                    desc: [ AppUtils.numberWithCommas(questionnaire['response_count'])]
                }
            );
        }

        questionnaire.infoMenu = infoMenu;

    } catch (ex) {
        console.error("updateSurveyTemplateQuickInsightDetails : error : ", ex);
    }

    return questionnaire;

};


function parseQuestionSummaryData(data) {

    let result = [];
    if (data && data.survey && data.survey.questions) {
        try {

            let questions = data.survey.questions;

            for (let i = 0; i < questions.length; i++) {
                if (
                    (questions[i].question_type != SURVEY_QUESTION_TYPES_VALUES.LINEAR_RATING_SCALE) &&
                    (questions[i].question_type != SURVEY_QUESTION_TYPES_VALUES.TEXT_LONG) &&
                    (questions[i].question_type != SURVEY_QUESTION_TYPES_VALUES.TEXT_SHORT) &&
                    (questions[i].question_type != SURVEY_QUESTION_TYPES_VALUES.EMAIL)) {
                    questions[i]['question_display_type'] = SURVEY_QUESTION_SUMMARY_DISPLAY_TYPE.GRAPH_BASED;
                } else {
                    questions[i]['question_display_type'] = SURVEY_QUESTION_SUMMARY_DISPLAY_TYPE.TEXT_BASED;
                }

                let questionTranslations = {};

                for (let j = 0; j < questions[i].translations.length; j++) {
                    let surveyQuestionSupportedLanguages = questions[i] && questions[i].translations[j] && questions[i].translations[j].language;

                    if ( surveyQuestionSupportedLanguages && surveyQuestionSupportedLanguages.language_code && questions[i].translations[j] ) {
                        questionTranslations[surveyQuestionSupportedLanguages.language_code] = questions[i].translations[j].translation;
                    }
                }

                questions[i]['question_title_translations'] = questionTranslations;

                if ( questions[i]["is_emoji_enabled"] ) {
                    let questionOptions = questions[i]["question_options"];

                    for(let j = 0 ; j < questionOptions.length; j++ ){
                        if(questionOptions[j]["icon_class"] !== "" ) {
                            questionOptions[j]["iconSvg"] = UIUtils.getSurveyGrayScaleEmojiSvgContent(questionOptions[j]["icon_class"]);
                        }
                    }
                }

            }
            result = questions;
        } catch (ex) {
            console.error("parseQuestionSummaryData : error : ", ex);
        }
        return result;
    }
}

function generateClientsOrSurveyFilterDropdown(data) {
    let result = [];

    try {

        result.push({
            key: -1,
            value: -1,
            label: <IntlMessages id={"DIC_CLIENTS_DROPDOWN_ALL"}/>,
            client_id: -1
        });

        for (let i = 0; i < data.length; i++) {
            result.push({
                key: data[i]['id'],
                value: data[i]['client_key'] ? data[i]['client_key'] : data[i]['id'],
                label: data[i]['name'],
                client_id: data[i]['client_id']
            })
        }
    } catch (ex) {
        console.error(ex);
    }
    return result;
}

/**To create survey dropdown values when change in client report filter */
export function getSurveysBasedonClients(clients, surveys) {

    let result = [];

    try {
        if (clients && surveys) {
            if (!clients || !clients.length) {
                result = surveys;
            } else {

                for (let i = 0; i < clients.length; i++) {

                    for (let j = 0; j < surveys.length; j++) {
                        if(surveys[j]['clientId'] === clients[i]['value'] && !result.includes(surveys[j])) {
                            result.push(surveys[j]);
                        }
                        if(surveys[j]['sharedClients'] && surveys[j]['sharedClients'].length > 0) {
                            if(surveys[j]['sharedClients'].includes(clients[i]['value']) && !result.includes(surveys[j])) {
                                result.push(surveys[j]);
                            }
                        }
                    }
                }
            }
        }
    } catch (ex) {
        console.error("getSurveysBasedonClients : error : ", ex);
    }

    return result;
}

/**To create client dropdown values when change in survey report filter */
export function getClientsBasedonSurveys(surveys, clients) {

    let result = [];

    try {
        if (clients && surveys) {
            if (!surveys.length) {
                result = clients;
            } else {

                let clientIds = [];

                for (let i = 0; i < surveys.length; i++) {

                    if (!clientIds.includes(surveys[i]['clientId'])) {
                        clientIds.push(surveys[i]['clientId']);
                    }

                    let sharedClients = surveys[i]['sharedClients'];

                    if (sharedClients && sharedClients.length > 0) {
                        for (let j = 0; j < sharedClients.length; j++) {
                            if (!clientIds.includes(sharedClients[j])) {
                                clientIds.push(sharedClients[j]);
                            }
                        }
                    }
                }
                for (let k = 0; k < clients.length; k++) {
                    if (clientIds.includes(clients[k]['value'])) {
                        result.push(clients[k]);
                    }
                }
            }
        }
    } catch (ex) {
        console.error("getClientsBasedonSurveys : error : ", ex);
    }

    return result;
}

/*** TO get country filter dropdown values from shared_client ***/
function generateClientsCountryDropdownDataSurveyTemplateQuickInsights(data) {
    let result = [];
    try {

        let tempResult = {};

        for (let i = 0; i < data.length; i++) {
            let tempData = {}
            if (typeof tempResult[data[i]['country']] == "undefined") {
                tempResult[data[i]['country']] = {
                    name: data[i]['country']
                };
                tempData = {
                    id: data[i]['country_mapping']['short_code'],
                    key: data[i]['country_mapping']['short_code'],
                    name : data[i]['country']
                }
                result.push(tempData);
            }
        }
    } catch (ex) {
        console.error("generateClientsCountryFilterDropdown : error : ", ex);
    }
    return result;
}

function parseSurveyTemplateLineGraphData(responseData){
    let result = {};

    try{

        result = responseData["questions"];

        let surveysData = responseData["questionnaire_details"];

        for ( let questionId in result ){

            let responses = [];
            let questionResponses = result[questionId]["question_responses"];

            for ( let surveyId in questionResponses ) {

                questionResponses[surveyId]["avg_score"] = AppUtils.getAverageScoreLabel(questionResponses[surveyId]["avg_score"]);

                if ( typeof surveysData[surveyId] != "undefined" ) {
                    questionResponses[surveyId]["name"] = surveysData[surveyId]["name"];
                    questionResponses[surveyId]["id"] = surveysData[surveyId]["id"];
                    questionResponses[surveyId]["translations"] = surveysData[surveyId]["translations"];
                    questionResponses[surveyId]["sequence_id"] = surveysData[surveyId]["sequence_id"];
                    questionResponses[surveyId]["start_date"] = AppUtils.getDateLabelFromDateDDMMYYYY(surveysData[surveyId]["start_date"], "-");
                    questionResponses[surveyId]["end_date"] = AppUtils.getDateLabelFromDateDDMMYYYY(surveysData[surveyId]["end_date"], "-");
                }

                if ( questionResponses[surveyId]["avg_score"] !== "NA" ) {
                    responses.push(questionResponses[surveyId]);
                }
            }


            responses.sort(function(a,b){return a["sequence_id"] - b["sequence_id"]});
            result[questionId]["responses"] = responses;

        }

    } catch ( ex ) {
        console.error("parseSurveyTemplateLineGraphData : error : ", ex );
    }

    return result;
}

export default ReducerSurveyTemplateQuickInsights;
