import {SURVEY_TEMPLATE_GET_QUESTIONS} from "../../../ActionTypes";


export const SURVEY_NEW_SURVEY_SET_SOURCE_TYPE = 'SURVEY_NEW_SURVEY_SET_SOURCE_TYPE';
export const SURVEY_NEW_SURVEY_SHOW_LOADER = "SURVEY_NEW_SURVEY_SHOW_LOADER";
export const SURVEY_NEW_SURVEY_HIDE_LOADER = "SURVEY_NEW_SURVEY_HIDE_LOADER";

export const SURVEY_NEW_SURVEY_SET_STEP = "SURVEY_NEW_SURVEY_SET_STEP";

export const SURVEY_NEW_SURVEY_SET_SURVEY = "SURVEY_NEW_SURVEY_SET_SURVEY";

export const SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_IN_PROGRESS = "SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_IN_PROGRESS";
export const SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_NONE = "SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_NONE";
export const SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO = "SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO";
export const SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_UPDATE = "SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_UPDATE";
export const SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_SUCCESS = 'SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_SUCCESS';
export const SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_ERROR = 'SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_ERROR';


export const SURVEY_NEW_SURVEY_GET_ALL_RECORDS_IN_PROGRESS = "SURVEY_LIST_GET_ALL_RECORDS_IN_PROGRESS";
export const SURVEY_NEW_SURVEY_GET_ALL_RECORDS_NONE = "SURVEY_LIST_GET_ALL_RECORDS_NONE";
export const SURVEY_NEW_SURVEY_GET_ALL_RECORDS = "SURVEY_LIST_GET_ALL_RECORDS";
export const SURVEY_NEW_SURVEY_GET_ALL_RECORDS_SUCCESS = 'SURVEY_LIST_GET_ALL_RECORDS_SUCCESS';
export const SURVEY_NEW_SURVEY_GET_ALL_RECORDS_ERROR = 'SURVEY_LIST_GET_ALL_RECORDS_ERROR';

/** to get the survey paginated list **/
export const SURVEY_GET_NEXT_LIST_IN_PROGRESS = "SURVEY_GET_NEXT_LIST_IN_PROGRESS";
export const SURVEY_GET_NEXT_LIST_NONE = "SURVEY_GET_NEXT_LIST_NONE";
export const SURVEY_GET_NEXT_LIST = "SURVEY_GET_NEXT_LIST";
export const SURVEY_GET_NEXT_LIST_SUCCESS = 'SURVEY_GET_NEXT_LIST_SUCCESS';
export const SURVEY_GET_NEXT_LIST_ERROR = 'SURVEY_GET_NEXT_LIST_ERROR';


export const SURVEY_NEW_SURVEY_GET_QUESTIONS_IN_PROGRESS = "SURVEY_NEW_SURVEY_GET_QUESTIONS_IN_PROGRESS";
export const SURVEY_NEW_SURVEY_GET_QUESTIONS_NONE = "SURVEY_NEW_SURVEY_GET_QUESTIONS_NONE";
export const SURVEY_NEW_SURVEY_GET_QUESTIONS = "SURVEY_NEW_SURVEY_GET_QUESTIONS";
export const SURVEY_NEW_SURVEY_GET_QUESTIONS_SUCCESS = 'SURVEY_NEW_SURVEY_GET_QUESTIONS_SUCCESS';
export const SURVEY_NEW_SURVEY_GET_QUESTIONS_ERROR = 'SURVEY_NEW_SURVEY_GET_QUESTIONS_ERROR';


export const SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL = 'SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL';
export const SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_SUCCESS = 'SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_SUCCESS';
export const SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_ERROR = 'SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_ERROR';
export const SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_NONE = 'SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_NONE';
export const SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_IN_PROGRESS = 'SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_IN_PROGRESS';

export const SURVEY_INFO_UPDATE_STATUS = 'SURVEY_INFO_UPDATE_STATUS';
export const SURVEY_INFO_UPDATE_STATUS_SUCCESS = 'SURVEY_INFO_UPDATE_STATUS_SUCCESS';
export const SURVEY_INFO_UPDATE_STATUS_ERROR = 'SURVEY_INFO_UPDATE_STATUS_ERROR';
export const SURVEY_INFO_UPDATE_STATUS_NONE = 'SURVEY_INFO_UPDATE_STATUS_NONE';
export const SURVEY_INFO_UPDATE_STATUS_IN_PROGRESS = 'SURVEY_INFO_UPDATE_STATUS_IN_PROGRESS';

export const SURVEY_NEW_SURVEY_IS_EDIT_QUESTION_ENABLED = 'SURVEY_NEW_SURVEY_IS_EDIT_QUESTION_ENABLED';
export const SURVEY_NEW_SURVEY_IS_EDIT_QUESTION_DISABLED = 'SURVEY_NEW_SURVEY_IS_EDIT_QUESTION_DISABLED';

export const SURVEY_QUESTIONS_SET_CURRENT_ITEM = 'SURVEY_QUESTIONS_SET_CURRENT_ITEM';

export const TEMPLATE_SURVEY_INFO_SET_ITEM = 'TEMPLATE_SURVEY_INFO_SET_ITEM';


export const SURVEY_GET_QUICK_INSIGHTS = 'SURVEY_GET_QUICK_INSIGHTS';
export const SURVEY_GET_QUICK_INSIGHTS_ERROR = 'SURVEY_GET_QUICK_INSIGHTS_ERROR';
export const SURVEY_GET_QUICK_INSIGHTS_IN_PROGRESS = 'SURVEY_GET_QUICK_INSIGHTS_IN_PROGRESS';
export const SURVEY_GET_QUICK_INSIGHTS_NONE = 'SURVEY_GET_QUICK_INSIGHTS_NONE';
export const SURVEY_GET_QUICK_INSIGHTS_SUCCESS = 'SURVEY_GET_QUICK_INSIGHTS_SUCCESS';


export const SURVEY_GET_QUESTION_SUMMARY = 'SURVEY_GET_QUESTION_SUMMARY';
export const SURVEY_GET_QUESTION_SUMMARY_ERROR = 'SURVEY_GET_QUESTION_SUMMARY_ERROR';
export const SURVEY_GET_QUESTION_SUMMARY_IN_PROGRESS = 'SURVEY_GET_QUESTION_SUMMARY_IN_PROGRESS';
export const SURVEY_GET_QUESTION_SUMMARY_NONE = 'SURVEY_GET_QUESTION_SUMMARY_NONE';
export const SURVEY_GET_QUESTION_SUMMARY_SUCCESS = 'SURVEY_GET_QUESTION_SUMMARY_SUCCESS';
export const SURVEY_GET_QUESTION_SUMMARY_GRAPH_TYPE = 'SURVEY_GET_QUESTION_SUMMARY_GRAPH_TYPE';

export const SURVEY_RESPONSES_DOWNLOAD = 'SURVEY_RESPONSES_DOWNLOAD';
export const SURVEY_RESPONSES_DOWNLOAD_ERROR = 'SURVEY_RESPONSES_DOWNLOAD_ERROR';
export const SURVEY_RESPONSES_DOWNLOAD_SUCCESS = 'SURVEY_RESPONSES_DOWNLOAD_SUCCESS';
export const SURVEY_RESPONSES_DOWNLOAD_IN_PROGRESS = 'SURVEY_RESPONSES_DOWNLOAD_IN_PROGRESS';
export const SURVEY_RESPONSES_DOWNLOAD_NONE = 'SURVEY_RESPONSES_DOWNLOAD_NONE';

/*** Survey Reports Raw data download */

export const SURVEY_RAW_DATA_RESPONSES_DOWNLOAD = 'SURVEY_RAW_DATA_RESPONSES_DOWNLOAD';
export const SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_ERROR = 'SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_ERROR';
export const SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_SUCCESS = 'SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_SUCCESS';
export const SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_IN_PROGRESS = 'SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_IN_PROGRESS';
export const SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_NONE = 'SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_NONE';

/*** survey Reports Summary Table ***/

export const SURVEY_GET_SUMMARY_TABLE_DATA='SURVEY_GET_SUMMARY_TABLE_DATA';
export const SURVEY_GET_SUMMARY_TABLE_DATA_ERROR='SURVEY_GET_SUMMARY_TABLE_DATA_ERROR';
export const SURVEY_GET_SUMMARY_TABLE_DATA_IN_PROGRESS='SURVEY_GET_SUMMARY_TABLE_DATA_IN_PROGRESS';
export const SURVEY_GET_SUMMARY_TABLE_DATA_NONE='SURVEY_GET_SUMMARY_TABLE_DATA_NONE';
export const SURVEY_GET_SUMMARY_TABLE_DATA_SUCCESS='SURVEY_GET_SUMMARY_TABLE_DATA_SUCCESS';

/*** Survey Reports Raw data download as uuid */

export const SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID = 'SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID';
export const SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR = 'SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR';
export const SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS = 'SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS';
export const SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_IN_PROGRESS = 'SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_IN_PROGRESS';
export const SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE = 'SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE';

/*** Survey Reports Raw data download as uuid status */

export const SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA = 'SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA';
export const SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_ERROR = 'SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_ERROR';
export const SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_SUCCESS = 'SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_SUCCESS';
export const SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_IN_PROGRESS = 'SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_IN_PROGRESS';
export const SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_NONE = 'SURVEY_RAW_DATA_RESPONSES_DOWNLOAD_UUID_DATA_NONE';


export const SURVEY_CLIENT_COUNTRIES = 'SURVEY_CLIENT_COUNTRIES';
export const SURVEY_CLIENT_COUNTRIES_SUCCESS = 'SURVEY_CLIENT_COUNTRIES_SUCCESS';
export const SURVEY_CLIENT_COUNTRIES_ERROR = 'SURVEY_CLIENT_COUNTRIES_ERROR';
export const SURVEY_CLIENT_COUNTRIES_NONE = 'SURVEY_CLIENT_COUNTRIES_NONE';


/** to get the single survey based on id **/
export const SURVEY_GET_SURVEY_BY_ID = 'SURVEY_GET_SURVEY_BY_ID';
export const SURVEY_GET_SURVEY_BY_ID_SUCCESS = 'SURVEY_GET_SURVEY_BY_ID_SUCCESS';
export const SURVEY_GET_SURVEY_BY_ID_ERROR = 'SURVEY_GET_SURVEY_BY_ID_ERROR';
export const SURVEY_GET_SURVEY_BY_ID_NONE = 'SURVEY_GET_SURVEY_BY_ID_NONE';

export const SURVEY_SET_REDEPLOY_STATUS = 'SURVEY_SET_REDEPLOY_STATUS';
export const SURVEY_RESET_REDEPLOY_STATUS = 'SURVEY_RESET_REDEPLOY_STATUS';

/** to save survey closing and opening message **/
export const SURVEY_NEW_CLOSING_MESSAGE_SAVE = 'SURVEY_NEW_CLOSING_MESSAGE_SAVE';
export const SURVEY_NEW_CLOSING_MESSAGE_SAVE_IN_PROGRESS = 'SURVEY_NEW_CLOSING_MESSAGE_SAVE_IN_PROGRESS';
export const SURVEY_NEW_OPENING_MESSAGE_SAVE = 'SURVEY_NEW_OPENING_MESSAGE_SAVE';
export const SURVEY_NEW_OPENING_MESSAGE_SAVE_IN_PROGRESS = 'SURVEY_NEW_OPENING_MESSAGE_SAVE_IN_PROGRESS';
export const SURVEY_NEW_OPENING_MESSAGE_SAVE_SUCCESS = 'SURVEY_NEW_OPENING_MESSAGE_SAVE_SUCCESS';
export const SURVEY_NEW_CLOSING_MESSAGE_SAVE_SUCCESS = 'SURVEY_NEW_CLOSING_MESSAGE_SAVE_SUCCESS';
export const SURVEY_NEW_OPENING_MESSAGE_SAVE_ERROR = 'SURVEY_NEW_OPENING_MESSAGE_SAVE_ERROR';
export const SURVEY_NEW_CLOSING_MESSAGE_SAVE_ERROR = 'SURVEY_NEW_CLOSING_MESSAGE_SAVE_ERROR';
export const SURVEY_NEW_ENABLE_MESSAGE_LAYOUT = 'SURVEY_NEW_ENABLE_MESSAGE_LAYOUT';
export const SURVEY_NEW_DISABLE_ADD_OPENING_MESSAGE = 'SURVEY_NEW_DISABLE_ADD_OPENING_MESSAGE';
export const SURVEY_NEW_ENABLE_CLOSE_MESSAGE_LAYOUT = 'SURVEY_NEW_ENABLE_CLOSE_MESSAGE_LAYOUT';
export const SURVEY_NEW_OPENING_MESSAGE_SAVE_STATUS_NONE = 'SURVEY_NEW_OPENING_MESSAGE_SAVE_STATUS_NONE';

/**Survey summary table  download */
export const SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA = 'SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA';
export const SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_ERROR = 'SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_ERROR';
export const SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_IN_PROGRESS = 'SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_IN_PROGRESS';
export const SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_NONE = 'SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_NONE';
export const SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_SUCCESS = 'SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA_SUCCESS';

export const SURVEY_SET_CURRENT_REPORT_TYPE = 'SURVEY_SET_CURRENT_REPORT_TYPE';
export const SURVEY_SET_CURRENT_GROUP_BY_TYPE = 'SURVEY_SET_CURRENT_GROUP_BY_TYPE';
export const SURVEY_SUMMARY_SET_TABLE_RELOAD = 'SURVEY_SUMMARY_SET_TABLE_RELOAD';
export const SET_SUMMARY_TABLE_LAYOUT_TYPE = 'SET_SUMMARY_TABLE_LAYOUT_TYPE';

/**Survey Redeploy */
export const SURVEY_REDEPLOY_SURVEY = 'SURVEY_REDEPLOY_SURVEY';
export const SURVEY_REDEPLOY_SURVEY_SUCCESS = 'SURVEY_REDEPLOY_SURVEY_SUCCESS';
export const SURVEY_REDEPLOY_SURVEY_ERROR = 'SURVEY_REDEPLOY_SURVEY_ERROR';
export const SURVEY_REDEPLOY_SURVEY_NONE = 'SURVEY_REDEPLOY_SURVEY_NONE';

/**Survey Redeploy : get series questions list*/
export const SURVEY_REDEPLOY_GET_SERIES_QUESTIONS = 'SURVEY_REDEPLOY_GET_SERIES_QUESTIONS';
export const SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_SUCCESS = 'SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_SUCCESS';
export const SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_ERROR = 'SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_ERROR';
export const SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_NONE = 'SURVEY_REDEPLOY_GET_SERIES_QUESTIONS_NONE';

/**Survey Redeploy : save series questions list*/
export const SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS = 'SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS';
export const SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS = 'SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS';
export const SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_ERROR = 'SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_ERROR';
export const SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_NONE = 'SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_NONE';

/**Survey Redeploy : to get line graph details */
export const SURVEY_REDEPLOY_GET_QUESTION_SUMMARY = 'SURVEY_REDEPLOY_GET_QUESTION_SUMMARY';
export const SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_SUCCESS = 'SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_SUCCESS';
export const SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_ERROR = 'SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_ERROR';
export const SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_NONE = 'SURVEY_REDEPLOY_GET_QUESTION_SUMMARY_NONE';

export const SURVEY_REDEPLOY_SHOW_SERIES_QUESTIONS_LAYOUT = 'SURVEY_REDEPLOY_SHOW_SERIES_QUESTIONS_LAYOUT';
export const SURVEY_REDEPLOY_HIDE_SERIES_QUESTIONS_LAYOUT = 'SURVEY_REDEPLOY_HIDE_SERIES_QUESTIONS_LAYOUT';

export const SURVEY_NEW_SURVEY_SETTINGS_DIALOG_TOGGLE = 'SURVEY_NEW_SURVEY_SETTINGS_DIALOG_TOGGLE';

/**Survey quickinsights : filters change ation */
export const SURVEY_QUICK_INSIGHTS_QUESTION_ORDER_FILTERS_CHANGE = 'SURVEY_QUICK_INSIGHTS_QUESTION_ORDER_FILTERS_CHANGE';
export const SURVEY_QUICK_INSIGHTS_RESPONDER_ACCESS_FILTERS_CHANGE = 'SURVEY_QUICK_INSIGHTS_RESPONDER_ACCESS_FILTERS_CHANGE';
export const SURVEY_QUICK_INSIGHTS_INVITED_MEDIA_FILTERS_CHANGE = 'SURVEY_QUICK_INSIGHTS_INVITED_MEDIA_FILTERS_CHANGE';
export const SURVEY_QUICK_INSIGHTS_UPDATE_SELECTED_COUNTRIES = 'SURVEY_QUICK_INSIGHTS_UPDATE_SELECTED_COUNTRIES';
export const SURVEY_QUICK_INSIGHTS_FILTERS_RESET = 'SURVEY_QUICK_INSIGHTS_FILTERS_RESET';
export const SURVEY_QUICK_INSIGHTS_COMPANY_STATUS_CHANGE = 'SURVEY_QUICK_INSIGHTS_COMPANY_STATUS_CHANGE';
export const SURVEY_QUICK_INSIGHTS_JOB_ROLE_CHANGE = 'SURVEY_QUICK_INSIGHTS_JOB_ROLE_CHANGE';

/**Survey summary table sorting */
export const SURVEY_SUMMARY_TABLE_SORTING = 'SURVEY_SUMMARY_TABLE_SORTING';
export const SURVEY_SUMMARY_TABLE_SORTING_NONE = 'SURVEY_SUMMARY_TABLE_SORTING_NONE';
