import {HTTP_REQUEST_STATUS} from "../../../constants/constants";
import ListRoundedIcon from '@material-ui/icons/ListRounded';
import InsertDriveFileRoundedIcon from '@material-ui/icons/InsertDriveFileRounded';

import {
    SURVEY_GET_NEXT_LIST,
    SURVEY_GET_NEXT_LIST_ERROR,
    SURVEY_GET_NEXT_LIST_NONE,
    SURVEY_GET_NEXT_LIST_SUCCESS,
    SURVEY_GET_SURVEY_BY_ID_SUCCESS,
    SURVEY_INFO_UPDATE_STATUS_NONE,
    SURVEY_INFO_UPDATE_STATUS_SUCCESS,
    SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_NONE,
    SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_SUCCESS,
    SURVEY_NEW_SURVEY_GET_ALL_RECORDS,
    SURVEY_NEW_SURVEY_GET_ALL_RECORDS_SUCCESS,
    SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_IN_PROGRESS,
    SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_NONE,
    SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_SUCCESS,
    SURVEY_NEW_SURVEY_SET_SOURCE_TYPE, SURVEY_NEW_SURVEY_SET_STEP,
    SURVEY_NEW_SURVEY_SET_SURVEY,
    SURVEY_NEW_CLOSING_MESSAGE_SAVE_IN_PROGRESS,
    SURVEY_NEW_OPENING_MESSAGE_SAVE_IN_PROGRESS,
    SURVEY_NEW_CLOSING_MESSAGE_SAVE_SUCCESS,
    SURVEY_NEW_OPENING_MESSAGE_SAVE_SUCCESS,
    SURVEY_NEW_OPENING_MESSAGE_SAVE_ERROR,
    SURVEY_NEW_CLOSING_MESSAGE_SAVE_ERROR,
    SURVEY_NEW_ENABLE_MESSAGE_LAYOUT,
    SURVEY_NEW_DISABLE_ADD_OPENING_MESSAGE,
    SURVEY_NEW_ENABLE_CLOSE_MESSAGE_LAYOUT,
    SURVEY_NEW_OPENING_MESSAGE_SAVE_STATUS_NONE,
    SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_ERROR,
    SURVEY_INFO_UPDATE_STATUS,
    SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL,
    SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_ERROR,
    SURVEY_NEW_SURVEY_SETTINGS_DIALOG_TOGGLE
} from "../../../constants/actions/survey/survey/actionTypesSurvey";
import {
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_DISABLE,
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_ENABLE,
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS,
    SURVEY_SURVEY_QUESTION_DELETE_SUCCESS,
    SURVEY_TEMPLATE_QUESTION_DELETE_SUCCESS,
    SURVEY_SURVEY_QUESTION_DUPLICATE_SUCCESS,
    SURVEY_TEMPLATE_QUESTION_DUPLICATE_SUCCESS,
    SURVEY_QUESTION_RANDOMIZE_ERROR,
    SURVEY_QUESTION_RANDOMIZE_IN_PROGRESS,
    SURVEY_QUESTION_RANDOMIZE_NONE,
    SURVEY_QUESTION_RANDOMIZE_SUCCESS,
    SURVEY_UPDATE_RANDOMIZE_OPTION
} from "../../../constants/actions/survey/questions/actionTypesSurveyQuestions";
import AppUtils from "../../../util/util";
import {SURVEY_CREATION_TYPES, SURVEY_STATUSES_VALUE, SURVEY_USER_ACTIONS} from "../../../constants/config";
import {SIGNOUT_USER_SUCCESS} from "../../../constants/ActionTypes";


export const SURVEY_STATUSES_COLORS = {
    DRAFT: "#FF8F00",
    ACTIVE: "#00695c",
    INACTIVE: "#607d8b",
    SCHEDULED: "#c6ff00",
    DELETED: "#E53935",
    COMPLETED: "#2962FF"
};


let surveySources = [
    {
        type: SURVEY_CREATION_TYPES['SCRATCH'],
        icon: InsertDriveFileRoundedIcon,
        titleId: "DIC_SURVEY_NEW_SURVEY_SOURCE_SCRATCH",
        subTitleId: "DIC_SURVEY_NEW_SURVEY_SOURCE_SCRATCH_INFO",
        color: "#2196F3",
        titleColor: "text-white",
        infoColor: "text-white",
        containerStyle: {
            paddingTop: "5%",
            paddingBottom: "5%"
        }
    },
    {
        type: SURVEY_CREATION_TYPES['TEMPLATE'],
        icon: ListRoundedIcon,
        titleId: "DIC_SURVEY_NEW_SURVEY_SOURCE_TEMPLATE",
        subTitleId: "DIC_SURVEY_NEW_SURVEY_SOURCE_TEMPLATE_INFO",
        color: "#00C1D5",
        backgroundClass: "jr-pink-purple-gradient-reverse hand",
        titleColor: "text-white",
        infoColor: "text-white",
    },
    /*{
        type: SURVEY_CREATION_TYPES['SURVEY'],
        icon: "file-plus",
        titleId: "DIC_SURVEY_NEW_SURVEY_SOURCE_SURVEY",
        subTitleId: "DIC_SURVEY_NEW_SURVEY_SOURCE_SURVEY_INFO",
        color: "#f44336",
        backgroundClass: "jr-blue-cyan-gradient-reverse hand",
        titleColor: "text-white",
        infoColor: "text-white",
        containerStyle: {
            paddingTop: "5%",
            paddingBottom: "5%"
        }
    }*/
];

let surveySourcesDM = [
    {
        type: SURVEY_CREATION_TYPES['TEMPLATE'],
        icon: "folder-star-alt",
        titleId: "DIC_SURVEY_NEW_SURVEY_SOURCE_TEMPLATE",
        subTitleId: "DIC_SURVEY_NEW_SURVEY_SOURCE_TEMPLATE_INFO",
        color: "#ff9800",
        backgroundClass: "jr-pink-purple-gradient-reverse hand",
        titleColor: "text-white",
        infoColor: "text-white",
    }
];

let stepsFromScratch = [
    {
        name: "DIC_SURVEY_NEW_SURVEY_STEP_SURVEY_DETAILS",
        value: 0
    },
    {
        name: "DIC_SURVEY_NEW_SURVEY_STEP_QUESTIONS",
        value: 1
    },
    {
        name: "DIC_SURVEY_NEW_SURVEY_STEP_FINALIZE",
        value: 2
    }
];

let stepsFromTemplate = [
    {
        name: "DIC_SURVEY_NEW_SURVEY_STEP_TEMPLATE_SOURCE",
        value: 0
    },
    {
        name: "DIC_SURVEY_NEW_SURVEY_STEP_SURVEY_DETAILS",
        value: 1
    },
    {
        name: "DIC_SURVEY_NEW_SURVEY_STEP_QUESTIONS",
        value: 2
    },
    {
        name: "DIC_SURVEY_NEW_SURVEY_STEP_FINALIZE",
        value: 3
    }
];

let stepsFromSurvey = [
    {
        name: "DIC_SURVEY_NEW_SURVEY_STEP_SURVEY_SOURCE",
        value: 0
    },
    {
        name: "DIC_SURVEY_NEW_SURVEY_STEP_SURVEY_DETAILS",
        value: 1
    },
    {
        name: "DIC_SURVEY_NEW_SURVEY_STEP_QUESTIONS",
        value: 2
    },
    {
        name: "DIC_SURVEY_NEW_SURVEY_STEP_FINALIZE",
        value: 3
    }
];

let templateInfoConfigLanguageSelection = [
    [
        {
            'key': 'supported_languages',
            'type': 'chips',
            'style': {},
            'labelClass': "pb-3",
            'localisation': "DIC_SURVEY_NEW_SURVEY_SUPPORTED_LANGUAGES",
            'isMandatory': true,
            'containerClass': 'col-md-6 col-sm-12 col-12',
            'items': []
        }
    ]
];


let templateInfoConfig = [
    [
        /*{
            'key': 'categories',
            'type': 'chips',
            'style': {},
            'labelClass': "pb-3",
            'localisation': "DIC_SURVEY_NEW_SURVEY_CATEGORIES",
            'isMandatory': false,
            'containerClass': 'col-md-6 col-sm-12 col-12'
        },*/
        {
            'key': 'reportingCategories',
            'type': 'chips',
            'style': {},
            'labelClass': "pb-3",
            'localisation': "DIC_SURVEY_NEW_SURVEY_REPORTING_CATEGORIES",
            'isMandatory': false,
            'containerClass': 'col-md-6 col-sm-12 col-12'
        },
        {
            'key': 'clients',
            'type': 'chips',
            'style': {},
            'labelClass': "pb-3",
            'localisation': "DIC_SURVEY_NEW_SURVEY_CLIENTS",
            'isMandatory': false,
            'containerClass': 'col-md-6 col-sm-12 col-12'
        }
    ]
];

let surveyFilters = [
    {
        key: 'survey_titles',
        type: "textfield",
        isMandatory: false,
        localisation: "DIC_SURVEY_LIST_FILTER_TITLE"
    },
    {
        key: 'supported_languages',
        type: "dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_LIST_FILTER_SUPPORTED_LANGUAGES"
    },
    {
        key: 'clients',
        type: "dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_LIST_FILTER_CLIENTS",
        isBrand: true
    },
    /* {
         key: 'categories',
         type: "dropdown",
         isMandatory: false,
         localisation: "DIC_SURVEY_LIST_FILTER_SUPPORTED_CATEGORIES"
     },*/
    {
        key: 'survey_status',
        type: "dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_LIST_FILTER_STATUS"
    },
    {
        key: 'client_country',
        type: "dropdown",
        isMandatory: false,
        localisation: "DIC_SURVEY_LIST_FILTER_COUNTRY",
        isBrand: true
    }
];

export const SURVEY_STEPS = {
    NONE: "NONE",
    VIEW: "VIEW",
    EDIT: "EDIT"
};

export const SURVEY_VIEW_ACTIONS = {
    VIEW: "VIEW",
    EDIT: "EDIT",
    QUICK_INSIGHTS: "QUICK_INSIGHTS",
    REPORT: "REPORT",
    CLONE: "CLONE",
    DEACTIVATE: "DEACTIVATE",
    REACTIVATE: "REACTIVATE",
    REMOVE: "REMOVE",
    GENERATE_URL: "GENERATE_URL",
    CLIENT_LIST: "CLIENT_LIST",
    SHOW_REDEPLOY_SURVEY: 'SHOW_REDEPLOY_SURVEY',
    INVITE: 'INVITE',
    VIEW_HISTORY:'VIEW_HISTORY'
};

export const SURVEY_OPTIONS_MENU = {
    ASSIGNED_ACTIVE: [
        /*{
            label : "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_REPORT",
            value : SURVEY_VIEW_ACTIONS.REPORT
        },*/
        {
            label: "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_INSIGHT",
            value: SURVEY_VIEW_ACTIONS.QUICK_INSIGHTS
        },
        /*{
            label : "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_CLONE",
            value : SURVEY_VIEW_ACTIONS.CLONE
        }*/
    ],
    ASSIGNED_DRAFT: [
        /*{
            label : "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_CLONE",
            value : SURVEY_VIEW_ACTIONS.CLONE
        }*/
    ],
    DRAFT: [
        {
            label: "DIC_SURVEY_SURVEY_NEW_SURVEY_EDIT",
            value: SURVEY_VIEW_ACTIONS.EDIT
        },
        {
            label: "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_REMOVE",
            value: SURVEY_VIEW_ACTIONS.REMOVE
        },
        /*{
            label : "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_CLONE",
            value : SURVEY_VIEW_ACTIONS.CLONE
        }*/
    ],
    ACTIVE: [
        /*{
            label : "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_CLONE",
            value : SURVEY_VIEW_ACTIONS.CLONE
        },*/
        {
            label: "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_DEACTIVATE",
            value: SURVEY_VIEW_ACTIONS.DEACTIVATE
        },
        /*{
            label : "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_REPORT",
            value : SURVEY_VIEW_ACTIONS.REPORT
        },*/
        {
            label: "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_INSIGHT",
            value: SURVEY_VIEW_ACTIONS.QUICK_INSIGHTS
        },
        {
            label: "DIC_SURVEY_SURVEY_NEW_SURVEY_EDIT",
            value: SURVEY_VIEW_ACTIONS.EDIT
        }
    ],
    INACTIVE: [
        /*{
            label : "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_REACTIVATE",
            value : SURVEY_VIEW_ACTIONS.REACTIVATE
        },*/
        /*{
            label : "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_REMOVE",
            value : SURVEY_VIEW_ACTIONS.REMOVE
        },*/
        {
            label: "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_INSIGHT",
            value: SURVEY_VIEW_ACTIONS.QUICK_INSIGHTS
        }
        /*{
            label : "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_CLONE",
            value : SURVEY_VIEW_ACTIONS.CLONE
        },*/
        /*{
            label : "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_REPORT",
            value : SURVEY_VIEW_ACTIONS.REPORT
        }*/
    ],
    SCHEDULED: [
        {
            label: "DIC_SURVEY_SURVEY_NEW_SURVEY_EDIT",
            value: SURVEY_VIEW_ACTIONS.EDIT
        },
        /*{
            label : "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_REMOVE",
            value : SURVEY_VIEW_ACTIONS.REMOVE
        },*/
        /*{
            label : "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_CLONE",
            value : SURVEY_VIEW_ACTIONS.CLONE
        },*/
        {
            label: "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_DEACTIVATE",
            value: SURVEY_VIEW_ACTIONS.DEACTIVATE
        }
    ],
    COMPLETED: [
        /*{
            label : "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_CLONE",
            value : SURVEY_VIEW_ACTIONS.CLONE
        },*/
        /*{
            label : "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_REPORT",
            value : SURVEY_VIEW_ACTIONS.REPORT
        },*/
        {
            label: "DIC_SURVEY_SURVEY_NEW_SURVEY_QUICK_INSIGHT",
            value: SURVEY_VIEW_ACTIONS.QUICK_INSIGHTS
        }
    ]
};


const initialData = {
    currentSurveyStep: SURVEY_STEPS.NONE,
    currentSurveyStepTitle: "DIC_SURVEY_SURVEY_NEW_SURVEY",
    surveyFilters: surveyFilters,
    surveys: [],
    surveysMap: {},
    surveysMapCount: 0,
    totalSurveys: 0,
    listStartIndex: 0,
    currentPage: 0,

    loader: false,
    alertMessage: '',
    isSuccessMessage: false,
    isErrorMessage: false,

    surveyInfoSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyGetRecordsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyGetQuestionsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyGenerateWebUrlStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyInfoStatusUpdate: HTTP_REQUEST_STATUS.NOT_STARTED,
    getSurveyPaginatedListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    messageSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyUpdateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,

    surveySources: surveySources,
    surveySourcesDM: surveySourcesDM,
    sourceType: "",
    newSurveyStepperSteps: [],
    surveyInfoConfig: templateInfoConfig,
    surveyInfoConfigLanguageSelection: templateInfoConfigLanguageSelection,
    sourceTypes: SURVEY_CREATION_TYPES,
    currentSurvey: null,
    currentSurveyQuestions: [],
    isAddQuestion: false,
    surveyWebUrl: "",
    isOpeningMessageLayoutEnabled: false,
    isAddOpeningMessageDisabled: false,
    isOpeningMessageSaved: false,
    isClosingMessageLayoutEnabled: false,
    alertOpeningClosingMessage: "",
    showSurveySettingsPopup: false,
    randomize: false
};


const ReducerWoVoSurvey = (state = initialData, action) => {

    switch (action.type) {

        case SIGNOUT_USER_SUCCESS: {

            return {
                ...state,
                currentSurveyStep: SURVEY_STEPS.NONE,
                currentSurveyStepTitle: "DIC_SURVEY_SURVEY_NEW_SURVEY",
                surveyFilters: surveyFilters,
                surveys: [],
                surveysMap: {},
                surveysMapCount: 0,
                loader: false,
                alertMessage: '',
                isSuccessMessage: false,
                isErrorMessage: false,

                surveyInfoSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                surveyGetRecordsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                surveyGetQuestionsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                surveyGenerateWebUrlStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                surveyInfoStatusUpdate: HTTP_REQUEST_STATUS.NOT_STARTED,
                getSurveyPaginatedListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,

                surveySources: surveySources,
                surveySourcesDM: surveySourcesDM,
                sourceType: "",
                newSurveyStepperSteps: [],
                surveyInfoConfig: templateInfoConfig,
                surveyInfoConfigLanguageSelection: templateInfoConfigLanguageSelection,
                sourceTypes: SURVEY_CREATION_TYPES,
                currentSurvey: null,
                currentSurveyQuestions: [],
                isAddQuestion: false,
                surveyWebUrl: ""
            }
        }

        case SURVEY_INFO_UPDATE_STATUS: {
            return {
                ...state,
                surveyInfoStatusUpdate: HTTP_REQUEST_STATUS.IN_PROGRESS
            };
        }

        case SURVEY_INFO_UPDATE_STATUS_NONE: {
            return {
                ...state,
                surveyInfoStatusUpdate: HTTP_REQUEST_STATUS.NOT_STARTED
            };
        }

        case SURVEY_INFO_UPDATE_STATUS_SUCCESS: {

            let currentSurvey = AppUtils.updateCurrentSurvey(action.payload.data.data);

            let surveys = state.surveys;

            for (let i = 0; i < surveys.length; i++) {

                if (surveys[i].id === currentSurvey.id) {

                    if (currentSurvey['status'] === SURVEY_STATUSES_VALUE.DELETED) {
                        surveys.splice(i, 1);
                    } else {
                        surveys[i] = currentSurvey;
                    }

                    break;
                }
            }

            if (surveys.length > 0) {
                surveys = JSON.parse(JSON.stringify(surveys));

                for (let i = 0; i < surveys.length; i++) {
                    surveys[i] = AppUtils.updateCurrentSurvey(surveys[i]);
                }

            }

            let prevUSurvey = state.currentSurvey;

            //if ( prevUSurvey != null && prevUSurvey.id === currentSurvey.id ){
            prevUSurvey = currentSurvey;
            //}

            prevUSurvey = AppUtils.updateCurrentSurvey(prevUSurvey);


            return {
                ...state,
                currentSurvey: prevUSurvey,
                surveys: surveys,
                surveyInfoStatusUpdate: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message
            };
        }

        case SURVEY_NEW_SURVEY_SET_STEP: {

            return {
                ...state,
                currentSurveyStep: action.payload
            };
        }

        case SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS: {

            let currentSurvey = state.currentSurvey;

            if (action.payload.data && action.payload.data.data && action.payload.data.parent && action.payload.data.parent.questions) {
                currentSurvey = AppUtils.addSurveyQuestions(state.currentSurvey, action.payload.data.data, action.payload.data.parent.questions);
                currentSurvey = AppUtils.updateCurrentSurvey(currentSurvey);
            }

            return {
                ...state,
                currentSurvey: currentSurvey
            };
        }

        case SURVEY_SURVEY_QUESTION_DELETE_SUCCESS:
        case SURVEY_TEMPLATE_QUESTION_DELETE_SUCCESS: {

            let {currentSurvey} = state;

            if (action.payload.data && action.payload.data.data) {
                currentSurvey = AppUtils.deleteSurveyQuestion(currentSurvey, action.payload.data.data);
            }

            currentSurvey = AppUtils.updateCurrentSurvey(currentSurvey);

            return {
                ...state,
                currentSurvey: currentSurvey
            };
        }

        case SURVEY_SURVEY_QUESTION_DUPLICATE_SUCCESS:
        case SURVEY_TEMPLATE_QUESTION_DUPLICATE_SUCCESS: {

            let {currentSurvey} = state;

            if (action.payload.data && action.payload.data.data) {
                currentSurvey = action.payload.data.data;
                currentSurvey.questions = AppUtils.getSortedQuestions(currentSurvey.questions);
                currentSurvey = AppUtils.updateCurrentSurvey(currentSurvey);
            }

            return {
                ...state,
                currentSurvey: currentSurvey
            };

        }

        case SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_SUCCESS: {

            let currentSurvey = state.currentSurvey
            if (action.payload.data && action.payload.data.data && action.payload.data.data.survey) {
                currentSurvey = AppUtils.updateCurrentSurvey(action.payload.data.data.survey);
            }

            return {
                ...state,
                currentSurvey: currentSurvey,
                surveyGenerateWebUrlStatus: HTTP_REQUEST_STATUS.SUCCESS,
                surveyWebUrl: action.payload.data.data.url,
                alertMessage: action.payload.data.message,
            };
        }

        case SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_NONE:
            return {
                ...state,
                surveyGenerateWebUrlStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                surveyWebUrl: ""
            };

        case SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL:
            return {
                ...state,
                surveyGenerateWebUrlStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                surveyWebUrl: ""
            };

        case SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_ERROR: {
            return {
                ...state,
                surveyGenerateWebUrlStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload && action.payload.data && action.payload.data.error
            }
        }

        case SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_ENABLE:
            return {
                ...state,
                isAddQuestion: true
            };
        case SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_ADD_QUESTION_DISABLE:
            return {
                ...state,
                isAddQuestion: false
            };

        case SURVEY_GET_SURVEY_BY_ID_SUCCESS:

            let isOpeningMessageSaved = false;
            let isAddOpeningMessageDisabled = false;

            if (action.payload.data.data['opening_message'] !== null && typeof action.payload.data.data['opening_message'] !== "undefined") {
                isOpeningMessageSaved = true;
                isAddOpeningMessageDisabled = true;
            }

            return {
                ...state,
                currentSurvey: AppUtils.updateCurrentSurvey(action.payload.data.data),
                isOpeningMessageSaved: isOpeningMessageSaved,
                isOpeningMessageLayoutEnabled: false,
                isClosingMessageLayoutEnabled: false,
                isAddOpeningMessageDisabled: isAddOpeningMessageDisabled

            };

        case SURVEY_NEW_SURVEY_SET_SURVEY:
            return {
                ...state,
                currentSurvey: action.payload
            };

        case SURVEY_GET_NEXT_LIST:
            return {
                ...state,
                getSurveyPaginatedListStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }

        case SURVEY_GET_NEXT_LIST_NONE:
            return {
                ...state,
                getSurveyPaginatedListStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }

        case SURVEY_GET_NEXT_LIST_ERROR:
            return {
                ...state,
                getSurveyPaginatedListStatus: HTTP_REQUEST_STATUS.FAIL
            }

        case SURVEY_GET_NEXT_LIST_SUCCESS: {
            let surveysMap = state.surveysMap;
            let totalSurveys = state.totalSurveys;
            let listStartIndex = state.listStartIndex;
            let currentPage = state.currentPage;
            let surveysMapCount = state.surveysMapCount;
            let surveys = [];

            if (totalSurveys !== 0 && action.payload.data && action.payload.data.data) {

                let data = action.payload.data.data;

                surveysMap = AppUtils.getRedeployedSurveys(surveysMap, data.surveys);

                if (data.surveys){
                    surveysMapCount += data.surveys.length;
                }

                totalSurveys = data["total"];
                listStartIndex = listStartIndex + data["count"];
                currentPage = data["page"];
            }

            Object.keys(surveysMap).forEach(function (key) {
                surveys.push(surveysMap[key]);
            });


            return {
                ...state,
                getSurveyPaginatedListStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                surveysMap: surveysMap,
                surveysMapCount: surveysMapCount,
                alertMessage: action.payload.data.message,
                totalSurveys: totalSurveys,
                listStartIndex: listStartIndex,
                currentPage: currentPage,
                surveys: surveys
            }
        }

        case SURVEY_NEW_SURVEY_GET_ALL_RECORDS:

            return {
                ...state,
                surveyGetRecordsStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                surveys: [],
                surveysMap: {},
                surveysMapCount: 0,
                alertMessage: [],
                totalSurveys: 0,
                listStartIndex: 0,
                currentPage: 0
            }

        case SURVEY_NEW_SURVEY_GET_ALL_RECORDS_SUCCESS:

            let surveysMap = state.surveysMap;
            let totalSurveys = state.totalSurveys;
            let listStartIndex = state.listStartIndex;
            let currentPage = state.currentPage;
            let surveysMapCount = 0;
            let surveys = [];

            if (action.payload.data && action.payload.data.data) {
                let data = action.payload.data.data;

                surveysMap = AppUtils.getRedeployedSurveys(surveysMap, data.surveys);
                surveysMapCount = data.surveys.length;
                totalSurveys = data["total"];
                listStartIndex = listStartIndex + data["count"];
                currentPage = data["page"];
            }



            Object.keys(surveysMap).forEach(function (key) {
                surveys.push(surveysMap[key]);
            });

            return {
                ...state,
                surveyGetRecordsStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                surveys: surveys,
                surveysMap: surveysMap,
                surveysMapCount: surveysMapCount,
                alertMessage: action.payload.data.message,
                totalSurveys: totalSurveys,
                listStartIndex: listStartIndex,
                currentPage: currentPage
            };

        case SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_NONE:

            return {
                ...state,
                surveyInfoSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: "",
                loader: false
            };

        case SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_IN_PROGRESS:

            return {
                ...state,
                surveyInfoSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: "",
                loader: true
            };

        case SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_ERROR: {
            return {
                ...state,
                surveyInfoSaveStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload.data.error.message,
                loader: false
            }
        }

        case SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_SUCCESS:

            let currentSurvey = action.payload.data.data;
            currentSurvey.questions = AppUtils.getSortedQuestions(currentSurvey.questions);
            currentSurvey = AppUtils.updateCurrentSurvey(currentSurvey);

            return {
                ...state,
                surveyInfoSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                currentSurvey: currentSurvey,
                alertMessage: action.payload.data.message,
                loader: false,
                currentSurveyStep: SURVEY_STEPS.VIEW
            };


        case SURVEY_NEW_SURVEY_SET_SOURCE_TYPE:

            let stepsList = [];

            switch (action.payload) {
                case SURVEY_CREATION_TYPES['SCRATCH']: {
                    stepsList = stepsFromScratch;
                    break;
                }
                case SURVEY_CREATION_TYPES['TEMPLATE']: {
                    stepsList = stepsFromTemplate;
                    break;
                }
                case SURVEY_CREATION_TYPES['SURVEY']: {
                    stepsList = stepsFromSurvey;
                    break;
                }
                default: {
                    stepsList = stepsFromScratch;
                    break;
                }
            }

            return {
                ...state,
                sourceType: action.payload,
                newSurveyStepperSteps: stepsFromScratch,
                stepsList: stepsList
            };

        case SURVEY_NEW_CLOSING_MESSAGE_SAVE_IN_PROGRESS:

            return {
                ...state,
                messageSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertOpeningClosingMessage: "",
                loader: true
            };

        case SURVEY_NEW_CLOSING_MESSAGE_SAVE_SUCCESS:

            return {
                ...state,
                messageSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                currentSurvey: AppUtils.updateCurrentSurvey(action.payload.data.data),
                alertOpeningClosingMessage: action.payload.data.message,
                isClosingMessageLayoutEnabled: false
            };

        case SURVEY_NEW_CLOSING_MESSAGE_SAVE_ERROR:

            return {
                ...state,
                messageSaveStatus: HTTP_REQUEST_STATUS.FAIL,
                loader: false,
                alertOpeningClosingMessage: action.payload.data.error,
                isSuccessMessage: false,
                isErrorMessage: true,
            };

        case SURVEY_NEW_ENABLE_MESSAGE_LAYOUT: {

            let isOpeningMessageSaved = false;
            let isAddOpeningMessageDisabled = true;

            if (action.payload === SURVEY_USER_ACTIONS.MESSAGE_CANCEL) {
                if (state.currentSurvey['opening_message'] !== null && typeof state.currentSurvey['opening_message'] !== "undefined") {
                    isOpeningMessageSaved = true;
                } else {
                    isAddOpeningMessageDisabled = false;
                }
            }

            return {
                ...state,
                isOpeningMessageLayoutEnabled: !state.isOpeningMessageLayoutEnabled,
                isOpeningMessageSaved: isOpeningMessageSaved,
                isAddOpeningMessageDisabled: isAddOpeningMessageDisabled
            };

        }

        case SURVEY_NEW_ENABLE_CLOSE_MESSAGE_LAYOUT:

            return {
                ...state,
                isClosingMessageLayoutEnabled: !state.isClosingMessageLayoutEnabled
            };

        case SURVEY_NEW_DISABLE_ADD_OPENING_MESSAGE:

            return {
                ...state,
                isAddOpeningMessageDisabled: true
            };

        case SURVEY_NEW_OPENING_MESSAGE_SAVE_IN_PROGRESS:

            return {
                ...state,
                messageSaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertOpeningClosingMessage: "",
                loader: true
            };

        case SURVEY_NEW_OPENING_MESSAGE_SAVE_SUCCESS:

            return {
                ...state,
                messageSaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertOpeningClosingMessage: action.payload.data.message,
                currentSurvey: AppUtils.updateCurrentSurvey(action.payload.data.data),
                isOpeningMessageSaved: true,
                isOpeningMessageLayoutEnabled: false,
                isAddOpeningMessageDisabled: true
            };

        case SURVEY_NEW_OPENING_MESSAGE_SAVE_ERROR:

            return {
                ...state,
                messageSaveStatus: HTTP_REQUEST_STATUS.FAIL,
                loader: false,
                alertOpeningClosingMessage: action.payload.data.error,
                isSuccessMessage: false,
                isErrorMessage: true,
            };

        case SURVEY_NEW_OPENING_MESSAGE_SAVE_STATUS_NONE: {
            return {
                ...state,
                messageSaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };
        }

        case SURVEY_NEW_SURVEY_SETTINGS_DIALOG_TOGGLE:{
            return {
                ...state,
                showSurveySettingsPopup: false
            }
        }        

        case SURVEY_QUESTION_RANDOMIZE_SUCCESS: {

            let randomize = state.randomize;

            if (typeof action.payload !== 'undefined' &&
                typeof action.payload.data !== 'undefined' &&
                typeof action.payload.data.data !== 'undefined') {
                randomize = action.payload.data.data['is_randomize'];
            }

            return {
                ...state,
                alertMessage: action.payload.data.message,
                randomize: randomize,
                surveyUpdateStatus: HTTP_REQUEST_STATUS.SUCCESS
            };
        }

        case SURVEY_QUESTION_RANDOMIZE_ERROR: {
            return {
                ...state,
                alertMessage: action.payload.data.error,
                surveyUpdateStatus: HTTP_REQUEST_STATUS.FAIL,
                randomize: false                
            };
        }

        case SURVEY_QUESTION_RANDOMIZE_IN_PROGRESS: {
            return {
                ...state,
                surveyUpdateStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            };
        }

        case SURVEY_QUESTION_RANDOMIZE_NONE: {
            return {
                ...state,
                surveyUpdateStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };
        }

        case SURVEY_UPDATE_RANDOMIZE_OPTION: {
            return {
                ...state,
                randomize: action.payload
            };
        }

        default:
            return state;
    }

};

export default ReducerWoVoSurvey;
