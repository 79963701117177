import {
    CHANGE_DIRECTION,
    CHANGE_NAVIGATION_STYLE,
    HORIZONTAL_MENU_POSITION,
    INSIDE_THE_HEADER,
    SWITCH_LANGUAGE,
    TOGGLE_COLLAPSED_NAV,
    VERTICAL_NAVIGATION,
    WINDOW_WIDTH,
    THEME_COLOR,
    COLLAPSED_DRAWER,
    MINI_DRAWER,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SET_USER,
    TRANSLATION_FONT_UPDATE_PREFERRED_FONT,
    TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS_SUCCESS,
    GET_TIMEZONE_LIST_SUCCESS
} from '../constants/ActionTypes';
import {BLUE} from 'constants/ThemeColors';
import {
    TRANSLATION_STRINGS_TRANSLATIONS_LIST_SUCCESS
} from "../constants/actions/adminSettings/translations/strings/actionTranslationStrings";

import AppLocale from "../lngProvider";
import {addLocaleData} from "react-intl";
import {DEFAULT_LANGUAGE_CODE} from "../constants/config";
import FontUtils from "../util/fontUtils";
import AppUtils from "../util/util";


const rltLocale = ['ar'];
const initialSettings = {
    navCollapsed: false,
    drawerType: MINI_DRAWER,
    themeColor: BLUE,
    darkTheme: false,
    width: window.innerWidth,
    isDirectionRTL: false,
    navigationStyle: VERTICAL_NAVIGATION,
    horizontalNavPosition: INSIDE_THE_HEADER,
    viewLanguage: DEFAULT_LANGUAGE_CODE,
    preferredFont: {},
    remoteTranslations: {},
    isDefaultTopBar : false,
    locale: {
        languageId: 'english',
        locale: 'en-US',
        name: 'English',
        icon: 'us'
    },
    timeZoneList: []
};

const settings = (state = initialSettings, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {
                ...state,
                navCollapsed: false
            };
        case TOGGLE_COLLAPSED_NAV:
            return {
                ...state,
                navCollapsed: action.isNavCollapsed
            };
        case WINDOW_WIDTH:
            return {
                ...state,
                width: action.width
            };
        case SWITCH_LANGUAGE: {
            let locale = state.locale;
            let viewLanguage = action.payload;

            try {
                let languageCode = action.payload;

                if (languageCode.indexOf("-") !== -1) {
                    let tempArr = languageCode.split("-");
                    languageCode = tempArr[0];
                }

                let isTranslationsUpdated = applyServerLocalisations(state.remoteTranslations, viewLanguage.toLowerCase(), languageCode);

                if (isTranslationsUpdated) {

                    //FontUtils.updateFontBasedOnLanguage(languageCode);
                    locale = {
                        languageId: languageCode,
                        locale: languageCode,
                        name: languageCode,
                        icon: 'us'
                    }
                }

            } catch (ex) {
                console.error("SWITCH_LANGUAGE : error : ", ex);
            }
    
            AppUtils.setCurrentTranslations(state.remoteTranslations, viewLanguage);

            return {
                ...state,
                locale: locale,
                isDirectionRTL: rltLocale.includes(viewLanguage),
                viewLanguage: viewLanguage
            };

        }

        case SIGNOUT_USER_SUCCESS: {

            let viewLanguage = DEFAULT_LANGUAGE_CODE;

            let currentLocale = {
                languageId: viewLanguage,
                locale: viewLanguage,
                name: viewLanguage,
                icon: 'us'
            };

            return {
                ...state,
                locale: currentLocale,
                isDirectionRTL: rltLocale.includes(viewLanguage),
                viewLanguage: viewLanguage
            };
        }

        case CHANGE_DIRECTION:
            return {
                ...state,
                isDirectionRTL: !state.isDirectionRTL

            };

        case CHANGE_NAVIGATION_STYLE:
            return {
                ...state,
                navigationStyle: action.payload
            };


        case HORIZONTAL_MENU_POSITION:
            return {
                ...state,
                horizontalNavPosition: action.payload
            };

        case THEME_COLOR:
            return {
                ...state,
                darkTheme: false,
                themeColor: action.color
            };

        case TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS_SUCCESS:
        case TRANSLATION_STRINGS_TRANSLATIONS_LIST_SUCCESS: {
            //applyServerLocalisations(action.payload.data.data);
            return {
                ...state,
                remoteTranslations: action.payload.data.data
            };
        }

        case GET_TIMEZONE_LIST_SUCCESS: {
            return {
                ...state,
                timeZoneList: action.payload
            }
        }

        case SET_USER: {

            removeDarkTheme();
            let locale = state.locale;
            AppUtils.setCurrentTranslations(state.remoteTranslations, locale.locale);
            
            return {
                ...state,
                locale: locale
            };
        }

        case SIGNIN_USER_SUCCESS:
            
            let locale = state.locale;

            let viewLanguage = DEFAULT_LANGUAGE_CODE;

            let preferredFont = {};

            try{
                preferredFont = ( typeof action.payload.user.preferred_font != "undefined" && action.payload.user.preferred_font.id !== "" ) ? action.payload.user.preferred_font : {};

                if ( typeof preferredFont.id != "undefined" ){
                    FontUtils.updateUserPreferredFont(preferredFont);
                }

            } catch ( ex ) {
            }

            try {


                let language = ( typeof action.payload.user.language == "undefined" || action.payload.user.language === "" ) ? viewLanguage : action.payload.user.language;

                //FontUtils.updateFontBasedOnLanguage(action.payload.user.language);

                if ( typeof state.remoteTranslations != "undefined" &&  typeof state.remoteTranslations[language] != "undefined"){
                    let result = applyLocalisation(locale, language, state.remoteTranslations , viewLanguage, state );
                    viewLanguage = result['viewLanguage'];
                    locale = result['locale'];
                }

            } catch (ex) {
                console.error("SIGNIN_USER_SUCCESS : error : ", ex);
            }

            removeDarkTheme();
            AppUtils.setCurrentTranslations(state.remoteTranslations, locale.locale);

            return {
                ...state,
                locale: locale,
                viewLanguage: viewLanguage,
                isDirectionRTL: rltLocale.includes(viewLanguage),
                preferredFont : preferredFont
            };
        case TRANSLATION_FONT_UPDATE_PREFERRED_FONT:{
            return {
                ...state,
                preferredFont : action.payload
            }
        }


        default:
            return state;
    }
};

const applyLocalisation = (locale, userLanguageCode,  remoteTranslations, viewLanguage, state) => {

    let result = {
        locale: locale,
        viewLanguage: userLanguageCode

    };

    if (typeof remoteTranslations[DEFAULT_LANGUAGE_CODE] == "undefined") {
        return result;
    }

    try {
        if ( typeof remoteTranslations != "undefined" && typeof remoteTranslations[userLanguageCode] == "undefined"){
            userLanguageCode = DEFAULT_LANGUAGE_CODE;
        }

        let languageCode = userLanguageCode;

        if (languageCode.indexOf("-") !== -1) {
            let tempArr = languageCode.split("-");
            languageCode = tempArr[0];
        } else if (languageCode.indexOf("-") !== -1) {
            let tempArr = languageCode.split("-");
            languageCode = tempArr[0];
        }

        let remoteTranslations = state.remoteTranslations;

        if ( typeof remoteTranslations[languageCode] != "undefined" || typeof remoteTranslations[userLanguageCode] != "undefined" ){
            applyServerLocalisations(remoteTranslations, userLanguageCode.toLowerCase(), languageCode);
        }


        let locale = {
            languageId: languageCode,
            locale: languageCode,
            name: languageCode,
            icon: 'us'
        };

        result['locale'] = locale;


    } catch (ex) {
        console.error("applyLocalisation : ERROR : ", ex);
    }

    return result;
};


const removeDarkTheme = () => {
    try {
        const body = document.body.classList;
        body.remove('dark-theme');
        body.add(BLUE);
    } catch (ex) {
    }
};

const applyServerLocalisations = (data, languageCode, viewLanguage) => {

    let isTranslationsUpdated = false;

    try {

        for (let item in data) {
            let currentCode = item.toLowerCase();

            if (languageCode != currentCode) {
                continue;
            }

            try {

                let appLocaleData = require('react-intl/locale-data/' + viewLanguage);
                let messages = data[item].data;

                AppLocale[viewLanguage] = {
                    messages: {
                        ...messages
                    },
                    locale: viewLanguage,
                    data: appLocaleData
                };
                addLocaleData(AppLocale[viewLanguage].data);
                isTranslationsUpdated = true;

            } catch (ex) {
                console.log("applyServerLocalisations : Inner error : " + ex)
            }
        }

    } catch (ex) {
        console.log("Settings : applyServerLocalisations : ERROR : " + ex);
    }

    return isTranslationsUpdated;
};


export default settings;
