import React, {Component} from 'react';
import moment from 'moment';
import {DatePicker} from 'material-ui-pickers';

export default class DatePickers extends Component {
    /*state = {
        selectedDate: moment(),
    };*/

    handleDateChange = (date) => {
        this.setState({selectedDate: date});

        if (this.props.onChange) {
            this.props.onChange(date)
        }

    };

    render() {
        const {defaultValue, minDate, placeholder, variant} = this.props;
        let tempDate = null;
        let minDateVal = "";

        try {
            if (defaultValue.indexOf("-") != -1) {
                let from = defaultValue.split("-")
                tempDate = new Date(from[2], from[1] - 1, from[0]);
            }

            if ( minDate && minDate.indexOf("-") != -1) {
                let from = minDate.split("-")
                minDateVal = new Date(from[2], from[1] - 1, from[0]);
            }


        } catch (ex) {

        }


        return (

            minDate ? (
                <div key="basic_day" className="picker">
                    <DatePicker
                        fullWidth
                        value={tempDate}
                        format="DD-MM-YYYY"
                        minDate={minDateVal}
                        onChange={this.handleDateChange}
                        animateYearScrolling={false}
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
                        placeholder={placeholder}
                        variant={variant}
                        
                    />
                </div>
            ) : (
                <div key="basic_day" className="picker">
                    <DatePicker
                        fullWidth
                        value={tempDate}
                        format="DD-MM-YYYY"
                        onChange={this.handleDateChange}
                        animateYearScrolling={false}
                        leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                        rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
                        placeholder={placeholder}
                        variant={variant}
                    />
                </div>
            )


        )

    }
}
