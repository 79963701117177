// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const SET_USER = 'SET_USER';
export const AUTH_UPDATE_MENU_CONFIGURATION = 'AUTH_UPDATE_MENU_CONFIGURATION';
export const SAVE_SEARCH_PARAMS = 'SAVE_SEARCH_PARAMS';
export const CLEAR_SEARCH_PARAMS = 'CLEAR_SEARCH_PARAMS';
export const UPDATE_LOCAL_TIMEZONE_SELECTION = 'UPDATE_LOCAL_TIMEZONE_SELECTION';

// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const


export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';


/**** Client ****/
export const CLIENT_FETCH_NEW_DATA = 'client_fetch_new_data';
export const CLIENT_SAVE = 'client_save';
export const CLIENT_GET_ALL_CLIENTS = 'CLIENT_GET_ALL_CLIENTS';
export const CLIENT_GET_ALL_CLIENTS_SUCCESS = 'CLIENT_GET_ALL_CLIENTS_SUCCESS';
export const CLIENT_SELECTED_CLIENT = 'CLIENT_SELECTED_CLIENT';
export const CLIENT_UPDATE_CLIENT_IN_LIST = 'CLIENT_UPDATE_CLIENT_IN_LIST';
export const CLIENT_GET_BY_STATUS = 'CLIENT_GET_BY_STATUS';


/**** E Learning Reports ****/

export const E_LEARNING_REPORTS_SET_CHILD_CLIENTS = 'E_LEARNING_REPORTS_SET_CHILD_CLIENTS';
export const E_LEARNING_REPORTS_PROGRESS_START = 'E_LEARNING_REPORTS_PROGRESS_START';
export const E_LEARNING_REPORTS_PROGRESS_END = 'E_LEARNING_REPORTS_PROGRESS_END';

export const E_LEARNING_REPORTS_ENROLMENT_GENERAL = 'E_LEARNING_REPORTS_ENROLMENT_GENERAL';
export const E_LEARNING_REPORTS_ENROLMENT_GENERAL_SUCCESS = 'E_LEARNING_REPORTS_ENROLMENT_GENERAL_SUCCESS';
export const E_LEARNING_REPORTS_ENROLMENT_TOPICS = 'E_LEARNING_REPORTS_ENROLMENT_TOPICS';
export const E_LEARNING_REPORTS_ENROLMENT_TOPICS_SUCCESS = 'E_LEARNING_REPORTS_ENROLMENT_TOPICS_SUCCESS';
export const E_LEARNING_REPORTS_ENROLMENT_PROPORTION = 'E_LEARNING_REPORTS_ENROLMENT_PROPORTION';
export const E_LEARNING_REPORTS_ENROLMENT_PROPORTION_SUCCESS = 'E_LEARNING_REPORTS_ENROLMENT_PROPORTION_SUCCESS';
export const E_LEARNING_REPORTS_ENROLMENT_STATUSES = 'E_LEARNING_REPORTS_ENROLMENT_STATUSES';
export const E_LEARNING_REPORTS_ENROLMENT_STATUSES_SUCCESS = 'E_LEARNING_REPORTS_ENROLMENT_STATUSES_SUCCESS';
export const E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN = 'E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN';
export const E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN_SUCCESS = 'E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN_SUCCESS';
export const E_LEARNING_REPORTS_ENROLMENT_COURSES = 'E_LEARNING_REPORTS_ENROLMENT_COURSES';
export const E_LEARNING_REPORTS_ENROLMENT_COURSES_SUCCESS = 'E_LEARNING_REPORTS_ENROLMENT_COURSES_SUCCESS';

export const E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO = 'E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO';
export const E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_SUCCESS = 'E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_SUCCESS';
export const E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE = 'E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE';
export const E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE_SUCCESS = 'E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE_SUCCESS';
export const E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_POPULARITY = 'E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_POPULARITY';
export const E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_POPULARITY_SUCCESS = 'E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_POPULARITY_SUCCESS';
export const E_LEARNING_REPORTS_USER_ACTIVITY_DIFFICULTY = 'E_LEARNING_REPORTS_USER_ACTIVITY_DIFFICULTY';
export const E_LEARNING_REPORTS_USER_ACTIVITY_DIFFICULTY_SUCCESS = 'E_LEARNING_REPORTS_USER_ACTIVITY_DIFFICULTY_SUCCESS';
export const E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_UA_DRILLING_INCREMENT = 'E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_UA_DRILLING_INCREMENT';
export const E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_UA_DRILLING_DECREMENT = 'E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_UA_DRILLING_DECREMENT';
export const E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_DISTRIBUTION_UA_DRILLING_INCREMENT = 'E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_DISTRIBUTION_UA_DRILLING_INCREMENT';
export const E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_DISTRIBUTION_UA_DRILLING_DECREMENT = 'E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_DISTRIBUTION_UA_DRILLING_DECREMENT';
export const E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_DIFFICULTY_UA_DRILLING_INCREMENT = 'E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_DIFFICULTY_UA_DRILLING_INCREMENT';
export const E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_DIFFICULTY_UA_DRILLING_DECREMENT = 'E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_DIFFICULTY_UA_DRILLING_DECREMENT';

export const E_LEARNING_REPORTS_COMPARISION_RATIO_COURSES = 'E_LEARNING_REPORTS_COMPARISION_RATIO_COURSES';
export const E_LEARNING_REPORTS_COMPARISION_RATIO_COURSES_SUCCESS = 'E_LEARNING_REPORTS_COMPARISION_RATIO_COURSES_SUCCESS';
export const E_LEARNING_REPORTS_COMPARISION_RATIO_USERS = 'E_LEARNING_REPORTS_COMPARISION_RATIO_USERS';
export const E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_SUCCESS = 'E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_SUCCESS';
export const E_LEARNING_REPORTS_COMPARISION_USERS_SELECTED_SUMMARISED = 'E_LEARNING_REPORTS_COMPARISION_USERS_SELECTED_SUMMARISED';
export const E_LEARNING_REPORTS_COMPARISION_USERS_SELECTED_SUMMARISED_RESSET = 'E_LEARNING_REPORTS_COMPARISION_USERS_SELECTED_SUMMARISED_RESSET';

export const E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_UUID_DATA = 'E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_UUID_DATA';
export const E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_UUID_DATA_SUCCESS = 'E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_UUID_DATA_SUCCESS';

export const E_LEARNING_REPORTS_COMPARISION_OVERTIME = 'E_LEARNING_REPORTS_COMPARISION_OVERTIME';
export const E_LEARNING_REPORTS_COMPARISION_OVERTIME_SUCCESS = 'E_LEARNING_REPORTS_COMPARISION_OVERTIME_SUCCESS';
export const E_LEARNING_REPORTS_COMPARISION_GENERAL = 'E_LEARNING_REPORTS_COMPARISION_GENERAL';
export const E_LEARNING_REPORTS_COMPARISION_GENERAL_SUCCESS = 'E_LEARNING_REPORTS_COMPARISION_GENERAL_SUCCESS';
export const E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE = 'E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE';
export const E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_SUCCESS = 'E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_SUCCESS';
export const E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE = 'E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE';
export const E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_SUCCESS = 'E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_SUCCESS';


export const E_LEARNING_REPORTS_COMPARISION_GENERAL_UA_DRILLING_INCREMENT = 'E_LEARNING_REPORTS_COMPARISION_GENERAL_UA_DRILLING_INCREMENT';
export const E_LEARNING_REPORTS_COMPARISION_GENERAL_UA_DRILLING_DECREMENT = 'E_LEARNING_REPORTS_COMPARISION_GENERAL_UA_DRILLING_DECREMENT';
export const E_LEARNING_REPORTS_COMPARISION_OVERTIME_UA_DRILLING_INCREMENT = 'E_LEARNING_REPORTS_COMPARISION_OVERTIME_UA_DRILLING_INCREMENT';
export const E_LEARNING_REPORTS_COMPARISION_OVERTIME_UA_DRILLING_DECREMENT = 'E_LEARNING_REPORTS_COMPARISION_OVERTIME_UA_DRILLING_DECREMENT';
export const E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_UA_DRILLING_INCREMENT = 'E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_UA_DRILLING_INCREMENT';
export const E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_UA_DRILLING_DECREMENT = 'E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_UA_DRILLING_DECREMENT';
export const E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_UA_DRILLING_INCREMENT = 'E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_UA_DRILLING_INCREMENT';
export const E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_UA_DRILLING_DECREMENT = 'E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_UA_DRILLING_DECREMENT';

export const E_LEARNING_REPORTS_COMPARISION_RATIO_UA_DRILLING_INCREMENT = 'E_LEARNING_REPORTS_COMPARISION_RATIO_UA_DRILLING_INCREMENT';
export const E_LEARNING_REPORTS_COMPARISION_RATIO_UA_DRILLING_DECREMENT = 'E_LEARNING_REPORTS_COMPARISION_RATIO_UA_DRILLING_DECREMENT';


/**** Survey Category ****/
export const SURVEY_CATEGORY_SHOW_LOADER = 'SURVEY_CATEGORY_SHOW_LOADER';
export const SURVEY_CATEGORY_HIDE_LOADER = 'SURVEY_CATEGORY_HIDE_LOADER';
export const SURVEY_CATEGORY_DISPLAY_NEW_EDIT_POPUP = 'SURVEY_CATEGORY_DISPLAY_NEW_EDIT_POPUP';
export const SURVEY_CATEGORY_HIDE_NEW_EDIT_POPUP = 'SURVEY_CATEGORY_HIDE_NEW_EDIT_POPUP';
export const SURVEY_CATEGORY_NEW_EDIT_SAVE = 'SURVEY_CATEGORY_NEW_EDIT_SAVE';
export const SURVEY_CATEGORY_NEW_EDIT_SAVE_SUCCESS = 'SURVEY_CATEGORY_NEW_EDIT_SAVE_SUCCESS';
export const SURVEY_CATEGORY_NEW_EDIT_SAVE_ERROR = 'SURVEY_CATEGORY_NEW_EDIT_SAVE_ERROR';
export const SURVEY_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS = 'SURVEY_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS';
export const SURVEY_CATEGORY_NEW_EDIT_SHOW_ERROR = 'SURVEY_CATEGORY_NEW_EDIT_SHOW_ERROR';
export const SURVEY_CATEGORY_NEW_EDIT_HIDE_ERROR = 'SURVEY_CATEGORY_NEW_EDIT_HIDE_ERROR';
export const SURVEY_CATEGORY_NEW_EDIT_RESET_ERROR = 'SURVEY_CATEGORY_NEW_EDIT_RESET_ERROR';
export const SURVEY_CATEGORY_GET_ALL_RECORDS = 'SURVEY_CATEGORY_GET_ALL_RECORDS';
export const SURVEY_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS = 'SURVEY_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS';
export const SURVEY_CATEGORY_GET_ALL_RECORDS_SUCCESS = 'SURVEY_CATEGORY_GET_ALL_RECORDS_SUCCESS';
export const SURVEY_CATEGORY_GET_ALL_RECORDS_ERROR = 'SURVEY_CATEGORY_GET_ALL_RECORDS_ERROR';
export const SURVEY_CATEGORY_UPDATE_STATUS = 'SURVEY_CATEGORY_UPDATE_STATUS';
export const SURVEY_CATEGORY_UPDATE_STATUS_IN_PROGRESS = 'SURVEY_CATEGORY_UPDATE_STATUS_IN_PROGRESS';
export const SURVEY_CATEGORY_UPDATE_STATUS_NONE = 'SURVEY_CATEGORY_UPDATE_STATUS_NONE';
export const SURVEY_CATEGORY_UPDATE_STATUS_SUCCESS = 'SURVEY_CATEGORY_UPDATE_STATUS_SUCCESS';
export const SURVEY_CATEGORY_UPDATE_STATUS_ERROR = 'SURVEY_CATEGORY_UPDATE_STATUS_ERROR';

/**** Survey Reporting Category ****/
export const SURVEY_REPORTING_CATEGORY_SHOW_LOADER = 'SURVEY_REPORTING_CATEGORY_SHOW_LOADER';
export const SURVEY_REPORTING_CATEGORY_HIDE_LOADER = 'SURVEY_REPORTING_CATEGORY_HIDE_LOADER';
export const SURVEY_REPORTING_CATEGORY_DISPLAY_NEW_EDIT_POPUP = 'SURVEY_REPORTING_CATEGORY_DISPLAY_NEW_EDIT_POPUP';
export const SURVEY_REPORTING_CATEGORY_HIDE_NEW_EDIT_POPUP = 'SURVEY_REPORTING_CATEGORY_HIDE_NEW_EDIT_POPUP';
export const SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE = 'SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE';
export const SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_SUCCESS = 'SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_SUCCESS';
export const SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_ERROR = 'SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_ERROR';
export const SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS = 'SURVEY_REPORTING_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS';
export const SURVEY_REPORTING_CATEGORY_NEW_EDIT_SHOW_ERROR = 'SURVEY_REPORTING_CATEGORY_NEW_EDIT_SHOW_ERROR';
export const SURVEY_REPORTING_CATEGORY_NEW_EDIT_HIDE_ERROR = 'SURVEY_REPORTING_CATEGORY_NEW_EDIT_HIDE_ERROR';
export const SURVEY_REPORTING_CATEGORY_NEW_EDIT_RESET_ERROR = 'SURVEY_REPORTING_CATEGORY_NEW_EDIT_RESET_ERROR';
export const SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS = 'SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS';
export const SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS = 'SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS';
export const SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_SUCCESS = 'SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_SUCCESS';
export const SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_ERROR = 'SURVEY_REPORTING_CATEGORY_GET_ALL_RECORDS_ERROR';
export const SURVEY_REPORTING_CATEGORY_UPDATE_STATUS = 'SURVEY_REPORTING_CATEGORY_UPDATE_STATUS';
export const SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_IN_PROGRESS = 'SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_IN_PROGRESS';
export const SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_NONE = 'SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_NONE';
export const SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_SUCCESS = 'SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_SUCCESS';
export const SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_ERROR = 'SURVEY_REPORTING_CATEGORY_UPDATE_STATUS_ERROR';
export const SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS = 'SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS';
export const SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS_SUCCESS = 'SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS_SUCCESS';
export const SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS_BY_LANGUAGE = 'SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS_BY_LANGUAGE';
export const SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS_NONE = 'SURVEY_REPORTING_CATEGORY_GET_ALL_ACTIVE_RECORDS_NONE';

/**** Survey : question types ****/
export const SURVEY_QUESTION_TYPE_SHOW_LOADER = 'SURVEY_QUESTION_TYPE_SHOW_LOADER';
export const SURVEY_QUESTION_TYPE_HIDE_LOADER = 'SURVEY_QUESTION_TYPE_HIDE_LOADER';
export const SURVEY_QUESTION_TYPE_DISPLAY_NEW_EDIT_POPUP = 'SURVEY_QUESTION_TYPE_DISPLAY_NEW_EDIT_POPUP';
export const SURVEY_QUESTION_TYPE_HIDE_NEW_EDIT_POPUP = 'SURVEY_QUESTION_TYPE_HIDE_NEW_EDIT_POPUP';
export const SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE = 'SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE';
export const SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_SUCCESS = 'SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_SUCCESS';
export const SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_ERROR = 'SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_ERROR';
export const SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_IN_PROGRESS = 'SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_IN_PROGRESS';
export const SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_NONE = 'SURVEY_QUESTION_TYPE_NEW_EDIT_SAVE_NONE';

export const SURVEY_QUESTION_TYPE_NEW_EDIT_SHOW_ERROR = 'SURVEY_QUESTION_TYPE_NEW_EDIT_SHOW_ERROR';
export const SURVEY_QUESTION_TYPE_NEW_EDIT_HIDE_ERROR = 'SURVEY_QUESTION_TYPE_NEW_EDIT_HIDE_ERROR';
export const SURVEY_QUESTION_TYPE_NEW_EDIT_RESET_ERROR = 'SURVEY_QUESTION_TYPE_NEW_EDIT_RESET_ERROR';

export const SURVEY_QUESTION_TYPE_GET_ALL_RECORDS = 'SURVEY_QUESTION_TYPE_GET_ALL_RECORDS';
export const SURVEY_QUESTION_TYPE_GET_ALL_RECORDS_IN_PROGRESS = 'SURVEY_QUESTION_TYPE_GET_ALL_RECORDS_IN_PROGRESS';
export const SURVEY_QUESTION_TYPE_GET_ALL_RECORDS_SUCCESS = 'SURVEY_QUESTION_TYPE_GET_ALL_RECORDS_SUCCESS';
export const SURVEY_QUESTION_TYPE_GET_ALL_RECORDS_ERROR = 'SURVEY_QUESTION_TYPE_GET_ALL_RECORDS_ERROR';

export const SURVEY_QUESTION_TYPE_UPDATE_STATUS = 'SURVEY_QUESTION_TYPE_UPDATE_STATUS';
export const SURVEY_QUESTION_TYPE_UPDATE_STATUS_IN_PROGRESS = 'SURVEY_QUESTION_TYPE_UPDATE_STATUS_IN_PROGRESS';
export const SURVEY_QUESTION_TYPE_UPDATE_STATUS_NONE = 'SURVEY_QUESTION_TYPE_UPDATE_STATUS_NONE';
export const SURVEY_QUESTION_TYPE_UPDATE_STATUS_SUCCESS = 'SURVEY_QUESTION_TYPE_UPDATE_STATUS_SUCCESS';
export const SURVEY_QUESTION_TYPE_UPDATE_STATUS_ERROR = 'SURVEY_CATEGORY_UPDATE_STATUS_ERROR';

/**** Survey : question bank ****/

export const SURVEY_QUESTION_BANK_NEW_EDIT_SAVE = 'SURVEY_QUESTION_BANK_NEW_EDIT_SAVE';
export const SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_SUCCESS = 'SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_SUCCESS';
export const SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_ERROR = 'SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_ERROR';
export const SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_NONE = 'SURVEY_QUESTION_BANK_NEW_EDIT_SAVE_NONE';

export const SURVEY_QUESTION_BANK_GET_ALL_RECORDS = 'SURVEY_QUESTION_BANK_GET_ALL_RECORDS';
export const SURVEY_QUESTION_BANK_GET_ALL_RECORDS_SUCCESS = 'SURVEY_QUESTION_BANK_GET_ALL_RECORDS_SUCCESS';
export const SURVEY_QUESTION_BANK_GET_ALL_ACTIVE_RECORDS_SUCCESS = 'SURVEY_QUESTION_BANK_GET_ALL_ACTIVE_RECORDS_SUCCESS';
export const SURVEY_QUESTION_BANK_GET_ALL_RECORDS_ERROR = 'SURVEY_QUESTION_BANK_GET_ALL_RECORDS_ERROR';

export const SURVEY_QUESTION_BANK_UPDATE_STATUS = 'SURVEY_QUESTION_BANK_UPDATE_STATUS';
export const SURVEY_QUESTION_BANK_UPDATE_STATUS_NONE = 'SURVEY_QUESTION_BANK_UPDATE_STATUS_NONE';
export const SURVEY_QUESTION_BANK_UPDATE_STATUS_SUCCESS = 'SURVEY_QUESTION_BANK_UPDATE_STATUS_SUCCESS';
export const SURVEY_QUESTION_BANK_UPDATE_STATUS_ERROR = 'SURVEY_CATEGORY_UPDATE_STATUS_ERROR';

export const SURVEY_QUESTION_BANK_UPLOAD = 'SURVEY_QUESTION_BANK_UPLOAD';
export const SURVEY_QUESTION_BANK_UPLOAD_SAVE_SUCCESS = 'SURVEY_QUESTION_BANK_UPLOAD_SAVE_SUCCESS';
export const SURVEY_QUESTION_BANK_UPLOAD_SAVE_ERROR = 'SURVEY_QUESTION_BANK_UPLOAD_SAVE_ERROR';
export const SURVEY_QUESTION_BANK_UPLOAD_SAVE_NONE = "SURVEY_QUESTION_BANK_UPLOAD_SAVE_NONE";

export const SURVEY_QUESTION_BANK_SET_STEP = 'SURVEY_QUESTION_BANK_SET_STEP';
export const SURVEY_QUESTION_BANK_SET_NEW_EDIT_ENABLED = 'SURVEY_QUESTION_BANK_SET_NEW_EDIT_ENABLED';
export const SURVEY_QUESTION_BANK_SET_NEW_EDIT_DISABLED = 'SURVEY_QUESTION_BANK_SET_NEW_EDIT_DISABLED';

/**** Survey : templates ****/
export const SURVEY_NEW_TEMPLATE_SHOW_LOADER = 'SURVEY_NEW_TEMPLATE_SHOW_LOADER';
export const SURVEY_NEW_TEMPLATE_HIDE_LOADER = 'SURVEY_NEW_TEMPLATE_HIDE_LOADER';

export const SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO = 'SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO';
export const SURVEY_NEW_TEMPLATE_NEW_EDIT_UPDATE_INFO = 'SURVEY_NEW_TEMPLATE_NEW_EDIT_UPDATE_INFO';
export const SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_SUCCESS = 'SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_SUCCESS';
export const SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_ERROR = 'SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_ERROR';
export const SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_IN_PROGRESS = 'SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_IN_PROGRESS';
export const SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_NONE = 'SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_NONE';

export const SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS = 'SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS';
export const SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS_IN_PROGRESS = 'SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS_IN_PROGRESS';
export const SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS_SUCCESS = 'SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS_SUCCESS';
export const SURVEY_NEW_TEMPLATE_GET_ALL_RECORDS_ERROR = 'SURVEY_QUESTION_TYPE_GET_ALL_RECORDS_ERROR';

export const SURVEY_TEMPLATE_GET_QUESTIONS = 'SURVEY_TEMPLATE_GET_QUESTIONS';
export const SURVEY_TEMPLATE_GET_QUESTIONS_IN_PROGRESS = 'SURVEY_TEMPLATE_GET_QUESTIONS_IN_PROGRESS';
export const SURVEY_TEMPLATE_GET_QUESTIONS_NONE = 'SURVEY_TEMPLATE_GET_QUESTIONS_NONE';
export const SURVEY_TEMPLATE_GET_QUESTIONS_SUCCESS = 'SURVEY_TEMPLATE_GET_QUESTIONS_SUCCESS';
export const SURVEY_TEMPLATE_GET_QUESTIONS_ERROR = 'SURVEY_TEMPLATE_GET_QUESTIONS_ERROR';

export const SURVEY_TEMPLATE_SET_STATUS = 'SURVEY_TEMPLATE_SET_STATUS';
export const SURVEY_TEMPLATE_SET_STATUS_IN_PROGRESS = 'SURVEY_TEMPLATE_SET_STATUS_IN_PROGRESS';
export const SURVEY_TEMPLATE_SET_STATUS_NONE = 'SURVEY_TEMPLATE_SET_STATUS_NONE';
export const SURVEY_TEMPLATE_SET_STATUS_SUCCESS = 'SURVEY_TEMPLATE_SET_STATUS_SUCCESS';
export const SURVEY_TEMPLATE_SET_STATUS_ERROR = 'SURVEY_TEMPLATE_SET_STATUS_ERROR';

/**Questionnaire Redeploy */
export const SURVEY_TEMPLATE_REDEPLOY = 'SURVEY_TEMPLATE_REDEPLOY';
export const SURVEY_TEMPLATE_REDEPLOY_SUCCESS = 'SURVEY_TEMPLATE_REDEPLOY_SUCCESS';
export const SURVEY_TEMPLATE_REDEPLOY_ERROR = 'SURVEY_TEMPLATE_REDEPLOY_ERROR';
export const SURVEY_TEMPLATE_REDEPLOY_NONE = 'SURVEY_TEMPLATE_REDEPLOY_NONE';

export const SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS = 'SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS';
export const SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_SUCCESS = 'SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_SUCCESS';
export const SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_ERROR = 'SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_ERROR';
export const SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_NONE = 'SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS_NONE';

export const SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS = 'SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS';
export const SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS = 'SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS';
export const SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_ERROR = 'SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_ERROR';
export const SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_NONE = 'SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_NONE';

export const SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY = 'SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY';
export const SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_SUCCESS = 'SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_SUCCESS';
export const SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_ERROR = 'SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_ERROR';
export const SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_NONE = 'SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY_NONE';

export const SURVEY_NEW_TEMPLATE_SET_SOURCE_TYPE = 'SURVEY_NEW_TEMPLATE_SET_SOURCE_TYPE';
export const SURVEY_NEW_TEMPLATE_SET_TEMPLATE = 'SURVEY_NEW_TEMPLATE_SET_TEMPLATE';

export const SURVEY_TEMPLATE_SET_STEP = "SURVEY_TEMPLATE_SET_STEP";

/**** Translations ****/
export const TRANSLATION_SUBJECT_SHOW_LOADER = 'TRANSLATION_SUBJECT_SHOW_LOADER';
export const TRANSLATION_SUBJECT_HIDE_LOADER = 'TRANSLATION_SUBJECT_HIDE_LOADER';
export const TRANSLATION_SUPPORTED_LANG_SAVE = 'TRANSLATION_SUPPORTED_LANG_SAVE';
export const TRANSLATION_SUPPORTED_LANG_SAVE_SUCCESS = 'TRANSLATION_SUPPORTED_LANG_SAVE_SUCCESS';
export const TRANSLATION_SUPPORTED_LANG_SAVE_IN_PROGRESS = 'TRANSLATION_SUPPORTED_LANG_SAVE_IN_PROGRESS';
export const TRANSLATION_SUPPORTED_LANG_SAVE_NONE = 'TRANSLATION_SUPPORTED_LANG_SAVE_NONE';
export const TRANSLATION_SUPPORTED_LANG_SAVE_SHOW_ERROR = 'TRANSLATION_SUPPORTED_LANG_SAVE_SHOW_ERROR';
export const TRANSLATION_SUPPORTED_LANG_SAVE_HIDE_ERROR = 'TRANSLATION_SUPPORTED_LANG_SAVE_HIDE_ERROR';
export const TRANSLATION_SUPPORTED_LANG_GET_LIST = 'TRANSLATION_SUPPORTED_LANG_GET_LIST';
export const TRANSLATION_SUPPORTED_LANG_GET_LIST_SUCCESS = 'TRANSLATION_SUPPORTED_LANG_GET_LIST_SUCCESS';

export const TRANSLATION_SUPPORTED_SAVED_LANG_EDIT = 'TRANSLATION_SUPPORTED_SAVED_LANG_EDIT';
export const TRANSLATION_SUPPORTED_SAVED_LANG_DELETE = 'TRANSLATION_SUPPORTED_SAVED_LANG_DELETE';
export const TRANSLATION_SUPPORTED_SAVED_LANG_DELETE_NONE = 'TRANSLATION_SUPPORTED_SAVED_LANG_DELETE_NONE';
export const DISPLAY_ERROR_NOTIFICATION = 'DISPLAY_ERROR_NOTIFICATION';
export const HIDE_ERROR_NOTIFICATION = 'HIDE_ERROR_NOTIFICATION';

/**** Translation Modules ****/

export const TRANSLATION_MODULE_SHOW_LOADER = 'TRANSLATION_MODULE_SHOW_LOADER';
export const TRANSLATION_MODULE_HIDE_LOADER = 'TRANSLATION_MODULE_HIDE_LOADER';
export const TRANSLATION_MODULE_DISPLAY_NEW_EDIT_POPUP = 'TRANSLATION_MODULE_DISPLAY_NEW_EDIT_POPUP';
export const TRANSLATION_MODULE_HIDE_NEW_EDIT_POPUP = 'TRANSLATION_MODULE_HIDE_NEW_EDIT_POPUP';
export const TRANSLATION_MODULE_NEW_EDIT_SAVE = 'TRANSLATION_MODULE_NEW_EDIT_SAVE';
export const TRANSLATION_MODULE_NEW_EDIT_SAVE_SUCCESS = 'TRANSLATION_MODULE_NEW_EDIT_SAVE_SUCCESS';
export const TRANSLATION_MODULE_NEW_EDIT_SAVE_ERROR = 'TRANSLATION_MODULE_NEW_EDIT_SAVE_ERROR';
export const TRANSLATION_MODULE_NEW_EDIT_SAVE_IN_PROGRESS = 'TRANSLATION_MODULE_NEW_EDIT_SAVE_IN_PROGRESS';
export const TRANSLATION_MODULE_NEW_EDIT_SAVE_NONE = 'TRANSLATION_MODULE_NEW_EDIT_SAVE_NONE';

export const TRANSLATION_MODULE_NEW_EDIT_SHOW_ERROR = 'TRANSLATION_MODULE_NEW_EDIT_SHOW_ERROR';
export const TRANSLATION_MODULE_NEW_EDIT_HIDE_ERROR = 'TRANSLATION_MODULE_NEW_EDIT_HIDE_ERROR';
export const TRANSLATION_MODULE_NEW_EDIT_RESET_ERROR = 'TRANSLATION_MODULE_NEW_EDIT_RESET_ERROR';

export const TRANSLATION_MODULE_GET_ALL_RECORDS = 'TRANSLATION_MODULE_GET_ALL_RECORDS';
export const TRANSLATION_MODULE_GET_ALL_RECORDS_IN_PROGRESS = 'TRANSLATION_MODULE_GET_ALL_RECORDS_IN_PROGRESS';
export const TRANSLATION_MODULE_GET_ALL_RECORDS_SUCCESS = 'TRANSLATION_MODULE_GET_ALL_RECORDS_SUCCESS';
export const TRANSLATION_MODULE_GET_ALL_RECORDS_ERROR = 'TRANSLATION_MODULE_GET_ALL_RECORDS_ERROR';

export const TRANSLATION_MODULE_UPDATE_STATUS = 'TRANSLATION_MODULE_UPDATE_STATUS';
export const TRANSLATION_MODULE_UPDATE_STATUS_IN_PROGRESS = 'TRANSLATION_MODULE_UPDATE_STATUS_IN_PROGRESS';
export const TRANSLATION_MODULE_UPDATE_STATUS_NONE = 'TRANSLATION_MODULE_UPDATE_STATUS_NONE';
export const TRANSLATION_MODULE_UPDATE_STATUS_SUCCESS = 'TRANSLATION_MODULE_UPDATE_STATUS_SUCCESS';
export const TRANSLATION_MODULE_UPDATE_STATUS_ERROR = 'TRANSLATION_MODULE_UPDATE_STATUS_ERROR';


/**** survey translations ****/
export const TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS = 'TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS';
export const TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS_SUCCESS = 'TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS_SUCCESS';
export const TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS_ERROR = 'TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS_ERROR';
export const TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS_NONE = 'TRANSLATIONS_GET_SURVEY_ALL_TRANSLATIONS_NONE';

export const TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE = 'TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE';
export const TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE_SUCCESS = 'TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE_SUCCESS';
export const TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE_ERROR = 'TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE_ERROR';
export const TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE_NONE = 'TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE_NONE';

export const TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID = 'TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID';
export const TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID_SUCCESS = 'TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID_SUCCESS';
export const TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID_ERROR = 'TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID_ERROR';
export const TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID_NONE = 'TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID_NONE';


/**** Translations Category ****/

export const TRANSLATION_CATEGORY_SHOW_LOADER = 'TRANSLATION_CATEGORY_SHOW_LOADER';
export const TRANSLATION_CATEGORY_HIDE_LOADER = 'TRANSLATION_CATEGORY_HIDE_LOADER';
export const TRANSLATION_CATEGORY_DISPLAY_NEW_EDIT_POPUP = 'TRANSLATION_CATEGORY_DISPLAY_NEW_EDIT_POPUP';
export const TRANSLATION_CATEGORY_HIDE_NEW_EDIT_POPUP = 'TRANSLATION_CATEGORY_HIDE_NEW_EDIT_POPUP';
export const TRANSLATION_CATEGORY_NEW_EDIT_SAVE = 'TRANSLATION_CATEGORY_NEW_EDIT_SAVE';
export const TRANSLATION_CATEGORY_NEW_EDIT_SAVE_SUCCESS = 'TRANSLATION_CATEGORY_NEW_EDIT_SAVE_SUCCESS';
export const TRANSLATION_CATEGORY_NEW_EDIT_SAVE_ERROR = 'TRANSLATION_CATEGORY_NEW_EDIT_SAVE_ERROR';
export const TRANSLATION_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS = 'TRANSLATION_CATEGORY_NEW_EDIT_SAVE_IN_PROGRESS';
export const TRANSLATION_CATEGORY_NEW_EDIT_SAVE_NONE = 'TRANSLATION_CATEGORY_NEW_EDIT_SAVE_NONE';

export const TRANSLATION_CATEGORY_NEW_EDIT_SHOW_ERROR = 'TRANSLATION_CATEGORY_NEW_EDIT_SHOW_ERROR';
export const TRANSLATION_CATEGORY_NEW_EDIT_HIDE_ERROR = 'TRANSLATION_CATEGORY_NEW_EDIT_HIDE_ERROR';
export const TRANSLATION_CATEGORY_NEW_EDIT_RESET_ERROR = 'TRANSLATION_CATEGORY_NEW_EDIT_RESET_ERROR';

export const TRANSLATION_CATEGORY_GET_ALL_RECORDS = 'TRANSLATION_CATEGORY_GET_ALL_RECORDS';
export const TRANSLATION_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS = 'TRANSLATION_CATEGORY_GET_ALL_RECORDS_IN_PROGRESS';
export const TRANSLATION_CATEGORY_GET_ALL_RECORDS_SUCCESS = 'TRANSLATION_CATEGORY_GET_ALL_RECORDS_SUCCESS';
export const TRANSLATION_CATEGORY_GET_ALL_RECORDS_ERROR = 'TRANSLATION_CATEGORY_GET_ALL_RECORDS_ERROR';

export const TRANSLATION_CATEGORY_UPDATE_STATUS = 'TRANSLATION_CATEGORY_UPDATE_STATUS';
export const TRANSLATION_CATEGORY_UPDATE_STATUS_IN_PROGRESS = 'TRANSLATION_CATEGORY_UPDATE_STATUS_IN_PROGRESS';
export const TRANSLATION_CATEGORY_UPDATE_STATUS_NONE = 'TRANSLATION_CATEGORY_UPDATE_STATUS_NONE';
export const TRANSLATION_CATEGORY_UPDATE_STATUS_SUCCESS = 'TRANSLATION_CATEGORY_UPDATE_STATUS_SUCCESS';
export const TRANSLATION_CATEGORY_UPDATE_STATUS_ERROR = 'TRANSLATION_CATEGORY_UPDATE_STATUS_ERROR';

export const TRANSLATION_FONT_GET_ALL_RECORDS = 'TRANSLATION_FONT_GET_ALL_RECORDS';
export const TRANSLATION_FONT_GET_ALL_RECORDS_SUCCESS = 'TRANSLATION_FONT_GET_ALL_RECORDS_SUCCESS';
export const TRANSLATION_FONT_GET_ALL_RECORDS_ERROR = 'TRANSLATION_FONT_GET_ALL_RECORDS_ERROR';

export const TRANSLATION_FONT_GET_ALL_ACTIVE_RECORDS = 'TRANSLATION_FONT_GET_ALL_ACTIVE_RECORDS';
export const TRANSLATION_FONT_GET_ALL_ACTIVE_RECORDS_SUCCESS = 'TRANSLATION_FONT_GET_ALL_ACTIVE_RECORDS_SUCCESS';
export const TRANSLATION_FONT_GET_ALL_ACTIVE_RECORDS_ERROR = 'TRANSLATION_FONT_GET_ALL_ACTIVE_RECORDS_ERROR';

export const TRANSLATION_FONT_NEW_EDIT_SAVE = 'TRANSLATION_FONT_NEW_EDIT_SAVE';
export const TRANSLATION_FONT_NEW_EDIT_SAVE_SUCCESS = 'TRANSLATION_FONT_NEW_EDIT_SAVE_SUCCESS';
export const TRANSLATION_FONT_NEW_EDIT_SAVE_ERROR = 'TRANSLATION_FONT_NEW_EDIT_SAVE_ERROR';
export const TRANSLATION_FONT_NEW_EDIT_SAVE_NONE = 'TRANSLATION_CATEGORY_NEW_EDIT_SAVE_NONE';

export const TRANSLATION_FONT_UPDATE_STATUS = 'TRANSLATION_FONT_UPDATE_STATUS';
export const TRANSLATION_FONT_UPDATE_STATUS_SUCCESS = 'TRANSLATION_FONT_UPDATE_STATUS_SUCCESS';
export const TRANSLATION_FONT_UPDATE_STATUS_ERROR = 'TRANSLATION_FONT_UPDATE_STATUS_ERROR';
export const TRANSLATION_FONT_UPDATE_STATUS_NONE = 'TRANSLATION_FONT_UPDATE_STATUS_NONE';

export const TRANSLATION_FONT_LIST_SORT = 'TRANSLATION_FONT_LIST_SORT';
export const TRANSLATION_FONT_UPDATE_PREFERRED_FONT = 'TRANSLATION_FONT_UPDATE_PREFERRED_FONT';
export const TRANSLATION_FONT_DISPLAY_NEW_EDIT_POPUP = 'TRANSLATION_FONT_DISPLAY_NEW_EDIT_POPUP';
export const TRANSLATION_FONT_HIDE_NEW_EDIT_POPUP = 'TRANSLATION_FONT_HIDE_NEW_EDIT_POPUP';

/**** Translation subModules ****/

export const TRANSLATION_SUBMODULE_SHOW_LOADER = 'TRANSLATION_SUBMODULE_SHOW_LOADER';
export const TRANSLATION_SUBMODULE_HIDE_LOADER = 'TRANSLATION_SUBMODULE_HIDE_LOADER';
export const TRANSLATION_SUBMODULE_DISPLAY_NEW_EDIT_POPUP = 'TRANSLATION_SUBMODULE_DISPLAY_NEW_EDIT_POPUP';
export const TRANSLATION_SUBMODULE_HIDE_NEW_EDIT_POPUP = 'TRANSLATION_SUBMODULE_HIDE_NEW_EDIT_POPUP';
export const TRANSLATION_SUBMODULE_NEW_EDIT_SAVE = 'TRANSLATION_SUBMODULE_NEW_EDIT_SAVE';
export const TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_SUCCESS = 'TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_SUCCESS';
export const TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_ERROR = 'TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_ERROR';
export const TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_IN_PROGRESS = 'TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_IN_PROGRESS';
export const TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_NONE = 'TRANSLATION_SUBMODULE_NEW_EDIT_SAVE_NONE';

export const TRANSLATION_SUBMODULE_NEW_EDIT_SHOW_ERROR = 'TRANSLATION_SUBMODULE_NEW_EDIT_SHOW_ERROR';
export const TRANSLATION_SUBMODULE_NEW_EDIT_HIDE_ERROR = 'TRANSLATION_SUBMODULE_NEW_EDIT_HIDE_ERROR';
export const TRANSLATION_SUBMODULE_NEW_EDIT_RESET_ERROR = 'TRANSLATION_SUBMODULE_NEW_EDIT_RESET_ERROR';

export const TRANSLATION_SUBMODULE_GET_ALL_RECORDS = 'TRANSLATION_SUBMODULE_GET_ALL_RECORDS';
export const TRANSLATION_SUBMODULE_GET_ALL_RECORDS_IN_PROGRESS = 'TRANSLATION_SUBMODULE_GET_ALL_RECORDS_IN_PROGRESS';
export const TRANSLATION_SUBMODULE_GET_ALL_RECORDS_SUCCESS = 'TRANSLATION_SUBMODULE_GET_ALL_RECORDS_SUCCESS';
export const TRANSLATION_SUBMODULE_GET_ALL_RECORDS_ERROR = 'TRANSLATION_SUBMODULE_GET_ALL_RECORDS_ERROR';

export const TRANSLATION_SUBMODULE_UPDATE_STATUS = 'TRANSLATION_SUBMODULE_UPDATE_STATUS';
export const TRANSLATION_SUBMODULE_UPDATE_STATUS_IN_PROGRESS = 'TRANSLATION_SUBMODULE_UPDATE_STATUS_IN_PROGRESS';
export const TRANSLATION_SUBMODULE_UPDATE_STATUS_NONE = 'TRANSLATION_SUBMODULE_UPDATE_STATUS_NONE';
export const TRANSLATION_SUBMODULE_UPDATE_STATUS_SUCCESS = 'TRANSLATION_SUBMODULE_UPDATE_STATUS_SUCCESS';
export const TRANSLATION_SUBMODULE_UPDATE_STATUS_ERROR = 'TRANSLATION_SUBMODULE_UPDATE_STATUS_ERROR';


/*** Client_PIC ****/

export const CLIENT_PIC_SHOW_LOADER = 'CLIENT_PIC_SHOW_LOADER';
export const CLIENT_PIC_HIDE_LOADER = 'CLIENT_PIC_HIDE_LOADER';
export const CLIENT_PIC_DISPLAY_NEW_EDIT_POPUP = 'CLIENT_PIC_DISPLAY_NEW_EDIT_POPUP';
export const CLIENT_PIC_HIDE_NEW_EDIT_POPUP = 'CLIENT_PIC_HIDE_NEW_EDIT_POPUP';
export const CLIENT_PIC_NEW_EDIT_SAVE = 'CLIENT_PIC_NEW_EDIT_SAVE';
export const CLIENT_PIC_NEW_EDIT_SAVE_SUCCESS = 'CLIENT_PIC_NEW_EDIT_SAVE_SUCCESS';
export const CLIENT_PIC_NEW_EDIT_SAVE_ERROR = 'CLIENT_PIC_NEW_EDIT_SAVE_ERROR';
export const CLIENT_PIC_NEW_EDIT_SAVE_IN_PROGRESS = 'CLIENT_PIC_NEW_EDIT_SAVE_IN_PROGRESS';
export const CLIENT_PIC_NEW_EDIT_SAVE_NONE = 'CLIENT_PIC_NEW_EDIT_SAVE_NONE';

export const CLIENT_PIC_NEW_EDIT_SHOW_ERROR = 'CLIENT_PIC_NEW_EDIT_SHOW_ERROR';
export const CLIENT_PIC_NEW_EDIT_HIDE_ERROR = 'CLIENT_PIC_NEW_EDIT_HIDE_ERROR';
export const CLIENT_PIC_NEW_EDIT_RESET_ERROR = 'CLIENT_PIC_NEW_EDIT_RESET_ERROR';

export const CLIENT_PIC_GET_ALL_RECORDS = 'CLIENT_PIC_GET_ALL_RECORDS';
export const CLIENT_PIC_GET_ALL_RECORDS_IN_PROGRESS = 'CLIENT_PIC_GET_ALL_RECORDS_IN_PROGRESS';
export const CLIENT_PIC_GET_ALL_RECORDS_SUCCESS = 'CLIENT_PIC_GET_ALL_RECORDS_SUCCESS';
export const CLIENT_PIC_GET_ALL_RECORDS_ERROR = 'CLIENT_PIC_GET_ALL_RECORDS_ERROR';

export const CLIENT_PIC_UPDATE_STATUS = 'CLIENT_PIC_UPDATE_STATUS';
export const CLIENT_PIC_UPDATE_STATUS_IN_PROGRESS = 'CLIENT_PIC_UPDATE_STATUS_IN_PROGRESS';
export const CLIENT_PIC_UPDATE_STATUS_NONE = 'CLIENT_PIC_UPDATE_STATUS_NONE';
export const CLIENT_PIC_UPDATE_STATUS_SUCCESS = 'CLIENT_PIC_UPDATE_STATUS_SUCCESS';
export const CLIENT_PIC_UPDATE_STATUS_ERROR = 'SURVEY_CATEGORY_UPDATE_STATUS_ERROR';

/*** Industry_Types ****/

export const INDUSTRY_TYPE_DISPLAY_NEW_EDIT_POPUP = 'INDUSTRY_TYPE_DISPLAY_NEW_EDIT_POPUP'
export const INDUSTRY_TYPE_HIDE_NEW_EDIT_POPUP = 'INDUSTRY_TYPE_HIDE_NEW_EDIT_POPUP'

export const INDUSTRY_TYPE_NEW_EDIT_SAVE_IN_PROGRESS = 'INDUSTRY_TYPE_NEW_EDIT_SAVE_IN_PROGRESS'
export const INDUSTRY_TYPE_NEW_EDIT_SAVE = 'INDUSTRY_TYPE_NEW_EDIT_SAVE'
export const INDUSTRY_TYPE_NEW_EDIT_SAVE_ERROR = 'INDUSTRY_TYPE_NEW_EDIT_SAVE_ERROR'
export const INDUSTRY_TYPE_NEW_EDIT_SAVE_SUCCESS = 'INDUSTRY_TYPE_NEW_EDIT_SAVE_SUCCESS'
export const INDUSTRY_TYPE_NEW_EDIT_SAVE_NONE = 'INDUSTRY_TYPE_NEW_EDIT_SAVE_NONE'

export const INDUSTRY_TYPE_GET_ALL_RECORDS = 'INDUSTRY_TYPE_GET_ALL_RECORDS'
export const INDUSTRY_TYPE_GET_ALL_RECORDS_SUCCESS = 'INDUSTRY_TYPE_GET_ALL_RECORDS_SUCCESS'
export const INDUSTRY_TYPE_GET_ALL_RECORDS_ERROR = 'INDUSTRY_TYPE_GET_ALL_RECORDS_ERROR '
export const INDUSTRY_TYPE_GET_ALL_RECORDS_IN_PROGRESS = 'INDUSTRY_TYPE_GET_ALL_RECORDS_IN_PROGRESS'

export const INDUSTRY_TYPE_UPDATE_STATUS_NONE = 'INDUSTRY_TYPE_UPDATE_STATUS_NONE'
export const INDUSTRY_TYPE_UPDATE_STATUS_IN_PROGRESS = 'INDUSTRY_TYPE_UPDATE_STATUS_IN_PROGRESS'
export const INDUSTRY_TYPE_UPDATE_STATUS = 'INDUSTRY_TYPE_UPDATE_STATUS'
export const INDUSTRY_TYPE_UPDATE_STATUS_SUCCESS = 'INDUSTRY_TYPE_UPDATE_STATUS_SUCCESS'

/**** Account Managers ****/

export const ACCOUNT_MANAGERS_SHOW_LOADER = 'ACCOUNT_MANAGERS_SHOW_LOADER';
export const ACCOUNT_MANAGERS_HIDE_LOADER = 'ACCOUNT_MANAGERS_HIDE_LOADER';
export const ACCOUNT_MANAGERS_SAVE = 'ACCOUNT_MANAGERS_SAVE';
export const ACCOUNT_MANAGERS_SAVE_SUCCESS = 'ACCOUNT_MANAGERS_SAVE_SUCCESS';
export const ACCOUNT_MANAGERS_SAVE_IN_PROGRESS = 'ACCOUNT_MANAGERS_SAVE_IN_PROGRESS';
export const ACCOUNT_MANAGERS_SAVE_NONE = 'ACCOUNT_MANAGERS_SAVE_NONE';
export const ACCOUNT_MANAGERS_SAVE_SHOW_ERROR = 'ACCOUNT_MANAGERS_SAVE_SHOW_ERROR';
export const ACCOUNT_MANAGERS_SAVE_HIDE_ERROR = 'ACCOUNT_MANAGERS_SAVE_HIDE_ERROR';
export const ACCOUNT_MANAGERS_GET_LIST = 'ACCOUNT_MANAGERS_GET_LIST';
export const ACCOUNT_MANAGERS_GET_LIST_SUCCESS = 'ACCOUNT_MANAGERS_GET_LIST_SUCCESS';
export const ACCOUNT_MANAGERS_GET_LIST_IN_PROGRESS = 'ACCOUNT_MANAGERS_GET_LIST_IN_PROGRESS';

export const ACCOUNT_MANAGERS_SET_CURRENT_RECORD = 'ACCOUNT_MANAGERS_SET_CURRENT_RECORD';
export const ACCOUNT_MANAGERS_EDIT = 'ACCOUNT_MANAGERS_EDIT';
export const ACCOUNT_MANAGERS_DELETE = 'ACCOUNT_MANAGERS_DELETE';
export const ACCOUNT_MANAGERS_DELETE_NONE = 'ACCOUNT_MANAGERS_DELETE_NONE';


/**** CONNECT ****/
export const CONNECT_API_CALL_REQUEST = "CONNECT_API_CALL_REQUEST";
export const CONNECT_API_CALL_SUCCESS = "CONNECT_API_CALL_SUCCESS";
export const CONNECT_API_CALL_FAILURE = "CONNECT_API_CALL_FAILURE";

/**** connect uuid status ****/
export const CONNECT_USERS_UUID_STATUS_REQUEST = "CONNECT_USERS_UUID_STATUS_REQUEST";
export const CONNECT_USERS_UUID_STATUS_REQUEST_SUCCESS = "CONNECT_USERS_UUID_STATUS_REQUEST_SUCCESS";
export const CONNECT_USERS_UUID_STATUS_REQUEST_FAILURE = "CONNECT_USERS_UUID_STATUS_REQUEST_FAILURE";

export const CONNECT_API_GET_CASE_OVERVIEW_REQUEST = "CONNECT_API_GET_CASE_OVERVIEW_REQUEST";
export const CONNECT_API_GET_CASE_OVERVIEW_SUCCESS = "CONNECT_API_GET_CASE_OVERVIEW_SUCCESS";
export const CONNECT_API_GET_CASE_OVERVIEW_FAILURE = "CONNECT_API_GET_CASE_OVERVIEW_FAILURE";

export const CONNECT_API_GET_CASE_PROPORTION_REQUEST = "CONNECT_API_GET_CASE_PROPORTION_REQUEST";
export const CONNECT_API_GET_CASE_PROPORTION_SUCCESS = "CONNECT_API_GET_CASE_PROPORTION_SUCCESS";
export const CONNECT_API_GET_CASE_PROPORTION_FAILURE = "CONNECT_API_GET_CASE_PROPORTION_FAILURE";
//Case popularity

export const CONNECT_API_GET_CASE_POPULARITY_REQUEST = "CONNECT_API_GET_CASE_POPULARITY_REQUEST";
export const CONNECT_API_GET_CASE_POPULARITY_SUCCESS = "CONNECT_API_GET_CASE_POPULARITY_SUCCESS";
export const CONNECT_API_GET_CASE_POPULARITY_FAILURE = "CONNECT_API_GET_CASE_POPULARITY_FAILURE";

export const CONNECT_API_GET_MESSAGE_COUNT_REQUEST = "CONNECT_API_GET_MESSAGE_COUNT_REQUEST";
export const CONNECT_API_GET_MESSAGE_COUNT_SUCCESS = "CONNECT_API_GET_CMESSAGE_COUNT_SUCCESS";
export const CONNECT_API_GET_MESSAGE_COUNT_FAILURE = "CONNECT_API_GET_CMESSAGE_COUNT_FAILURE";

export const CONNECT_API_GET_CASE_STATUS_REQUEST = "CONNECT_API_GET_CASE_STATUS_REQUEST";
export const CONNECT_API_GET_CASE_STATUS_SUCCESS = "CONNECT_API_GET_CASE_STATUS_SUCCESS";
export const CONNECT_API_GET_CASE_STATUS_FAILURE = "CONNECT_API_GET_CASE_STATUS_FAILURE";

export const CONNECT_API_GET_CASETIME_OVERTIME_REQUEST = "CONNECT_API_GET_CASETIME_OVERTIME_REQUEST";
export const CONNECT_API_GET_CASETIME_OVERTIME_SUCCESS = "CONNECT_API_GET_CASETIME_OVERTIME_SUCCESS";
export const CONNECT_API_GET_CASETIME_OVERTIME_FAILURE = "CONNECT_API_GET_CASETIME_OVERTIME_FAILURE";

export const CONNECT_API_GET_CASE_HANDLING_RESOLVED_COUNT_REQUEST = "CONNECT_API_GET_CASE_HANDLING_RESOLVED_COUNT_REQUEST";
export const CONNECT_API_GET_CASE_HANDLING_RESOLVED_COUNT_SUCCESS = "CONNECT_API_GET_CASE_HANDLING_RESOLVED_COUNT_SUCCESS";
export const CONNECT_API_GET_CASE_HANDLING_RESOLVED_COUNT_FAILURE = "CONNECT_API_GET_CASE_HANDLING_RESOLVED_COUNT_FAILURE";


export const CONNECT_API_GET_CASE_HANDLING_MESSAGE_REQUEST = "CONNECT_API_GET_CASE_HANDLING_MESSAGE_REQUEST";
export const CONNECT_API_GET_CASE_HANDLING_MESSAGE_SUCCESS = "CONNECT_API_GET_CASE_HANDLING_MESSAGE_SUCCESS";
export const CONNECT_API_GET_CASE_HANDLING_MESSAGE_FAILURE = "CONNECT_API_GET_CASE_HANDLING_MESSAGE_FAILURE";

///reply-avg-count
export const CONNECT_API_GET_REPLY_AVG_COUNT_REQUEST = "CONNECT_API_GET_REPLY_AVG_COUNT_REQUEST";
export const CONNECT_API_GET_REPLY_AVG_COUNT_SUCCESS = "CONNECT_API_GET_REPLY_AVG_COUNT_SUCCESS";
export const CONNECT_API_GET_REPLY_AVG_COUNT_FAILURE = "CONNECT_API_GET_REPLY_AVG_COUNT_FAILURE";

export const CONNECT_API_GET_CASE_SATISFACTION_REQUEST = "CONNECT_API_GET_CASE_SATISFACTION_REQUEST";
export const CONNECT_API_GET_CASE_SATISFACTION_SUCCESS = "CONNECT_API_GET_CASE_SATISFACTION_SUCCESS";
export const CONNECT_API_GET_CASE_SATISFACTION_FAILURE = "CONNECT_API_GET_CASE_SATISFACTION_FAILURE";


export const CONNECT_API_GET_MANAGEMENT_CASE_TYPE_REQUEST = "CONNECT_API_GET_MANAGEMENT_CASE_TYPE_REQUEST";
export const CONNECT_API_GET_MANAGEMENT_CASE_TYPE_SUCCESS = "CONNECT_API_GET_MANAGEMENT_CASE_TYPE_SUCCESS";
export const CONNECT_API_GET_MANAGEMENT_CASE_TYPE_FAILURE = "CONNECT_API_GET_MANAGEMENT_CASE_TYPE_FAILURE";

export const CONNECT_API_GENERAL_REQUEST = "CONNECT_API_GENERAL_REQUEST";
export const CONNECT_API_GENERAL_SUCCESS = "CONNECT_API_GENERAL_SUCCESS";
export const CONNECT_API_GENERAL_TYPE_FAILURE = "CONNECT_API_GENERAL_FAILURE";

export const CONNECT_GENERAL_UUID_STATUS_REQUEST = "CONNECT_GENERAL_UUID_STATUS_REQUEST";
export const CONNECT_GENERAL_UUID_STATUS_REQUEST_SUCCESS = "CONNECT_GENERAL_UUID_STATUS_REQUEST_SUCCESS";
export const CONNECT_GENERAL_UUID_STATUS_REQUEST_FAILURE = "CONNECT_GENERAL_UUID_STATUS_REQUEST_FAILURE";


/**** Connect Rawdata Download ****/

export const CONNECT_RAW_DATA_RESPONSES_DOWNLOAD = 'CONNECT_RAW_DATA_RESPONSES_DOWNLOAD';
export const CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_ERROR = 'CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_ERROR';
export const CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_SUCCESS = 'CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_SUCCESS';
export const CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_IN_PROGRESS = 'CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_IN_PROGRESS';
export const CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_NONE = 'CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_NONE';

export const CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID = 'CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID';
export const CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS = 'CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS';
export const CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR = 'CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR';
export const CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE = 'CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE';

export const CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS = 'CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS';
export const CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS = 'CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS';
export const CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR = 'CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR';
export const CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_NONE = 'CONNECT_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_NONE';

export const CONNECT_API_GET_SUMMARY_TABLE_REQUEST = "CONNECT_API_GET_SUMMARY_TABLE_REQUEST";
export const CONNECT_API_GET_SUMMARY_TABLE_SUCCESS = "CONNECT_API_GET_SUMMARY_TABLE_SUCCESS";
export const CONNECT_API_GET_SUMMARY_TABLE_FAILURE = "CONNECT_API_GET_SUMMARY_TABLE_FAILURE";

export const CONNECT_API_GET_SUMMARY_TABLE_UUID_REQUEST = "CONNECT_API_GET_SUMMARY_TABLE_UUID_REQUEST";
export const CONNECT_API_GET_SUMMARY_TABLE_UUID_SUCCESS = "CONNECT_API_GET_SUMMARY_TABLE_UUID_SUCCESS";
export const CONNECT_API_GET_SUMMARY_TABLE_UUID_FAILURE = "CONNECT_API_GET_SUMMARY_TABLE_UUID_FAILURE";

/**** Connect SummaryTable Download ****/
export const CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID = 'CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID';
export const CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_ERROR = 'CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_ERROR';
export const CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_SUCCESS = 'CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_SUCCESS';
export const CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_IN_PROGRESS = 'CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_IN_PROGRESS';
export const CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_NONE = 'CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_NONE';

export const CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA = 'CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA';
export const CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_ERROR = 'CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_ERROR_DATA';
export const CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_SUCCESS = 'CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_SUCCESS';
export const CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_IN_PROGRESS = 'CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_IN_PROGRESS';
export const CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_NONE = 'CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID_DATA_NONE';

/**Company Post Raw data */
export const COMPANY_POST_RAW_DATA_RESPONSES_UUID = 'COMPANY_POST_RAW_DATA_RESPONSES_UUID';
export const COMPANY_POST_RAW_DATA_RESPONSES_UUID_ERROR = 'COMPANY_POST_RAW_DATA_RESPONSES_UUID_ERROR';
export const COMPANY_POST_RAW_DATA_RESPONSES_UUID_SUCCESS = 'COMPANY_POST_RAW_DATA_RESPONSES_UUID_SUCCESS';
export const COMPANY_POST_RAW_DATA_RESPONSES_UUID_IN_PROGRESS = 'COMPANY_POST_RAW_DATA_RESPONSES_UUID_IN_PROGRESS';
export const COMPANY_POST_RAW_DATA_RESPONSES_UUID_NONE = 'COMPANY_POST_RAW_DATA_RESPONSES_UUID_NONE';

export const COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS = 'COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS';
export const COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS_ERROR = 'COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS_ERROR';
export const COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS_SUCCESS = 'COMPANY_POST_RAW_DATA_RESPONSES_UUID_STATUS_SUCCESS';

export const COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID = 'COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID';
export const COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS = 'COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_SUCCESS';
export const COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR = 'COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_ERROR';
export const COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE = 'COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_NONE';
export const COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_IN_PROGRESS = 'COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_IN_PROGRESS';

export const COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS = 'COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS';
export const COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS = 'COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_SUCCESS';
export const COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR = 'COMPANY_POST_RAW_DATA_RESPONSES_DOWNLOAD_UUID_STATUS_ERROR';

export const GET_TIMEZONE_LIST = 'GET_TIMEZONE_LIST'
export const GET_TIMEZONE_LIST_SUCCESS = 'GET_TIMEZONE_LIST_SUCCESS'