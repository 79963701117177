import {
    E_LEARNING_REPORTS_ENROLMENT_GENERAL_SUCCESS,
    E_LEARNING_REPORTS_ENROLMENT_TOPICS_SUCCESS,
    E_LEARNING_REPORTS_ENROLMENT_PROPORTION_SUCCESS,
    E_LEARNING_REPORTS_ENROLMENT_STATUSES_SUCCESS,
    E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN_SUCCESS,
    E_LEARNING_REPORTS_ENROLMENT_COURSES_SUCCESS,
    E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_SUCCESS,
    E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE_SUCCESS,
    E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_POPULARITY_SUCCESS,
    E_LEARNING_REPORTS_USER_ACTIVITY_DIFFICULTY_SUCCESS,
    E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_UA_DRILLING_INCREMENT,
    E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_UA_DRILLING_DECREMENT,
    E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_DISTRIBUTION_UA_DRILLING_INCREMENT,
    E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_DISTRIBUTION_UA_DRILLING_DECREMENT,
    E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_DIFFICULTY_UA_DRILLING_INCREMENT,
    E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_DIFFICULTY_UA_DRILLING_DECREMENT,

    E_LEARNING_REPORTS_COMPARISION_OVERTIME_SUCCESS,
    E_LEARNING_REPORTS_COMPARISION_GENERAL_SUCCESS,
    E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_SUCCESS,
    E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_SUCCESS,
    E_LEARNING_REPORTS_COMPARISION_OVERTIME_UA_DRILLING_INCREMENT,
    E_LEARNING_REPORTS_COMPARISION_OVERTIME_UA_DRILLING_DECREMENT,
    E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_UA_DRILLING_INCREMENT,
    E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_UA_DRILLING_DECREMENT,
    E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_UA_DRILLING_INCREMENT,
    E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_UA_DRILLING_DECREMENT,
    E_LEARNING_REPORTS_COMPARISION_GENERAL_UA_DRILLING_INCREMENT,
    E_LEARNING_REPORTS_COMPARISION_GENERAL_UA_DRILLING_DECREMENT,

    E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_SUCCESS,
    E_LEARNING_REPORTS_COMPARISION_RATIO_UA_DRILLING_DECREMENT,
    E_LEARNING_REPORTS_COMPARISION_RATIO_UA_DRILLING_INCREMENT,
    E_LEARNING_REPORTS_COMPARISION_USERS_SELECTED_SUMMARISED,
    CLIENT_SELECTED_CLIENT,
    E_LEARNING_REPORTS_COMPARISION_USERS_SELECTED_SUMMARISED_RESSET,
    CLIENT_GET_ALL_CLIENTS_SUCCESS,
    E_LEARNING_REPORTS_SET_CHILD_CLIENTS,
    E_LEARNING_REPORTS_PROGRESS_END,
    E_LEARNING_REPORTS_PROGRESS_START,
    E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE,
    E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE,
    E_LEARNING_REPORTS_COMPARISION_GENERAL,
    E_LEARNING_REPORTS_COMPARISION_OVERTIME,
    E_LEARNING_REPORTS_COMPARISION_RATIO_USERS,
    E_LEARNING_REPORTS_COMPARISION_RATIO_COURSES,
    E_LEARNING_REPORTS_ENROLMENT_GENERAL,
    E_LEARNING_REPORTS_ENROLMENT_TOPICS,
    E_LEARNING_REPORTS_ENROLMENT_PROPORTION,
    E_LEARNING_REPORTS_ENROLMENT_STATUSES,
    E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN,
    E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO,
    E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE,
    E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_UUID_DATA_SUCCESS

} from "../constants/ActionTypes";
import React from "react";
import IntlMessages from "../util/IntlMessages";
import AppUtils from "../util/util";
import {AGE_GROUP_TYPES, GENDER_TYPES, TENURE_GROUP_TYPES} from "../constants/config";
import {
    REPORT_FILTERS_ELEARNING_UPDATE_DATE_RANGE,
    REPORT_FILTERS_ELEARNING_SET_DATE_RANGE
} from '../constants/actions/reports/actionsReportFilters';

let userActionMaxDepth = 4;
let comparisonOverTimeMaxDepth = 2;
let comparisonRatioMaxDepth = 3;

let comparisonOverTimeColors = ['#BF360C', '#33691E', '#2962FF', '#FF8A65', '#FF6F00', '#FFCA28', '#FFD54F', '#FFAB00', '#FFC400', '#FFD740', '#039BE5', '#03A9F4', '#29B6F6', '#4FC3F7', '#81D4FA'];

let comparisonContentAvgScoreColors = ['#BF360C', '#33691E', '#2962FF', '#FF8A65', '#FF6F00', '#FFCA28', '#FFD54F', '#FFAB00', '#FFC400', '#FFD740', '#039BE5', '#03A9F4', '#29B6F6', '#4FC3F7', '#81D4FA'];

let breakdown_colors_level_one = ['#BF360C', '#33691E'];

let breakdown_colors_level_two = {
    1: ['#D84315', '#E64A19', '#F4511E', '#FF5722', '#FF7043', '#FF8A65', '#FF6E40', '#FF3D00'],
    2: ['#558B2F', '#689F38', '#7CB342', '#8BC34A', '#9CCC65', '#AED581']
};

let breakdown_colors_level_three = {
    1: ['#FF6F00', '#FF8F00', '#FFA000', '#FFB300', '#FFC107', '#FFCA28', '#FFD54F', '#FFAB00', '#FFC400', '#FFD740'],
    2: ['#0091EA', '#00B0FF', '#40C4FF', '#80D8FF', '#0277BD', '#0288D1', '#039BE5', '#03A9F4', '#29B6F6', '#4FC3F7', '#81D4FA']
};

let breakdown_colors_level_four = {
    1: ['#F57F17', '#E65100', '#EF6C00', '#F57C00', '#FB8C00', '#FF9800', '#FFA726', '#FF6D00', '#FF9100', '#FFAB40', '#FFAB00', '#FFC400'],
    2: ['#2962FF', '#2979FF', '#448AFF', '#82B1FF', '#1565C0', '#1976D2', '#1E88E5', '#2196F3', '#42A5F5', '#64B5F6', '#90CAF9']
};

let filterContentsChipsParent = [
    {
        value: 1,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_ENROLMENT_SUMMARY"/>
    },
    {
        value: 2,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_USER_ACTIVITY"/>
    },
    {
        value: 3,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE"/>
    },
    {
        value: 4,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_COMPANY_COMPARISION"/>
    }
];

let filterContentsChipsChild = [
    {
        value: 1,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_ENROLMENT_SUMMARY"/>
    },
    {
        value: 2,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_USER_ACTIVITY"/>
    },
    {
        value: 3,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE"/>
    }
];

let filterValueConstants = {
    "FILTER_VALUE_INDIVIDUAL": "INDIVIDUAL",
    "FILTER_VALUE_OVERALL": "OVERALL",
    "FILTER_VALUE_GENDER": "GENDER",
    "FILTER_VALUE_AGE_GROUP": "AGE_GROUP",
    "FILTER_VALUE_TENURE_GROUP": "TENURE_GROUP",
    "FILTER_VALUE_SMART_PHONE": "SMART_PHONE",
    "FILTER_VALUE_COUNTRY": "COUNTRY",
    "FILTER_VALUE_COMPANY": "COMPANY"
};

const genderDataType = [
    {
        value: GENDER_TYPES.ALL,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_GENDER_ALL"/>
    },
    {
        value: GENDER_TYPES.MALE,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_GENDER_MALE"/>
    },
    {
        value: GENDER_TYPES.FEMALE,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_GENDER_FEMALE"/>
    },
    {
        value: GENDER_TYPES.UNDISCLOSED,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_GENDER_UNDISCLOSED"/>
    },
    {
        value: GENDER_TYPES.OTHER,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_GENDER_OTHERS"/>
    }
];


let ageGroupDataType = [
    {
        value: AGE_GROUP_TYPES.ALL,
        filterValue: "",
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_AGE_GROUP_ALL"/>
    },
    {
        value: AGE_GROUP_TYPES["0_13"],
        filterValue: "0_13",
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_AGE_GROUP_0_13"/>
    },
    {
        value: AGE_GROUP_TYPES["14_18"],
        filterValue: "14_18",
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_AGE_GROUP_14_18"/>
    },
    {
        value: AGE_GROUP_TYPES["19_35"],
        filterValue: "19_35",
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_AGE_GROUP_19_35"/>
    },
    {
        value: AGE_GROUP_TYPES["36_50"],
        filterValue: "36_50",
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_AGE_GROUP_36_50"/>
    },
    {
        value: AGE_GROUP_TYPES["51_100"],
        filterValue: "51_100",
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_AGE_GROUP_51_100"/>
    }
];

let tenureGroupDataType = [
    {
        value: TENURE_GROUP_TYPES.ALL,
        filterValue: "",
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_TENURE_GROUP_ALL"/>
    },
    {
        value: TENURE_GROUP_TYPES["0_1"],
        filterValue: "0_1",
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_TENURE_GROUP_0_1"/>
    },
    {
        value: TENURE_GROUP_TYPES["1_3"],
        filterValue: "1_3",
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_TENURE_GROUP_1_3"/>
    },
    {
        value: TENURE_GROUP_TYPES["3_MORE"],
        filterValue: "3_MORE",
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_TENURE_GROUP_3_MORE"/>
    }
];


let filtersSummariseOptions = {
    child: [
        {
            value: filterValueConstants.FILTER_VALUE_OVERALL,
            label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_OVERALL"/>
        },
        /*{
            value: filterValueConstants.FILTER_VALUE_INDIVIDUAL,
            label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_INDIVIDUAL"/>
        },*/
        {
            value: filterValueConstants.FILTER_VALUE_GENDER,
            label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_GENDER"/>
        },
        {
            value: filterValueConstants.FILTER_VALUE_AGE_GROUP,
            label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_AGE_GROUP"/>
        },
        {
            value: filterValueConstants.FILTER_VALUE_TENURE_GROUP,
            label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_TENURE_GROUP"/>
        },
        {
            value: filterValueConstants.FILTER_VALUE_SMART_PHONE,
            label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_SMART_PHONE"/>
        }
    ],
    parent: [
        {
            value: filterValueConstants.FILTER_VALUE_OVERALL,
            label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_OVERALL"/>
        },
        {
            value: filterValueConstants.FILTER_VALUE_GENDER,
            label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_GENDER"/>
        },
        {
            value: filterValueConstants.FILTER_VALUE_AGE_GROUP,
            label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_AGE_GROUP"/>
        },
        {
            value: filterValueConstants.FILTER_VALUE_TENURE_GROUP,
            label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_TENURE_GROUP"/>
        },
        {
            value: filterValueConstants.FILTER_VALUE_SMART_PHONE,
            label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_SMART_PHONE"/>
        },
        {
            value: filterValueConstants.FILTER_VALUE_COUNTRY,
            label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_COUNTRY"/>
        },
        {
            value: filterValueConstants.FILTER_VALUE_COMPANY,
            label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_COMPANY"/>
        }
    ]

};

const smartPhoneDataType = [
    {
        value: -1,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_SMART_PHONE_ALL"/>
    },
    {
        value: 1,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_SMART_PHONE_REGISTERED"/>
    },
    {
        value: 0,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_SMART_PHONE_UNREGISTERED"/>
    }
];

const overallDataType = [
    {
        value: -1,
        label: <IntlMessages id="DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_SUMMARISE_BY_OVERALL_ALL"/>
    }
];

let selectionRange = AppUtils.getDefaultDateRangeSelection();
let timeResult = AppUtils.getReportsDates();

const initialSettings = {
    isQueryInProgress: false,

    enrolmentGeneral: {
        total_enrolments: 0,
        total_workers: 0,
        enrolment_rate: 0
    },
    enrolmentTopics: {
        active: 0,
        inactive: 0
    },
    enrolmentProportion: [
        {
            "key_val": "1_50",
            "name": 'Enrolled in at least one but less than half of active topics',
            "Enrollment Proportion": 0,
        },
        {
            "key_val": "51_100",
            "name": 'Enrolled in at least half of active topics',
            "Enrollment Proportion": 0
        }
    ],
    enrolmentStatuses: [],
    enrolmentBreakdown: [],
    enrolmentBreakdownTotalCount: [],
    enrolmentCourses: {},

    filterContentsChipsChild: filterContentsChipsChild,
    filterContentsChipsParent: filterContentsChipsParent,
    filtersSummariseOptions: filtersSummariseOptions,


    drillingDataTitles: {
        1: "DIC_REPORTS_E_LEARNING_DRILLING_TITLE_AREA",
        2: "DIC_REPORTS_E_LEARNING_DRILLING_TITLE_CORE_COMPETENCIES",
        3: "DIC_REPORTS_E_LEARNING_DRILLING_TITLE_COURSE",
        4: "DIC_REPORTS_E_LEARNING_DRILLING_TITLE_TOPIC"
    },
    drillingDataTitlesComparision: {
        1: "DIC_REPORTS_E_LEARNING_DRILLING_TITLE_COUNTRY",
        2: "DIC_REPORTS_E_LEARNING_DRILLING_TITLE_CLIENT"
    },

    userActivityCompletionRatio: {},
    userActivityCompletionRatioDepth: 4,
    userActivityCompletionRatioDrillingData: [],
    userActivityCompletionRatioDrillingTitle: "",


    userActivityQuizScore: {
        1: [['day', '0', 'b', 'c', 'd'], ['', 0, 0, 0, 100]],
        2: [['day', '0', 'b', 'c', 'd'], ['', 0, 0, 0, 100]],
        3: [['day', '0', 'b', 'c', 'd'], ['', 0, 0, 0, 100]],
        4: [['day', '0', 'b', 'c', 'd'], ['', 0, 0, 0, 100]]
    },
    userActivityQuizScoreRatioDepth: 4,
    userActivityQuizScoreDrillingData: [],
    userActivityQuizScoreDrillingTitle: "",

    userActivityQuizScoreContentDifficulty: {
        1: [['ID', 'Avg quiz attempts', 'Avg module score', 'Module', 'users'], ['', 0, 0, '', 0], ['', 100, 100, '', 0]],
        2: [['ID', 'Avg quiz attempts', 'Avg module score', 'Module', 'users'], ['', 0, 0, '', 0], ['', 100, 100, '', 0]],
        3: [['ID', 'Avg quiz attempts', 'Avg module score', 'Module', 'users'], ['', 0, 0, '', 0], ['', 100, 100, '', 0]],
        4: [['ID', 'Avg quiz attempts', 'Avg module score', 'Module', 'users'], ['', 0, 0, '', 0], ['', 100, 100, '', 0]],

    },
    userActivityContentDifficultyDepth: 4,
    userActivityContentDifficultyDrillingData: [],
    userActivityContentDifficultyDrillingTitle: "",

    userActivityContentPopularity: {
        more: {}, less: {}
    },
    userActivityDifficulty: {},

    //comparison : over time
    comparisonOvertimeData: {
        1: {},
        2: {
            data: [],
            metaData: []
        }
    },
    comparisonOvertimeDataDepth: 2,
    comparisonOvertimeDataDrillingTitle: "",
    comparisonOvertimeDataDrillingData: {
        data: [],
        metaData: []
    },

    //comparison : content avg score
    comparisonContentAvgScore: {},
    comparisonContentAvgScoreDataDepth: 2,
    comparisonContentAvgScoreDrillingTitle: "",
    comparisonContentAvgScoreDrillingData: {
        data: [],
        metaData: []
    },

    //comparison : quiz score
    comparisonQuizScore: {},
    comparisonQuizScoreDepth: 2,
    comparisonQuizScoreDrillingData: [],
    comparisonQuizScoreDrillingTitle: "",


    //comparison : general
    comparisonGeneral: {},
    comparisonGeneralDepth: 2,
    comparisonGeneralDrillingData: [],
    comparisonGeneralDrillingTitle: "",
    selectedSummaryType: "",

    //comparison ratio
    comparisonRatioData: {},
    comparisonRatioCourses: {},
    comparisonRatioDepth: 1,
    comparisonRatioDrillingData: [],
    comparisonRatioDrillingTitle: "",
    comparisonRatioHeaderDrillingData: [],
    comparisonRatioDataUUID: "",
    comparisonRatioDataUUIDCounter: 0,

    selectedClient: {},
    isClientIsBrand: false,
    columnTitle: "DIC_E_LEARNING_REPORTS_SUMMARY_TABLE_COLUMN_TITLE_EMPLOYEE_OVERALL",

    childClients: [],
    filterValueConstants : filterValueConstants,
    reportComparisonPayload:{},
    elearningSelectionRange: selectionRange,
    reportStartDate: timeResult['start_date'],
    reportEndDate: timeResult['end_date'],
    reportEndDateStr: timeResult['end_date_str'],
    reportStartDateStr: timeResult['start_date_str'],
    elearningRefreshCounter: 0

};

const RADIAN = Math.PI / 180;


const e_learning_reports = (state = initialSettings, action) => {

    switch (action.type) {

        case E_LEARNING_REPORTS_COMPARISION_RATIO_USERS:{
            return {
                ...state,
                reportComparisonPayload:action.payload,
                isQueryInProgress: true
            };
        }

        case E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE:
        case E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO:
        case E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN:
        case E_LEARNING_REPORTS_ENROLMENT_STATUSES:
        case E_LEARNING_REPORTS_ENROLMENT_PROPORTION:
        case E_LEARNING_REPORTS_ENROLMENT_TOPICS:
        case E_LEARNING_REPORTS_ENROLMENT_GENERAL:
        case E_LEARNING_REPORTS_COMPARISION_RATIO_COURSES:
        case E_LEARNING_REPORTS_COMPARISION_OVERTIME:
        case E_LEARNING_REPORTS_COMPARISION_GENERAL:
        case E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE:
        case E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE: {
            return {
                ...state,
               
            };
        }

        case E_LEARNING_REPORTS_PROGRESS_END: {
            return {
                ...state,
                isQueryInProgress: false
            };
        }

        case E_LEARNING_REPORTS_PROGRESS_START: {
            return {
                ...state,
                isQueryInProgress: true
            };
        }

        case E_LEARNING_REPORTS_SET_CHILD_CLIENTS: {
            return {
                ...state,
                childClients: action.payload
            };
        }

        case CLIENT_GET_ALL_CLIENTS_SUCCESS: {

            let clientsList = action.payload.clients;
            let selectedClient = {};
            let isClientIsBrand = false;

            if (clientsList.length > 0) {
                selectedClient = clientsList[0];
                isClientIsBrand = AppUtils.isClientIsBrand(selectedClient);

                console.log("isClientIsBrand : " + isClientIsBrand)

            }

            return {
                ...state,
                clientsList: clientsList,
                selectedClient: selectedClient,
                isClientIsBrand: isClientIsBrand,
                selectedSummaryType: isClientIsBrand ? filtersSummariseOptions.parent[0].value : filtersSummariseOptions.child[0].value
            };
        }

        case CLIENT_SELECTED_CLIENT: {

            let isClientIsBrand = AppUtils.isClientIsBrand(action.payload.client)

            return {
                ...state,
                selectedSummaryType: isClientIsBrand ? filtersSummariseOptions.parent[0].value : filtersSummariseOptions.child[0].value
            };

        }

        case E_LEARNING_REPORTS_COMPARISION_USERS_SELECTED_SUMMARISED_RESSET: {
            return {
                ...state,
                comparisonRatioDrillingData: []
            };
        }

        case E_LEARNING_REPORTS_COMPARISION_USERS_SELECTED_SUMMARISED: {

            let parsedData = state.comparisonRatioData;
            parsedData["users"] = parsedData[action.payload];

            let columnTitle = getColumnTitle(action.payload, state.isClientIsBrand);

            console.log("columnTitle : " + columnTitle)

            if (columnTitle === "") {
                columnTitle = state.columnTitle;
            }


            return {
                ...state,
                selectedSummaryType: action.payload,
                columnTitle: columnTitle

                /*columnTitle: getColumnTitle(action.payload),
                comparisonRatioDrillingData: parsedData['users'][state.comparisonRatioDepth],
                comparisonRatioDrillingTitle: state.drillingDataTitles[state.comparisonRatioDepth],
                comparisonRatioHeaderDrillingData: parsedData['columns'][state.comparisonRatioDepth]*/
            };
        }

        case E_LEARNING_REPORTS_ENROLMENT_GENERAL_SUCCESS:
            return {
                ...state,
                enrolmentGeneral: action.payload["general_enrolment"],
                isQueryInProgress: false
            };

        case E_LEARNING_REPORTS_ENROLMENT_TOPICS_SUCCESS:
            return {
                ...state,
                enrolmentTopics: action.payload["topics"],
            };

        case E_LEARNING_REPORTS_ENROLMENT_PROPORTION_SUCCESS:
            return {
                ...state,
                enrolmentProportion: parseEnrolmentProportionResponse(action.payload),
                isQueryInProgress: false
            };

        case E_LEARNING_REPORTS_ENROLMENT_STATUSES_SUCCESS:
            return {
                ...state,
                enrolmentStatuses: parseEnrolmentStatusesResponse(action.payload),
                isQueryInProgress: false
            };

        case E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN_SUCCESS:
            let temp = parseEnrolmentBreakdownResponse(action.payload.data);

            return {
                ...state,
                enrolmentBreakdown: temp["data"],
                enrolmentBreakdownTotalCount: temp['totalEnrolments'],
                isQueryInProgress: false

            };


        case E_LEARNING_REPORTS_ENROLMENT_COURSES_SUCCESS:
            return {
                ...state,
                enrolmentCourses: action.payload
            };

        case E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_SUCCESS: {
            let parsedData = generateUserActivityCompletionRatioByLevel(action.payload);

            return {
                ...state,
                userActivityCompletionRatio: parsedData,
                userActivityCompletionRatioDrillingData: parsedData[state.userActivityCompletionRatioDepth],
                userActivityCompletionRatioDrillingTitle: state.drillingDataTitles[state.userActivityCompletionRatioDepth],
                isQueryInProgress: false
            };

        }


        case E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE_SUCCESS:

            let parsedData = parseUserActivityQuizDistributionResponse(action.payload);

            return {
                ...state,
                userActivityQuizScore: parsedData,
                userActivityQuizScoreDrillingData: parsedData[state.userActivityCompletionRatioDepth],
                userActivityQuizScoreDrillingTitle: state.drillingDataTitles[state.userActivityCompletionRatioDepth],
                isQueryInProgress: false

            };

        case E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_POPULARITY_SUCCESS:
            return {
                ...state,
                userActivityContentPopularity: action.payload["content_popularity"]["data"]
            };

        case E_LEARNING_REPORTS_USER_ACTIVITY_DIFFICULTY_SUCCESS: {
            let parsedData = parseUserActivityContentDifficultyResponse(action.payload);
            return {
                ...state,
                userActivityQuizScoreContentDifficulty: parsedData,
                userActivityContentDifficultyDrillingData: parsedData[state.userActivityContentDifficultyDepth],
                userActivityContentDifficultyDrillingTitle: state.drillingDataTitles[state.userActivityContentDifficultyDepth],
                isQueryInProgress: false
            };
        }


        case E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_UA_DRILLING_INCREMENT: {
            let depth = state.userActivityCompletionRatioDepth;

            if (depth > 1) {
                depth--;
            }

            return {
                ...state,
                userActivityCompletionRatioDrillingData: state.userActivityCompletionRatio[depth],
                userActivityCompletionRatioDrillingTitle: state.drillingDataTitles[depth],
                userActivityCompletionRatioDepth: depth
            };
        }

        case E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO_UA_DRILLING_DECREMENT: {
            let depth = state.userActivityCompletionRatioDepth;
            if (depth < userActionMaxDepth) {
                depth++;
            }
            return {
                ...state,
                userActivityCompletionRatioDrillingData: state.userActivityCompletionRatio[depth],
                userActivityCompletionRatioDrillingTitle: state.drillingDataTitles[depth],
                userActivityCompletionRatioDepth: depth
            };

        }

        case E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_DISTRIBUTION_UA_DRILLING_INCREMENT: {
            let depth = state.userActivityQuizScoreRatioDepth;
            if (depth < userActionMaxDepth) {
                depth++;
            }

            return {
                ...state,
                userActivityQuizScoreDrillingData: state.userActivityQuizScore[depth],
                userActivityQuizScoreDrillingTitle: state.drillingDataTitles[depth],
                userActivityQuizScoreRatioDepth: depth
            };
        }

        case E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_DISTRIBUTION_UA_DRILLING_DECREMENT: {
            let depth = state.userActivityQuizScoreRatioDepth;
            if (depth > 1) {
                depth--;
            }
            return {
                ...state,
                userActivityQuizScoreDrillingData: state.userActivityQuizScore[depth],
                userActivityQuizScoreDrillingTitle: state.drillingDataTitles[depth],
                userActivityQuizScoreRatioDepth: depth
            };

        }

        case E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_DIFFICULTY_UA_DRILLING_INCREMENT: {

            let depth = state.userActivityContentDifficultyDepth;
            if (depth > 1) {
                depth--;
            }


            return {
                ...state,
                userActivityContentDifficultyDrillingData: state.userActivityQuizScoreContentDifficulty[depth],
                userActivityContentDifficultyDrillingTitle: state.drillingDataTitles[depth],
                userActivityContentDifficultyDepth: depth
            };
        }

        case E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_DIFFICULTY_UA_DRILLING_DECREMENT: {
            let depth = state.userActivityContentDifficultyDepth;
            if (depth < userActionMaxDepth) {
                depth++;
            }
            return {
                ...state,
                userActivityContentDifficultyDrillingData: state.userActivityQuizScoreContentDifficulty[depth],
                userActivityContentDifficultyDrillingTitle: state.drillingDataTitles[depth],
                userActivityContentDifficultyDepth: depth
            };

        }

        case E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_UUID_DATA_SUCCESS: {

            if (action.payload.status === "SUCCESS") {
                let parsedData = parseComparisonRatioResponse(action.payload.data, state.clientsList, state.isClientIsBrand, state.childClients);
                parsedData["users"] = parsedData[state.selectedSummaryType];

                return {
                    ...state,
                    //selectedSummaryType: state.isClientIsBrand ? filtersSummariseOptions.parent[0].value : filtersSummariseOptions.child[0].value,
                    comparisonRatioData: parsedData,
                    comparisonRatioCourses: parsedData['columns'],
                    comparisonRatioDrillingData: parsedData['users'][state.comparisonRatioDepth],
                    comparisonRatioDrillingTitle: state.drillingDataTitles[state.comparisonRatioDepth],
                    comparisonRatioHeaderDrillingData: parsedData['columns'][state.comparisonRatioDepth],
                    isQueryInProgress: false,
                    comparisonRatioDataUUID: "",
                    comparisonRatioDataUUIDCounter: 0
                };
            } else if (action.payload.status === "IN_PROGRESS") {
                return {
                    ...state,
                    comparisonRatioDataUUID: action.payload.uuid,
                    comparisonRatioDataUUIDCounter: state.comparisonRatioDataUUIDCounter + 1,
                }
            } else if (action.payload.status === "NOT_FOUND") {
                return {
                    ...state,
                    comparisonRatioDataUUID: "",
                    comparisonRatioDataUUIDCounter: 0,
                }
            }

            break;
        }

        //comparison ratio start
        case E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_SUCCESS: {

            let parsedData = parseComparisonRatioResponse(action.payload, state.clientsList, state.isClientIsBrand, state.childClients);
            parsedData["users"] = parsedData[state.selectedSummaryType];


            return {
                ...state,
                //selectedSummaryType: state.isClientIsBrand ? filtersSummariseOptions.parent[0].value : filtersSummariseOptions.child[0].value,
                comparisonRatioData: parsedData,
                comparisonRatioCourses: parsedData['columns'],
                comparisonRatioDrillingData: parsedData['users'][state.comparisonRatioDepth],
                comparisonRatioDrillingTitle: state.drillingDataTitles[state.comparisonRatioDepth],
                comparisonRatioHeaderDrillingData: parsedData['columns'][state.comparisonRatioDepth],
                isQueryInProgress: false
            };
        }

        case E_LEARNING_REPORTS_COMPARISION_RATIO_UA_DRILLING_INCREMENT: {

            let depth = state.comparisonRatioDepth;
            if (depth > 1) {
                depth--;
            }


            return {
                ...state,
                comparisonRatioDrillingData: state.comparisonRatioData['users'][depth],
                comparisonRatioDrillingTitle: state.drillingDataTitles[depth],
                comparisonRatioHeaderDrillingData: state.comparisonRatioData['columns'][depth],
                comparisonRatioDepth: depth
            };
        }

        case E_LEARNING_REPORTS_COMPARISION_RATIO_UA_DRILLING_DECREMENT: {
            let depth = state.comparisonRatioDepth;
            if (depth < comparisonRatioMaxDepth) {
                depth++;
            }
            return {
                ...state,
                comparisonRatioDrillingData: state.comparisonRatioData['users'][depth],
                comparisonRatioDrillingTitle: state.drillingDataTitles[depth],
                comparisonRatioHeaderDrillingData: state.comparisonRatioData['columns'][depth],
                comparisonRatioDepth: depth
            };

        }

        //comparison ratio end


        //comparison general start
        case E_LEARNING_REPORTS_COMPARISION_GENERAL_SUCCESS: {

            let parsedData = parseComparisonGeneralResponse(action.payload);
            return {
                ...state,
                comparisonGeneral: parsedData,
                comparisonGeneralDrillingData: parsedData[state.comparisonGeneralDepth],
                comparisonGeneralDrillingTitle: state.drillingDataTitlesComparision[state.comparisonGeneralDepth]
            };
        }

        case E_LEARNING_REPORTS_COMPARISION_GENERAL_UA_DRILLING_INCREMENT: {

            let depth = state.comparisonGeneralDepth;
            if (depth > 1) {
                depth--;
            }


            return {
                ...state,
                comparisonGeneralDrillingData: state.comparisonGeneral[depth],
                comparisonGeneralDrillingTitle: state.drillingDataTitlesComparision[depth],
                comparisonGeneralDepth: depth
            };
        }

        case E_LEARNING_REPORTS_COMPARISION_GENERAL_UA_DRILLING_DECREMENT: {
            let depth = state.comparisonGeneralDepth;
            if (depth < comparisonOverTimeMaxDepth) {
                depth++;
            }
            return {
                ...state,
                comparisonGeneralDrillingData: state.comparisonGeneral[depth],
                comparisonGeneralDrillingTitle: state.drillingDataTitlesComparision[depth],
                comparisonGeneralDepth: depth
            };

        }

        //comparison general end

        //comparison content avg score start
        case E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_SUCCESS: {

            let parsedData = parseComparisonContentAvgScoreResponse(action.payload);
            return {
                ...state,
                comparisonContentAvgScore: parsedData,
                comparisonContentAvgScoreDrillingData: parsedData[state.comparisonContentAvgScoreDataDepth],
                comparisonContentAvgScoreDrillingTitle: state.drillingDataTitlesComparision[state.comparisonContentAvgScoreDataDepth]
            };

        }

        case E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_UA_DRILLING_INCREMENT: {

            let depth = state.comparisonContentAvgScoreDataDepth;
            if (depth > 1) {
                depth--;
            }


            return {
                ...state,
                comparisonContentAvgScoreDrillingData: state.comparisonContentAvgScore[depth],
                comparisonContentAvgScoreDrillingTitle: state.drillingDataTitlesComparision[depth],
                comparisonContentAvgScoreDataDepth: depth
            };
        }

        case E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE_UA_DRILLING_DECREMENT: {
            let depth = state.comparisonContentAvgScoreDataDepth;
            if (depth < comparisonOverTimeMaxDepth) {
                depth++;
            }
            return {
                ...state,
                comparisonContentAvgScoreDrillingData: state.comparisonContentAvgScore[depth],
                comparisonContentAvgScoreDrillingTitle: state.drillingDataTitlesComparision[depth],
                comparisonContentAvgScoreDataDepth: depth
            };

        }
        //comparison content avg score end

        //comparison quiz score start
        case E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_SUCCESS: {

            let parsedData = parseComparisonQuizScoreResponse(action.payload);
            return {
                ...state,
                comparisonQuizScore: parsedData,
                comparisonQuizScoreDrillingData: parsedData[state.comparisonQuizScoreDepth],
                comparisonQuizScoreDrillingTitle: state.drillingDataTitlesComparision[state.comparisonQuizScoreDepth]
            };
        }

        case E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_UA_DRILLING_INCREMENT: {

            let depth = state.comparisonQuizScoreDepth;
            if (depth > 1) {
                depth--;
            }


            return {
                ...state,
                comparisonQuizScoreDrillingData: state.comparisonQuizScore[depth],
                comparisonQuizScoreDrillingTitle: state.drillingDataTitlesComparision[depth],
                comparisonQuizScoreDepth: depth
            };
        }

        case E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE_UA_DRILLING_DECREMENT: {
            let depth = state.comparisonQuizScoreDepth;
            if (depth < comparisonOverTimeMaxDepth) {
                depth++;
            }
            return {
                ...state,
                comparisonQuizScoreDrillingData: state.comparisonQuizScore[depth],
                comparisonQuizScoreDrillingTitle: state.drillingDataTitlesComparision[depth],
                comparisonQuizScoreDepth: depth
            };

        }

        //comparison quiz score end

        //comparison over time start
        case E_LEARNING_REPORTS_COMPARISION_OVERTIME_SUCCESS: {
            let parsedData = parseComparisonOvertimeResponse(action.payload);
            return {
                ...state,
                comparisonOvertimeData: parsedData,
                comparisonOvertimeDataDrillingData: parsedData[state.comparisonOvertimeDataDepth],
                comparisonOvertimeDataDrillingTitle: state.drillingDataTitlesComparision[state.comparisonOvertimeDataDepth],
            };
        }

        case E_LEARNING_REPORTS_COMPARISION_OVERTIME_UA_DRILLING_INCREMENT: {

            let depth = state.comparisonOvertimeDataDepth;
            if (depth > 1) {
                depth--;
            }


            return {
                ...state,
                comparisonOvertimeDataDrillingData: state.comparisonOvertimeData[depth],
                comparisonOvertimeDataDrillingTitle: state.drillingDataTitlesComparision[depth],
                comparisonOvertimeDataDepth: depth
            };
        }

        case E_LEARNING_REPORTS_COMPARISION_OVERTIME_UA_DRILLING_DECREMENT: {
            let depth = state.comparisonOvertimeDataDepth;
            if (depth < comparisonOverTimeMaxDepth) {
                depth++;
            }
            return {
                ...state,
                comparisonOvertimeDataDrillingData: state.comparisonOvertimeData[depth],
                comparisonOvertimeDataDrillingTitle: state.drillingDataTitlesComparision[depth],
                comparisonOvertimeDataDepth: depth
            };

        }
        //comparison over time end

        /**to update elearning report date range */
        case REPORT_FILTERS_ELEARNING_UPDATE_DATE_RANGE: {
            return{
                ...state,
                elearningSelectionRange: action.payload
            };
        }

        /** to set elarning date range**/
        case REPORT_FILTERS_ELEARNING_SET_DATE_RANGE: {

            let timeResult = AppUtils.getReportsDates(action.payload.startDate, action.payload.endDate);

            return {
                ...state,
                reportStartDate: timeResult['start_date'],
                reportEndDate: timeResult['end_date'],
                reportEndDateStr: timeResult['end_date_str'],
                reportStartDateStr: timeResult['start_date_str'],
                elearningRefreshCounter: state.elearningRefreshCounter + 1
            };
        }

        default:
            return state;
    }
};

const parseComparisonRatioHeadersByLevel = (data, level) => {
    let result = [];
    try {
        for (let i = 0; i < data.length; i++) {

            if (level === data[i]['group_level']) {
                result.push(data[i]['name']);
                //result.push(parseHeaderText(data[i]['name']));
            }
        }
    } catch (ex) {
    }

    return result;
};


const parseHeaderText = (name) => {

    let result = name;

    try {

        if (typeof name != "undefined" && name !== "") {
            let tempArray;

            if (name[0] === "{") {
                tempArray = name.split("{");

                if (tempArray.length > 1) {
                    tempArray = tempArray[1].split("}")

                    if (tempArray.length > 1) {
                        result = tempArray[1]
                    }
                }
            } else {
                tempArray = name.split("{");

                if (tempArray.length > 0) {
                    result = tempArray[0]
                }
            }
        }
    } catch (e) {

    }

    return result;
};

/****
 * E Learning :  Comparison ratio : users : headers
 ****/
const parseComparisonRatioHeaders = (data) => {

    let result = {};


    try {

        let subCategories = [];
        let courses = [];
        let tempArr;

        for (let i = 0; i < data.length; i++) {

            tempArr = parseComparisonRatioHeadersByLevel(data[i]['child_items'], 2);
            if (tempArr.length > 0) {
                subCategories = subCategories.concat(tempArr);
            }

            for (let j = 0; j < data[i]['child_items'].length; j++) {
                tempArr = parseComparisonRatioHeadersByLevel(data[i]['child_items'][j]['child_items'], 3);
                if (tempArr.length > 0) {
                    courses = courses.concat(tempArr);
                }
            }
        }

        result[1] = parseComparisonRatioHeadersByLevel(data, 1);
        result[2] = subCategories;
        result[3] = courses;

    } catch (ex) {
    }

    return result;
};

/****
 * E Learning :  Comparison ratio : users : headers
 ****/
const getComparisonRatioUserRecord = (user_id, list, isCustomFields) => {

    let result = {};
    try {

        result['id'] = user_id;

        for (let i = 0; i < list.length; i++) {
            result[list[i]['name'] + '_enrolment'] = list[i]['enrolment'];
            result[list[i]['name'] + '_lesson_completed'] = list[i]['lesson_completed'];
            result[list[i]['name'] + '_quiz_top_score'] = list[i]['quiz_top_score'];
            result['unique_enrolment'] = list[i]['unique_enrolment'];
            result['unique_lesson_completed'] = list[i]['unique_lesson_completed'];
            result['unique_quiz_top_score'] = list[i]['unique_quiz_top_score'];
        }

    } catch (e) {

    }

    return result;
}

/****
 * E Learning :  Comparison ratio : to get summarise filters keys
 ****/

const getKeysArrayBySummarise = (summarise, childClients) => {

    switch (summarise) {
        // overall
        case filterValueConstants.FILTER_VALUE_OVERALL:
            return overallDataType;

        // gender
        case filterValueConstants.FILTER_VALUE_GENDER:
            return genderDataType;

        // age group
        case filterValueConstants.FILTER_VALUE_AGE_GROUP:
            return ageGroupDataType;

        // tenure group
        case filterValueConstants.FILTER_VALUE_TENURE_GROUP:
            return tenureGroupDataType;

        // smart phone
        case filterValueConstants.FILTER_VALUE_SMART_PHONE:
            return smartPhoneDataType;

        // company
        case filterValueConstants.FILTER_VALUE_COMPANY:
            let result = [];

            for (let i = 0; i < childClients.length; i++) {
                result.push({
                    label: <IntlMessages id={childClients[i].name}/>,
                    value: childClients[i].id,
                })
            }

            return result;

        // country
        case filterValueConstants.FILTER_VALUE_COUNTRY: {
            let result = [];

            for (let i = 0; i < childClients.length; i++) {
                let isValid = true;
                for (let j = 0; j < result.length; j++) {
                    if (result[j].label === childClients[i].country) {
                        isValid = false;
                        break;
                    }
                }

                if (isValid) {
                    result.push({
                        label: childClients[i].country,
                        value: childClients[i].country,
                    })
                }
            }

            return result;
        }

        // individual
        case filterValueConstants.FILTER_VALUE_INDIVIDUAL:
            return overallDataType;

        default:
            break;

    }

    return overallDataType;
}


const formatValue = (value) => {

    try {
        return isInt(value) ? value : value.toFixed(2);
    } catch (e) {

    }

    return value;
};

function isInt(n) {
    return n % 1 === 0;
}

const formatValues = (item) => {
    item['enrolment'] = formatValue(item['enrolment']);
    item['lesson_completed'] = formatValue(item['lesson_completed']);
    item['quiz_top_score'] = formatValue(item['quiz_top_score']);
    item['unique_enrolment'] = formatValue(item['unique_enrolment']);
    item['unique_lesson_completed'] = formatValue(item['unique_lesson_completed']);
    item['unique_quiz_top_score'] = formatValue(item['unique_quiz_top_score']);

    return item;
};

/****
 * E Learning :  Comparison ratio : users : headers
 ****/
const parseComparisonRatioFiltersData = (data, childClients) => {

    let result = {};
    try {

        let course_users = data['comparison_data'];
        let categories = data['courses'];
        let summarisedBy = data['summarised_by'];


        let category_data = course_users['1'];

        let keysArray = getKeysArrayBySummarise(summarisedBy, childClients);

        let categoryUsersList = [];


        for (let i = 0; i < keysArray.length; i++) {

            let result_category = [];
            let uniqueEnrolments = 0;
            let uniqueLessonCompleted = 0;
            let uniqueQuizAvg = 0;
            let uniqueQuizAvgCounter = 0;

            for (let j = 0; j < categories.length; j++) {
                if (typeof  categories[j]['parent_id'] != "undefined" && categories[j]['parent_id'] !== 0 ){
                    continue;
                }

                let temp_category = {};
                temp_category['name'] = categories[j]['name'];
                temp_category['enrolment'] = 0;
                temp_category['lesson_completed'] = 0;
                temp_category['quiz_top_score'] = 0;
                temp_category['unique_enrolment'] = 0;
                temp_category['unique_lesson_completed'] = 0;
                temp_category['unique_quiz_top_score'] = 0;


                if (typeof category_data != "undefined") {
                    for (let k = 0; k < category_data.length; k++) {

                        if (category_data[k]['id'] === categories[j]['id'] && keysArray[i]['value'] === category_data[k]['emp_id']) {
                            temp_category['enrolment'] = category_data[k]['enrolment'];
                            temp_category['lesson_completed'] = category_data[k]['lesson_completed'];
                            temp_category['quiz_top_score'] = category_data[k]['quiz_top_score'] ? category_data[k]['quiz_top_score'] : 0;
                            temp_category['unique_enrolment'] = category_data[k]['unique_enrolment'];
                            temp_category['unique_lesson_completed'] = category_data[k]['unique_lesson_completed'];
                            temp_category['unique_quiz_top_score'] = category_data[k]['avg_top_quiz_score'] ? category_data[k]['avg_top_quiz_score'] : 0;
                            uniqueEnrolments += temp_category['unique_enrolment'];
                            uniqueLessonCompleted += temp_category['unique_lesson_completed'];

                            if (temp_category['unique_quiz_top_score'] && temp_category['unique_quiz_top_score'] !== 0) {
                                uniqueQuizAvg += temp_category['unique_quiz_top_score'];
                                uniqueQuizAvgCounter += 1;
                            }

                            break;
                        }
                    }
                }

                result_category.push(temp_category);

            }

            for (let i = 0; i < result_category.length; i++) {
                /*result_category[i]['unique_enrolment'] = uniqueEnrolments;
                result_category[i]['unique_lesson_completed'] = uniqueLessonCompleted;*/
                result_category[i]['unique_quiz_top_score'] = uniqueQuizAvg;
                result_category[i] = formatValues(result_category[i]);
            }

            let temp_category = getComparisonRatioUserRecord(keysArray[i]['label'], result_category);
            categoryUsersList.push(temp_category);

        }

        result[1] = categoryUsersList;


        /*let course_users = data['comparison_data'];
        let categories = data['courses'];
        let summarisedBy = data['summarised_by']


        let keysArray = getKeysArrayBySummarise(summarisedBy, childClients);

        let courseUsersList = [];
        let childCategoryUsersList = [];
        let categoryUsersList = [];

        for (let i = 0; i < keysArray.length; i++) {


            let result_course = [];
            let result_child_category = [];
            let result_category = [];


            for (let j = 0; j < categories.length; j++) {

                if (categories[j]['name'] === 'Personal Wellbeing') {
                    continue
                }

                let temp_category = {};

                temp_category['name'] = categories[j]['name'];
                temp_category['enrolment'] = 0;
                temp_category['lesson_completed'] = 0;
                temp_category['quiz_top_score'] = 0;
                temp_category['unique_enrolment'] = 0;
                temp_category['unique_lesson_completed'] = 0;
                temp_category['unique_quiz_top_score'] = 0;


                let child_categories = categories[j]['child_items'];

                for (let k = 0; k < child_categories.length; k++) {

                    let temp_child_category = {};

                    temp_child_category['name'] = child_categories[k]['name'];
                    temp_child_category['enrolment'] = 0;
                    temp_child_category['lesson_completed'] = 0;
                    temp_child_category['quiz_top_score'] = 0;
                    temp_child_category['unique_enrolment'] = 0;
                    temp_child_category['unique_lesson_completed'] = 0;
                    temp_child_category['unique_quiz_top_score'] = 0;


                    let courses = child_categories[k]['child_items'];

                    for (let l = 0; l < courses.length; l++) {
                        let temp_course = {};
                        temp_course['name'] = courses[l]['name'];
                        temp_course['enrolment'] = 0;
                        temp_course['lesson_completed'] = 0;
                        temp_course['quiz_top_score'] = 0;
                        temp_course['unique_enrolment'] = 0;
                        temp_course['unique_lesson_completed'] = 0;
                        temp_course['unique_quiz_top_score'] = 0;


                        for (let m = 0; m < course_users.length; m++) {

                            let isValid = false;
                            let dataIndex = -1;

                            if (typeof course_users[m].data != "undefined") {
                                for (let n = 0; n < course_users[m].data.length; n++) {

                                    if (course_users[m].data[n].emp_id === keysArray[i].value) {
                                        dataIndex = n;
                                        isValid = true;
                                        break;
                                    }

                                }
                            }

                            if (isValid && courses[l]['id'] === course_users[m]['id']) {

                                let item = course_users[m].data[dataIndex];

                                temp_course['enrolment'] = item['courses']['enrolment'];
                                temp_course['lesson_completed'] = item['courses']['lesson_completed'];
                                temp_course['quiz_top_score'] = item['courses']['quiz_top_score'] != null ? item['courses']['quiz_top_score'] : 0;
                                temp_course['unique_enrolment'] = item['unique_enrolment'];
                                temp_course['unique_lesson_completed'] = item['unique_lesson_completed'];
                                temp_course['unique_quiz_top_score'] = item['avg_top_quiz_score'] != null ? item['avg_top_quiz_score'] : 0;

                                temp_child_category['enrolment'] += temp_course['enrolment'];
                                temp_child_category['lesson_completed'] += temp_course['lesson_completed'];
                                temp_child_category['quiz_top_score'] += temp_course['quiz_top_score'];
                                temp_child_category['unique_enrolment'] = temp_course['unique_enrolment'];
                                temp_child_category['unique_lesson_completed'] = temp_course['unique_lesson_completed'];
                                temp_child_category['unique_quiz_top_score'] = temp_course['unique_quiz_top_score'];

                                temp_category['enrolment'] += temp_course['enrolment'];
                                temp_category['lesson_completed'] += temp_course['lesson_completed'];
                                temp_category['quiz_top_score'] += temp_course['quiz_top_score'];
                                temp_category['unique_enrolment'] = temp_course['unique_enrolment'];
                                temp_category['unique_lesson_completed'] = temp_course['unique_lesson_completed'];
                                temp_category['unique_quiz_top_score'] = temp_course['unique_quiz_top_score'];

                                break;
                            }
                        }

                        temp_course = formatValues(temp_course);
                        result_course.push(temp_course);

                        break;
                    }

                    temp_child_category = formatValues(temp_child_category);
                    result_child_category.push(temp_child_category);
                }

                temp_category = formatValues(temp_category);
                result_category.push(temp_category);

            }

            let temp_course = getComparisonRatioUserRecord(keysArray[i]['label'], result_course);
            let temp_child_category = getComparisonRatioUserRecord(keysArray[i]['label'], result_child_category);
            let temp_category = getComparisonRatioUserRecord(keysArray[i]['label'], result_category);

            courseUsersList.push(temp_course);
            childCategoryUsersList.push(temp_child_category);
            categoryUsersList.push(temp_category);
        }

        result[1] = categoryUsersList;
        result[2] = childCategoryUsersList;
        result[3] = courseUsersList;
        */
    } catch
        (e) {
    }

    return result;
}

/****
 * E Learning :  Comparison ratio : users : headers
 ****/
const parseComparisonRatioUsers = (course_users, categories, users_list) => {
    let result = {};
    try {

        let courseUsersList = [];
        let childCategoryUsersList = [];
        let categoryUsersList = [];
        let completedArray = {};

        for (let i = 0; i < users_list.length; i++) {

            let result_course = [];
            let result_child_category = [];
            let result_category = [];


            for (let j = 0; j < categories.length; j++) {

                let temp_category = {};

                temp_category['name'] = categories[j]['name'];
                temp_category['enrolment'] = 0;
                temp_category['lesson_completed'] = 0;
                temp_category['quiz_top_score'] = 0;
                temp_category['unique_enrolment'] = 0;
                temp_category['unique_lesson_completed'] = 0;
                temp_category['unique_quiz_top_score'] = 0;


                let child_categories = categories[j]['child_items'];

                for (let k = 0; k < child_categories.length; k++) {

                    let temp_child_category = {};

                    temp_child_category['name'] = child_categories[k]['name'];
                    temp_child_category['enrolment'] = 0;
                    temp_child_category['lesson_completed'] = 0;
                    temp_child_category['quiz_top_score'] = 0;
                    temp_child_category['unique_enrolment'] = 0;
                    temp_child_category['unique_lesson_completed'] = 0;
                    temp_child_category['unique_quiz_top_score'] = 0;


                    let courses = child_categories[k]['child_items'];

                    for (let l = 0; l < courses.length; l++) {
                        let temp_course = {};
                        temp_course['name'] = courses[l]['name'];
                        temp_course['enrolment'] = 0;
                        temp_course['lesson_completed'] = 0;
                        temp_course['quiz_top_score'] = 0;
                        temp_course['unique_enrolment'] = 0;
                        temp_course['unique_lesson_completed'] = 0;
                        temp_course['unique_quiz_top_score'] = 0;


                        for (let m = 0; m < course_users.length; m++) {
                            if (courses[l]['id'] === course_users[m]['id']) {

                                let temp_users = course_users[m]['data'];

                                for (let n = 0; n < temp_users.length; n++) {

                                    if (users_list[i]['userid'] === temp_users[n]['emp_id']) {


                                        if (completedArray[temp_course['name'] + "_" + users_list[i]['userid']]) {
                                            continue;
                                        }

                                        completedArray[temp_course['name'] + "_" + users_list[i]['userid']] = true;

                                        temp_course['enrolment'] = temp_users[n]['courses']['enrolment'];
                                        temp_course['lesson_completed'] = temp_users[n]['courses']['lesson_completed'];
                                        temp_course['quiz_top_score'] = temp_users[n]['courses']['quiz_top_score'] != null ? temp_users[n]['courses']['quiz_top_score'] : 0;
                                        temp_course['unique_enrolment'] = temp_users[n]['unique_enrolment'];
                                        temp_course['unique_lesson_completed'] = temp_users[n]['unique_lesson_completed'];
                                        temp_course['unique_quiz_top_score'] = temp_users[n]['avg_top_quiz_score'] != null ? temp_users[n]['avg_top_quiz_score'] : 0;

                                        temp_child_category['enrolment'] += temp_course['enrolment'];
                                        temp_child_category['lesson_completed'] += temp_course['lesson_completed'];
                                        temp_child_category['quiz_top_score'] += temp_course['quiz_top_score'];
                                        temp_child_category['unique_enrolment'] = temp_course['unique_enrolment'];
                                        temp_child_category['unique_lesson_completed'] = temp_course['unique_lesson_completed'];
                                        temp_child_category['unique_quiz_top_score'] = temp_course['unique_quiz_top_score'];

                                        temp_category['enrolment'] += temp_course['enrolment'];
                                        temp_category['lesson_completed'] += temp_course['lesson_completed'];
                                        temp_category['quiz_top_score'] += temp_course['quiz_top_score'];
                                        temp_category['unique_enrolment'] = temp_course['unique_enrolment'];
                                        temp_category['unique_lesson_completed'] = temp_course['unique_lesson_completed'];
                                        temp_category['unique_quiz_top_score'] = temp_course['unique_quiz_top_score'];

                                        temp_course['company_id'] = temp_users[n]['company_id'];
                                        temp_course['country_id'] = temp_users[n]['country_id'];
                                        temp_course['dob'] = temp_users[n]['dob'];
                                        temp_course['emp_id'] = temp_users[n]['emp_id'];
                                        temp_course['gender_id'] = temp_users[n]['gender_id'];
                                        temp_course['is_smart_phone'] = temp_users[n]['is_smart_phone'];
                                        temp_course['created_date'] = temp_users[n]['c_d'];

                                        temp_child_category['company_id'] = temp_users[n]['company_id'];
                                        temp_child_category['country_id'] = temp_users[n]['country_id'];
                                        temp_child_category['dob'] = temp_users[n]['dob'];
                                        temp_child_category['emp_id'] = temp_users[n]['emp_id'];
                                        temp_child_category['gender_id'] = temp_users[n]['gender_id'];
                                        temp_child_category['is_smart_phone'] = temp_users[n]['is_smart_phone'];
                                        temp_child_category['created_date'] = temp_users[n]['c_d'];

                                        temp_category['company_id'] = temp_users[n]['company_id'];
                                        temp_category['country_id'] = temp_users[n]['country_id'];
                                        temp_category['dob'] = temp_users[n]['dob'];
                                        temp_category['emp_id'] = temp_users[n]['emp_id'];
                                        temp_category['gender_id'] = temp_users[n]['gender_id'];
                                        temp_category['is_smart_phone'] = temp_users[n]['is_smart_phone'];
                                        temp_category['c_d'] = temp_users[n]['c_d'];

                                        break;
                                    }
                                }
                            }
                        }

                        result_course.push(temp_course);

                        break;
                    }

                    result_child_category.push(temp_child_category);
                }

                result_category.push(temp_category);

            }

            let temp_course = getComparisonRatioUserRecord(users_list[i]['id'], result_course, true);
            let temp_child_category = getComparisonRatioUserRecord(users_list[i]['id'], result_child_category, true);
            let temp_category = getComparisonRatioUserRecord(users_list[i]['id'], result_category, true);

            courseUsersList.push(temp_course);
            childCategoryUsersList.push(temp_child_category);
            categoryUsersList.push(temp_category);
        }

        result[1] = categoryUsersList;
        result[2] = childCategoryUsersList;
        result[3] = courseUsersList;

    } catch
        (e) {
    }

    console.log("result child : ")
    console.log(result)

    return result;

};

const getMissedHeaders = (prevValue, mappedValues) => {

    let result = [];

    try {

        let modifiedKey;
        let convertedHeader;


        for (let i = 0; i < mappedValues.length; i++) {
            let tempJSON = mappedValues[i];

            let prevKeys = [];

            for (let key in tempJSON) {
                if (key !== "id" && key !== "unique_enrolment" && key !== "unique_lesson_completed" && key !== "unique_quiz_top_score" &&
                    key.indexOf('_lesson_completed') === -1 && key.indexOf('_quiz_top_score') === -1) {
                    prevKeys.push(key);
                }
            }

            for (let j = 0; j < prevKeys.length; j++) {
                modifiedKey = prevKeys[j].replace("_enrolment", "");


                convertedHeader = parseHeaderText(modifiedKey);

                if (convertedHeader !== modifiedKey) {


                    tempJSON[convertedHeader + '_enrolment'] = tempJSON[modifiedKey + "_enrolment"];
                    tempJSON[convertedHeader + '_quiz_top_score'] = tempJSON[modifiedKey + "_quiz_top_score"];
                    tempJSON[convertedHeader + '_lesson_completed'] = tempJSON[modifiedKey + "_lesson_completed"];

                    delete tempJSON[modifiedKey + "_enrolment"];
                    delete tempJSON[modifiedKey + "_quiz_top_score"];
                    delete tempJSON[modifiedKey + "_lesson_completed"];
                }
            }


            for (let key in tempJSON) {

                if (key !== "unique_enrolment" && key.indexOf("_enrolment") !== -1) {

                    let isValid = true;
                    let resultValue = key;

                    resultValue = resultValue.replace("_enrolment", "");

                    for (let j = 0; j < result.length; j++) {
                        if (result[j] === resultValue) {
                            isValid = false;
                            break;
                        }
                    }

                    if (isValid) {
                        result.push(resultValue);
                    }

                }
            }
        }

        let tempArr = [];
        let temp;

        for (let i = 0; i < prevValue.length; i++) {

            temp = parseHeaderText(prevValue[i]);

            let isValid = true;

            for (let j = 0; j < tempArr.length; j++) {
                if (temp === tempArr[j]) {
                    isValid = false;
                }
            }

            if (isValid) {
                tempArr.push(temp);
            }
        }

        for (let i = 0; i < result.length; i++) {

            let isValid = true;

            for (let j = 0; j < tempArr.length; j++) {
                if (result[i] === tempArr[j]) {
                    isValid = false;
                }
            }

            if (isValid) {
                tempArr.push(result[i]);
            }
        }

        result = tempArr;

    } catch (e) {

    }

    return result;
};

/****
 * E Learning :  Comparison ratio : users : http response parser
 ****/
const parseComparisonRatioResponse = (data, clientsList, isClientIsBrand, childClients) => {

        let result = {};


        try {

            result['columns'] = parseComparisonRatioHeaders(data['courses']);

            let summarisedBy = data['summarised_by'];

            // individual filters
            if (summarisedBy === filterValueConstants.FILTER_VALUE_INDIVIDUAL) {
                result[summarisedBy] = parseComparisonRatioUsers(data['comparison_data'], data['courses'], data['users']);

                // non individual filters
            } else {
                result[summarisedBy] = parseComparisonRatioFiltersData(data, childClients);
            }

            console.log("result : ")
            console.log(result)

            result['columns'][1] = getMissedHeaders(result['columns'][1], result[summarisedBy][1]);
            result['columns'][2] = getMissedHeaders(result['columns'][2], result[summarisedBy][2]);


            result['columns'][3] = getMissedHeaders(result['columns'][3], result[summarisedBy][3]);


        } catch
            (ex) {
            console.error(ex);
        }

        return result;
    }
;

/****
 * E Learning :  Comparison : Content avg score : clients
 ****/
const getCountriesGeneralItemFromCourseList = (courseData) => {
    let result = [];


    try {

        let countryList = [];

        for (let i = 0; i < courseData.length; i++) {
            let isExist = false;
            for (let j = 0; j < countryList.length; j++) {
                if (countryList[j] === courseData[i].country) {
                    isExist = true;
                    break;
                }
            }
            if (!isExist) {
                countryList.push(courseData[i].country);
            }
        }


        for (let i = 0; i < countryList.length; i++) {

            let rowData = {};
            rowData['name'] = countryList[i];
            let children = [];

            for (let j = 0; j < courseData.length; j++) {

                if (courseData[j].country === countryList[i]) {
                    let temp = {}
                    temp['name'] = courseData[j]['name'];
                    temp['size'] = courseData[j]['value'];
                    children.push(temp);
                }
            }
            rowData['children'] = children;
            result.push(rowData);
        }

    } catch (ex) {
    }

    return result;
};


/****
 * E Learning :  Comparison : Content avg score : clients
 ****/
const getClientsGeneralItemFromCourseList = (courseData) => {
    let result = [];

    try {
        for (let i = 0; i < courseData.length; i++) {
            let rowData = {};
            rowData['name'] = courseData[i]['name'];
            rowData['size'] = courseData[i]['value'];
            result.push(rowData);
        }

    } catch (ex) {
    }

    return result;
};

/****
 * E Learning :  Comparison : quiz score : http response parser
 ****/
const parseComparisonGeneralResponse = (data) => {

    let general = {};

    try {

        let comparison_general = data["comparison_general"];
        general[1] = getCountriesGeneralItemFromCourseList(comparison_general);
        general[2] = getClientsGeneralItemFromCourseList(comparison_general);

    } catch (ex) {
        console.error(ex);
    }

    return general;
};

/****
 * E Learning :  Comparison : Content avg score : clients
 ****/
const getCountryQuizScoreItemFromCourseList = (courseData) => {
    let result = [];

    try {

        result.push(['', '', '', '', '']);

        let countryList = [];

        for (let i = 0; i < courseData.length; i++) {
            let isExist = false;
            for (let j = 0; j < countryList.length; j++) {
                if (countryList[j] === courseData[i].country) {
                    isExist = true;
                    break;
                }
            }
            if (!isExist) {
                countryList.push(courseData[i].country);
            }
        }

        for (let i = 0; i < countryList.length; i++) {
            let rowData = [];
            rowData.push(countryList[i]);
            let quizScoreTemp = [];

            for (let j = 0; j < courseData.length; j++) {

                if (countryList[i] === courseData[j].country) {

                    for (let k = 0; k < courseData[j].data.length; k++) {
                        Object.keys(courseData[j].data[k]).forEach(function (key) {
                            quizScoreTemp.push(courseData[j].data[k][key]);
                        });
                    }
                }
            }

            if (quizScoreTemp.length > 0) {
                let minAvg = quizScoreTemp[0];
                let maxAvg = quizScoreTemp[0];


                for (let j = 1; j < quizScoreTemp.length; j++) {

                    if (quizScoreTemp[j] > maxAvg) {
                        maxAvg = quizScoreTemp[j];
                    } else if (quizScoreTemp[j] < minAvg) {
                        minAvg = quizScoreTemp[j];
                    }
                }
                rowData.push((minAvg - 5) < 0 ? minAvg : (minAvg - 5), minAvg, maxAvg, (maxAvg + 5) > 100 ? (maxAvg + 1 > 100 ? maxAvg : maxAvg + 1) : (maxAvg + 5));
            } else {
                rowData.push(0, 0, 0, 0);
            }

            result.push(rowData);


        }
    } catch (ex) {
    }

    return result;
};

/****
 * E Learning :  Comparison : Content avg score : clients
 ****/
const getClientsQuizScoreItemFromCourseList = (courseData) => {
    let result = [];

    try {

        result.push(['', '', '', '', '']);

        for (let i = 0; i < courseData.length; i++) {
            let rowData = [];
            rowData.push(courseData[i]['name']);
            let quizScoreTemp = [];

            for (let j = 0; j < courseData[i].data.length; j++) {
                Object.keys(courseData[i].data[j]).forEach(function (key) {
                    quizScoreTemp.push(courseData[i].data[j][key]);
                });
            }

            if (quizScoreTemp.length > 0) {
                let minAvg = quizScoreTemp[0];
                let maxAvg = quizScoreTemp[0];


                for (let j = 1; j < quizScoreTemp.length; j++) {

                    if (quizScoreTemp[j] > maxAvg) {
                        maxAvg = quizScoreTemp[j];
                    } else if (quizScoreTemp[j] < minAvg) {
                        minAvg = quizScoreTemp[j];
                    }
                }
                rowData.push((minAvg - 5) < 0 ? minAvg : (minAvg - 5), minAvg, maxAvg, (maxAvg + 5) > 100 ? (maxAvg + 1 > 100 ? maxAvg : maxAvg + 1) : (maxAvg + 5));
            }

            result.push(rowData);
        }

    } catch (ex) {
    }

    return result;
};

/****
 * E Learning :  Comparison : quiz score : http response parser
 ****/
const parseComparisonQuizScoreResponse = (data) => {

    let quizScore = {};

    try {

        data = {
            'comparison_quiz_score': [
                {
                    name: "Reports Client : 1",
                    id: "1",
                    parent: "0",
                    country: "India",
                    data: [
                        {
                            'course_name_1': 60
                        },
                        {
                            'course_name_2': 70
                        },
                        {
                            'course_name_3': 80
                        },
                        {
                            'course_name_4': 85
                        },
                        {
                            'course_name_5': 90
                        },
                    ]
                },
                {
                    name: "Reports Client : 2",
                    id: "1",
                    parent: "0",
                    country: "USA",
                    data: [
                        {
                            'course_name_1': 10
                        },
                        {
                            'course_name_2': 20
                        },
                        {
                            'course_name_3': 30
                        },
                        {
                            'course_name_4': 45
                        },
                        {
                            'course_name_5': 50
                        },
                    ]
                },
                {
                    name: "Reports Client : 3",
                    id: "1",
                    parent: "0",
                    country: "India",
                    data: [
                        {
                            'course_name_1': 25
                        },
                        {
                            'course_name_2': 35
                        },
                        {
                            'course_name_3': 45
                        },
                        {
                            'course_name_4': 55
                        },
                        {
                            'course_name_5': 40
                        },
                    ]
                }
            ]
        };

        let comparison_quiz_score = data["comparison_quiz_score"];
        quizScore[1] = getCountryQuizScoreItemFromCourseList(comparison_quiz_score);
        quizScore[2] = getClientsQuizScoreItemFromCourseList(comparison_quiz_score);
    } catch (ex) {
        console.error(ex);
    }

    return quizScore;
};


/****
 * E Learning :  Comparison : Content avg score : countries
 ****/
const getCountriesContentAvgScoreItemFromCourseList = (courseData) => {
    let result = {};

    try {

        let data = [];
        let metaData = [];
        let countryList = [];
        let metaDataTemp = [];

        for (let i = 0; i < courseData.length; i++) {
            let isExist = false;
            for (let j = 0; j < countryList.length; j++) {
                if (countryList[j] === courseData[i].country) {
                    isExist = true;
                    break;
                }
            }
            if (!isExist) {
                countryList.push(courseData[i].country);
            }
        }

        for (let i = 0; i < countryList.length; i++) {

            let rowData = {};
            for (let j = 0; j < courseData.length; j++) {

                if (courseData[j].country === countryList[i]) {
                    let data = courseData[j].data;

                    for (let k = 0; k < data.length; k++) {

                        Object.keys(data[k]).forEach(function (key) {

                            if (typeof rowData[key] == 'undefined') {
                                rowData[key] = [];
                            }

                            rowData[key].push(data[k][key]);

                            let isKeyExist = false;
                            for (let l = 0; l < metaDataTemp.length; l++) {
                                if (metaDataTemp[l] === key) {
                                    isKeyExist = true;
                                    break;
                                }
                            }

                            if (!isKeyExist) {
                                metaDataTemp.push(key);
                            }
                        })
                    }
                }
            }

            let avgScore = {};
            avgScore.name = countryList[i];

            Object.keys(rowData).forEach(function (key) {
                let total = 0;
                let tempData = rowData[key];
                let itemsCount = 0;
                for (let j = 0; j < tempData.length; j++) {
                    total += tempData[j];
                    itemsCount++;
                }

                if (itemsCount > 0) {
                    avgScore[key] = total / itemsCount;
                } else {
                    avgScore[key] = 0;
                }
            });

            data.push(avgScore);

        }


        let colorIndex = 0;

        for (let i = 0; i < metaDataTemp.length; i++) {

            if (colorIndex === comparisonContentAvgScoreColors.length) {
                colorIndex = 0;
            }

            metaData.push({
                name: metaDataTemp[i],
                color: comparisonContentAvgScoreColors[colorIndex]
            });

            colorIndex++;

        }

        result.data = data;
        result.metaData = metaData;
    } catch (ex) {
    }

    return result;
};

/****
 * E Learning :  Comparison : Content avg score : clients
 ****/
const getClientsContentAvgScoreItemFromCourseList = (courseData) => {
    let result = {};

    try {

        let data = [];
        let metaData = [];
        let metaDataTemp = [];


        for (let i = 0; i < courseData.length; i++) {
            let rowData = {};
            rowData['name'] = courseData[i]['name'];

            for (let j = 0; j < courseData[i].data.length; j++) {

                Object.keys(courseData[i].data[j]).forEach(function (key) {
                    rowData[key] = courseData[i].data[j][key];

                    let isExist = false;

                    for (let k = 0; k < metaDataTemp.length; k++) {
                        if (key === metaDataTemp[k]) {
                            isExist = true;
                            break;
                        }
                    }

                    if (!isExist) {
                        metaDataTemp.push(key);
                    }
                });
            }

            data.push(rowData);
        }

        let colorIndex = 0;

        for (let i = 0; i < metaDataTemp.length; i++) {

            if (colorIndex === comparisonContentAvgScoreColors.length) {
                colorIndex = 0;
            }

            metaData.push({
                name: metaDataTemp[i],
                color: comparisonContentAvgScoreColors[colorIndex]
            });

            colorIndex++;

        }

        result.data = data;
        result.metaData = metaData;


    } catch (ex) {
    }

    return result;
};

/****
 * E Learning :  Comparison : Content avg score : http response parser
 ****/
const parseComparisonContentAvgScoreResponse = (data) => {

    let contentAvgScore = {};

    try {

        data = {
            'comparison_content_avg_score': [
                {
                    name: "Reports Client : 1",
                    id: "1",
                    parent: "0",
                    country: "India",
                    data: [
                        {
                            'course_name_1': 60
                        },
                        {
                            'course_name_2': 70
                        },
                        {
                            'course_name_3': 80
                        },
                        {
                            'course_name_4': 85
                        },
                        {
                            'course_name_5': 90
                        },
                    ]
                },
                {
                    name: "Reports Client : 2",
                    id: "1",
                    parent: "0",
                    country: "USA",
                    data: [
                        {
                            'course_name_1': 10
                        },
                        {
                            'course_name_2': 20
                        },
                        {
                            'course_name_3': 30
                        },
                        {
                            'course_name_4': 45
                        },
                        {
                            'course_name_5': 50
                        },
                    ]
                },
                {
                    name: "Reports Client : 3",
                    id: "1",
                    parent: "0",
                    country: "India",
                    data: [
                        {
                            'course_name_1': 25
                        },
                        {
                            'course_name_2': 35
                        },
                        {
                            'course_name_3': 45
                        },
                        {
                            'course_name_4': 55
                        },
                        {
                            'course_name_5': 40
                        },
                    ]
                }
            ]
        };

        let content_avg_score = data["comparison_content_avg_score"];
        contentAvgScore[1] = getCountriesContentAvgScoreItemFromCourseList(content_avg_score);
        contentAvgScore[2] = getClientsContentAvgScoreItemFromCourseList(content_avg_score);
    } catch (ex) {
        console.error(ex);
    }

    return contentAvgScore;
};


/****
 * E Learning :  Comparison : Over time : by country
 ****/
const parseComparisonOvertimeResponseCountries = (completion_ratio) => {
    let result = {
        data: [],
        metaData: []
    };

    try {

        let colorIndex = 0;

        let countries = [];

        for (let i = 0; i < completion_ratio.length; i++) {
            let isExist = false;

            for (let j = 0; j < countries.length; j++) {
                if (completion_ratio[i].country === countries[j]) {
                    isExist = true;
                    break;
                }
            }

            if (!isExist) {
                countries.push(completion_ratio[i].country)
            }
        }


        for (let i = 0; i < countries.length; i++) {

            if (colorIndex === comparisonOverTimeColors.length) {
                colorIndex = 0;
            }

            result.metaData.push({
                    name: countries,
                    color: comparisonOverTimeColors[colorIndex]
                }
            );

            colorIndex++;
        }

        let data = {};

        let keysData = completion_ratio[0].data;

        for (let i = 0; i < keysData.length; i++) {
            Object.keys(keysData[i]).forEach(function (key) {
                data[key] = {};
            });
        }

        Object.keys(data).forEach(function (key) {

            for (let i = 0; i < completion_ratio.length; i++) {
                for (let j = 0; j < completion_ratio[i].data.length; j++) {
                    let isMatched = false;
                    Object.keys(completion_ratio[i].data[j]).forEach(function (key_one) {
                        if (key_one === key) {
                            isMatched = true;

                            let percentage = completion_ratio[i].data[j][key_one]['percentage'];

                            if (typeof data[key][completion_ratio[i].country] != "undefined") {
                                percentage += data[key][completion_ratio[i].country]
                            }

                            data[key][completion_ratio[i].country] = percentage;
                        }
                    });

                    if (isMatched) {
                        break;
                    }
                }
            }
        });

        Object.keys(data).forEach(function (key) {
            let rowData = {};
            rowData['name'] = key;

            Object.keys(data[key]).forEach(function (key_one) {
                rowData[key_one] = data[key][key_one];
            });

            result.data.push(rowData);

        });

    } catch (ex) {
    }

    return result;
};

/****
 * E Learning :  Comparison : Over time : by client
 ****/
const parseComparisonOvertimeResponseClients = (completion_ratio) => {
    let result = {
        data: [],
        metaData: []
    };

    try {

        let colorIndex = 0;

        for (let i = 0; i < completion_ratio.length; i++) {

            if (colorIndex === comparisonOverTimeColors.length) {
                colorIndex = 0;
            }

            result.metaData.push({
                    name: completion_ratio[i]['name'],
                    color: comparisonOverTimeColors[colorIndex]
                }
            );

            colorIndex++;
        }

        let data = {};

        let keysData = completion_ratio[0].data;

        for (let i = 0; i < keysData.length; i++) {
            Object.keys(keysData[i]).forEach(function (key) {
                data[key] = {};
            });
        }

        Object.keys(data).forEach(function (key) {

            for (let i = 0; i < completion_ratio.length; i++) {
                for (let j = 0; j < completion_ratio[i].data.length; j++) {
                    let isMatched = false;
                    Object.keys(completion_ratio[i].data[j]).forEach(function (key_one) {
                        if (key_one === key) {
                            isMatched = true;
                            data[key][completion_ratio[i].name] = completion_ratio[i].data[j][key_one]['percentage'];
                        }
                    });

                    if (isMatched) {
                        break;
                    }
                }
            }
        });

        Object.keys(data).forEach(function (key) {
            let rowData = {};
            rowData['name'] = key;

            Object.keys(data[key]).forEach(function (key_one) {
                rowData[key_one] = data[key][key_one];
            });

            result.data.push(rowData);

        });

    } catch (ex) {
    }

    return result;
};

/****
 * E Learning :  Comparison : Over time : http response parser
 ****/

const parseComparisonOvertimeResponse = (data) => {

    let overTime = {};

    try {
        let completion_ratio = data["comparison_over_time"];
        overTime[1] = parseComparisonOvertimeResponseCountries(completion_ratio);
        overTime[2] = parseComparisonOvertimeResponseClients(completion_ratio);
    } catch (ex) {
        console.error(ex);
    }

    return overTime;
};

/****
 * E Learning :  User Activity : Quiz distribution : item parser
 ****/
const parseUserActivityContentDifficultyItems = (contentDifficulty, attempt) => {

    try {

        if (typeof attempt != "undefined" && attempt != null) {
            contentDifficulty[attempt['group_level']].push(['',
                isNaN(attempt.attempts) ? 0 : parseInt(attempt.attempts),
                isNaN(attempt.avg_score) ? 0 : parseInt(attempt.avg_score),
                attempt.name,
                attempt.users]);
        }

        let items = attempt["child_items"];

        if (typeof items != "undefined" && items != null) {
            for (let i = 0; i < items.length; i++) {
                parseUserActivityContentDifficultyItems(contentDifficulty, items[i]);
            }
        }

    } catch (ex) {
        console.log(ex)
    }
};

/****
 * E Learning :  User Activity : Quiz distribution : http response parser
 ****/

const parseUserActivityContentDifficultyResponse = (data) => {

    /*let contentDifficulty = {
        1: [['ID', 'Avg quiz attempts', 'Avg module score', 'Module', 'users'], ['', 0, 0, '', 0], ['', 100, 100, '', 0]],
        2: [['ID', 'Avg quiz attempts', 'Avg module score', 'Module', 'users'], ['', 0, 0, '', 0], ['', 100, 100, '', 0]],
        3: [['ID', 'Avg quiz attempts', 'Avg module score', 'Module', 'users'], ['', 0, 0, '', 0], ['', 100, 100, '', 0]],
        4: [['ID', 'Avg quiz attempts', 'Avg module score', 'Module', 'users'], ['', 0, 0, '', 0], ['', 100, 100, '', 0]]
    };*/

    let contentDifficulty = {
        1: [['ID', 'Avg quiz attempts', 'Avg module score', 'Module', 'users']],
        2: [['ID', 'Avg quiz attempts', 'Avg module score', 'Module', 'users']],
        3: [['ID', 'Avg quiz attempts', 'Avg module score', 'Module', 'users']],
        4: [['ID', 'Avg quiz attempts', 'Avg module score', 'Module', 'users']]
    };

    try {
        let completion_ratio = data["completion_ratio"]["data"];

        if (completion_ratio) {
            for (let i = 0; i < completion_ratio.length; i++) {
                parseUserActivityContentDifficultyItems(contentDifficulty, completion_ratio[i]);
            }
        }
    } catch (ex) {
        console.error(ex);
    }

    return contentDifficulty;
};

/****
 * E Learning :  User Activity : Quiz distribution : item parser
 ****/
const parseUserActivityQuizDistributionItems = (quizDistribution, attempt) => {

    try {

        if (typeof attempt != "undefined" && attempt != null) {

            let result = [attempt.name, attempt.min, attempt.min + 5, attempt.max - 5, attempt.max];

            if (attempt.min > 0 && attempt.min === attempt.max) {
                result = [attempt.name, attempt.min - 5, attempt.min - 0.5, attempt.min + 0.5, attempt.min + 5];
            }

            if (result[2] > result[3]) {
                result = [attempt.name, result[2] - 5, result[2], result[2], result[2] + 5];
            }


            quizDistribution[attempt.group_level].push(result);
        }

        let items = attempt["child_items"];

        if (typeof items != "undefined" && items != null) {
            for (let i = 0; i < items.length; i++) {
                parseUserActivityQuizDistributionItems(quizDistribution, items[i]);
            }
        }

    } catch (ex) {
        console.log(ex)
    }
};

/****
 * E Learning :  User Activity : Quiz distribution : http response parser
 ****/

const parseUserActivityQuizDistributionResponse = (data) => {

    let quizDistribution = {
        1: [['day', '0', 'b', 'c', 'd'], ['', 0, 0, 0, 100]],
        2: [['day', '0', 'b', 'c', 'd'], ['', 0, 0, 0, 100]],
        3: [['day', '0', 'b', 'c', 'd'], ['', 0, 0, 0, 100]],
        4: [['day', '0', 'b', 'c', 'd'], ['', 0, 0, 0, 100]]
    };

    try {
        let quiz_score = data["quiz_score"];

        if (quiz_score) {
            for (let i = 0; i < quiz_score.length; i++) {
                parseUserActivityQuizDistributionItems(quizDistribution, quiz_score[i]);
            }
        }
    } catch (ex) {
        console.error(ex);
    }

    return quizDistribution;
};

/****
 * E Learning :  User Activity : Quiz distribution : item parser
 ****/
const parseCompletionRatioByLevelItems = (completionRatio, ratio) => {

    try {

        if (typeof ratio != "undefined" && ratio != null) {

            let onePercentage = ((ratio["not_enrolled"] > 0 ? ratio["not_enrolled"] : 0) + (ratio["enrolled"] > 0 ? ratio["enrolled"] : 0) +
                (ratio["lesson_completed"] > 0 ? ratio["lesson_completed"] : 0) + (ratio["quiz_completed"] > 0 ? ratio["quiz_completed"] : 0)) / 100;

            let nonEnrolled = parseInt(ratio["not_enrolled"] > 0 ? ratio["not_enrolled"] / onePercentage : 0);
            let enrolled = parseInt(ratio["enrolled"] > 0 ? ratio["enrolled"] / onePercentage : 0);
            let lesson_completed = parseInt(ratio["lesson_completed"] > 0 ? ratio["lesson_completed"] / onePercentage : 0);
            let quiz_completed = parseInt(ratio["quiz_completed"] > 0 ? ratio["quiz_completed"] / onePercentage : 0);

            completionRatio[ratio.group_level].push({
                "name": ratio["name"],
                "Non Enrolled": nonEnrolled,
                "Enrolled": enrolled,
                "Lesson Completed": lesson_completed,
                "Quiz Completed": quiz_completed
            });
        }

        let items = ratio["child_items"];

        if (typeof items != "undefined" && items != null) {
            for (let i = 0; i < items.length; i++) {
                parseCompletionRatioByLevelItems(completionRatio, items[i]);
            }
        }
    } catch (ex) {
        console.log(ex)
    }
};

/****
 * E Learning :  User activity : Generating the completion Ratio data based on depth
 ****/
const generateUserActivityCompletionRatioByLevel = (data, depth) => {

    let result = {1: [], 2: [], 3: [], 4: []};

    try {

        let completion_ratio = data['completion_ratio'];

        if (completion_ratio) {
            for (let i = 0; i < completion_ratio.length; i++) {
                parseCompletionRatioByLevelItems(result, completion_ratio[i]);
            }
        }
    } catch (ex) {
        console.error(ex)
    }
    return result;
};

/****
 * E Learning :  Enrolment : Breakdown : http response parser
 ****/

const parseEnrolmentProportionResponse = (data) => {

    let proportion_data = [
        {
            key_val: "1_50",
            name: 'Enrolled in at least one but less than half of active topics',
            "Enrollment Proportion": 0
        },
        {key_val: "51_100", name: 'Enrolled in at least half of active topics', "Enrollment Proportion": 0}
    ];

    try {
        let proportion = data["proportion"];
        if (proportion) {
            for (let i = 0; i < proportion_data.length; i++) {
                proportion_data[i]["Enrollment Proportion"] = proportion[proportion_data[i].key_val];
            }
        }
    } catch (ex) {
        console.error(ex);
    }

    return proportion_data;
};

/****
 * E Learning :  Enrolment : Breakdown : http response parser
 ****/

const parseEnrolmentStatusesResponse = (data) => {

    let result = [];

    result.push(
        {
            name: '',
            "Topics Enrolled": 0,
            "Lessons Completed": 0,
            "Quizzes Passed": 0
        }
    );

    try {
        let statuses = data['statuses'];
        if (typeof statuses != "undefined" && statuses != null) {

            for (let i = 0; i < statuses.data.length; i++) {
                for (let key in statuses.data[i]) {
                    let obj = statuses.data[i];
                    result.push(
                        {
                            name: key,
                            "Topics Enrolled": obj[key]['enrolment'] ? obj[key]['enrolment'] : 0,
                            "Lessons Completed": obj[key]['lesson_completed'] ? obj[key]['lesson_completed'] : 0,
                            "Quizzes Passed": obj[key]['quiz_passed'] ? obj[key]['quiz_passed'] : 0
                        }
                    );
                }
            }
        }

    } catch (ex) {
        console.error(ex)
    }

    return result;
};


/****
 * For getting random color code
 ****/
const getRandomColorCode = () => {
    return '#' + parseInt(Math.random() * 0x3D4E17).toString(16);
};


let breakdown_colors_level_one_index = 0;
let breakdown_colors_level_two_index = 0;
let breakdown_colors_level_three_index = 0;
let breakdown_colors_level_four_index = 0;
/****
 * For generating the breakdown values
 ****/
const getBreakDownContent = (item, type_val, level_one) => {

    let color = getRandomColorCode();

    switch (type_val) {
        case 1: {

            if (breakdown_colors_level_one_index >= breakdown_colors_level_one.length) {
                breakdown_colors_level_one_index = 0;
            }

            color = breakdown_colors_level_one[breakdown_colors_level_one_index];
            breakdown_colors_level_one_index++;
            break;
        }

        case 2 : {
            if (breakdown_colors_level_two_index >= breakdown_colors_level_two[level_one].length) {
                breakdown_colors_level_two_index = 0;
            }
            color = breakdown_colors_level_two[level_one][breakdown_colors_level_two_index];
            breakdown_colors_level_two_index++;
            break;
        }
        case 3: {
            if (breakdown_colors_level_three_index >= breakdown_colors_level_three[level_one].length) {
                breakdown_colors_level_three_index = 0;
            }
            color = breakdown_colors_level_three[level_one][breakdown_colors_level_three_index];
            breakdown_colors_level_three_index++;
            break;
        }
        case 4 : {
            if (breakdown_colors_level_four_index >= breakdown_colors_level_four[level_one].length) {
                breakdown_colors_level_four_index = 0;
            }
            color = breakdown_colors_level_four[level_one][breakdown_colors_level_four_index];
            breakdown_colors_level_four_index++;
            break;
        }

        default: {
            break;
        }
    }


    return {
        name: typeof item.name != "undefined" ? item.name : "NA",
        value: typeof item.enrolled != "undefined" ? item.enrolled : 0,
        color: color
    }
};

/****
 * For rendering the breakdown pie text value
 ****/
const renderCustomizedLabel = (cx, cy, midAngle, innerRadius, outerRadius, percent, index) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x > cx ? x : x} y={y} fill="white" textAnchor={x > cx ? 'end' : 'start'} dominantBaseline="central"
              style={{fontSize: '0.7rem'}}>
            {percent > 0 ? (percent * 100).toFixed(0) + '%' : ''}
        </text>
    );
};

/****
 * E Learning :  Enrolment : Breakdown : http response parser
 ****/

const parseEnrolmentBreakdownResponse = (data) => {
    let response = [];
    let level_one_data = [];
    let level_two_data = [];
    let level_three_data = [];
    let level_four_data = [];

    breakdown_colors_level_one_index = 0;
    breakdown_colors_level_two_index = 0;
    breakdown_colors_level_three_index = 0;
    breakdown_colors_level_four_index = 0;

    let temp_3, temp_4;
    let count_4;
    let totalEnrolments = 0;


    for (let i = 0; i < data.length; i++) {

        let temp = getBreakDownContent(data[i], 1);

        if (typeof temp['value'] != 'undefined') {
            totalEnrolments += temp['value'];
        }


        level_one_data.push(
            temp
        );

        if (typeof data[i]["child_items"] != "undefined") {
            let child_categories = data[i]["child_items"];

            for (let j = 0; j < child_categories.length; j++) {
                level_two_data.push(
                    getBreakDownContent(child_categories[j], 2, breakdown_colors_level_one_index)
                );

                if (typeof child_categories[j]["child_items"] != "undefined") {
                    let courses = child_categories[j]["child_items"];

                    for (let k = 0; k < courses.length; k++) {

                        count_4 = 0;
                        if (typeof courses[k]["child_items"] != "undefined") {
                            let lessons = courses[k]["child_items"];

                            for (let l = 0; l < lessons.length; l++) {
                                temp_4 = getBreakDownContent(lessons[l], 4, breakdown_colors_level_one_index);
                                level_four_data.push(temp_4);
                                count_4 += temp_4.value;
                            }
                        }

                        temp_3 = getBreakDownContent(courses[k], 3, breakdown_colors_level_one_index)
                        temp_3.value = count_4;
                        level_three_data.push(
                            temp_3
                        );

                    }
                }
            }
        }
    }

    response.push({
        level: 1,
        innerRadius: 80,
        outerRadius: 130,
        lineThreshold: 150,
        label_callback: function ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) {
            return renderCustomizedLabel(cx, cy, midAngle, innerRadius, outerRadius, percent, index);
        },
        color: getRandomColorCode(),
        data: level_one_data
    });

    response.push(
        {
            level: 2,
            innerRadius: 131,
            outerRadius: 180,
            lineThreshold: 100,
            color: getRandomColorCode(),
            label_callback: function ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) {
                return renderCustomizedLabel(cx, cy, midAngle, innerRadius, outerRadius, percent, index);
            },
            data: level_two_data
        }
    );

    response.push(
        {
            level: 3,
            innerRadius: 181,
            outerRadius: 230,
            lineThreshold: 50,
            color: getRandomColorCode(),
            label_callback: function ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) {
                return renderCustomizedLabel(cx, cy, midAngle, innerRadius, outerRadius, percent, index);
            },
            data: level_three_data
        }
    );

    response.push(
        {
            level: 4,
            innerRadius: 231,
            outerRadius: 280,
            lineThreshold: 0,
            color: getRandomColorCode(),
            label_callback: function ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) {
                return renderCustomizedLabel(cx, cy, midAngle, innerRadius, outerRadius, percent, index);
            },
            data: level_four_data
        }
    );

    return {
        "data": response,
        "totalEnrolments": totalEnrolments
    };
};

const getColumnTitle = (selectedSummaryType, isClientIsBrand) => {
    try {

        let tempList = isClientIsBrand ? filtersSummariseOptions.parent : filtersSummariseOptions.child;

        for (let i = 0; i < tempList.length; i++) {
            if (selectedSummaryType === tempList[i].value) {
                return tempList[i].label;
            }
        }
    } catch (e) {
    }
    return "";
};

export default e_learning_reports;
