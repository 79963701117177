import {baseUrl} from "../util/keys";

export const HTTP_TIMEOUT = 3000000;

/**** login ****/
export const URL_LOGIN_AUTHENTICATE = '/login/token/';
export const URL_LOGIN_VALIDATE = '/login/validate/';
export const URL_LOGIN_REFRESH_TOKEN = '/login/refresh/';
export const URL_LOGOUT = '/login/logout/';

/**** clients ****/
export const URL_CLIENT_FETCH_NEW_DATA = '/clients/newpage/';
export const URL_CLIENT_SAVE_NEW_CLIENT = '/clients/create/';
export const URL_CLIENT_SAVE_CLIENT_LOGO = '/clients/savelogo/';
export const URL_CLIENT_ALL = '/clients/list/';
export const URL_CLIENT_LIST_ALL = '/clients/list/all/';
export const URL_CLIENT_FILTERS_GET = '/clients/filters/';
export const URL_CLIENT_FILTERS_DATA = '/clients/filters/';
export const URL_CLIENT_CHANGE_STATUS = '/clients/status/';
export const URL_CLIENT_COUNTRIES_LIST = '/clients/country-list/';

/**** participants ****/
export const URL_PARTICIPANTS_SAVE_NEW_GROUP = '/participants/groups/create/';
export const URL_PARTICIPANTS_GROUPS_ALL = '/participants/groups/list/';
export const URL_PARTICIPANTS_DELETE_GROUP = '/participants/groups/delete/';

export const URL_PARTICIPANTS_FIELD_CONFIG_ALL = '/participants/fieldConfig/list/';
export const URL_PARTICIPANTS_FIELD_CONFIG_SAVE_CUSTOM_FIELD = '/participants/fieldConfig/custom/save';
export const URL_PARTICIPANTS_FIELD_CONFIG_DELETE_CUSTOM_FIELD = '/participants/fieldConfig/custom/delete';

export const URL_PARTICIPANTS_FIELD_CONFIG_MANDATORY_FIELD_CONFIG = '/participants/fieldConfig/config/';

export const URL_PARTICIPANTS_FORM_DATA = '/participants/participant/data/';

/**** E learning reports ****/
export const URL_E_LEARNING_REPORTS_ENROLMENT_GENERAL = '/elearning/reports/enrolment/general/';
export const URL_E_LEARNING_REPORTS_ENROLMENT_TOPICS = '/elearning/reports/enrolment/topics/';
export const URL_E_LEARNING_REPORTS_ENROLMENT_PROPORTION = '/elearning/reports/enrolment/proportion/';
export const URL_E_LEARNING_REPORTS_ENROLMENT_STATUSES = '/elearning/reports/enrolment/statuses/';
export const URL_E_LEARNING_REPORTS_ENROLMENT_BREAKDOWN = '/elearning/reports/enrolment/breakdown/';
export const URL_E_LEARNING_REPORTS_ENROLMENT_COURSES = '/elearning/reports/enrolment/courses/';
export const URL_E_LEARNING_REPORTS_SUMMARY_TABLE = '/elearning_reports/summary_table/';
export const URL_E_LEARNING_REPORTS_SUMMARY_TABLE_UUID = '/elearning_reports/summary_table/uuid_status/';

export const URL_E_LEARNING_REPORTS_USER_ACTIVITY_COMPLETION_RATIO = '/elearning/reports/useractivity/completionratio/';
export const URL_E_LEARNING_REPORTS_USER_ACTIVITY_QUIZ_SCORE = '/elearning/reports/useractivity/quizscore/';
export const URL_E_LEARNING_REPORTS_USER_ACTIVITY_CONTENT_POPULARITY = '/elearning/reports/useractivity/contentpopularity/';
export const URL_E_LEARNING_REPORTS_USER_ACTIVITY_DIFFICULTY = '/elearning/reports/useractivity/contentdifficulty/';

export const URL_E_LEARNING_REPORTS_COMPARISION_RATIO_COURSES = '/elearning/reports/comparison/courses/';
export const URL_E_LEARNING_REPORTS_COMPARISION_RATIO_USERS = '/elearning/reports/comparison/users/';
export const URL_E_LEARNING_REPORTS_COMPARISION_RATIO_USERS_UUID = '/elearning/reports/comparison/users-uuid/';

export const URL_E_LEARNING_REPORTS_COMPARISION_OVERTIME = '/elearning/reports/comparison/overtime/';
export const URL_E_LEARNING_REPORTS_COMPARISION_GENERAL = '/elearning/reports/comparison/general/';
export const URL_E_LEARNING_REPORTS_COMPARISION_CONTENT_AVG_SCORE = '/elearning/reports/comparison/contentavgscore/';
export const URL_E_LEARNING_REPORTS_COMPARISION_QUIZ_SCORE = '/elearning/reports/comparison/quizscore/';

export const URL_E_LEARNING_RAW_DATA_DOWNLOAD_UUID = '/elearning_reports/summary_table/download/';
export const URL_E_LEARNING_RAW_DATA_DOWNLOAD_UUID_STATUS = '/elearning_reports/summary_table/download/status/';
/**** Survey : clone  ****/
export const URL_SURVEY_CLONE_SURVEY = '/survey/clone/survey/';
export const URL_SURVEY_CLONE_SURVEY_TEMPLATE = '/survey/clone/survey/template/';

/**** Survey : category  ****/
export const URL_SURVEY_CATEGORY_SAVE = '/survey/categories/new/';
export const URL_SURVEY_CATEGORY_UPDATE = '/survey/categories/update/';
export const URL_SURVEY_CATEGORY_LIST = '/survey/categories/list/';
export const URL_SURVEY_CATEGORY_UPDATE_STATUS = '/survey/categories/status/';

/**** Survey : Reporting category  ****/
export const URL_SURVEY_REPORTING_CATEGORY_SAVE = '/survey/reporting_categories/new/';
export const URL_SURVEY_REPORTING_CATEGORY_UPDATE = '/survey/reporting_categories/new/';
export const URL_SURVEY_REPORTING_CATEGORY_LIST = '/survey/reporting_categories/list/';
export const URL_SURVEY_REPORTING_CATEGORY_UPDATE_STATUS = '/survey/reporting_categories/status/';
export const URL_SURVEY_REPORTING_CATEGORY_ACTIVE_LIST = '/survey/reporting_categories/active/list/';

/**** Survey : question types  ****/
export const URL_SURVEY_QUESTION_TYPE_SAVE = '/survey/questiontypes/new/';
export const URL_SURVEY_QUESTION_TYPE_UPDATE = '/survey/questiontypes/update/';
export const URL_SURVEY_QUESTION_TYPE_LIST = '/survey/questiontypes/list/';
export const URL_SURVEY_QUESTION_TYPE_UPDATE_STATUS = '/survey/questiontypes/status/';

/**** Survey : question bank  ****/
export const URL_SURVEY_QUESTION_BANK_SAVE = '/survey/question_bank/new/';
export const URL_SURVEY_QUESTION_BANK_UPDATE = '/survey/question_bank/update/';
export const URL_SURVEY_QUESTION_BANK_LIST = '/survey/question_bank/list/';
export const URL_SURVEY_QUESTION_BANK_UPDATE_STATUS = '/survey/question_bank/status/';
export const URL_SURVEY_QUESTION_BANK_UPLOAD = '/survey/question_bank/import/';

/**** Survey : templates  ****/
export const URL_SURVEY_TEMPLATE_SAVE_INFO = '/survey/templates/info/save/';
//export const URL_SURVEY_TEMPLATE_GET_ALL_RECORDS = '/survey/templates/list/';
export const URL_SURVEY_TEMPLATE_GET_ALL_RECORDS = '/survey/templates/paginated/';
export const URL_SURVEY_TEMPLATE_GET_QUESTIONS = '/survey/templates/questions/';
export const URL_SURVEY_TEMPLATE_SET_STATUS = '/survey/templates/set-status/';
export const URL_SURVEY_TEMPLATE_GET_RECORD_BY_ID = '/survey/templates/get/';

/**** Survey : template questions  ****/
export const URL_SURVEY_TEMPLATE_QUESTIONS_SAVE = '/survey/templates/question/save/';
export const URL_SURVEY_TEMPLATE_QUESTIONS_DELETE = '/survey/templates/question/delete/';

/**** Survey : New Survey  ****/
export const URL_SURVEY_NEW_SURVEY_SAVE_INFO = '/survey/survey/info/save/';
//export const URL_SURVEY_NEW_SURVEY_GET_ALL_RECORDS = '/survey/survey/list/';
export const URL_SURVEY_NEW_SURVEY_GET_ALL_RECORDS = '/survey/surveys/paginated/';
export const URL_SURVEY_NEW_SURVEY_GET_QUESTIONS = '/survey/survey/questions/';
export const URL_SURVEY_NEW_SURVEY_GENERATE_WEB_URL = '/survey/survey/generate-web-url/';
export const URL_SURVEY_UPDATE_STATUS = '/survey/info/set-status/';
export const URL_SURVEY_QUESTIONS_ORDER_UPDATE = '/survey/reorder-question/';
export const URL_SURVEY_TEMPLATE_QUESTIONS_ORDER_UPDATE = '/survey/template/reorder-question/';
export const URL_SURVEY_GET_RECORD_BY_ID = '/survey/survey/get/';

/**** Survey : new survey questions  ****/
export const URL_SURVEY_NEW_SURVEY_QUESTIONS_SAVE = '/survey/survey/question/save/';
export const URL_SURVEY_NEW_SURVEY_QUESTIONS_DELETE = '/survey/survey/question/delete/';

/**** Survey: duplicate survey/template questions ****/

export const URL_SURVEY_QUESTIONS_DUPLICATE = '/survey/duplicate/survey/question/';
export const URL_SURVEY_TEMPLATE_QUESTIONS_DUPLICATE = '/survey/duplicate/template/question/';

/*** Survey:Quick Insights */
export const URL_SURVEY_GET_QUICK_INSIGHT_DATA = '/survey/survey/quickinsights/';

/*** Survey:Question Summary */
export const URL_SURVEY_GET_QUESTION_SUMMARY_DATA = '/survey/survey/question/summary/';
export const URL_SURVEY_SURVEY_RESPONSES_DOWNLOAD = '/survey/survey/responses/download/';

/*** Survey Template:Quick Insights */
export const URL_SURVEY_TEMPLATE_GET_QUICK_INSIGHT_DATA = '/survey/templates/quickinsights/';

/*** Survey Template:Question Summary */
export const URL_SURVEY_TEMPLATE_GET_QUESTION_SUMMARY_DATA = '/survey/templates/question/summary/';
export const URL_SURVEY_TEMPLATE_RESPONSES_DOWNLOAD = '/survey/templates/responses/download/';

/*** Survey: Reports ***/
export const URL_SURVEY_REPORTS_RAW_DATA_DOWNLOAD = '/survey/skeleton/rawdata/download/';
export const URL_SURVEY_REPORTS_RAW_DATA_DOWNLOAD_UUID = '/survey/skeleton/raw-data/download-uuid/';
export const URL_SURVEY_REPORTS_RAW_DATA_DOWNLOAD_UUID_DATA = '/survey/skeleton/raw-data/download-uuid-status/';

/*** template: Reports rawdata ***/
export const URL_SURVEY_TEMPLATE_REPORTS_RAW_DATA_DOWNLOAD_UUID = '/survey/questionnaire/raw-data/download-uuid/';
export const URL_SURVEY_TEMPLATE_REPORTS_RAW_DATA_DOWNLOAD_UUID_DATA = '/survey/questionnaire/raw-data/download-uuid-status/';

/**** Survey: Summary Table ****/
export const URL_SURVEY_GET_SUMMARY_TABLE_DATA_QUESTIONS = '/survey/reports/summary-table/survey-questions/';
export const URL_SURVEY_GET_SUMMARY_TABLE_DATA_CATEGORIES = '/survey/reports/summary-table/survey-category/';
export const URL_SURVEY_DOWNLOAD_SUMMARY_TABLE_DATA = '/survey/survey/summary-table/download/';

/**** Survey: Summary Table ****/
export const URL_SURVEY_TEMPLATE_GET_SUMMARY_TABLE_QUESTION_DATA = '/survey/reports/summary-table/questionnaire-questions/';
export const URL_SURVEY_TEMPLATE_GET_SUMMARY_TABLE_CATEGORY_DATA = '/survey/skeleton/questionnaire/summary-table/';
export const URL_SURVEY_TEMPLATE_DOWNLOAD_SUMMARY_TABLE_DATA = '/survey/skeleton/questionnaire/summary-table/download/';

/**** WoVo Survey response ******/
export const URL_SURVEY_RESPONSE_SAVE = "/survey/response/save/";
export const URL_SURVEY_RESPONSE_ANONYMOUS_SAVE = "/survey/responses/no-auth/capture/";
export const URL_SURVEY_RESPONSE_AUTHENTICATE_USER = "/survey/response/authenticate_user/";

/**** WOVO Sync ****/
export const URL_WOVO_DB_SYNC_CLIENTS = '/wovosync/clients/';


/**** User Settings ****/
export const URL_USER_SETTINGS_UPDATE_PREFERRED_LANGUAGE = '/user_settings/update_preferred_language/';
export const URL_USER_SETTINGS_UPDATE_TIME_ZONE = '/login/user_update/';
export const URL_USER_SETTINGS_UPDATE_PREFERRED_CLIENT = '/user_settings/update_preferred_client/';
export const URL_USER_SETTINGS_UPDATE_PREFERRED_THEME_COLOUR = '/user_settings/update_theme_colour/';
export const URL_USER_SETTINGS_UPDATE_PROFILE_IMAGE = '/user_settings/update_profile_image/';


/**** Translations Language ****/

export const URL_TRANSLATIONS_LANGUAGE_CREATE = '/translations/language/create/';
export const URL_TRANSLATIONS_LANGUAGE_LIST = '/translations/language/list/';
export const URL_TRANSLATIONS_LANGUAGE_UPLOAD_JSON = '/translations/upload/';
export const URL_TRANSLATIONS_LANGUAGE_DOWNLOAD_JSON = '/translations/download/';
export const URL_TRANSLATIONS_LANGUAGE_GET_TRANSLATIONS_LIST = '/translations/languages/';

export const URL_TRANSLATIONS_FONTS_CREATE_UPDATE = '/translations/fonts/create_update/';
export const URL_TRANSLATIONS_FONTS_SET_STATUS = '/translations/fonts/set_status/';
export const URL_TRANSLATIONS_FONTS_GET_LIST = '/translations/fonts/list/';
export const URL_TRANSLATIONS_FONTS_GET_ACTIVE_LIST = '/translations/fonts/list/active/';
export const URL_TRANSLATIONS_GET_ALL_SURVEY_TRANSLATIONS = '/translations/survey/';
export const URL_TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_LANGUAGE_ID = '/translations/survey/language/';
export const URL_TRANSLATIONS_GET_SURVEY_TRANSLATIONS_BY_SURVEY_ID = '/translations/survey_id/';

export const URL_TIMEZONE_LIST = '/utils/timezones'
/**** Translations Category ****/

export const URL_ADMIN_SETTINGS_TRANSLATIONS_CATEGORY_CREATE = '/translations/category/create/';
export const URL_ADMIN_SETTINGS_TRANSLATIONS_CATEGORY_LIST = '/translations/category/list/';

/**** Translations Modules ****/

export const URL_TRANSLATIONS_MODULE_CREATE = '/translations/module/create/';
export const URL_TRANSLATIONS_MODULE_LIST = '/translations/module/list/';

/**** Translations Submodules ****/

export const URL_TRANSLATIONS_SUBMODULE_CREATE = '/translations/submodules/create/';
export const URL_TRANSLATIONS_SUBMODULE_LIST = '/translations/submodules/list/';


/**** Account Managers ****/

export const URL_ADMINSETTINGS_MANAGERS_CREATE = '/adminsettings/managers/new/';
export const URL_ADMINSETTINGS_MANAGERS_LIST = '/adminsettings/managers/list/';
export const URL_ADMINSETTINGS_MANAGERS_DELETE = '/adminsettings/managers/status/';


/**** CLient PIC ******/

export const URL_ADMIN_SETTINGS_CLIENTPIC_CREATE = "/adminsettings/clientpic/new/";
export const URL_ADMIN_SETTINGS_CLIENTPIC_LIST = "/adminsettings/clientpic/list/";
export const URL_ADMIN_SETTINGS_CLIENTPIC_DELETE = "/adminsettings/clientpic/status/";

/**** Industry Types ******/

export const URL_ADMIN_SETTINGS_INDUSTRY_CREATE = "/industry/create/";
export const URL_ADMIN_SETTINGS_INDUSTRY_LIST = "/industry/list/";


/**** clients ****/

export const URL_CONNECT_COMPANY_USERS = '/wovo_reports/connect/users_uuid/';
export const URL_CONNECT_COMPANY_USERS_STATUS = '/wovo_reports/connect/users_uuid_status/';
export const URL_CONNECT_CASE_OVERVIEW = '/wovo_reports/connect/casesummary/';
export const URL_CONNECT_CASE_PROPORTION = '/wovo_reports/connect/caseproportion/';
export const URL_CONNECT_CASE_POPULARITY = '/wovo_reports/connect/casetypepopularity/';
export const URL_CONNECT_GET_MESSAGE_COUNT = '/wovo_reports/connect/messagecount/';
export const URL_CONNECT_GET_CASE_STATUS = '/wovo_reports/connect/casestatus/';
export const URL_CONNECT_GET_CASETYPE_OVERTIME = '/wovo_reports/connect/casetype-overtime/';
export const URL_CONNECT_GET_CASE_HANDLING_RESOLVED_COUNT = '/wovo_reports/connect/case-handling/resolved-count/';
export const URL_CONNECT_GET_CASE_HANDLING_MESSAGE_COUNT = '/wovo_reports/connect/case-handling/messages-count/';
export const URL_CONNECT_GET_CASE_HANDLING_REPLY_AVG_COUNT = '/wovo_reports/connect/case-handling/reply-avg-count/';
export const URL_CONNECT_GET_CASE_HANDLING_REPLY_CASE_SATISFACTION = '/wovo_reports/connect/case-handling/case-satisfaction/';
export const URL_CONNECT_GET_CASE_HANDLING_MANAGEMENT_PERFOMANCE = '/wovo_reports/connect/case-handling/management-performance/';
export const URL_CONNECT_GET_CASE_CONNECT_GENERAL = '/wovo_reports/connect/general_uuid/';
export const URL_CONNECT_GET_CASE_CONNECT_GENERAL_STATUS = '/wovo_reports/connect/general_uuid_status/';
export const URL_CONNECT_GET_SUMMARY_TABLE = '/wovo_reports/connect/summary-table/';
export const URL_CONNECT_GET_SUMMARY_TABLE_UUID = '/wovo_reports/connect/summary-table-uuid/';


/*** Connect Rawdata download***/
export const URL_CONNECT_REPORTS_RAW_DATA_DOWNLOAD = '/wovo_reports/connect/rawdata/download/';
export const URL_CONNECT_REPORTS_RAW_DATA_DOWNLOAD_UUID = '/wovo_reports/connect/raw_data/download_uuid/';
export const URL_CONNECT_REPORTS_RAW_DATA_DOWNLOAD_UUID_STATUS = '/wovo_reports/connect/raw_data/download_uuid/status/';

/**survey closing and opening message */
export const URL_SURVEY_SAVE_CLOSING_MESSAGE = '/survey/survey/closing-message/save';
export const URL_SURVEY_SAVE_OPENING_MESSAGE = '/survey/survey/opening-message/save';

/*** Connect summarytable download***/
export const URL_CONNECT_SUMMARY_TABLE_DOWNLOAD_UUID = '/wovo_reports/connect/summary-table/download/';
export const URL_CONNECT_SUMMARY_TABLE_DOWNLOAD = '/wovo_reports/connect/summary-table/download-uuid/';


/**** admin controls ****/
export const URL_ADMIN_CONTROLS_ACTIVATE_SURVEY_DETAILS = '/survey/admin/survey/get_status_details/';
export const URL_ADMIN_CONTROLS_ACTIVATE_SURVEY_SET_STATUS = '/survey/admin/survey/set_status/';
export const URL_ADMIN_CONTROLS_SURVEY_DUPLICATE_RESPONSE_COUNT = '/survey/admin/survey/get_duplicate_count/';
export const URL_ADMIN_CONTROLS_SURVEY_ALL_RESPONSES_RAW_DATA = '/survey/admin/survey/all_raw_data/';
export const URL_ADMIN_CONTROLS_SURVEY_ALL_RESPONSES_RAW_DATA_STATUS = '/survey/admin/survey/all_raw_data/status/';
export const URL_ADMIN_CONTROLS_SURVEY_UPDATE_SURVEY_DEMO_GRAPHIC_DETAILS = '/survey/admin/responses/update_demo_graphic/';
export const URL_ADMIN_CONTROLS_RESET_SURVEY_RESPONSE_CACHED_DATA = '/survey/admin/survey/reset_survey_cache/';
export const URL_ADMIN_CONTROLS_INCREASE_SURVEY_RESPONSES_BY_COUNT = '/survey/admin/survey/increase_responses/';
export const URL_ADMIN_CONTROLS_SAVE_SURVEY_RESPONSE_BY_REQUEST_ID = '/survey/admin/survey/save_response_by_request_id/';
export const URL_ADMIN_CONTROLS_WC_GET_CUSTOM_FIELD_STATUS = '/worker_contacts/custom_fields/sync_ucms_details/';
export const URL_ADMIN_CONTROLS_WC_GET_CUSTOM_FIELD_SYNC = '/worker_contacts/custom_fields/sync_ucms/';
export const URL_ADMIN_CONTROLS_WC_DOWNLOAD_DIFF_FILE = '/worker_contacts/download_wc_difference/';

/**Company Post Raw data */
export const URL_COMPANY_POST_RAW_DATA_UUID = '/wovo_reports/company_post/raw_data/';
export const URL_COMPANY_POST_RAW_DATA_UUID_STATUS = '/wovo_reports/company_post/raw_data/status/';
export const URL_COMPANY_POST_RAW_DATA_DOWNLOAD_UUID = '/wovo_reports/company_post/raw_data/download/';
export const URL_COMPANY_POST_RAW_DATA_DOWNLOAD_UUID_STATUS = '/wovo_reports/company_post/raw_data/download/status/';

/** client modules  */
export const URL_CLIENT_MODULE_SAVE_MODULE = '/client_modules/save/';
export const URL_CLIENT_MODULE_ADMIN_LIST = '/client_modules/list/admin/';
export const URL_CLIENT_MODULE_GET_CONFIGURATION = '/client_modules/get_configuration/';
export const URL_CLIENT_MODULE_SET_CONFIGURATION = '/client_modules/set_configuration/';

/** survey : translations : download  */
export const URL_SURVEY_DOWNLOAD_TRANSLATION_FILE = '/survey/survey/translations/download/';
/** survey : translations : upload  */
export const URL_SURVEY_UPLOAD_TRANSLATION_FILE = '/survey/survey/translations/upload/';
/**Survey : save redeploy */
export const URL_SURVEY_SAVE_REDEPLOY = '/survey/redeploy/survey/';
export const URL_SURVEY_REDEPLOY_GET_SERIES_QUESTIONS = '/survey/redeploy/questions/series_list/';
export const URL_SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS = '/survey/redeploy/questions/series_list/save/';
export const URL_SURVEY_REDEPLOY_GET_QUESTION_SUMMARY = '/survey/redeploy/questions/summary/';
/** Survey Template : save redeploy */
export const URL_TEMPLATE_SAVE_REDEPLOY = '/survey/redeploy/questionnaire/';
/** Survey Template : get questions */
export const URL_SURVEY_TEMPLATE_REDEPLOY_GET_SERIES_QUESTIONS = '/survey/redeploy/questionnaire/questions/series_list/';
export const URL_SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS = '/survey/redeploy/questionnaire/questions/series_list/save/';
export const URL_SURVEY_TEMPLATE_REDEPLOY_GET_QUESTION_SUMMARY = '/survey/redeploy-questionnaire/questions/summary/';

/** worker contacts **/
export const URL_WC_DOWNLOAD_WORKER_CONTACTS_TEMPLATE = baseUrl + '/worker_contacts/download_template/';
export const URL_WC_DOWNLOAD_WORKER_CONTACTS_ATTACHMENT_DOWNLOAD = baseUrl + '/worker_contacts/download_attachment/';
export const URL_WC_DOWNLOAD_WORKER_CONTACTS = baseUrl + '/worker_contacts/download_contacts/';
export const URL_WC_DEACTIVATE_WORKER_CONTACTS = baseUrl + '/worker_contacts/deactivate_contacts/';
export const URL_WC_REACTIVATE_WORKER_CONTACTS = baseUrl + '/worker_contacts/reactivate_contacts/';
export const URL_WC_DELETE_WORKER_CONTACTS = baseUrl + '/worker_contacts/delete_contacts/';
export const URL_WC_DELETE_WORKER_CONTACTS_SYNC_MS_SQL_CONTACTS_DATA = baseUrl + '/worker_contacts/sync_contact_attachments/';
export const URL_WC_DOWNLOAD_WORKER_CONTACTS_IMPORT_LIST = baseUrl + '/worker_contacts/import_list/';
export const URL_WC_DOWNLOAD_WORKER_CONTACTS_UPLOAD_EXCEL = baseUrl + '/worker_contacts/import_contacts/';
export const URL_ADMIN_CONTROLS_WC_SYNC_WORKER_CONTACTS_FROM_MS_SQL = baseUrl + '/worker_contacts/sync_contacts/';
export const URL_WC_GET_PAGINATED_LIST = baseUrl + '/worker_contacts/paginated_list/';
export const URL_WC_ACTIVATE_WORKER_CONTACT_TASK_START = baseUrl + '/worker_contacts/start_attachment_task/';
export const URL_WC_ACTIVATE_WORKER_CONTACT_TASK_STOP = baseUrl + '/worker_contacts/stop_attachment_task/';
export const URL_WC_ACTIVATE_WORKER_CONTACT_TASK_STATUS = baseUrl + '/worker_contacts/status_attachment_task/';
export const URL_WC_GROUPS_GET_LIST = baseUrl + '/worker_contacts/groups_list/';
export const URL_WC_GROUPS_GET_ACTIVE_LIST = baseUrl + '/worker_contacts/groups_list/active/';
export const URL_WC_GROUPS_SAVE_GROUP = baseUrl + '/worker_contacts/save_group/';
export const URL_WC_SAVE_CONTACT = baseUrl + '/worker_contacts/save_contact/';
export const URL_WC_DEACTIVATE_CONTACT = baseUrl + '/worker_contacts/deactivate_contact/';
export const URL_WC_DEACTIVATE_WORKER_CONTACT_GROUPS = baseUrl + '/worker_contacts/deactivate_contact_groups/';
export const URL_WC_ACTIVATE_WORKER_CONTACT_GROUPS = baseUrl + '/worker_contacts/activate_contact_groups/';
export const URL_WC_GET_CONTACT = baseUrl + '/worker_contacts/get_contact/';
export const URL_WC_GET_CUSTOM_FIELDS_WITH_VALUES = baseUrl + '/worker_contacts/custom_fields/active/';

/**survey question randomize */
export const URL_SURVEY_QUESTION_RANDOMIZE = '/survey/randomize_survey_questions/';

export const URL_COUNTRY_GET_ALL_RECORDS = baseUrl + '/country/countries_list/';

/**** client module ****/
export const URL_WOVO_CLIENTS_GET_PAGINATED_LIST = baseUrl + '/clients/paginated_list/';
export const URL_WOVO_CLIENTS_SAVE_CLIENT = baseUrl + '/clients/save_client/';
export const URL_WOVO_CLIENTS_GET_CLIENT_BY_KEY = baseUrl + '/clients/get_client_by_key/';
export const URL_WOVO_CLIENTS_GET_CLIENT_KEYS_BY_USER = baseUrl + '/clients/get_client_keys_by_user/';

/**** contact settings ****/
export const URL_CONTACT_SETTINGS_GET_CUSTOM_FIELDS = baseUrl + '/worker_contacts/get_contact_settings/';
export const URL_CONTACT_SETTINGS_REMOVE_CUSTOM_FIELD_VALUES = baseUrl + '/worker_contacts/contact_settings/remove_value/';

/**Countries */
export const URL_BRAND_CHILD_CLIENTS_COUNTRIES = '/clients/get_countries_by_client/';

/** payslips **/
export const URL_PAY_SLIP_LIST = '/payslip/list/';
export const URL_UPDATE_PAYSLIP = '/payslip/update/';
export const URL_SEND_PAYSLIP = '/payslip/create/';
export const URL_PAYSLIP_VERIFY_DATA_UPLOADED = '/payslip/verify_excel/';
export const URL_PAYSLIP_DOWNLOAD_TEMPLATE = '/payslip/download_template/';

/**Reset Registration */
export const URL_RESET_REGISTRATION_VERIFY_EXCEL_UPLOADED = '/worker_contacts/reset_contact/';
export const URL_RESET_REGISTRATION_DOWNLOAD_TEMPLATE = '/worker_contacts/reset_contact/template/';
export const URL_RESET_REGISTRATION_GET_LIST = '/worker_contacts/reset_contact/list/';

/** survey invite **/
export const URL_SURVEY_INVITE_OUTREACH = '/survey/survey_invite/outreach/';
export const URL_SURVEY_INVITE_GET_PATCH = '/survey/survey_invites/';
export const URL_SURVEY_INVITE_CREATE = '/survey/survey_invites/create/';
export const URL_SURVEY_INVITE_UPDATE_SURVEY_PERIOD = '/survey/info/set-survey-period/';
export const URL_SURVEY_INVITE_DOWNLOAD_DELIVERY_REPORT = '/survey/survey_invite/delivery_report/';
export const URL_SURVEY_INVITE_SEND_REMINDER = '/survey/survey_invites/send-reminder/';
export const URL_SURVEY_INVITE_INVITED_MEDIA_DETAILS = '/survey/survey_invite/consolidated_summary/';

/** survey view history **/
export const URL_SURVEY_VIEW_HISTORY = '/survey/survey-history/';

/**Downtime details */
export const URL_GET_DOWNTIME_DETAILS = '/downtime/next/';
