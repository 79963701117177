import {
    SURVEY_CLIENT_COUNTRIES,
    SURVEY_CLIENT_COUNTRIES_ERROR,
    SURVEY_CLIENT_COUNTRIES_NONE,
    SURVEY_CLIENT_COUNTRIES_SUCCESS,
    SURVEY_GET_SURVEY_BY_ID_SUCCESS,
    SURVEY_INFO_UPDATE_STATUS_SUCCESS,
    SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_SUCCESS,
    SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_SUCCESS,
    TEMPLATE_SURVEY_INFO_SET_ITEM,
    SURVEY_NEW_CLOSING_MESSAGE_SAVE_SUCCESS,
    SURVEY_NEW_OPENING_MESSAGE_SAVE_SUCCESS,
    SURVEY_GET_SURVEY_BY_ID_ERROR,
    SURVEY_GET_SURVEY_BY_ID_NONE,
    SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL,
    SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_NONE,
    SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_ERROR,
    SURVEY_NEW_SURVEY_SET_SURVEY,
    SURVEY_REDEPLOY_SURVEY_SUCCESS,
    SURVEY_REDEPLOY_SURVEY_ERROR,
    SURVEY_REDEPLOY_SURVEY_NONE, SURVEY_REDEPLOY_SURVEY, SURVEY_SET_REDEPLOY_STATUS, SURVEY_RESET_REDEPLOY_STATUS, SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS
} from "../../../constants/actions/survey/survey/actionTypesSurvey";

import {
    SIGNIN_USER_SUCCESS,
    SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_SUCCESS,
    SURVEY_TEMPLATE_SET_STATUS,
    SURVEY_TEMPLATE_SET_STATUS_ERROR,
    SURVEY_TEMPLATE_SET_STATUS_NONE,
    SURVEY_TEMPLATE_SET_STATUS_SUCCESS,
    SURVEY_TEMPLATE_REDEPLOY_ERROR, 
    SURVEY_TEMPLATE_REDEPLOY, 
    SURVEY_TEMPLATE_REDEPLOY_SUCCESS,
    SURVEY_TEMPLATE_REDEPLOY_NONE,
    SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS
} from "../../../constants/ActionTypes";
import {
    SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS,
    SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS,
    SURVEY_SURVEY_QUESTION_DELETE_SUCCESS,
    SURVEY_TEMPLATE_QUESTION_DELETE_SUCCESS,
    SURVEY_SURVEY_QUESTION_DUPLICATE_SUCCESS,
    SURVEY_TEMPLATE_QUESTION_DUPLICATE_SUCCESS,
    SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_SUCESS,
    SURVEY_SURVEY_QUESTION_ORDER_CHANGE_SUCESS
} from "../../../constants/actions/survey/questions/actionTypesSurveyQuestions";
import AppUtils from "../../../util/util";
import {HTTP_REQUEST_STATUS} from "../../../constants/constants";
import {
    SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_ERROR,
    SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_NONE,
    SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_SUCCESS
} from "../../../constants/actions/survey/survey/actioTypesSurveyTemplate";
import {WOVO_USER_ROLES} from "../../../constants/userRoles";
import {
    SURVEY_QUESTIONNAIRE_SET_NAVIGATION_VIEW, SURVEY_QUESTIONNAIRE_SET_QUESTIONNAIRE,
    SURVEY_TRANSLATIONS_DOWNLOAD,
    SURVEY_TRANSLATIONS_DOWNLOAD_ERROR,
    SURVEY_TRANSLATIONS_DOWNLOAD_NONE,
    SURVEY_TRANSLATIONS_DOWNLOAD_SUCCESS,
    SURVEY_TRANSLATIONS_HIDE_POPUP,
    SURVEY_TRANSLATIONS_SHOW_POPUP,
    SURVEY_TRANSLATIONS_UPLOAD,
    SURVEY_TRANSLATIONS_UPLOAD_ERROR,
    SURVEY_TRANSLATIONS_UPLOAD_NONE,
    SURVEY_TRANSLATIONS_UPLOAD_SUCCESS
} from "../../../constants/actions/survey/survey/actionsSurveyUtils";
import {NAVIGATION_STEPS, SURVEY_STATUSES_VALUE, SURVEY_SUMMARY_TYPE, SURVEY_CLONE_SOURCE_TYPES} from "../../../constants/config";
import {SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_SUCCESS, SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_SUCCESS} from "../../../constants/actions/survey/clone/actionTypesSurveyCloneHandler";

const surveyInfoLabelActions = {};
surveyInfoLabelActions[SURVEY_STATUSES_VALUE.DRAFT] = [
    {
        label: "DIC_KEY_ADMIN_ACTIVATE_SURVEY_SURVEY_DETAILS_OPEN",
        action: SURVEY_STATUSES_VALUE.ACTIVE
    }
];

surveyInfoLabelActions[SURVEY_STATUSES_VALUE.ACTIVE] = [
    {
        label: "DIC_KEY_ADMIN_ACTIVATE_SURVEY_SURVEY_DETAILS_CLOSE",
        action: SURVEY_STATUSES_VALUE.COMPLETED
    }
];

surveyInfoLabelActions[SURVEY_STATUSES_VALUE.SCHEDULED] = [
    {
        label: "DIC_KEY_ADMIN_ACTIVATE_SURVEY_SURVEY_DETAILS_CLOSE",
        action: SURVEY_STATUSES_VALUE.COMPLETED
    }
];
surveyInfoLabelActions[SURVEY_STATUSES_VALUE.INACTIVE] = [];
surveyInfoLabelActions[SURVEY_STATUSES_VALUE.DELETED] = [];
surveyInfoLabelActions[SURVEY_STATUSES_VALUE.COMPLETED] = [];

const questionnaireInfoLabelActions = {};
questionnaireInfoLabelActions[SURVEY_STATUSES_VALUE.DRAFT] = [
    {
        label: "DIC_KEY_ADMIN_ACTIVATE_SURVEY_SURVEY_DETAILS_OPEN",
        action: SURVEY_STATUSES_VALUE.ACTIVE
    }
];

questionnaireInfoLabelActions[SURVEY_STATUSES_VALUE.ACTIVE] = [
    {
        label: "DIC_KEY_ADMIN_ACTIVATE_SURVEY_SURVEY_DETAILS_CLOSE",
        action: SURVEY_STATUSES_VALUE.COMPLETED
    }
];

questionnaireInfoLabelActions[SURVEY_STATUSES_VALUE.SCHEDULED] = [
    {
        label: "DIC_KEY_ADMIN_ACTIVATE_SURVEY_SURVEY_DETAILS_CLOSE",
        action: SURVEY_STATUSES_VALUE.COMPLETED
    }
];
questionnaireInfoLabelActions[SURVEY_STATUSES_VALUE.INACTIVE] = [];
questionnaireInfoLabelActions[SURVEY_STATUSES_VALUE.DELETED] = [];
questionnaireInfoLabelActions[SURVEY_STATUSES_VALUE.COMPLETED] = [];

const surveyQuestionnaireNavigationSteps = {
    "SURVEY": [
        {
            label: "DIC_SURVEY_INFO_NAVIGATION_STEP_SETTINGS",
            hasNextStep: true,
            width: "col-md-3",
            key: NAVIGATION_STEPS.SETTINGS,
            enabled: true
        },
        {
            label: "DIC_SURVEY_INFO_NAVIGATION_STEP_QUESTION_BUILDER",
            hasNextStep: true,
            width: "col-md-3",
            key: NAVIGATION_STEPS.QUESTION_BUILDER,
            enabled: true
        },
        {
            label: "DIC_SURVEY_INFO_NAVIGATION_STEP_INVITE",
            hasNextStep: true,
            width: "col-md-3",
            key: NAVIGATION_STEPS.INVITE,
            enabled: true
        },
        {
            label: "DIC_SURVEY_INFO_NAVIGATION_STEP_REPORT",
            hasNextStep: false,
            width: "col-md-3",
            key: NAVIGATION_STEPS.REPORTS,
            enabled: true
        }
    ],
    "SURVEY_ASSIGNED": [
        {
            label: "DIC_SURVEY_INFO_NAVIGATION_STEP_SETTINGS",
            hasNextStep: true,
            width: "col-md-3",
            key: NAVIGATION_STEPS.SETTINGS,
            enabled: false
        },
        {
            label: "DIC_SURVEY_INFO_NAVIGATION_STEP_QUESTION_BUILDER",
            hasNextStep: true,
            width: "col-md-3",
            key: NAVIGATION_STEPS.QUESTION_BUILDER,
            enabled: true
        },
        {
            label: "DIC_SURVEY_INFO_NAVIGATION_STEP_INVITE",
            hasNextStep: true,
            width: "col-md-3",
            key: NAVIGATION_STEPS.INVITE,
            enabled: true
        },
        {
            label: "DIC_SURVEY_INFO_NAVIGATION_STEP_REPORT",
            hasNextStep: false,
            width: "col-md-3",
            key: NAVIGATION_STEPS.REPORTS,
            enabled: true
        }
    ],
    "QUESTIONNAIRE": [
        {
            label: "DIC_QUESTIONNAIRE_INFO_NAVIGATION_STEP_SETTINGS",
            hasNextStep: true,
            width: "col-md-4",
            key: NAVIGATION_STEPS.SETTINGS,
            enabled: true
        },
        {
            label: "DIC_QUESTIONNAIRE_INFO_NAVIGATION_STEP_QUESTION_BUILDER",
            hasNextStep: true,
            width: "col-md-4",
            key: NAVIGATION_STEPS.QUESTION_BUILDER,
            enabled: true
        },
        {
            label: "DIC_QUESTIONNAIRE_INFO_NAVIGATION_STEP_REPORT",
            hasNextStep: false,
            width: "col-md-4",
            key: NAVIGATION_STEPS.REPORTS,
            enabled: true
        }
    ],
    "QUESTIONNAIRE_ASSIGNED": [
        {
            label: "DIC_QUESTIONNAIRE_INFO_NAVIGATION_STEP_SETTINGS",
            hasNextStep: false,
            width: "col-md-4",
            key: NAVIGATION_STEPS.SETTINGS,
            enabled: false
        },
        {
            label: "DIC_QUESTIONNAIRE_INFO_NAVIGATION_STEP_QUESTION_BUILDER",
            hasNextStep: true,
            width: "col-md-4",
            key: NAVIGATION_STEPS.QUESTION_BUILDER,
            enabled: true
        },
        {
            label: "DIC_QUESTIONNAIRE_INFO_NAVIGATION_STEP_REPORT",
            hasNextStep: false,
            width: "col-md-4",
            key: NAVIGATION_STEPS.REPORTS,
            enabled: false
        }
    ]
}


const initialData = {
    surveyInfoLabelActions: surveyInfoLabelActions,
    questionnaireInfoLabelActions: questionnaireInfoLabelActions,
    surveyQuestionnaireNavigationSteps: surveyQuestionnaireNavigationSteps,
    currentItem: null,
    currentViewType: "",
    counter: 0,
    surveyWebUrl: "",
    childClientCountries: [],
    childClientCountriesStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    isUserHasTemplateCreateAccess: false,
    questionnaireGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    templateActivateDeactivateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyActivateDeactivateStatus: HTTP_REQUEST_STATUS.NOT_STARTED,

    isTranslationLayoutVisible: false,
    surveyTranslationsDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    surveyTranslationsUploadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    alertMessage: "",

    currentNavigationView: NAVIGATION_STEPS.SETTINGS,
    isQuestionnaire: false,

    isSurveyRedeployed: false,
    redeployedItem: {},
    surveyRedeploySaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
    alertMessageSurveyRedeploy: ''

};

const ReducerWoVoSurveyUtils = (state = initialData, action) => {

    switch (action.type) {

        case SURVEY_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS: {

            let currentItem = state.currentItem;

            if (action.payload.data && action.payload.data.data) {
                currentItem = AppUtils.updateCurrentSurvey(action.payload.data.data);         
                currentItem = JSON.parse(JSON.stringify(currentItem));
            }

            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1
            }
        }

        case SURVEY_TEMPLATE_REDEPLOY_SAVE_SERIES_QUESTIONS_SUCCESS: {

            let currentItem = state.currentItem;

            if (action.payload.data && action.payload.data.data) {
                currentItem = AppUtils.updateSurveyTemplateDetails(action.payload.data.data);
                currentItem = JSON.parse(JSON.stringify(currentItem));
            }

            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1
            }
        }

        case SURVEY_CLONE_SURVEY_TEMPLATE_SAVE_SUCCESS: {
            let currentItem = state.currentItem;

            if (action.payload.data && action.payload.data.data) {
                currentItem = AppUtils.updateCurrentSurvey(action.payload.data.data);
                currentItem = JSON.parse(JSON.stringify(currentItem));
            }

            return {
                ...state,
                currentNavigationView: NAVIGATION_STEPS.SETTINGS,
                isQuestionnaire: false,
                currentItem: currentItem,
                counter: state.counter + 1,
                currentViewType: SURVEY_SUMMARY_TYPE.SURVEY
            }
        }

        case SURVEY_CLONE_TEMPLATE_FROM_TEMPLATE_SUCCESS: {
            return {
                ...state,
                currentNavigationView: NAVIGATION_STEPS.SETTINGS
            }
        }

        case SURVEY_QUESTIONNAIRE_SET_NAVIGATION_VIEW: {
            return {
                ...state,
                currentNavigationView: action.payload
            }
        }

        case SURVEY_QUESTIONNAIRE_SET_QUESTIONNAIRE: {

            return {
                ...state,
                isQuestionnaire: action.payload
            }
        }

        case SURVEY_TRANSLATIONS_DOWNLOAD: {
            return {
                ...state,
                surveyTranslationsDownloadStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }
        case SURVEY_TRANSLATIONS_DOWNLOAD_SUCCESS: {


            if (action.payload && action.payload.data && action.payload.data.data) {
                let fileUrl = AppUtils.getAppBaseUrl() + action.payload.data.data;
                AppUtils.downloadFile(fileUrl);

            }

            return {
                ...state,
                surveyTranslationsDownloadStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message
            }
        }
        case SURVEY_TRANSLATIONS_DOWNLOAD_ERROR: {
            return {
                ...state,
                surveyTranslationsDownloadStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: ""
            }
        }
        case SURVEY_TRANSLATIONS_DOWNLOAD_NONE: {
            return {
                ...state,
                surveyTranslationsDownloadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }
        case SURVEY_TRANSLATIONS_UPLOAD: {
            return {
                ...state,
                surveyTranslationsUploadStatus: HTTP_REQUEST_STATUS.IN_PROGRESS,
                alertMessage: ""
            }
        }
        case SURVEY_TRANSLATIONS_UPLOAD_SUCCESS: {
            return {
                ...state,
                surveyTranslationsUploadStatus: HTTP_REQUEST_STATUS.SUCCESS,
                alertMessage: action.payload.data.message
            }
        }
        case SURVEY_TRANSLATIONS_UPLOAD_ERROR: {
            return {
                ...state,
                surveyTranslationsUploadStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload.data.error
            }
        }
        case SURVEY_TRANSLATIONS_UPLOAD_NONE: {
            return {
                ...state,
                surveyTranslationsUploadStatus: HTTP_REQUEST_STATUS.NOT_STARTED,
                alertMessage: ""
            }
        }

        case SURVEY_TRANSLATIONS_SHOW_POPUP: {
            return {
                ...state,
                isTranslationLayoutVisible: true
            }
        }

        case SURVEY_TRANSLATIONS_HIDE_POPUP: {
            return {
                ...state,
                isTranslationLayoutVisible: false
            }
        }

        case SIGNIN_USER_SUCCESS: {

            let currentUserRole = action.payload.user["role"];
            let isUserHasTemplateCreateAccess = false;

            if (currentUserRole === WOVO_USER_ROLES.SUPER_ADMIN ||
                currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER ||
                currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER_PHASE_1 ||
                currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_MANAGER_UNDER_ARMOUR ||
                currentUserRole === WOVO_USER_ROLES.BRAND_DASHBOARD_TCP) {
                isUserHasTemplateCreateAccess = true;
            }

            return {
                ...state,
                isUserHasTemplateCreateAccess: isUserHasTemplateCreateAccess
            }
        }

        /** client countries : in progress   **/
        case SURVEY_CLIENT_COUNTRIES: {
            return {
                ...state,
                childClientCountriesStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case SURVEY_NEW_SURVEY_SET_SURVEY:
            return {
                ...state,
                currentItem: {},
                counter: 0

            };

        case SURVEY_TEMPLATE_SET_STATUS_SUCCESS: {
            let currentItem = AppUtils.updateSurveyTemplateDetails(action.payload.data.data, state.isUserHasTemplateCreateAccess);
            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1,
                templateActivateDeactivateStatus: HTTP_REQUEST_STATUS.SUCCESS
            }
        }
        case SURVEY_TEMPLATE_SET_STATUS_NONE: {
            return {
                ...state,
                templateActivateDeactivateStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }
        case SURVEY_TEMPLATE_SET_STATUS_ERROR: {
            return {
                ...state,
                templateActivateDeactivateStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }
        case SURVEY_TEMPLATE_SET_STATUS: {
            return {
                ...state,
                templateActivateDeactivateStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        /** client countries : success   **/
        case SURVEY_CLIENT_COUNTRIES_SUCCESS: {

            let childClientCountries = [];
            childClientCountries.push({
                "key": '',
                "value": '',
                "label": 'All'
            });

            if (action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.countries) {
                let countries = action.payload.data.data.countries;

                for (let i = 0; i < countries.length; i++) {
                    childClientCountries.push({
                        "key": countries[i],
                        "value": countries[i],
                        "label": countries[i]
                    })
                }
            }

            return {
                ...state,
                childClientCountriesStatus: HTTP_REQUEST_STATUS.SUCCESS,
                childClientCountries: childClientCountries
            }
        }

        /** client countries : in progress   **/
        case SURVEY_CLIENT_COUNTRIES_ERROR: {
            return {
                ...state,
                childClientCountriesStatus: HTTP_REQUEST_STATUS.FAIL,
                childClientCountries: []
            }
        }

        /** client countries : none   **/
        case SURVEY_CLIENT_COUNTRIES_NONE: {
            return {
                ...state,
                childClientCountriesStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }


        case SURVEY_INFO_UPDATE_STATUS_SUCCESS: {

            let currentItem = AppUtils.updateCurrentSurvey(action.payload.data.data);

            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1
            };
        }

        case SURVEY_GET_SURVEY_BY_ID_ERROR: {

            return {
                ...state,
                surveyGetStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }

        case SURVEY_GET_SURVEY_BY_ID_NONE: {
            return {
                ...state,
                surveyGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case SURVEY_GET_SURVEY_BY_ID_SUCCESS: {

            let currentItem = state.currentItem

            if (action.payload && action.payload.data && action.payload.data.data) {
                currentItem = AppUtils.updateCurrentSurvey(action.payload.data.data);
            }

            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1,
                currentViewType: SURVEY_SUMMARY_TYPE.SURVEY,
                surveyGetStatus: HTTP_REQUEST_STATUS.SUCCESS
            };
        }

        case SURVEY_RESET_REDEPLOY_STATUS: {

            return {
                ...state,
                isSurveyRedeployed: false,
                redeployedItem: {}
            }
        }

        case SURVEY_SET_REDEPLOY_STATUS: {

            let isSurveyRedeployed = action.payload.isSurveyRedeployed;
            let redeployedItem = action.payload.redeployedItem;


            return {
                ...state,
                isSurveyRedeployed: isSurveyRedeployed,
                redeployedItem: redeployedItem
            }
        }


        case TEMPLATE_SURVEY_INFO_SET_ITEM:
            return {
                ...state,
                currentItem: action.payload.item,
                counter: state.counter + 1,
                currentViewType: action.payload.type,
                surveyWebUrl: ""
            };

        case SURVEY_NEW_TEMPLATE_NEW_EDIT_SAVE_INFO_SUCCESS: {

            let currentItem = state.currentItem;
            if (action.payload.data && action.payload.data.data) {
                currentItem = AppUtils.updateSurveyTemplateDetails(action.payload.data.data, state.isUserHasTemplateCreateAccess);
            }
            return {
                ...state,
                currentItem,
                counter: state.counter + 1,
                currentNavigationView: NAVIGATION_STEPS.QUESTION_BUILDER
            }
        }

        case SURVEY_NEW_SURVEY_NEW_EDIT_SAVE_INFO_SUCCESS: {
            let currentItem = state.currentItem
            if (action.payload && action.payload.data && action.payload.data.data) {
                currentItem = AppUtils.updateCurrentSurvey(action.payload.data.data);
            }
            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1,
                currentNavigationView: NAVIGATION_STEPS.QUESTION_BUILDER
            };

        }


        case SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS: {

            let currentItem = state.currentItem;

            if (action.payload.data && action.payload.data.template) {
                currentItem = AppUtils.updateSurveyTemplateDetails(action.payload.data.template, state.isUserHasTemplateCreateAccess);
                currentItem = JSON.parse(JSON.stringify(currentItem))
            } else if (action.payload.data && action.payload.data.parent) {
                currentItem = AppUtils.updateSurveyTemplateDetails(action.payload.data.parent, state.isUserHasTemplateCreateAccess);
                currentItem = JSON.parse(JSON.stringify(currentItem))
            }

            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1
            };
        }
        case SURVEY_SURVEY_NEW_QUESTION_NEW_EDIT_SAVE_SUCCESS:

            let currentItem = state.currentItem;

            if (action.payload.data && action.payload.data.data) {
                if (action.payload.data.parent && action.payload.data.parent.questions) {
                    currentItem = AppUtils.addSurveyQuestions(currentItem, action.payload.data.data, action.payload.data.parent.questions);
                } else {
                    currentItem = AppUtils.addSurveyQuestions(currentItem, action.payload.data.data);

                    currentItem = AppUtils.updateSurveyTemplateDetails(currentItem, state.isUserHasTemplateCreateAccess);
                }

            }

            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1
            };


        case SURVEY_SURVEY_QUESTION_DELETE_SUCCESS:
        case SURVEY_TEMPLATE_QUESTION_DELETE_SUCCESS: {

            let {currentItem} = state;

            if (action.payload.data && action.payload.data.data) {
                currentItem = AppUtils.deleteSurveyQuestion(currentItem, action.payload.data.data);
            }


            if (action.type === SURVEY_SURVEY_QUESTION_DELETE_SUCCESS) {
                currentItem = AppUtils.updateCurrentSurvey(currentItem);
            } else {
                currentItem = AppUtils.updateSurveyTemplateDetails(currentItem);
            }

            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1
            };

        }

        case SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_ERROR: {

            return {
                ...state,
                questionnaireGetStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }

        case SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_NONE: {
            return {
                ...state,
                questionnaireGetStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case SURVEY_TEMPLATE_GET_TEMPLATE_BY_ID_SUCCESS: {


            let {currentItem} = state;

            if (action.payload.data && action.payload.data.data) {
                currentItem = action.payload.data.data;
                currentItem.questions = AppUtils.getSortedQuestions(currentItem.questions);
                currentItem = AppUtils.updateSurveyTemplateDetails(currentItem, state.isUserHasTemplateCreateAccess);
            }

            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1,
                questionnaireGetStatus: HTTP_REQUEST_STATUS.SUCCESS
            }
        }

        case SURVEY_TEMPLATE_QUESTION_DUPLICATE_SUCCESS: {

            let {currentItem} = state;

            if (action.payload.data && action.payload.data.data) {
                currentItem = action.payload.data.data;
                currentItem.questions = AppUtils.getSortedQuestions(currentItem.questions);
                currentItem = AppUtils.updateSurveyTemplateDetails(currentItem, state.isUserHasTemplateCreateAccess);
            }

            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1
            };
        }

        case SURVEY_SURVEY_QUESTION_DUPLICATE_SUCCESS: {

            let {currentItem} = state;

            if (action.payload.data && action.payload.data.data) {
                currentItem = action.payload.data.data;
                currentItem.questions = AppUtils.getSortedQuestions(currentItem.questions);
                currentItem = AppUtils.updateCurrentSurvey(currentItem);
            }

            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1
            };
        }

        case SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL: {
            return {
                ...state,
                surveyActivateDeactivateStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            }
        }

        case SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_NONE: {
            return {
                ...state,
                surveyActivateDeactivateStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            }
        }

        case SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_ERROR: {
            return {
                ...state,
                surveyActivateDeactivateStatus: HTTP_REQUEST_STATUS.FAIL,
                alertMessage: action.payload && action.payload.data && action.payload.data.error
            }
        }

        case SURVEY_NEW_SURVEY_GENERATE_SURVEY_WEB_URL_SUCCESS: {
            let currentItem = state.currentItem
            if (action.payload.data && action.payload.data.data && action.payload.data.data.survey) {
                currentItem = AppUtils.updateCurrentSurvey(action.payload.data.data.survey);
            }

            return {
                ...state,
                surveyWebUrl: action.payload.data.data.url,
                currentItem: currentItem,
                counter: state.counter + 1,
                surveyActivateDeactivateStatus: HTTP_REQUEST_STATUS.SUCCESS
            };
        }

        case SURVEY_TEMPLATE_QUESTION_ORDER_CHANGE_SUCESS:
        case SURVEY_SURVEY_QUESTION_ORDER_CHANGE_SUCESS: {
            let {currentItem} = state;
            if (action.payload.data && action.payload.data.data) {

                if (action.type === SURVEY_SURVEY_QUESTION_ORDER_CHANGE_SUCESS) {
                    currentItem = AppUtils.updateCurrentSurvey(action.payload.data.data)
                } else {
                    currentItem = AppUtils.updateSurveyTemplateDetails(action.payload.data.data)
                }
            }

            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1
            };
        }

        case SURVEY_NEW_CLOSING_MESSAGE_SAVE_SUCCESS:
        case SURVEY_NEW_OPENING_MESSAGE_SAVE_SUCCESS: {

            let {currentItem} = state;
            if (action.payload.data && action.payload.data.data) {
                currentItem = AppUtils.updateCurrentSurvey(action.payload.data.data)
            }

            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1
            };
        }

        case SURVEY_NEW_CLOSING_MESSAGE_SAVE_SUCCESS: {

            let {currentItem} = state;
            if (action.payload.data && action.payload.data.data) {
                currentItem = AppUtils.updateCurrentSurvey(action.payload.data.data)
            }

            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1,
            };
        }

        case SURVEY_REDEPLOY_SURVEY_SUCCESS: {

            let currentItem = state.currentItem
            let redeployedItem = {};

            if (action.payload && action.payload.data && action.payload.data.data) {

                if (action.payload.data.data) {
                    currentItem = AppUtils.updateCurrentSurvey(action.payload.data.data);
                }

                if (action.payload.data.series_surveys) {
                    let surveysMap = AppUtils.getRedeployedSurveys({}, action.payload.data.series_surveys);

                    for (let item in surveysMap ) {
                        redeployedItem = surveysMap[item];
                        break;
                    }

                }

            }

            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1,
                currentNavigationView: NAVIGATION_STEPS.SETTINGS,
                alertMessageSurveyRedeploy: action.payload.data.message,
                surveyRedeploySaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                isSurveyRedeployed: true,
                redeployedItem: redeployedItem
            };
        }

        case SURVEY_REDEPLOY_SURVEY: {
            return {
                ...state,
                surveyRedeploySaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            };
        }

        case SURVEY_REDEPLOY_SURVEY_ERROR: {
            return {
                ...state,
                alertMessageSurveyRedeploy: action.payload.data.error,
                surveyRedeploySaveStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }

        case SURVEY_REDEPLOY_SURVEY_NONE: {
            return {
                ...state,
                alertMessageSurveyRedeploy: '',
                surveyRedeploySaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };
        }

        case SURVEY_TEMPLATE_REDEPLOY: {
            return {
                ...state,
                surveyRedeploySaveStatus: HTTP_REQUEST_STATUS.IN_PROGRESS
            };
        }

        case SURVEY_TEMPLATE_REDEPLOY_SUCCESS: {
            let currentItem = state.currentItem
            let redeployedItem = {};

            if (action.payload && action.payload.data && action.payload.data.data) {
                if (action.payload.data.data) {
                    currentItem = AppUtils.updateSurveyTemplateDetails(action.payload.data.data, state.isUserHasTemplateCreateAccess);
                }
                if (action.payload.data.series_surveys) {
                    let surveysMap = AppUtils.getRedeployedTemplates({}, action.payload.data.series_surveys, state.isUserHasTemplateCreateAccess);
                    for (let item in surveysMap ) {
                        redeployedItem = surveysMap[item];
                        break;
                    }
                }
            }

            return {
                ...state,
                currentItem: currentItem,
                counter: state.counter + 1,
                currentNavigationView: NAVIGATION_STEPS.SETTINGS,
                alertMessageSurveyRedeploy: action.payload.data.message,
                surveyRedeploySaveStatus: HTTP_REQUEST_STATUS.SUCCESS,
                isSurveyRedeployed: true,
                redeployedItem: redeployedItem
            };
        }

        case SURVEY_TEMPLATE_REDEPLOY_ERROR: {
            return {
                ...state,
                alertMessageSurveyRedeploy: action.payload.data.error,
                surveyRedeploySaveStatus: HTTP_REQUEST_STATUS.FAIL
            }
        }

        case SURVEY_TEMPLATE_REDEPLOY_NONE: {
            return {
                ...state,
                alertMessageSurveyRedeploy: '',
                surveyRedeploySaveStatus: HTTP_REQUEST_STATUS.NOT_STARTED
            };
        }

        default:
            return state;
    }

};

export default ReducerWoVoSurveyUtils;