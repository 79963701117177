import {all} from 'redux-saga/effects';
import SagaTranslationStrings from "./adminSettings/translations/translationStrings/sagaTranslationStrings";
import authSagas from './Auth';
import clientSaga from './clients/SagaClients';
import eLearningReports from './ELearningReports';
import SurveyTemplates from './survey/template/sagaSurveyTemplates';
import SurveyQuestionTypes from './survey/questionTypes/SagaQuestionTypes'
import SagaSurveyQuestionBank from './survey/questionBank/sagaSurveyQuestionBank'
import SurveyCategories from './survey/category/SurveyCategories';
import SurveyQuestions from './survey/questions/sagaSurveyQuestions';
import NewSurvey from './survey/survey/sagaNewSurvey';
import ClientPIC from './adminSettings/clientPIC/SagaClientPIC';
import SagaUserSettings from './user/settings/sagaUserSettings';
import Translations from "./Translations";
import IndustryTypes from "./adminSettings/Industry/SagaIndustryType"
import SagaSurveyResponses from "./survey/responses/SagaSurveyResponses";
import AccountManagers from "./adminSettings/accountManagers/sagaAccountManagers";
import SurveyQuickInsights from "./survey/quickInsights/sagaSurveyQuickInsights";
import SagaTranslationCategories from "./adminSettings/translations/categories/sagaTranslationCategories";
import SagaSurveyCloneHandler from "./survey/clone/sagaSurveyCloneHandler";
import SagaTranslationSubmodules from "./adminSettings/translations/submodules/sagaTranslationSubmodules";
import SagaTranslationsModules from "./adminSettings/translations/modules/sagaTranslationModules";
import SagaSurveySummaryTable from "./survey/summaryTable/sagaSurveySummaryTable";
import SagaSurveyReportsRawdata from "./reports/survey/rawdata/sagaSurveyRawdata";
import SagaConnectReportsRawdata from "./reports/connect/rawdata/sagaConnectRawdata";
import connectSaga from "./connect/connectSaga";
import SagaSurveyReportingCategories from "./survey/reportingCategory/sagaSurveyReportingCategory";
import SurveyTemplateQuickInsights from './survey/quickInsights/sagaSurveyTemplateQuickInsights';
import SagaSurveyTemplateReportsRawdata from './reports/template/rawdata/sagaSurveyTemplateRawdata';
import SagaSurveyTemplateSummaryTable from "./survey/summaryTable/sagaSurveyTemplateSummaryTable";
import SagaConnectSummaryTableDownload from './reports/connect/summaryTable/sagaConnectSummaryTableDownload';
import SagaAdminControlsActivateSurvey from './adminControls/survey/sagaAdminControlsActivateSurvey';
import SagaCompanyPostRawData from './reports/companyPost/sagaCompanyPostRawData';
import SagaClientModules from './clientModules/sagaClientModules';
import SagaTranslationFonts from './adminSettings/translations/fonts/sagaTranslationFonts';
import SagaSurveyManageTranslations from './survey/translations/sagaSurveyManageTranslations';
import SagaWorkerContacts from './wc/sagaWorkerContacts';
import SagaWoVoClients from './clients/SagaWoVoClients';
import SagaContactSettings from './contactSettings/sagaContactSettings';
import SagaCounties from './countries/sagaCountries';
import SagaPayslip from './payslip/sagaPayslip';
import SagaViewHistory from './survey/survey/sagaViewHistory';
import SagaResetRegistration from './wc/resetRegistration/sagaResetRegistration';
import SagaSurveyInvite from './survey/surveyInvite/sagaSurveyInvite';
import SagaMaintenanceBanner from './adminSettings/maintenanceBanner/sagaMaintenanceBanner';

export default function* rootSaga(getState) {
    yield all([
        SagaContactSettings(),
        SagaWoVoClients(),
        SagaWorkerContacts(),
        SagaUserSettings(),
        SagaTranslationStrings(),
        SagaSurveyResponses(),
        SurveyQuestions(),
        SurveyTemplates(),
        SurveyQuestionTypes(),
        SagaSurveyQuestionBank(),
        NewSurvey(),
        authSagas(),
        clientSaga(),
        eLearningReports(),
        SurveyCategories(),
        Translations(),
        SagaTranslationCategories(),
        SagaTranslationsModules(),
        SagaTranslationSubmodules(),
        AccountManagers(),
        ClientPIC(),
        IndustryTypes(),
        SurveyQuickInsights(),
        SagaSurveyCloneHandler(),
        SagaSurveyReportsRawdata(),
        SagaSurveySummaryTable(),
        connectSaga(),
        SagaConnectReportsRawdata(),
        SagaSurveyReportingCategories(),
        SurveyTemplateQuickInsights(),
        SagaSurveyTemplateReportsRawdata(),
        SagaSurveyTemplateSummaryTable(),
        SagaConnectSummaryTableDownload(),
        SagaAdminControlsActivateSurvey(),
        SagaCompanyPostRawData(),
        SagaClientModules(),
        SagaTranslationFonts(),
        SagaSurveyManageTranslations(),
        SagaCounties(),
        SagaPayslip(),
        SagaResetRegistration(),
        SagaSurveyInvite(),
        SagaViewHistory(),
        SagaMaintenanceBanner()
    ]);
}
